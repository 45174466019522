
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import { BillingItem } from "@/pages/Billing/types";

const pageSize = 20;

export default defineComponent({
  name: "BillingList",
  props: {
    billing: {
      type: Object as PropType<Array<BillingItem>>,
      default: () => new Array<BillingItem>(),
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
    granularity: {
      type: String,
      default: () => "day",
    },
  },
  watch: {
    billing(newValue: Array<BillingItem> | null | undefined) {
      if (!newValue) {
        this.currentBilling = [];
        this.currentIndex = 0;
      } else {
        this.currentBilling = newValue.slice(0, pageSize - 1);
        this.currentIndex = pageSize - 1;
      }
    },
  },
  data(): { currentBilling: Array<BillingItem>; currentIndex: number } {
    return {
      currentBilling: this.$props.billing?.slice(0, pageSize - 1),
      currentIndex: pageSize - 1,
    };
  },
  computed: {
    hasMore(): boolean {
      return this.currentIndex < this.$props.billing.length;
    },
  },
  methods: {
    loadMore() {
      this.currentBilling.push(
        ...this.$props.billing.slice(
          this.currentIndex,
          this.currentIndex + pageSize
        )
      );
      this.currentIndex += pageSize;
    },
  },
});
