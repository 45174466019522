import {
  ExperimentConfig,
  ExperimentState,
  ExperimentStatus,
  ExperimentType,
  IBestKpi,
  IDatasourceConfig,
  IExperiment,
  IFolder,
  IKpi,
  TimeSeriesIdentifiersCandidate,
} from "../types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { State } from "@/store";
import { $http } from "@/main";
import { isNil } from "@/common/utils";
import { DatasourceDetail } from "@/types";
import _ from "lodash";
import { ROOT_FOLDER } from "@/common/constant";
import { convertToSummaryMetric } from "./metrics";
import request from "axios";

const EXP_CONFIG: ExperimentConfig = {
  endDate: undefined,
  startDate: undefined,
  forecastHorizon: 14,
  gap: 0,
  targetColumn: "",
  timeSeriesColumn: "",
  timeSeriesIdentifiers: [],
  transactionDsId: "",
  primaryMetric: "",
  otherMetrics: [],
};
const SELECT_DS = {
  id: "",
  name: "",
  description: "",
  schemas: [],
};

const STEPS_DS = "select";
const getDefaultState = (): ExperimentState => {
  return {
    experiments: [],
    loading: false,
    loadCount: 0,
    experimentDetail: undefined,
    datasourceConfig: [],
    datasourceConfigDetail: {},
    tokenExperiments: "",
    tokenFolders: "",
    lastAccessExperiments: [],
    nameNewExperiment: "",
    experimentType: "",
    algorithms: [],
    kpi: [],
    //autoTs
    stepExperiment: STEPS_DS,
    disableSelectButton: true,
    disableSettingButton: true,
    selectDataSource: SELECT_DS,
    experimentConfig: EXP_CONFIG,
    timeSeriesIdentifiersCandidates: [],
    actualData: undefined,
    testData: undefined,
    forecastData: undefined,
    loadingPredictionGraphData: false,
    bestKpi: {},
    currentFolder: undefined,
    folders: [],
  };
};
const ExperimentState: ExperimentState = getDefaultState();

const LIMIT = 20;
const SORT = "createdDate";
const DSC = true;

const experimentMutations: MutationTree<ExperimentState> = {
  SET_EXPERIMENTS(state, experiments: Array<IExperiment>) {
    state.experiments = experiments;
    // state.lastAccessExperiments = state.experiments
    //   .sort((x, y) => (x.lastModifiedDate < y.lastModifiedDate && 1) || -1)
    //   .slice(0, 4);
  },
  ADD_EXPERIMENT(state, experimentObject: IExperiment) {
    state.experiments.push(experimentObject);
  },
  ADD_EXPERIMENTS(state, experiments: IExperiment[]) {
    state.experiments.push(...experiments);
  },
  SET_TOKEN_EXPERIMENTS(state, token) {
    state.tokenExperiments = token;
  },
  SET_EXPERIMENT_TYPE(state, type) {
    state.experimentType = type;
  },
  CHANGE_NAME_EXPERIMENT(state, name) {
    state.nameNewExperiment = name;
  },
  RESET_NAME_EXPERIMENT(state) {
    state.nameNewExperiment = "";
  },
  SET_STEP_EXPERIMENT(state, data) {
    state.stepExperiment = data;
  },
  SET_SHOW_SELECT_BUTTON(state, data) {
    state.disableSelectButton = data;
  },
  SET_SHOW_SETTING_BUTTON(state, data) {
    state.disableSettingButton = data;
  },
  SET_SELECT_DS(state, data) {
    state.selectDataSource = data;
  },
  SET_EXP_CONF(state, data) {
    state.experimentConfig = data;
  },
  CLEAR_CREATE_EXPERIMENT(state) {
    state.stepExperiment = STEPS_DS;
    state.selectDataSource = SELECT_DS;
    state.experimentConfig = EXP_CONFIG;
  },
  SET_EXPERIMENT_DETAIL(state, experiment: IExperiment) {
    state.experimentDetail = experiment;
  },
  SET_FOLDER_NAME(state, name: string) {
    if (state.experimentDetail) {
      state.experimentDetail.folderName = name;
    }
  },
  SET_LOADING(state, payload: boolean) {
    state.loading = payload;
  },
  SET_DATASOURCE_CONFIG(state, payload: IDatasourceConfig[]) {
    state.datasourceConfig = payload;
  },
  SET_DATASOURCE_CONFIG_DETAIL(
    state,
    { id, datasourceDetail }: { id: string; datasourceDetail: DatasourceDetail }
  ) {
    state.datasourceConfigDetail[id] = datasourceDetail;
  },
  SET_TIME_SERIES_IDENTIFIERS_CANDIDATES(
    state,
    {
      columnName,
      candidate,
    }: { columnName: string; candidate: string[] | number[] }
  ) {
    candidate.forEach((e: any) => {
      state.timeSeriesIdentifiersCandidates.push({
        idc: columnName,
        candidate: e,
      });
    });
  },
  RESET_TIME_SERIES_IDENTIFIERS_CANDIDATES(state) {
    state.timeSeriesIdentifiersCandidates = [];
  },
  SET_EXPERIMENT_KPI(state, payload: IKpi[]) {
    state.kpi = payload;
  },
  SET_ACTUAL_DATA(state, payload: any) {
    state.actualData = payload;
  },
  SET_TEST_DATA(state, payload: any) {
    state.testData = payload;
  },
  SET_FORECAST_DATA(state, payload: any) {
    state.forecastData = payload;
  },
  SET_TARGET_MINMAX(state, payload: { min?: number; max?: number }) {
    state.targetColumnMinMax = payload;
  },
  SET_ALGORITHMS(state, payload: string[]) {
    state.algorithms = payload;
  },
  SET_LOADING_PREDICTION_GRAPH(state, payload: boolean) {
    state.loadingPredictionGraphData = payload;
  },
  CLEAR(state) {
    Object.assign(state, getDefaultState());
  },
  SET_BEST_KPI(state, payload: IKpi[]) {
    const bestKpi: IBestKpi = {
      scaled_mean_absolute_error: undefined,
      mean_absolute_error: undefined,
      mean_absolute_percentage_error: undefined,
      root_mean_squared_error: undefined,
    };
    for (const kpi in bestKpi) {
      const kpiValues = payload.filter((x) => {
        const mapKpi = convertToSummaryMetric(x.kpiName);
        return mapKpi.name === kpi;
      });
      const min = _.minBy(kpiValues, "value");
      if (min) {
        bestKpi[kpi] = { value: min?.value, algorithmId: min?.algorithmId };
      }
    }
    state.bestKpi = bestKpi;
  },
  SET_CURRENT_FOLDER(state, payload: IFolder) {
    if (payload) {
      state.currentFolder = payload;
      state.currentFolder.folderPath.push({
        name: payload.name,
        id: payload.id,
      });
    } else {
      state.currentFolder = {
        id: ROOT_FOLDER,
        name: "HOME",
        folderId: ROOT_FOLDER,
        type: ExperimentType.Folder,
        folderPath: [
          {
            name: "HOME",
            id: "ROOT",
          },
        ],
      };
    }
  },
  SET_FOLDERS(state, payload: IFolder[]) {
    state.folders = payload;
  },
  INCREASE_LOAD_COUNT(state) {
    state.loadCount++;
  },
  APPEND_FOLDERS(state, payload: IFolder[]) {
    console.log(state.folders);
    state.folders.push(...payload);
    console.log(state.folders);
  },
  SET_TOKEN_FOLDERS(state, token: string) {
    state.tokenFolders = token;
  },
};

const experimentActions: ActionTree<ExperimentState, State> = {
  loadFolder(
    { commit },
    { id, workspaceId }: { id: string; workspaceId: string }
  ) {
    $http
      .get<IExperiment>(`/metadata/experiment/${id}?workspaceId=${workspaceId}`)
      .then((response) => {
        const experiment = response.data;
        // this.dispatch("identities/loadIdentities", [experiment.createdBy]);
        commit("SET_CURRENT_FOLDER", experiment);
      });
  },
  async loadFolders(
    { commit },
    {
      parentFolderId,
      workspaceId,
    }: { parentFolderId: string; workspaceId: string }
  ) {
    const {
      data: { content: folders, token },
    } = await $http.get(
      `/metadata/experiment/q?folderId=${parentFolderId}&_limit=${32}&type=FOLDER&_sort=${SORT}&_dsc=${DSC}&workspaceId=${workspaceId}`
    );
    commit("SET_FOLDERS", folders);
    commit("SET_TOKEN_FOLDERS", token);
  },
  async loadMoreFolders(
    { state, commit },
    {
      parentFolderId,
      workspaceId,
    }: { parentFolderId: string; workspaceId: string }
  ) {
    const {
      data: { content: folders, token },
    } = await $http.get(
      `/metadata/experiment/q?folderId=${parentFolderId}&_limit=${32}&type=FOLDER&_sort=${SORT}&_dsc=${DSC}&workspaceId=${workspaceId}&_t=${
        state.tokenFolders
      }`
    );
    commit("APPEND_FOLDERS", folders);
    commit("SET_TOKEN_FOLDERS", token);
  },
  loadExperiment(
    { commit },
    { id, workspaceId }: { id: string; workspaceId: string }
  ) {
    $http
      .get<IExperiment>(`/metadata/experiment/${id}?workspaceId=${workspaceId}`)
      .then((response) => {
        const experiment = response.data;
        // this.dispatch("identities/loadIdentities", [experiment.createdBy]);
        if (experiment.folderId !== "ROOT") {
          $http
            .get<IExperiment>(
              `/metadata/experiment/${experiment.folderId}?workspaceId=${workspaceId}`
            )
            .then((folder) => {
              const folderName = folder.data.name;
              commit("SET_EXPERIMENT_DETAIL", experiment);
              commit("SET_FOLDER_NAME", folderName);
            });
        } else {
          commit("SET_EXPERIMENT_DETAIL", experiment);
        }
      });
  },
  async loadExperiments(
    { commit },
    { filter, sort, dsc, folderID, expType, workspaceId }
  ) {
    try {
      commit("SET_LOADING", true);
      if (!sort) {
        sort = SORT;
      }
      if (isNil(dsc)) {
        dsc = DSC;
      }
      let url = "/metadata/experiment/";
      if (filter) {
        url += filter;
      }
      url += `&_limit=${LIMIT}&_sort=${sort}&_dsc=${dsc}&workspaceId=${workspaceId}`;
      if (expType) {
        url += `&type=${expType}`;
      }
      if (folderID) {
        url += `&folderId=${folderID}`;
      }
      const result = await $http.get<{ content: IExperiment[]; token: string }>(
        url
      );
      if (result) {
        // this.dispatch(
        //   "identities/loadIdentities",
        //   new Set(result.data.content.map((ds) => ds.createdBy))
        // );

        commit("SET_EXPERIMENTS", result.data.content);
        commit("SET_TOKEN_EXPERIMENTS", result.data.token);
        commit("INCREASE_LOAD_COUNT");
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async loadMoreExperiments(
    { state, commit },
    { filter, sort, dsc, folderID, expType, workspaceId }
  ) {
    if (!state.loading) {
      commit("SET_LOADING", true);
      try {
        if (!sort) {
          sort = SORT;
        }
        if (isNil(dsc)) {
          dsc = DSC;
        }
        let url = "/metadata/experiment/";
        if (filter) {
          url += filter;
        }
        url += `&_limit=${LIMIT}&_sort=${sort}&_dsc=${dsc}&_t=${state.tokenExperiments}&workspaceId=${workspaceId}`;
        if (expType) {
          url += `&type=${expType}`;
        }
        if (folderID) {
          url += `&folderId=${folderID}`;
        }
        const result = await $http.get<{
          content: IExperiment[];
          token: string;
        }>(url);
        if (result) {
          // this.dispatch(
          //   "identities/loadIdentities",
          //   new Set(result.data.content.map((ds) => ds.createdBy))
          // );

          commit("ADD_EXPERIMENTS", result.data.content);
          commit("SET_TOKEN_EXPERIMENTS", result.data.token);
        }
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_LOADING", false);
      }
    }
  },
  async loadRecentExperiments(
    { commit },
    { sort, dsc, folderID, createdBy, workspaceId }
  ) {
    const limit = 30;
    if (!sort) {
      sort = SORT;
    }
    if (isNil(dsc)) {
      dsc = DSC;
    }
    const result = await $http.get<{ content: IExperiment[]; token: string }>(
      `/metadata/experiment/?createdBy=${createdBy}&_limit=${limit}&_sort=${sort}&_dsc=${dsc}&folderId=${folderID}&workspaceId=${workspaceId}`
    );
    if (result) {
      // this.dispatch(
      //   "identities/loadIdentities",
      //   new Set(result.data.content.map((ds) => ds.createdBy))
      // );

      commit(
        "SET_EXPERIMENTS",
        result.data.content
          .filter((exp) => exp.type != ExperimentType.Folder)
          .slice(0, 8)
      );
      commit("SET_TOKEN_EXPERIMENTS", result.data.token);
    }
  },
  async delete(_, { experimentId, workspaceId }): Promise<boolean> {
    try {
      await $http.delete(
        `/metadata/experiment/${experimentId}?workspaceId=${workspaceId}`
      );
      return true;
    } catch (_: any) {
      return false;
    }
  },
  async folderHasRunningExp(_, { folderId, workspaceId }): Promise<boolean> {
    const { data } = await $http.get(
      `/metadata/experiment/folder-id/${folderId}/exp-running?workspaceId=${workspaceId}`
    );
    return data && data.length;
  },
  deleteAll(
    { dispatch },
    {
      experimentIds,
      workspaceId,
    }: { experimentIds: string[]; workspaceId: string }
  ): Promise<Array<boolean>> {
    return Promise.all(
      experimentIds.map((e) =>
        dispatch("delete", { experimentId: e, workspaceId: workspaceId })
      )
    );
  },
  async createFolder(_, folderData) {
    try {
      if (!folderData) {
        return;
      }
      const folder: IExperiment = (
        await $http.post("/metadata/experiment", folderData)
      ).data;
      return folder;
    } catch (err: any) {
      if (request.isAxiosError(err)) {
        /// TODO: backend need to supply error code instead of error message
        /// for now, checking error message is necessary
        if (
          err.response?.data?.message ===
          "The maximum level of folder could be created is 3"
        ) {
          throw new Error("message.create.folder.error.max_level");
        } else if (
          err.response?.data?.message === "The maximum number of folders is 32"
        ) {
          throw new Error("message.create.folder.error.max_child");
        }
      }

      throw new Error("message.create.folder.error.generic");
    }
  },

  async moveToFolder(
    { dispatch },
    {
      experiment,
      destinationId,
    }: {
      experiment: IExperiment;
      destinationId: string;
    }
  ): Promise<Error | void> {
    try {
      experiment.folderId = destinationId;
      await dispatch("update", experiment);
    } catch (err: any) {
      if (request.isAxiosError(err)) {
        /// TODO: backend need to supply error code instead of error message
        /// for now, checking error message is necessary
        if (
          err.response?.data?.message ===
          "The maximum level of folder could be created is 3"
        ) {
          return new Error("message.move.folder.error.max_level");
        } else if (
          err.response?.data?.message === "The maximum number of folders is 32"
        ) {
          return new Error("message.move.folder.error.max_child");
        }
      }

      return new Error("message.move.error.generic");
    }
  },

  async update({ commit }, experimentData: IExperiment) {
    if (!experimentData) {
      return;
    }

    const experiment: IExperiment = (
      await $http.put("/metadata/experiment", experimentData)
    ).data;
    commit("CLEAR_CREATE_EXPERIMENT");

    return experiment;
  },
  async create(_, experimentData) {
    if (!experimentData) {
      return;
    }

    const experiment: IExperiment = (
      await $http.post("/metadata/experiment", experimentData)
    ).data;
    return experiment;
  },
  async startTrain({ dispatch }, { id, workspaceId }) {
    await $http.post(
      `/workers/job/exp-id/${id}/run?workspaceId=${workspaceId}`,
      {}
    );
    dispatch("loadExperiment", {
      id: id,
      workspaceId: workspaceId,
    });
  },
  async loadDatasourceConfig({ commit }, { id, workspaceId }) {
    async function loadDatasourceDetail(id: string) {
      return (
        await $http.get<{ content: DatasourceDetail }>(
          `/metadata/datasource/${id}?workspaceId=${workspaceId}`
        )
      ).data;
    }

    const datasourceConfigs = (
      await $http.get<{ content: Array<IDatasourceConfig> }>(
        `/metadata/datasource-config/exp-id/${id}?_limit=${LIMIT}&workspaceId=${workspaceId}`
      )
    ).data.content;
    commit("SET_DATASOURCE_CONFIG", datasourceConfigs);
    datasourceConfigs.map((datasourceConfig) =>
      loadDatasourceDetail(datasourceConfig.datasourceId).then((detail) =>
        commit("SET_DATASOURCE_CONFIG_DETAIL", {
          id: datasourceConfig.datasourceId,
          datasourceDetail: detail,
        })
      )
    );
  },
  async loadTargetColumnMinMax(
    { commit },
    {
      id,
      target,
      workspaceId,
    }: {
      id: string;
      target: string;
      workspaceId: string;
    }
  ) {
    try {
      const result = (
        await $http.get<{
          min?: number;
          max?: number;
        }>(
          `/workers/static/ds-id/${id}/candidates/${target}/statistics?workspaceId=${workspaceId}`
        )
      ).data;
      commit("SET_TARGET_MINMAX", result);
    } catch (error) {
      console.error(error);
    }
  },
  async loadKpi(
    { commit },
    {
      id,
      payload,
      workspaceId,
    }: { id: string; payload: any; workspaceId: string }
  ) {
    const result = (
      await $http.post(
        `/reports/experiment/${id}/kpi?workspaceId=${workspaceId}`,
        payload
      )
    ).data;
    commit("SET_EXPERIMENT_KPI", result);
  },
  async loadAvgKpi({ commit }, { id, workspaceId }) {
    const result = (
      await $http.post(
        `/reports/experiment/${id}/kpi?workspaceId=${workspaceId}`,
        {
          keys: {},
        }
      )
    ).data;
    commit("SET_BEST_KPI", result);
  },
  async loadPredictionData(
    { commit },
    {
      id,
      payload,
      workspaceId,
    }: { id: string; payload: any; workspaceId: string }
  ) {
    commit("SET_LOADING_PREDICTION_GRAPH", true);
    const [$actual, $test, $result] = await Promise.all([
      $http.post(
        `/reports/experiment/${id}/actual?workspaceId=${workspaceId}`,
        payload
      ),
      $http.post(
        `/reports/experiment/${id}/test?workspaceId=${workspaceId}`,
        payload
      ),
      $http.post(
        `/reports/experiment/${id}/result?workspaceId=${workspaceId}`,
        payload
      ),
    ]);
    const actual = $actual.data;
    const test = $test.data;
    const result = $result.data;
    const combinedForecast = [...test, ...result];
    commit("SET_ACTUAL_DATA", actual);
    commit("SET_FORECAST_DATA", combinedForecast);
    commit("SET_LOADING_PREDICTION_GRAPH", false);
  },
  async loadPredictionDataforAutoML(
    { commit },
    {
      id,
      payload,
      workspaceId,
      isCompleted,
    }: { id: string; payload: any; workspaceId: string; isCompleted: boolean }
  ) {
    commit("SET_LOADING_PREDICTION_GRAPH", true);
    const $actual = await $http.post(
      `/reports/experiment/${id}/actual?workspaceId=${workspaceId}`,
      payload
    );
    const actual = $actual.data;
    commit("SET_ACTUAL_DATA", actual);
    if (isCompleted) {
      const $test = await $http.post(
        `/reports/experiment/${id}/test?workspaceId=${workspaceId}`,
        payload
      );
      const test = $test.data;
      commit("SET_FORECAST_DATA", test);
    } else {
      commit("SET_FORECAST_DATA", []);
    }
    commit("SET_LOADING_PREDICTION_GRAPH", false);
  },
  async loadActual(
    { commit },
    {
      id,
      key1,
      key2,
      key3,
      workspaceId,
    }: {
      id: string;
      key1: string;
      key2?: string;
      key3?: string;
      workspaceId: string;
    }
  ) {
    commit("SET_ACTUAL_DATA", []);
    const result = (
      await $http.get(
        `/reports/experiment/${id}/actual?key1=${key1}&key2=${key2}&key3=${key3}&workspaceId=${workspaceId}`
      )
    ).data;
    commit("SET_ACTUAL_DATA", result);
  },
  async loadResult(
    { commit },
    {
      id,
      key1,
      key2,
      key3,
      workspaceId,
    }: {
      id: string;
      key1: string;
      key2?: string;
      key3?: string;
      workspaceId: string;
    }
  ) {
    const result = (
      await $http.get<any[]>(
        `/reports/experiment/${id}/test?key1=${key1}&key2=${key2}&key3=${key3}&workspaceId=${workspaceId}`
      )
    ).data;
    commit("SET_FORECAST_DATA", result);
  },
  async loadIdcsCandidate(
    { commit },
    {
      datasourceConfig,
      workspaceId,
    }: { datasourceConfig: IDatasourceConfig; workspaceId: string }
  ) {
    if (datasourceConfig && datasourceConfig.timeSeriesIdentifiers) {
      commit("RESET_TIME_SERIES_IDENTIFIERS_CANDIDATES");
      datasourceConfig.timeSeriesIdentifiers.map(async (columnName) => {
        const candidate = (
          await $http.get<{ content: any[] }>(
            `/workers/static/ds-id/${datasourceConfig.datasourceId}/candidates/${columnName}?workspaceId=${workspaceId}`
          )
        ).data;
        commit("SET_TIME_SERIES_IDENTIFIERS_CANDIDATES", {
          columnName,
          candidate,
        });
      });
    }
  },
  async loadAlgorithms({ commit }, workspaceId: string) {
    const algorithms = (
      await $http.get(`/workers/static/algorithms?workspaceId=${workspaceId}`)
    ).data;
    commit("SET_ALGORITHMS", algorithms);
  },
  async loadIntermittent(_, { id, workspaceId }) {
    try {
      const data = (
        await $http.get(
          `/workers/static/exp-id/${id}/data-cluster?workspaceId=${workspaceId}`
        )
      ).data;
      return data;
    } catch (err) {
      return undefined;
    }
  },
  async loadHistogram(_, { id, workspaceId }) {
    try {
      const data = (
        await $http.get(
          `/workers/static/exp-id/${id}/histogram?workspaceId=${workspaceId}`
        )
      ).data;
      return data;
    } catch (err) {
      return undefined;
    }
  },
  async loadDistribution(_, { id, workspaceId }) {
    try {
      const data = (
        await $http.get(
          `/workers/static/exp-id/${id}/distribution?workspaceId=${workspaceId}`
        )
      ).data;
      return data;
    } catch (err) {
      return undefined;
    }
  },
  async loadDecomposition(_, { payload, workspaceId }) {
    try {
      const data = (
        await $http.get(
          `/workers/static/exp-id/${payload.id}/decomposition/${payload.key}?workspaceId=${workspaceId}`
        )
      ).data;
      return data;
    } catch (err) {
      return undefined;
    }
  },
  async cancelTrain({ dispatch }, { id, workspaceId }) {
    await $http.post(
      `/workers/job/exp-id/${id}/cancel?workspaceId=${workspaceId}`,
      {}
    );
    dispatch("loadExperiment", {
      id: id,
      workspaceId: workspaceId,
    });
  },
  async download(_, { id, workspaceId }) {
    const response = await $http.post(
      `reports/experiment/${id}/actual-predict/download?workspaceId=${workspaceId}`,
      {
        keys: {},
      },
      {
        responseType: "blob",
      }
    );
    const fileName = `${id}.csv`;
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/csv",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    return;
  },
  async downloadActualTestAutoMl(_, { id, workspaceId }) {
    const response = await $http.post(
      `reports/experiment/${id}/actual-test-automl/download?workspaceId=${workspaceId}`,
      {
        keys: {},
      },
      {
        responseType: "blob",
      }
    );
    const fileName = `${id}.csv`;
    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/csv",
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    return;
  },
};

const experimentGetters: GetterTree<ExperimentState, State> = {
  getOneExperiment:
    () =>
    async (id: string, workspaceId: string): Promise<IExperiment> => {
      return (
        await $http.get<IExperiment>(
          `/metadata/experiment/${id}?workspaceId=${workspaceId}`
        )
      ).data;
    },
  getExperimentFiles: () => async (id: string, workspaceId: string) => {
    return (
      await $http.get(`/workers/blob/ds-id/${id}?workspaceId=${workspaceId}`)
    ).data;
  },
  hasMoreExperiment: (state: ExperimentState) => (): boolean => {
    return !isNil(state.tokenExperiments) && state.tokenExperiments.length > 0;
  },
  hasMoreFolders: (state: ExperimentState) => !!state.tokenFolders,
  GetDatasourceId: (state: ExperimentState) => {
    return state.selectDataSource.id;
  },
  GetDatasourceSchema: (state: ExperimentState) => {
    return state.selectDataSource.schemas;
  },
  isCompleted: (state: ExperimentState) => (): boolean => {
    return ExperimentStatus.Completed === state.experimentDetail?.status;
  },
  getCandidate:
    () =>
    async (datasourceId: string, columnName: string, workspaceId: string) => {
      return (
        await $http.get(
          `/workers/static/ds-id/${datasourceId}/candidates/${columnName}?workspaceId=${workspaceId}`
        )
      ).data;
    },
  getCandidateStatistics:
    () =>
    async (datasourceId: string, columnName: string, workspaceId: string) => {
      return (
        await $http.get(
          `/workers/static/ds-id/${datasourceId}/candidates/${columnName}/statistics?workspaceId=${workspaceId}`
        )
      ).data;
    },
  getTransactionalDatasourceConfig: (
    state: ExperimentState
  ): IDatasourceConfig | undefined => {
    return state.datasourceConfig.find(
      (d) => d.datasourceType === "TRANSACTIONAL_DATA"
    );
  },
  getTimeSeriesIdentifiersCandidates:
    (state: ExperimentState) => (idc: string, search: string) => {
      const timeSeriesIdentifiersCandidates =
        state.timeSeriesIdentifiersCandidates.filter(
          (x: TimeSeriesIdentifiersCandidate) => x.idc === idc
        );
      if (search === "") {
        return timeSeriesIdentifiersCandidates
          .splice(0, 500)
          .map((x: TimeSeriesIdentifiersCandidate) => ({
            idc: x.idc,
            candidate: x.candidate?.toString(),
          }));
      }

      return timeSeriesIdentifiersCandidates
        .filter((x: TimeSeriesIdentifiersCandidate) =>
          x.candidate.toString().toLowerCase().includes(search.toLowerCase())
        )
        .splice(0, 500)
        .map((x: TimeSeriesIdentifiersCandidate) => ({
          idc: x.idc,
          candidate: x.candidate?.toString(),
        }));
    },
};

export const experimentsModule: Module<ExperimentState, State> = {
  namespaced: true,
  state: ExperimentState,
  getters: experimentGetters,
  mutations: experimentMutations,
  actions: experimentActions,
};
