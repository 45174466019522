
import { defineComponent } from "@vue/runtime-core";
import { mapActions, mapGetters, mapState } from "vuex";
import { IAutoMLModel } from "@/pages/Experiment/automl/types";
import {
  DatasourceConfig,
  ExperimentStatus,
  IDatasourceConfig,
  IExperiment,
  TimeSeriesIdentifiersCandidate,
} from "@/pages/Experiment/types";
import Timeseries from "@/pages/Experiment/automl/components/model-evaluation/Timeseries.vue";
import NonTimeseries from "@/pages/Experiment/automl/components/model-evaluation/NonTimeseries.vue";

export default defineComponent({
  components: { Timeseries, NonTimeseries },
  data(): {
    selectedModel: string | null;
    timeSeriesIdentifierCandidateOption: TimeSeriesIdentifiersCandidate[][];
    timeSeriesIdentifierCandidateSelectedValue: Array<TimeSeriesIdentifiersCandidate | null>;
  } {
    return {
      selectedModel: "",
      timeSeriesIdentifierCandidateOption: [],
      timeSeriesIdentifierCandidateSelectedValue: [],
    };
  },
  beforeMount() {
    if (this.listModels) {
      this.selectedModel = this.listModels[0];
    }
    if (this.datasourceConfig && this.experimentDetail) {
      this.loadIdcsCandidate({
        datasourceConfig: this.datasourceConfig[0],
        workspaceId: this.experimentDetail.workspaceId,
      });
    }
  },
  computed: {
    ...mapState("autoML", ["models"]),
    ...mapState("experiments", [
      "experimentDetail",
      "timeSeriesIdentifiersCandidates",
      "datasourceConfig",
    ]),
    ...mapGetters("autoML", ["sortedModelsList"]),
    ...mapGetters("experiments", ["getTimeSeriesIdentifiersCandidates"]),
    datasourceAndExp(): {
      datasourceConfig: DatasourceConfig;
      experiment: IExperiment;
    } {
      return {
        datasourceConfig: this.datasourceConfig?.[0],
        experiment: this.experimentDetail,
      };
    },
    idFilter(): Record<string, any> {
      const payload: Record<string, any> = {};
      this.timeSeriesIdentifierCandidateSelectedValue.forEach((e) => {
        if (e !== null) payload[e.idc] = e.candidate;
      });

      return payload;
    },
    predictionType(): string {
      if (this.experimentDetail?.config?.timeSeries) {
        return "timeseries";
      }
      return "nonTimeseries";
    },
    experimentStatus(): number {
      switch (this.experimentDetail.status) {
        case ExperimentStatus.Completed:
          return 1;
        case ExperimentStatus.Failed:
        case ExperimentStatus.Canceled:
          return -1;
        default:
          return 0;
      }
    },
    listModels(): string[] {
      return this.sortedModelsList(
        this.experimentDetail.config?.primaryMetric,
        this.experimentStatus
      );
    },
    model(): IAutoMLModel | null {
      if (this.models?.length) {
        for (const value of this.models) {
          if (value.name === this.selectedModel) {
            return value;
          }
        }
      }
      return null;
    },
  },
  methods: {
    ...mapActions("experiments", ["loadIdcsCandidate"]),
    onFilterChanged(idc: string, index: number, event: any) {
      this.timeSeriesIdentifierCandidateOption[index] =
        this.getTimeSeriesIdentifiersCandidates(idc, event.value);
    },
  },
  watch: {
    datasourceAndExp: {
      handler(
        newValue: {
          datasourceConfig?: IDatasourceConfig;
          experiment?: IExperiment;
        },
        oldValue: {
          datasourceConfig?: IDatasourceConfig;
          experiment?: IExperiment;
        }
      ) {
        if (
          JSON.stringify(newValue.datasourceConfig) !=
            JSON.stringify(oldValue.datasourceConfig) ||
          newValue.experiment?.status != oldValue.experiment?.status
        ) {
          this.loadIdcsCandidate({
            datasourceConfig: newValue.datasourceConfig,
            workspaceId: this.experimentDetail.workspaceId,
          });
        }
      },
      deep: true,
    },
    listModels(newVal) {
      if (!!newVal && newVal.length) {
        this.selectedModel = newVal[0];
      } else {
        this.selectedModel = null;
      }
    },
    timeSeriesIdentifiersCandidates: {
      handler() {
        const idcs = this.datasourceConfig?.[0]?.timeSeriesIdentifiers;
        if (idcs) {
          this.timeSeriesIdentifierCandidateOption = new Array(idcs.length);
          this.timeSeriesIdentifierCandidateSelectedValue = new Array(
            idcs.length
          );
          idcs.forEach((e: any, i: number) => {
            const candidate = this.getTimeSeriesIdentifiersCandidates(e, "");
            this.timeSeriesIdentifierCandidateOption[i] = candidate;
            this.timeSeriesIdentifierCandidateSelectedValue[i] = null;
          });
        }
      },
      deep: true,
    },
  },
});
