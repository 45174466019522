
import { defineComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
export default defineComponent({
  props: {
    workspaceId: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapMutations("experiments", ["CLEAR_CREATE_EXPERIMENT"]),
    createDatasource() {
      this.CLEAR_CREATE_EXPERIMENT();
      this.$router.push(`/workspace/${this.workspaceId}/datasource/create`);
    },
  },
  computed: {
    ...mapGetters("workspace", ["canEdit"]),
  },
});
