
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";
import _ from "lodash";

export default defineComponent({
  props: {
    model: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      settingRange: 1 as number,
      loadingData: true as boolean,
      minSettingRange: 0 as number,
    };
  },
  created() {
    this.loadResidual();
  },
  watch: {
    payloadEvaluation() {
      this.loadResidual();
    },
  },
  computed: {
    ...mapState("application", ["theme"]),
    ...mapState("autoML", ["residual"]),
    ...mapState("experiments", ["experimentDetail"]),
    getResidual() {
      return this.residual;
    },
    payloadEvaluation(): {
      experimentId: string;
      workspaceId: string;
      modelName: string;
    } {
      return {
        experimentId: this.experimentDetail.id || "",
        workspaceId: this.experimentDetail.workspaceId || "",
        modelName: this.model,
      };
    },
    chartResidualHistogram(): unknown {
      const maxResidual = Math.max.apply(
        Math,
        this.residual.map((o: any) => {
          return o.residual;
        })
      );
      let arrayResidual = [];
      for (let i = 0; i < maxResidual; i += this.settingRange) {
        arrayResidual.push(i);
      }
      if (arrayResidual[arrayResidual.length - 1] !== maxResidual) {
        arrayResidual.push(maxResidual);
      }
      let residualByRange = [];

      let countResidualByRange = [];
      for (let i = 0; i < arrayResidual.length - 1; i++) {
        let a = arrayResidual[i];
        let b = arrayResidual[i + 1];
        residualByRange.push("[" + a.toString() + "," + b.toString() + ")");
        let count = 0;
        this.residual.forEach((e: any) => {
          if (e.residual >= a && e.residual <= b) {
            count++;
          }
        });
        countResidualByRange.push(count);
      }
      const sum = _.sum(countResidualByRange);
      const option = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: residualByRange,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Count",
            data: countResidualByRange,
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            label: {
              show: true,
              color: "var(--primary-color)",
              position: "top",
              formatter: (data: any) => {
                const percent = (data.value / sum) * 100;
                if (percent % 2 === 0) {
                  return percent + "%";
                } else {
                  return percent.toFixed(1) + "%";
                }
              },
            },
          },
        ],
      };
      return option;
    },
    chartActualPredictScatter(): unknown {
      const data = this.residual.map((e: any) => [e.actual, e.predict]);
      const option = {
        xAxis: {},
        yAxis: {},
        tooltip: {
          formatter: "{a}: ({c})",
        },
        series: [
          {
            data: data,
            type: "scatter",
          },
        ],
      };
      return option;
    },
    chartActualResidualScatter(): unknown {
      const data = this.residual.map((e: any) => [e.actual, e.residual]);
      const option = {
        xAxis: {},
        yAxis: {},
        tooltip: {
          formatter: "{a}: ({c})",
        },
        series: [
          {
            data: data,
            type: "scatter",
          },
        ],
      };
      return option;
    },
  },
  methods: {
    ...mapActions("autoML", ["loadResidualModel"]),
    loadResidual() {
      this.loadResidualModel(this.payloadEvaluation);
    },
  },
});
