
import { useStore } from "@/store";
import { defineComponent, ref } from "@vue/runtime-core";
import { mapGetters, mapState, mapActions } from "vuex";
import { Datasource, DatasourceType } from "@/pages/Datasource/types";

export default defineComponent({
  data(): {
    selectedDatasource?: Datasource;
    sortOrder: boolean;
    sortField: string;
    search_key: string;
    displayButton: string | undefined;
  } {
    return {
      selectedDatasource: undefined,
      sortOrder: true,
      sortField: "createdDate",
      search_key: "",
      displayButton: "",
    };
  },
  props: {
    workspaceId: {
      type: String,
      required: true,
    },
  },
  emits: ["clickRow", "selectDatasource"],
  setup() {
    const store = useStore();
    const listDatasource: any = ref(null);
    return { store, listDatasource };
  },
  computed: {
    ...mapState("datasources", ["datasources", "loading"]),
    ...mapGetters("datasources", ["hasMoreDatasource"]),
    ...mapGetters("identities", ["getFullname"]),
  },
  created() {
    this.loadDatasources({
      sort: this.sortField,
      dsc: this.sortOrder,
      type: DatasourceType.DATASOURCE,
      workspaceId: this.workspaceId,
    });
  },
  methods: {
    ...mapActions("datasources", ["loadDatasources", "loadMoreDatasources"]),
    loadMoreDatasource(): void {
      this.loadMoreDatasources({
        name: this.search_key,
        sort: this.sortField,
        dsc: this.sortOrder,
        type: DatasourceType.DATASOURCE,
        workspaceId: this.workspaceId,
      });
    },
    async loadDatasourceByName() {
      this.loadDatasources({
        name: this.search_key,
        sort: this.sortField,
        dsc: this.sortOrder,
        type: DatasourceType.DATASOURCE,
        workspaceId: this.workspaceId,
      });
    },
    onSortChange(sortField: string, sortOrder: number) {
      this.loading = true;
      if (sortOrder === 1) {
        this.sortOrder = false;
        this.sortField = sortField;
      } else {
        this.sortOrder = true;
        this.sortField = sortField;
      }
      this.loadDatasources({
        name: this.search_key,
        sort: this.sortField,
        dsc: this.sortOrder,
        type: DatasourceType.DATASOURCE,
        workspaceId: this.workspaceId,
      });
      this.loading = false;
    },
    onRowSelect(selectDs: any) {
      this.displayButton = selectDs.data.id;
      this.$emit("clickRow", selectDs);
    },
    onSort(event: any) {
      this.onSortChange(event.sortField, event.sortOrder);
    },
    handleSelectDs(selectDs: any) {
      this.$emit("clickRow", selectDs);
      this.$emit("selectDatasource");
    },
  },
});
