import { RouteLocation, RouteRecordRaw } from "vue-router";
import SelectDatasource from "../common/components/SelectDatasource/SelectDatasource.vue";
import AutoML from "./components/AutoML.vue";
import ConfigAutoML from "./components/create/ConfigAutoML.vue";
import AutoMLDetail from "./components/AutoMLDetail.vue";
import Evaluation from "./components/evaluation/Evaluation.vue";
import History from "../common/components/History.vue";
import Prediction from "./components/prediction/Prediction.vue";
import ModelEvaluation from "./components/model-evaluation/ModelEvaluation.vue";

export const autoMLRoutes: Array<RouteRecordRaw> = [
  {
    path: "/workspace/:workspaceId/experiment/automl",
    name: "AutoML",
    component: AutoML,
    children: [
      {
        path: "",
        redirect: "/workspace/:workspaceId/experiment",
      },
      {
        path: ":id",
        component: AutoMLDetail,
        props: true,
        meta: {
          breadcrumb: [
            {
              label: "breadcrumb.experiment",
              to: "/workspace/:workspaceId/experiment",
            },
            {
              label: ":folderName",
              to: `/workspace/:workspaceId/experiment?folderId=:folderId`,
            },
            { label: "breadcrumb.detail" },
          ],
        },
        children: [
          {
            path: "",
            redirect: (to: RouteLocation): string => {
              return `/workspace/${to.params.workspaceId}/experiment/automl/${to.params.id}/evaluation`;
            },
          },
          {
            path: "evaluation",
            component: Evaluation,
            props: true,
            meta: {
              breadcrumb: [
                {
                  label: "breadcrumb.experiment",
                  to: "/workspace/:workspaceId/experiment",
                },
                {
                  label: ":folderName",
                  to: `/workspace/:workspaceId/experiment?folderId=:folderId`,
                },
                { label: "breadcrumb.detail" },
              ],
            },
          },
          {
            path: "prediction",
            component: Prediction,
            props: true,
            meta: {
              breadcrumb: [
                {
                  label: "breadcrumb.experiment",
                  to: "/workspace/:workspaceId/experiment",
                },
                {
                  label: ":folderName",
                  to: `/workspace/:workspaceId/experiment?folderId=:folderId`,
                },
                { label: "breadcrumb.detail" },
              ],
            },
          },
          {
            path: "history",
            component: History,
            props: true,
            meta: {
              breadcrumb: [
                {
                  label: "breadcrumb.experiment",
                  to: "/workspace/:workspaceId/experiment",
                },
                {
                  label: ":folderName",
                  to: `/workspace/:workspaceId/experiment?folderId=:folderId`,
                },
                { label: "breadcrumb.detail" },
              ],
            },
          },
          {
            path: "model-evaluation",
            component: ModelEvaluation,
            props: false,
            meta: {
              breadcrumb: [
                {
                  label: "breadcrumb.experiment",
                  to: "/workspace/:workspaceId/experiment",
                },
                {
                  label: ":folderName",
                  to: `/workspace/:workspaceId/experiment?folderId=:folderId`,
                },
                { label: "breadcrumb.detail" },
              ],
            },
          },
        ],
      },
      {
        path: "create",
        props: (route) => ({
          folderId: route.query.folderId,
          ...route.params,
        }),
        component: SelectDatasource,
        meta: {
          breadcrumb: [
            {
              label: "breadcrumb.experiment",
              to: "/workspace/:workspaceId/experiment",
            },
            { label: "breadcrumb.select.datasource" },
          ],
        },
      },
      {
        path: "create/:id",
        props: (route) => ({
          folderId: route.query.folderId,
          ...route.params,
        }),
        component: ConfigAutoML,
        name: "ConfigAUTOML",
        meta: {
          breadcrumb: [
            {
              label: "breadcrumb.experiment",
              to: "/workspace/:workspaceId/experiment",
            },
            {
              label: "breadcrumb.select.datasource",
              to: "/workspace/:workspaceId/experiment/automl/create",
            },
            { label: "breadcrumb.config.automl" },
          ],
        },
      },
    ],
    meta: {
      breadcrumb: [{ label: "breadcrumb.automl" }],
    },
    props: (route) => ({ folderId: route.query.folderId, ...route.params }),
  },
];
