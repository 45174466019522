import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { State } from "@/store";
import { $http } from "@/main";
import _ from "lodash";
import {
  IWorkspace,
  IWorkspaceBasicInfo,
  WorkspaceRole,
  WorkspaceState,
  WorkspaceType,
} from "./types";
import { IExperiment } from "../Experiment/types";
import { Datasource } from "@/types";
import { isNil } from "@/common/utils";

const getDefaultState = (): WorkspaceState => {
  return {
    recentItems: [],
    workspaces: [],
    tenantWorkspaces: [],
    tokenWorkspaces: "",
    workspaceDetail: {
      type: "",
      name: "",
      ownerId: "",
      userRoleList: [],
      createdBy: "",
      createdDate: "",
      desc: "",
      id: localStorage.workspace || "",
      lastModifiedBy: "",
      lastModifiedDate: "",
    },
    privateWorkspace: {
      type: "",
      name: "",
      ownerId: "",
      userRoleList: [],
      createdBy: "",
      createdDate: "",
      desc: "",
      id: localStorage.workspace || "",
      lastModifiedBy: "",
      lastModifiedDate: "",
    },
    userRole: "",
  };
};
const WorkspaceState: WorkspaceState = getDefaultState();

const LIMIT = 20;
const SORT = "createdDate";
const DSC = true;

const workspaceMutations: MutationTree<WorkspaceState> = {
  SET_RECENT_ITEMS(state, payload: Array<IExperiment | Datasource>) {
    state.recentItems = payload;
  },
  SET_PRIVATE_WORKSPACE(state, res: { ws: IWorkspace; userId: string }) {
    if (res.ws && res.ws.id) {
      localStorage.setItem("private_workspace", res.ws.id);
      localStorage.setItem("workspace", res.ws.id);
    }
    state.privateWorkspace = res.ws;
  },
  SET_WORKSPACE_DETAIL(state, res: { ws: IWorkspace; userId: string }) {
    if (res.ws && res.ws.id) {
      localStorage.setItem("workspace", res.ws.id);
    }
    state.workspaceDetail = res.ws;
    if (res.ws) {
      state.userRole = WorkspaceRole.None;
      for (const user of res.ws.userRoleList) {
        if (res.userId === res.ws.ownerId) {
          state.userRole = WorkspaceRole.Admin;
          break;
        } else if (user.userId === res.userId) {
          state.userRole = user.role;
          break;
        }
      }
    } else {
      state.userRole = WorkspaceRole.Admin;
    }
  },
  SET_TENANT_WORKSPACES(state, ws: IWorkspaceBasicInfo[]) {
    state.tenantWorkspaces = ws;
  },
  SET_WORKSPACES(state, ws: IWorkspace[]) {
    state.workspaces = ws;
  },
  ADD_WORKSPACES(state, ws: IWorkspace[]) {
    state.workspaces?.push(...ws);
  },
  SET_TOKEN_WORKSPACES(state, token: string) {
    state.tokenWorkspaces = token;
  },
  CLEAR_WORKSPACE(state) {
    localStorage.removeItem("workspace");
    state.workspaceDetail = {
      type: "",
      name: "",
      ownerId: "",
      userRoleList: [],
      createdBy: "",
      createdDate: "",
      desc: "",
      id: "",
      lastModifiedBy: "",
      lastModifiedDate: "",
    };
  },
  CLEAR_WORKSPACES(state) {
    state.workspaces = [];
  },
  CLEAR_RECENT_ITEMS(state) {
    state.recentItems = [];
  },
  CLEAR(state) {
    Object.assign(state, getDefaultState());
  },
};

const workspaceActions: ActionTree<WorkspaceState, State> = {
  loadRecentItems({ commit }, workspaceId: string) {
    const $autoTS = $http.get<{ content: IExperiment[]; token: string }>(
      `/metadata/experiment/q?_limit=${10}&_sort=${SORT}&_dsc=${DSC}&type=AUTOTS&workspaceId=${workspaceId}`
    );

    const $autoML = $http.get<{ content: IExperiment[]; token: string }>(
      `/metadata/experiment/q?_limit=${10}&_sort=${SORT}&_dsc=${DSC}&type=AUTOML&workspaceId=${workspaceId}`
    );
    const $datasources = $http.get<{ content: Datasource[]; token: string }>(
      `/metadata/datasource/q?_limit=${10}&_sort=${SORT}&_dsc=${DSC}&type=DATASOURCE&workspaceId=${workspaceId}`
    );
    Promise.all([$autoTS, $autoML, $datasources]).then(
      ([autotsExperiments, automlExperiments, datasource]) => {
        const recentItems = _.sortBy(
          [
            ...autotsExperiments.data.content,
            ...automlExperiments.data.content,
            ...datasource.data.content,
          ],
          ["lastModifiedDate"]
        );
        // this.dispatch(
        //   "identities/loadIdentities",
        //   new Set(recentItems.map((item) => item.lastModifiedBy))
        // );
        commit("SET_RECENT_ITEMS", recentItems.reverse().slice(0, 8));
      }
    );
  },
  async loadLatestWorkspace(_, userId: string) {
    if (localStorage.workspace) {
      await this.dispatch(
        "workspace/loadWorkspaceById",
        localStorage.workspace
      );
    } else {
      await this.dispatch("workspace/loadPrivateWorkspace", userId);
    }
  },
  async loadTenantWorkspaces({ commit }) {
    const { data } = await $http.get<Array<IWorkspace>>(
      "/metadata/workspace/all"
    );
    commit("SET_TENANT_WORKSPACES", data);
  },
  async loadPrivateWorkspace({ commit }, userId: string): Promise<IWorkspace> {
    const result = await $http.get<{ content: IWorkspace[] }>(
      `/metadata/workspace/q?ownerId=${userId}&type=PRIVATE&_limit=1&_sort=createdDate&_dsc=true`
    );
    commit("SET_PRIVATE_WORKSPACE", {
      ws: result.data.content[0],
      userId: userId,
    });
    return result.data.content[0];
  },
  async loadWorkspaceById({ commit, rootState }, id: string) {
    const result = await $http.get(`/metadata/workspace/${id}`);
    const ws = result.data;
    const currentUser = rootState.authentication.userId;
    commit("SET_WORKSPACE_DETAIL", { ws: ws, userId: currentUser });
    return result;
    // this.dispatch("identities/loadIdentities", [ws.createdBy]);
  },
  async loadWorkspaces(
    { commit },
    payload: { filter: string; sort: string; dsc: boolean }
  ) {
    let url = "/metadata/workspace/";
    if (payload.filter) {
      url += payload.filter;
    }
    url += `&type=SHARED&_limit=${LIMIT}&_sort=${payload.sort}&_dsc=${payload.dsc}`;

    const result = await $http.get<{ content: IWorkspace[]; token: string }>(
      url
    );

    // this.dispatch(
    //   "identities/loadIdentities",
    //   new Set(result.data.content.map((item) => item.createdBy))
    // );

    commit("SET_WORKSPACES", result.data.content);
    commit("SET_TOKEN_WORKSPACES", result.data.token);
  },
  async loadMoreWorkspaces(
    { state, commit },
    payload: { filter: string; sort: string; dsc: boolean }
  ) {
    let url = "/metadata/workspace/";
    if (payload.filter) {
      url += payload.filter;
    }
    url += `&type=SHARED&_limit=${LIMIT}&_sort=${payload.sort}&_dsc=${payload.dsc}&_t=${state.tokenWorkspaces}`;

    const result = await $http.get<{ content: IWorkspace[]; token: string }>(
      url
    );
    commit("ADD_WORKSPACES", result.data.content);
    commit("SET_TOKEN_WORKSPACES", result.data.token);
  },
  async create(_, workspace: IWorkspace) {
    if (!workspace) {
      return;
    }

    const newWS: IWorkspace = (
      await $http.post("/metadata/workspace", workspace)
    ).data;
    return newWS;
  },
  async update(_, workspace: IWorkspace) {
    if (!workspace) {
      return;
    }

    const newWS: IWorkspace = (
      await $http.put("/metadata/workspace", workspace)
    ).data;
    return newWS;
  },
  async updateMember(_, workspace: IWorkspace) {
    if (!workspace) {
      return;
    }
    await $http.post(
      `/metadata/workspace/${workspace.id}/members`,
      workspace.userRoleList
    );
  },
  async delete({ commit }, workspaceId: string) {
    await $http.delete(`/metadata/workspace/${workspaceId}`);
    commit("CLEAR_WORKSPACE");
  },
};

const workspaceGetters: GetterTree<WorkspaceState, State> = {
  hasMoreWorkspaces: (state: WorkspaceState) => (): boolean => {
    return !isNil(state.tokenWorkspaces) && state.tokenWorkspaces.length > 0;
  },
  isAdmin: (state: WorkspaceState) => (): boolean => {
    return state.userRole === WorkspaceRole.Admin;
  },
  canEdit: (state: WorkspaceState) => (): boolean => {
    return (
      state.userRole === WorkspaceRole.Admin ||
      state.userRole === WorkspaceRole.Member
    );
  },
  isSharedWorkspace: (state: WorkspaceState) => (): boolean => {
    return state.workspaceDetail?.type === WorkspaceType.Shared;
  },
};

export const workspacesModule: Module<WorkspaceState, State> = {
  namespaced: true,
  state: WorkspaceState,
  getters: workspaceGetters,
  mutations: workspaceMutations,
  actions: workspaceActions,
};
