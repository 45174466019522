import { Datasource } from "@/types";
import { IExperiment } from "../Experiment/types";

export interface WorkspaceState {
  recentItems?: Array<IExperiment | Datasource>;
  workspaces?: Array<IWorkspace>;
  tenantWorkspaces: Array<IWorkspaceBasicInfo>;
  tokenWorkspaces: string;
  privateWorkspace: IWorkspace;
  workspaceDetail?: IWorkspace;
  userRole: string;
}

export interface IWorkspace {
  type: string;
  name: string;
  ownerId: string;
  userRoleList: IMember[];
  createdBy: string;
  createdDate: string;
  desc?: string;
  id: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  workspaceId?: string;
}

export interface IWorkspaceBasicInfo {
  id: string;
  name: string;
}

export interface IMember {
  userId: string;
  role: string;
}

export enum WorkspaceType {
  Shared = "SHARED",
  Private = "PRIVATE",
}

export enum WorkspaceRole {
  Admin = "ADMIN",
  Member = "MEMBER",
  Viewer = "VIEWER",
  None = "NONE",
}
