import { createI18n } from "vue-i18n";

const messages = {
  en: require("./en.json"),
  ja: require("./ja.json"),
};

export const i18n = createI18n({
  locale: navigator.language ? navigator.language.slice(0, 2) : "en", // set locale
  fallbackLocale: "en", // set fallback locale
  messages, // set locale messages
  globalInjection: true,
});
