
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "@/store";
import { mapActions, mapState } from "vuex";
import { mapMutations } from "vuex";
import { DatasourceDetail, DatasourceSchema } from "../../types";
import { DatasourceType, getDataRoleFromDataType } from "@/types";
import { RootFolder } from "@/pages/Common/types";

export default defineComponent({
  setup() {
    const store = useStore();
    return { store };
  },
  props: {
    workspaceId: {
      type: String,
    },
  },
  data() {
    return {
      uploading: false,
    };
  },
  computed: {
    ...mapState("datasources", [
      "selectedFile",
      "nameNewDatasource",
      "listTypeData",
      "listRowData",
      "listHeader",
      "showUploadButton",
      "disabled",
      "disabledPreviewButton",
      "selectedSheetName",
      "selectedSheetIndex",
    ]),
    folderId: {
      get(): string {
        return this.$route.query.folderId?.toString() || RootFolder.id;
      },
      set(value: string) {
        this.$router.replace({ query: { folderId: value } });
      },
    },
  },
  watch: {
    nameNewDatasource: function (val) {
      if (val.trim() === "") {
        this.SHOW_UPLOAD(true);
      } else {
        this.SHOW_UPLOAD(false);
      }
    },
  },
  methods: {
    ...mapMutations("datasources", [
      "SHOW_UPLOAD_BUTTON",
      "SET_LIST_ROW_DATA",
      "CLEAR_NEW_DATASOURCE",
      "SET_SHOW_NAME_DATASOURCE",
      "SHOW_UPLOAD",
    ]),
    ...mapActions("datasources", ["createDatasource"]),
    onClear() {
      this.$router.push(`/workspace/${this.workspaceId}/datasource/create`);
      this.CLEAR_NEW_DATASOURCE();
    },
    onPreview() {
      console.log(this.workspaceId);
      this.$router.push(
        `/workspace/${this.workspaceId}/datasource/create/format?folderId=${this.folderId}`
      );
      this.SHOW_UPLOAD_BUTTON(true);
    },
    onBack() {
      this.$router.push(
        `/workspace/${this.workspaceId}/datasource/create/preview?folderId=${this.folderId}`
      );
      this.SHOW_UPLOAD_BUTTON(false);
    },
    onUpload() {
      if (this.nameNewDatasource.trim() === "") {
        this.SET_SHOW_NAME_DATASOURCE(true);
        this.SHOW_UPLOAD(true);
        return;
      }
      this.uploading = true;
      const schemas: DatasourceSchema[] = this.listHeader.map(
        (header: any, index: number) => ({
          index,
          name: header.header,
          type: header.code.type,
          role: getDataRoleFromDataType(header.code.type),
          format: header.code.type === "date" ? header.code.format : "",
        })
      );
      const newDatasource: DatasourceDetail = {
        name: this.nameNewDatasource,
        schemas: schemas,
        type: DatasourceType.DATASOURCE,
        folderId: this.folderId,
        workspaceId: this.workspaceId,
      };
      this.createDatasource({
        newDatasource,
        listTypeData: this.listTypeData,
        listRow: this.listRowData,
        files: this.selectedFile,
      })
        .then((datasource) => {
          this.onClear();
          this.$router.push(
            `/workspace/${this.workspaceId}/datasource/${datasource.id}`
          );
        })
        .finally(() => {
          this.uploading = false;
        });
    },
  },
});
