import { createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "font-icon type",
  style: {"display":"inline-block"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock("span", _hoisted_1, [
    _createVNode("i", {
      class: _ctx.iconClass,
      style: {"font-size":"1.5rem"}
    }, null, 2)
  ], 512)), [
    [
      _directive_tooltip,
      _ctx.$t(_ctx.tooltip),
      void 0,
      { top: true }
    ]
  ])
}