
import Papa, { ParseResult } from "papaparse";
import { getListDataType } from "@/common/utils";
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "@/store";
import { mapActions, mapState } from "vuex";
import { mapMutations } from "vuex";
import { TypeData } from "../../types";
import { setSelectedDelimiter, setSelectedLinebreak } from "@/common/helpers";
import { typeData, typeDate, delimiters, linebreaks } from "@/common/constant";
import { getDataRoleFromDataType } from "@/types";
import { detectEncoding } from "@/common/encoding";
import XLSX from "xlsx";
import { RootFolder } from "@/pages/Common/types";

const MAX_FILE_SIZE = 1024 * 1024 * 1024;

export default defineComponent({
  data() {
    return {
      uploadFile: false,
      totalRow: 0,
      typeData: [] as TypeData[],
      typeDate: [] as TypeData[],
      delimiter: "",
      linebreak: "",
      delimiters: [] as TypeData[],
      selectedLinebreak: "",
      linebreaks: [] as TypeData[],
      hasError: true,
      errorMessage: "",
      data: [] as any[][],
      listHeader: [] as { field: any; header: any }[],
      hasSetMetadata: false,
      maxFileSize: MAX_FILE_SIZE,
    };
  },
  props: {
    workspaceId: {
      type: String,
    },
  },
  created() {
    this.typeData = typeData;
    this.typeDate = typeDate;
    this.delimiters = delimiters;
    this.linebreaks = linebreaks;
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    ...mapState("datasources", [
      "nameNewDatasource",
      "listRowData",
      "listHeader",
      "listTypeData",
      "selectedFile",
      "showUploadButton",
      "selectedHeader",
      "listPreviewData",
      "disabled",
      "showInputDatasource",
    ]),
    folderId: {
      get(): string {
        return this.$route.query.folderId?.toString() || RootFolder.id;
      },
      set(value: string) {
        this.$router.replace({ query: { folderId: value } });
      },
    },
  },
  methods: {
    ...mapMutations("datasources", [
      "SET_LIST_ROW_DATA",
      "SET_LIST_HEADER_DATA",
      "SHOW_UPLOAD_BUTTON",
      "SET_LINEBREAK",
      "SET_DELIMITER",
      "SET_SELECT_LINEBREAK",
      "SET_SELECT_DELIMITER",
      "SET_LIST_REVIEW_DATA",
      "SET_SELECT_HEADER",
      "SET_SELECT_FILE",
      "SET_TOTALROW",
      "SET_NAME_DATASOURCE",
      "SET_ENCODING",
      "SET_WORKBOOK",
    ]),
    ...mapActions("datasources", ["changeWorksheet"]),
    onFileSelect({ files }: { files: File[] }) {
      this.data = [];
      this.listHeader = [];
      this.hasSetMetadata = false;
      this.uploadFile = true;
      if (files[0] != undefined) {
        if (!this.nameNewDatasource || this.nameNewDatasource == "") {
          this.SET_NAME_DATASOURCE(files[0].name);
        }
        if (files[0].name.endsWith(".csv")) {
          this.handleCsvFile(files[0]);
        } else if (
          files[0].name.endsWith(".xlsx") ||
          files[0].name.endsWith(".xls")
        ) {
          this.handleXlsFile(files[0]);
        }
        this.SET_SELECT_FILE(files);
      } else {
        this.uploadFile = false;
      }
    },
    handleCsvFile(file: File) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const contents = new Uint8Array(reader.result as any);

        // detect encoding type
        const encodingType = detectEncoding(contents);
        this.SET_ENCODING(encodingType);
        Papa.parse(file, {
          header: true,
          worker: true,
          preview: 1000,
          encoding: encodingType,
          step: this.onStep,
          complete: this.onComplete,
          skipEmptyLines: true,
          dynamicTyping: true,
          delimiter: ",",
          delimitersToGuess: [
            ",",
            "\t",
            "|",
            ";",
            Papa.RECORD_SEP,
            Papa.UNIT_SEP,
          ],
        });
      };
    },
    async handleXlsFile(file: File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        var ab = e.target?.result;
        const encodingType = detectEncoding(ab);
        this.SET_ENCODING(encodingType);
        const workbook = XLSX.read(new Uint8Array(ab as any), {
          type: "array",
          sheetRows: 1001,
        });
        this.SET_WORKBOOK(workbook);
        this.uploadFile = false;
        this.changeWorksheet(0);
        this.$router.push(
          `/workspace/${this.workspaceId}/datasource/create/preview?folderId=${this.folderId}`
        );
      };
      reader.readAsArrayBuffer(file);
    },
    onStep(results: ParseResult<any>) {
      if (results.errors.length) {
        this.errorMessage = this.$t(`error.${results.errors[0].code}`);
        return;
      }
      this.errorMessage = "";
      if (!this.hasSetMetadata) {
        this.SET_LINEBREAK(
          setSelectedLinebreak(results.meta.linebreak[0]).linebreak
        );
        this.SET_DELIMITER(
          setSelectedDelimiter(results.meta.delimiter[0]).delimiter
        );
        this.SET_SELECT_LINEBREAK(
          setSelectedLinebreak(results.meta.linebreak[0]).selectedLinebreak
        );
        this.SET_SELECT_DELIMITER(
          setSelectedDelimiter(results.meta.delimiter[0]).selectedDelimiter
        );
        if (results.meta && results.meta.fields) {
          this.listHeader = results.meta.fields.map((x: any) => {
            const data = {
              field: x,
              header: x,
            };
            return data;
          });
        }
        this.hasSetMetadata = true;
      }
      if (this.data.length < 1000) {
        this.data.push(results.data);
      }
    },
    onComplete() {
      this.uploadFile = false;
      if (this.data.length) {
        this.SET_TOTALROW(this.data.length);
        this.SET_LIST_ROW_DATA(this.data);
        this.setListHeader(this.data, this.listHeader);
        this.$router.push(
          `/workspace/${this.workspaceId}/datasource/create/preview?folderId=${this.folderId}`
        );
      }
    },

    setListHeader(data: any[], listHeader: any) {
      const typeData = getListDataType(data);
      // Fix bug #1768, if column name is number, it will be reordered so we need to map column and data type based on index of "data", not index of "listHeader"
      const rawListHeader = Object.entries(data[0]).map((x) => x[0]);
      const _listHeader = typeData.map((obj: any, index: number) =>
        Object.assign(
          {},
          obj,
          listHeader.find(
            (h: { field: string; header: string }) =>
              h.field === rawListHeader[index]
          )
        )
      );
      this.SET_LIST_HEADER_DATA(_listHeader);
      const listPreviewData = _listHeader.map((x: any) => {
        return {
          columns: x.header,
          dataType: x.code.type,
          role: getDataRoleFromDataType(x.code.type),
          format: x.code.format,
        };
      });
      this.SET_LIST_REVIEW_DATA(listPreviewData);
      this.SET_SELECT_HEADER(listPreviewData[0]);
    },
  },
});
