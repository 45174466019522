
import Prediction from "../forecast/Prediction.vue";
import RunTable from "../forecast/RunTable.vue";
import { defineComponent } from "vue-demi";
import { useStore } from "@/store";
import { mapActions, mapGetters, mapState } from "vuex";
import Summary from "../../../common/components/Summary.vue";
import {
  TimeSeriesIdentifiersCandidate,
  IDatasourceConfig,
  IAutoTSRun,
  IKpi,
  IJobTreeTable,
  ExperimentStatus,
  ExperimentType,
  IBestKpi,
  ChartAxisOption,
} from "../../../types";
import { convertToSummaryMetric } from "../../../common/metrics";
import { $http } from "@/main";
import ChartSettingsDialog from "@/pages/Experiment/common/components/ChartSettingsDialog.vue";
import { ChartSettings } from "@/pages/Experiment/common/types";

export default defineComponent({
  components: {
    Prediction,
    RunTable,
    Summary,
    ChartSettingsDialog,
  },
  data(): {
    runTableHeaders: string[];
    loading: boolean;
    activeIndex: number;
    timeSeriesIdentifierCandidateOption: TimeSeriesIdentifiersCandidate[][];
    timeSeriesIdentifierCandidateSelectedValue: TimeSeriesIdentifiersCandidate[];
    downloading: boolean;
    // summaryMetrics: SummaryMetric[];
    chartType: string;
    selected: unknown;
    zoom?: unknown;
    chartSettings: ChartSettings;
  } {
    return {
      runTableHeaders: ["Algorithm", "Status", "KPIs"],
      loading: false,
      activeIndex: 0,
      timeSeriesIdentifierCandidateOption: [],
      timeSeriesIdentifierCandidateSelectedValue: [],
      downloading: false,
      chartType: "line",
      selected: undefined,
      chartSettings: {
        selectedChartAxisOption: ChartAxisOption.CustomRange,
        customChartRange: { min: 0 },
        showQuantile: { upper: false, under: false },
        highlightQuantile: { upper: 80, under: 20 },
      },
    };
  },
  props: {
    id: {
      type: String,
    },
    workspaceId: {
      type: String,
    },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  created() {
    if (this.datasourceConfig) {
      this.loadIdcsCandidate({
        datasourceConfig: this.datasourceConfig[0],
        workspaceId: this.workspaceId,
      });
    }
    this.loadJobsByExperimentId({
      experimentId: this.experimentDetail.id,
      expType: ExperimentType.AutoTS,
      workspaceId: this.workspaceId,
    });
    this.loadAvgKpi({
      id: this.experimentDetail.id,
      workspaceId: this.workspaceId,
    });
    this.loadAlgorithms(this.workspaceId);
  },
  watch: {
    datasourceConfig: {
      handler(newValue: IDatasourceConfig[], oldValue: IDatasourceConfig[]) {
        if (
          newValue.length &&
          JSON.stringify(newValue[0]) != JSON.stringify(oldValue[0])
        ) {
          this.loadIdcsCandidate({
            datasourceConfig: newValue[0],
            workspaceId: this.workspaceId,
          });
          this.loadTargetColumnMinMax({
            workspaceId: this.workspaceId,
            target: newValue[0].targetColumn,
            id: newValue[0].datasourceId,
          });
          if (!newValue[0].timeSeriesIdentifiers) {
            this.loadPredictionData({
              id: this.experimentDetail.id,
              payload: {
                keys: {},
                from: this.experimentDetail.config.startDate,
                to: this.experimentDetail.config.endDate,
              },
              workspaceId: this.workspaceId,
            });
            this.loadKpi({
              id: this.experimentDetail.id,
              payload: { keys: {} },
              workspaceId: this.workspaceId,
            });
          }
        }
      },
    },
    timeSeriesIdentifiersCandidates: {
      handler() {
        const idcs = this.datasourceConfig[0]?.timeSeriesIdentifiers;
        if (idcs) {
          this.timeSeriesIdentifierCandidateOption = new Array(idcs.length);
          this.timeSeriesIdentifierCandidateSelectedValue = new Array(
            idcs.length
          );
          idcs.forEach((e: any, i: number) => {
            const candidate = this.getTimeSeriesIdentifiersCandidates(e, "");
            this.timeSeriesIdentifierCandidateOption[i] = candidate;
            this.timeSeriesIdentifierCandidateSelectedValue[i] = candidate[0];
          });
        }
      },
      deep: true,
    },
    timeSeriesIdentifierCandidateSelectedValue: {
      handler(newValue: TimeSeriesIdentifiersCandidate[]) {
        if (!newValue.some((item) => item === undefined)) {
          let payload: { [k: string]: any } = {};
          newValue.forEach((e: TimeSeriesIdentifiersCandidate) => {
            payload[e.idc.toString()] = e.candidate;
          });
          this.loadPredictionData({
            id: this.experimentDetail.id,
            payload: {
              keys: payload,
              from: this.experimentDetail.config.startDate,
              to: this.experimentDetail.config.endDate,
            },
            workspaceId: this.workspaceId,
          });
          this.loadKpi({
            id: this.experimentDetail.id,
            payload: { keys: payload },
            workspaceId: this.workspaceId,
          });
        }
      },
      deep: true,
    },
    experimentDetail(newValue) {
      if (newValue.status !== ExperimentStatus.Completed) {
        this.loadAvgKpi({
          id: newValue.id,
          workspaceId: this.workspaceId,
        });
        let payload: { [k: string]: any } = {};
        if (
          !this.timeSeriesIdentifierCandidateSelectedValue.some(
            (item) => item === undefined
          ) &&
          newValue.config?.timeSeriesIdentifiers.length > 0
        ) {
          this.timeSeriesIdentifierCandidateSelectedValue.forEach(
            (e: TimeSeriesIdentifiersCandidate) => {
              payload[e.idc.toString()] = e.candidate;
            }
          );
        }
        this.loadPredictionData({
          id: newValue.id,
          payload: {
            keys: payload,
            from: newValue.config.startDate,
            to: newValue.config.endDate,
          },
          workspaceId: this.workspaceId,
        });
        this.loadKpi({
          id: newValue.id,
          payload: { keys: payload },
          workspaceId: this.workspaceId,
        });
      }
    },
  },
  computed: {
    ...mapState("experiments", [
      "experimentDetail",
      "datasourceConfig",
      "kpi",
      "bestKpi",
      "timeSeriesIdentifiersCandidates",
      "actualData",
      "loadingPredictionGraphData",
      "datasourceConfigDetail",
      "algorithms",
    ]),
    ...mapState("jobs", ["jobs", "jobTreeTable"]),
    ...mapState("workspace", ["workspaceDetail"]),
    ...mapGetters("experiments", [
      "getTimeSeriesIdentifiersCandidates",
      "targetColumnMinMax",
    ]),

    runData(): IAutoTSRun[] | undefined {
      // grouping KPI by algorithmId
      const res = new Map<string, IAutoTSRun>();
      this.kpi?.forEach((kpi: IKpi) => {
        const algo = res.get(kpi.algorithmId);
        if (algo) {
          algo[kpi.kpiName] = kpi.value;
        } else {
          res.set(kpi.algorithmId, {
            jobId: kpi.jobId,
            algorithmId: kpi.algorithmId,
            [kpi.kpiName]: kpi.value,
          } as IAutoTSRun);
        }
      });
      res.forEach((run, algo) => {
        const targetName = `training_${algo}_pipeline`;
        if (this.jobTreeTable?.length) {
          for (const job of this.jobTreeTable as Array<IJobTreeTable>) {
            const childs = job.children.slice();
            let item;
            while (childs.length) {
              item = childs.pop();
              if (item && item.data.name == targetName) {
                run.startTime = item.data.startedTime;
                run.duration = item.data.duration;
                run.status = item.data.status as ExperimentStatus;
                return;
              }
              if (item?.children) {
                childs.push(...item?.children);
              }
            }
          }
        }
      });

      return Array.from(res.values());
    },
    summaryMetrics() {
      const metrics = [];

      if (this.experimentDetail && this.experimentDetail.config) {
        if (this.experimentDetail.config.primaryMetric) {
          metrics.push((this.experimentDetail as any).config.primaryMetric);
        }
        if (this.experimentDetail.config.otherMetrics) {
          metrics.push(...(this.experimentDetail as any).config.otherMetrics);
        }
      }
      return metrics.map(convertToSummaryMetric);
    },
    summaryKpi() {
      if (this.experimentDetail.kpiScores) {
        const bestKpi: IBestKpi = {
          scaled_mean_absolute_error: undefined,
          mean_absolute_error: undefined,
          mean_absolute_percentage_error: undefined,
          root_mean_squared_error: undefined,
          mean_absolute_error_percent: undefined,
        };
        for (const algoId in this.experimentDetail.kpiScores) {
          const algo = this.experimentDetail.kpiScores[algoId][0];
          for (const kpi in bestKpi) {
            if (algo[kpi] < (bestKpi[kpi]?.value || Number.MAX_VALUE)) {
              bestKpi[kpi] = { value: algo[kpi], algorithmId: algoId };
            }
          }
        }
        return bestKpi;
      } else {
        return this.bestKpi;
      }
    },
    algorithmOptions(): Array<string> {
      const algorithms =
        this.experimentDetail?.config?.modelList || this.algorithms;
      return algorithms;
    },
  },
  methods: {
    ...mapActions("experiments", [
      "loadActual",
      "loadResult",
      "loadPredictionData",
      "loadIdcsCandidate",
      "loadKpi",
      "startTrain",
      "loadAvgKpi",
      "loadAlgorithms",
      "loadTargetColumnMinMax",
    ]),
    ...mapActions("jobs", ["loadJobsByExperimentId"]),
    onfilterChanged(idc: string, index: number, event: any) {
      this.timeSeriesIdentifierCandidateOption[index] =
        this.getTimeSeriesIdentifiersCandidates(idc, event.value);
    },
    toggle(event: any) {
      (this.$refs.op as any).toggle(event);
    },
    toggleChartSettings(event: Event) {
      (this.$refs.chartSettingsOP as any).toggle(event);
    },
    onSaveChartSettings(chartSettings: ChartSettings) {
      this.chartSettings.selectedChartAxisOption =
        chartSettings.selectedChartAxisOption;
      this.chartSettings.customChartRange = {
        ...chartSettings.customChartRange,
      };
      this.chartSettings.highlightQuantile = {
        ...chartSettings.highlightQuantile,
      };
      this.chartSettings.showQuantile = { ...chartSettings.showQuantile };
      this.toggleChartSettings(new Event(""));
    },
    onCancelChartSettings() {
      this.toggleChartSettings(new Event(""));
    },
    async onAlgotithmSelect(event: any) {
      (this.$refs.op as any).hide();
      this.downloading = true;
      await this.downloadPredictionCsv(this.id, event.data);
      this.downloading = false;
    },
    async downloadPredictionCsv(
      expId: string | undefined,
      algotithmName: string
    ) {
      console.log(`Download file csv for algotithm ${algotithmName}`);
      try {
        const downloadUrl = await $http.get<string>(
          `/workers/blob/download-prediction-chart-csv/${expId}/${algotithmName}?workspaceId=${this.workspaceId}`
        );
        window.open(downloadUrl.data, "_blank");
      } catch (error) {
        console.error(error);
      }
    },
  },
});
