import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = {
  key: 1,
  class: "datasource"
}
const _hoisted_3 = { class: "p-fileupload-content" }
const _hoisted_4 = { class: "p-text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.listRowData.length === 0 && !_ctx.uploadFile)
      ? (_openBlock(), _createBlock(_component_FileUpload, {
          key: 0,
          accept: ".csv,.xls,.xlsx",
          onSelect: _ctx.onFileSelect,
          maxFileSize: _ctx.maxFileSize,
          showUploadButton: true,
          multiple: false,
          invalidFileSizeMessage: _ctx.$t('message.error.file_size_exceeded')
        }, null, 8, ["onSelect", "maxFileSize", "invalidFileSizeMessage"]))
      : _createCommentVNode("", true),
    (_ctx.uploadFile)
      ? (_openBlock(), _createBlock("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _createVNode("div", _hoisted_4, [
              _createVNode("h5", null, _toDisplayString(_ctx.$t("upload.datasource.checking")), 1),
              _createVNode(_component_ProgressSpinner, {
                strokeWidth: "3",
                animationDuration: ".5s"
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}