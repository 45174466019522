
import { BarChart } from "echarts/charts";
import {
  AxisPointerComponent,
  GridComponent,
  ToolboxComponent,
  TooltipComponent,
} from "echarts/components";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { defineComponent, ref } from "vue";
import { mapState } from "vuex";

use([
  CanvasRenderer,
  BarChart,
  TooltipComponent,
  ToolboxComponent,
  AxisPointerComponent,
  GridComponent,
]);

export default defineComponent({
  name: "Histogram",
  props: ["data"],
  data(): {
    option: any;
  } {
    return {
      option: undefined,
    };
  },
  async created() {
    if (this.data) {
      const option = ref({
        grid: {
          top: "10%",
          left: "5%",
          right: "5%",
          bottom: "0%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "<strong>{b}</strong>: {c}",
        },
        xAxis: {
          type: "category",
          data: this.data.dateRange,
          axisLabel: { interval: 0, rotate: 45 },
        },
        yAxis: {
          type: "value",
          name: this.data.name,
          nameTextStyle: {
            fontWeight: "bold",
            align: "left",
          },
        },
        series: [
          {
            type: "bar",
            data: this.data.idcsCount,
            label: {
              show: true,
              position: "top",
              // formatter: function (d: any): any {
              //   return (
              //     d.data +
              //     "(" +
              //     Math.round(this.data.idcsPercentage[d.dataIndex]) +
              //     "%)"
              //   );
              // },
            },
          },
        ],
      });
      this.option = option;
    }
  },
  computed: {
    ...mapState("application", ["theme"]),
  },
  methods: {
    isLoading() {
      return this.data;
    },
  },
  watch: {
    data(newValue) {
      const option = ref({
        grid: {
          top: "10%",
          left: "5%",
          right: "5%",
          bottom: "0%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: "<strong>{b}</strong>: {c}",
        },
        xAxis: {
          type: "category",
          data: newValue.dateRange,
          axisLabel: { interval: 0, rotate: 45 },
        },
        yAxis: {
          type: "value",
          name: newValue.name,
          nameTextStyle: {
            fontWeight: "bold",
            align: "left",
          },
        },
        series: [
          {
            type: "bar",
            data: newValue.idcsCount,
            label: {
              show: true,
              position: "top",
              // formatter: function (d: any): any {
              //   return (
              //     d.data +
              //     "(" +
              //     Math.round(this.data.idcsPercentage[d.dataIndex]) +
              //     "%)"
              //   );
              // },
            },
          },
        ],
      });
      this.option = option;
    },
  },
});
