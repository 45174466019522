import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardItem = _resolveComponent("CardItem")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.recentItems, (item) => {
      return (_openBlock(), _createBlock("div", {
        class: "p-col-12 p-md-6",
        key: item.id
      }, [
        _createVNode(_component_router_link, {
          to: _ctx.getLink(item)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CardItem, {
              name: item.name,
              type: item.type ? item.type : 'datasource',
              status: item.status,
              createdBy: item.lastModifiedBy,
              createdDate: item.lastModifiedDate
            }, null, 8, ["name", "type", "status", "createdBy", "createdDate"])
          ]),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 128))
  ]))
}