import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-d-flex p-mb-2" }
const _hoisted_2 = { class: "p-text-nowrap p-text-truncate" }
const _hoisted_3 = {
  key: 0,
  class: "p-d-flex p-jc-center p-ai-center"
}
const _hoisted_4 = {
  key: 1,
  class: "p-d-flex p-jc-center p-ai-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Column = _resolveComponent("Column")!
  const _component_ExperimentTag = _resolveComponent("ExperimentTag")!
  const _component_ExperimentStatus = _resolveComponent("ExperimentStatus")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      (_ctx.canEdit())
        ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
            key: 0,
            disabled: _ctx.selectedColumn.length == 0,
            class: "p-button-rounded p-button-secondary p-button-text p-ml-auto",
            icon: "pi pi-trash",
            onClick: _ctx.onClickDeleteMultiple
          }, null, 8, ["disabled", "onClick"])), [
            [
              _directive_tooltip,
              _ctx.$t('button.delete'),
              void 0,
              { top: true }
            ]
          ])
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_DataTable, {
      value: _ctx.relatedExperiments,
      selectionMode: "multiple",
      frozen: "true",
      onRowSelect: _ctx.onRowSelect,
      sortField: 'lastModifiedDate',
      sortOrder: -1
    }, {
      footer: _withCtx(() => [
        (_ctx.hasMoreExperiment())
          ? (_openBlock(), _createBlock("div", _hoisted_3, [
              _createVNode(_component_Button, {
                label: _ctx.$t('button.more'),
                class: "p-button-text",
                onClick: _ctx.loadMoreExperiments
              }, null, 8, ["label", "onClick"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.relatedExperiments && _ctx.relatedExperiments.length == 0)
          ? (_openBlock(), _createBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("experiment.list-experiment.table.empty")), 1))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        (_ctx.canEdit())
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              style: {"max-width":"3rem"}
            }, {
              header: _withCtx(() => [
                _createVNode(_component_Checkbox, {
                  modelValue: _ctx.isCheckedAll,
                  binary: true,
                  onClick: _ctx.onClickCheckboxAll
                }, null, 8, ["modelValue", "onClick"])
              ]),
              body: _withCtx((slotProps) => [
                _createVNode(_component_Checkbox, {
                  value: slotProps.data,
                  modelValue: _ctx.selectedColumn,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedColumn = $event)),
                  onClick: _ctx.onClickCheckbox
                }, null, 8, ["value", "modelValue", "onClick"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_Column, {
          field: "name",
          header: _ctx.$t('autots.overview.experiment.name'),
          sortable: true
        }, {
          body: _withCtx((slotProps) => [
            _createVNode("div", _hoisted_2, [
              _createVNode(_component_ExperimentTag, {
                style: {"border-radius":"5px"},
                name: slotProps.data.type
              }, null, 8, ["name"]),
              _createVNode("span", null, [
                _createVNode(_component_Button, {
                  class: "p-button-link p-p-0",
                  label: slotProps.data.name,
                  onClick: ($event: any) => (_ctx.openExperiment(slotProps.data)),
                  style: {"color":"var(--text-color)"}
                }, null, 8, ["label", "onClick"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "createdBy",
          header: _ctx.$t('autots.overview.owner')
        }, {
          body: _withCtx((slotProps) => [
            _createVNode("div", null, _toDisplayString(_ctx.getFullname(slotProps.data.createdBy)), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "status",
          header: _ctx.$t('autots.overview.status')
        }, {
          body: _withCtx((slotProps) => [
            _createVNode(_component_ExperimentStatus, {
              status: slotProps.data.status
            }, null, 8, ["status"])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "lastModifiedDate",
          header: _ctx.$t('autots.overview.updated.date'),
          sortable: true
        }, {
          body: _withCtx((slotProps) => [
            _withDirectives(_createVNode("span", null, [
              _createTextVNode(_toDisplayString(_ctx.$moment(slotProps.data.lastModifiedDate).fromNow()), 1)
            ], 512), [
              [_directive_tooltip, 
            _ctx.$moment(slotProps.data.lastModifiedDate).format(
              'YYYY-MM-DD HH:mm:ss'
            )
          ]
            ])
          ]),
          _: 1
        }, 8, ["header"]),
        (_ctx.canEdit())
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 1,
              header: _ctx.$t('autots.overview.action'),
              headerStyle: "width: 10rem; text-align: center",
              bodyStyle: "overflow: visible"
            }, {
              body: _withCtx((slotProps) => [
                _withDirectives(_createVNode(_component_Button, {
                  class: "p-mr-2 p-button-rounded p-button-secondary p-button-text",
                  icon: "pi pi-trash",
                  onClick: ($event: any) => (_ctx.confirmDeleteExperiment($event, slotProps.data))
                }, null, 8, ["onClick"]), [
                  [
                    _directive_tooltip,
                    _ctx.$t('button.delete'),
                    void 0,
                    { top: true }
                  ]
                ])
              ]),
              _: 1
            }, 8, ["header"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["value", "onRowSelect"])
  ], 64))
}