
import { useStore } from "@/store";
import { FileStatus } from "@/types";
import { defineComponent, ref } from "@vue/runtime-core";
import { mapActions, mapGetters, mapState } from "vuex";
import { FileDetail } from "../../types";
export default defineComponent({
  data(): {
    keyword: string;
    curFilesNum: number;
    sortKey: string;
    sortOrder: number;
    sortField: string;
    sortOptions: { label: string; value: string; icon: string }[];
    $reload?: any;
  } {
    return {
      keyword: "",
      curFilesNum: 0,
      sortKey: "createdDate",
      sortOrder: -1,
      sortField: "createdDate",
      sortOptions: [
        {
          label: "label.order.name.asc",
          value: "name",
          icon: "pi pi-sort-alpha-down",
        },
        {
          label: "label.order.name.dsc",
          value: "!name",
          icon: "pi pi-sort-alpha-down-alt",
        },
        {
          label: "label.order.created_date.asc",
          value: "createdDate",
          icon: "pi pi-sort-amount-down",
        },
        {
          label: "label.order.created_date.dsc",
          value: "!createdDate",
          icon: "pi pi-sort-amount-down-alt",
        },
      ],
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    workspaceId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const listFiles: any = ref(null);
    return { store, listFiles };
  },
  created() {
    this.reload();
    this.$reload = setInterval(() => {
      this.reload();
    }, 10000);
  },
  beforeUnmount() {
    if (this.$reload) {
      clearInterval(this.$reload);
    }
  },
  computed: {
    ...mapState("authentication", ["token"]),
    ...mapState("datasources", ["files", "loading", "datasourceDetail"]),
    ...mapGetters("datasources", ["hasMoreFiles"]),
    ...mapGetters("identities", ["getFullname"]),
    ...mapGetters("workspace", ["canEdit"]),
  },
  methods: {
    ...mapActions("datasources", [
      "deleteFile",
      "loadFilesByDsId",
      "loadMoreFilesByDsId",
      "loadDatasourceDetail",
      "loadTotalFiles",
    ]),
    reload() {
      const payload = {
        datasourceId: this.id,
        name: this.keyword,
        sort: this.sortField,
        dsc: this.sortOrder === -1 ? true : false,
        workspaceId: this.workspaceId,
      };
      this.loadFilesByDsId(payload);
    },
    confirmDeleteFile(event: Event, file: FileDetail) {
      event.preventDefault();
      this.$confirm.require({
        message: this.$t(
          "datasource.list-files.dialog.delete.confirm_message",
          {
            name: file.name,
          }
        ),
        header: this.$t("datasource.list-files.dialog.delete.title"),
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: async () => {
          const res = await this.deleteFile({
            fileId: file.id,
            workspaceId: this.workspaceId,
          });
          if (res) {
            this.$toast.add({
              severity: "error",
              summary: this.$t("message.error"),
              detail: this.$t(
                "datasource.list-files.dialog.message.delete_error"
              ),
              life: 3000,
            });
          } else {
            this.loadDatasourceDetail({
              id: this.id,
              workspaceId: this.workspaceId,
            });
            this.loadTotalFiles({
              id: this.id,
              workspaceId: this.workspaceId,
            });
            this.$toast.add({
              severity: "success",
              summary: this.$t("message.successful"),
              detail: this.$t(
                "datasource.list-files.dialog.message.delete_success",
                { name: file.name }
              ),
              life: 3000,
            });
          }
        },
      });
    },
    onSortChange(event: any) {
      const value = event.value.value;
      const sortValue = event.value;
      if (value.indexOf("!") === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);
        this.sortKey = sortValue;
      } else {
        this.sortOrder = 1;
        this.sortField = value;
        this.sortKey = sortValue;
      }
      this.reload();
    },
    downloadFile(data: FileDetail) {
      this.store.dispatch("datasources/downloadFile", {
        id: data.id,
        workspaceId: this.workspaceId,
      });
    },
    loadMoreFiles() {
      const payload = {
        datasourceId: this.id,
        name: this.keyword,
        sort: this.sortField,
        dsc: this.sortOrder === -1 ? true : false,
        workspaceId: this.workspaceId,
      };
      this.loadMoreFilesByDsId(payload);
    },
    isEnableDelete(state: FileStatus) {
      return state === FileStatus.REJECTED || state === FileStatus.COMPLETED;
    },
    isEnableDownload(state: FileStatus) {
      return state === FileStatus.REJECTED || state === FileStatus.COMPLETED;
    },
    addFile(): void {
      this.$router.push(
        `/workspace/${this.workspaceId}/datasource/${this.id}/upload`
      );
    },
    getFileName(data: FileDetail) {
      return (
        data.name +
        (data.sheetName && data.extension.indexOf("xls") >= 0
          ? ` [${data.sheetName}]`
          : "")
      );
    },
  },
});
