
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    status: {
      type: String,
    },
  },
  computed: {
    containerClass(): string {
      return `p-tag-status-${this.status?.toLowerCase()}`;
    },
    iconClass() {
      return ["p-tag-icon"];
    },
  },
});
