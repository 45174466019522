import { RouteRecordRaw } from "vue-router";

export const commonRoutes: Array<RouteRecordRaw> = [
  {
    path: "/:notFound(.*)",
    name: "NotFound",
    component: () => import("./NotFound.vue"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("./Error.vue"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: () => import("./Forbidden.vue"),
    meta: {
      isPublic: true,
    },
  },
];
