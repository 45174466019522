import { RouteRecordRaw } from "vue-router";
import Home from "./components/Home.vue";
import Experiment from "../autots/components/Experiment.vue";

export const experimentRoutes: Array<RouteRecordRaw> = [
  {
    path: "/workspace/:workspaceId/experiment",
    name: "Experiment",
    component: Experiment,
    children: [
      {
        path: "",
        component: Home,
        props: (route) => ({ folderId: route.query.folderId, ...route.params }),
      },
    ],
    props: (route) => ({ folderId: route.query.folderId, ...route.params }),
    meta: {
      breadcrumb: [{ label: "breadcrumb.experiment" }],
    },
  },
];
