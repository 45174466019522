
import { defineComponent, PropType } from "vue";
import ListMember from "./ListMember.vue";
import { IMember, IWorkspace, WorkspaceType } from "../types";
import { mapActions, mapState } from "vuex";
import { maxLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default defineComponent({
  props: {
    mode: {
      type: String,
      default: "create",
    },
    workspace: {
      type: Object as PropType<IWorkspace>,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    ListMember,
  },
  validations() {
    return {
      newName: {
        required,
        maxLength: maxLength(28),
      },
      newDescription: {
        maxLength: maxLength(512),
      },
    };
  },
  data(): {
    showDialog: boolean;
    newName: string;
    newDescription: string;
    newMembers: IMember[];
  } {
    return {
      showDialog: true,
      newName: "",
      newDescription: "",
      newMembers: [],
    };
  },
  created() {
    if (this.workspace) {
      this.newName = this.workspace.name;
      this.newDescription = this.workspace.desc || "";
      this.newMembers = this.workspace.userRoleList;
    }
  },
  computed: {
    ...mapState("authentication", ["userId"]),
    hasError(): boolean {
      return this.newName === "";
    },
    editMemberMode(): boolean {
      return this.mode === "assign" || this.mode === "create";
    },
    editNameMode(): boolean {
      return this.mode === "create" || this.mode === "update";
    },
    viewMemberMode(): boolean {
      return this.mode !== "update";
    },
    header(): string {
      switch (this.mode) {
        case "create":
          return this.$t("workspace.edit.popup.title.create");
        case "update":
          return this.$t("workspace.menu.edit");
        case "view":
          return this.$t("workspace.edit.popup.title.view");
        case "assign":
          return this.$t("workspace.edit.popup.set.member");
        default:
          return " ";
      }
    },
  },
  methods: {
    ...mapActions("workspace", ["create", "update", "updateMember"]),
    handleClose() {
      this.$emit("clicked", false);
      this.showDialog = false;
    },
    updateMembersList(members: IMember[]) {
      this.newMembers = members;
    },
    async createWorkspace() {
      if (!this.v$.newName.$invalid && !this.v$.newDescription.$invalid) {
        const payload = {
          type: WorkspaceType.Shared,
          name: this.newName,
          description: this.newDescription,
          ownerId: this.userId,
        };

        const ws = await this.create(payload);
        ws.userRoleList = this.newMembers;
        await this.updateMember(ws);

        this.showDialog = false;
        this.$emit("clicked", true);
      }
    },
    async updateWorkspace() {
      if (!this.v$.newName.$invalid && !this.v$.newDescription.$invalid) {
        const payload = {
          ...this.workspace,
          name: this.newName,
          desc: this.newDescription,
        };

        await this.update(payload);
        this.showDialog = false;
        this.$emit("clicked", true);
      }
    },
    async updateMembers() {
      const payload = {
        ...this.workspace,
        userRoleList: this.newMembers,
      };

      await this.updateMember(payload);
      this.showDialog = false;
      this.$emit("clicked", true);
    },
  },
});
