import { ActionTree, GetterTree, MutationTree, Module } from "vuex";
import {
  BillingState,
  BillingItem,
  BillingDetailItem,
  ApiBillingItem,
  convertApiModel,
  ApiBillingDetailItem,
  convertDetailApiModel,
} from "./types";
import { State } from "@/store";
import { $http } from "@/main";
import _ from "lodash";
import moment, { Moment } from "moment";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

const getDefaultState = (): BillingState => ({
  loading: {
    report: false,
    details: false,
  },
  downloadingJobs: {},
});
const billingState = getDefaultState();

const billingMutations: MutationTree<BillingState> = {
  SET_BILLING_INFO(state: BillingState, billingInfo: Array<BillingItem>) {
    state.billingItems = billingInfo;
  },
  SET_LOADING_REPORT(state: BillingState, loading: boolean) {
    state.loading.report = loading;
  },
  CLEAR_BILLING_INFO(state: BillingState) {
    state.billingItems = undefined;
  },
  SET_BILLING_DETAILS(
    state: BillingState,
    billingDetails: Array<BillingDetailItem>
  ) {
    state.billingDetails = billingDetails;
  },
  ADD_BILLING_DETAILS(
    state: BillingState,
    billingDetails: Array<BillingDetailItem>
  ) {
    state.billingDetails?.push(...billingDetails);
  },
  SET_TOKEN_BILLING_DETAILS(state: BillingState, token?: string | null) {
    state.tokenBillingDetails = token;
  },
  CLEAR_BILLING_DETAILS(state: BillingState) {
    state.billingDetails = [];
  },
  SET_LOADING_DETAILS(state: BillingState, loading: boolean) {
    state.loading.details = loading;
  },
  ADD_DOWNLOADING_JOB(state: BillingState, { job }: { job: string }) {
    state.downloadingJobs[job] = {
      progress: 0,
      error: false,
    };
  },
  SET_DOWNLOADING_JOB_PROGRESS(
    state: BillingState,
    { job, progress, error }: { job: string; progress: number; error: boolean }
  ) {
    if (!state.downloadingJobs[job]) {
      return;
    }

    state.downloadingJobs[job].progress = progress;
    state.downloadingJobs[job].error = error;
  },
};

const billingActions: ActionTree<BillingState, State> = {
  async loadBillingInfo(
    { commit },
    { from, to }: { from: string; to: string }
  ) {
    commit("SET_LOADING_REPORT", true);
    try {
      /// TODO: integrate with backend API
      const { data } = await $http.get<Array<ApiBillingItem>>(
        `/metadata/billing/report?from=${from}&to=${to}`
      );

      commit("SET_BILLING_INFO", data.map(convertApiModel));
    } catch (err) {
      console.log(err);
    } finally {
      commit("SET_LOADING_REPORT", false);
    }
  },

  async loadBillingDetails(
    { commit },
    {
      from,
      to,
      workspaceId,
      userId,
    }: { from: string; to: string; workspaceId: string; userId: string }
  ) {
    try {
      commit("SET_LOADING_DETAILS", true);

      const workspaceQuery = workspaceId ? `&workspace=${workspaceId}` : "";
      const userQuery = userId ? `&user=${userId}` : "";
      const {
        data: { content, token },
      } = await $http.get<{
        content: Array<ApiBillingDetailItem>;
        token: string;
      }>(
        `/metadata/billing/details?_limit=10&from=${from}&to=${to}${workspaceQuery}${userQuery}`
      );

      commit("SET_TOKEN_BILLING_DETAILS", token);
      commit("SET_BILLING_DETAILS", content?.map(convertDetailApiModel));
    } catch (err) {
      console.log(err);
    } finally {
      commit("SET_LOADING_DETAILS", false);
    }
  },

  async loadMoreBillingDetails(
    { commit, state },
    { from, to, workspaceId, userId }
  ) {
    try {
      commit("SET_LOADING_DETAILS", true);

      const workspaceQuery = workspaceId ? `&workspace=${workspaceId}` : "";
      const userQuery = userId ? `&user=${userId}` : "";
      const {
        data: { content, token },
      } = await $http.get<{
        content: Array<ApiBillingDetailItem>;
        token: string;
      }>(
        `/metadata/billing/details?_limit=10&from=${from}&to=${to}${workspaceQuery}${userQuery}&_t=${state.tokenBillingDetails}`
      );

      commit("SET_TOKEN_BILLING_DETAILS", token);
      commit("ADD_BILLING_DETAILS", content?.map(convertDetailApiModel));
    } finally {
      commit("SET_LOADING_DETAILS", false);
    }
  },

  async downloadDetails(
    { commit, rootGetters, state },
    {
      from,
      to,
      workspaceId,
      userId,
      headerText,
      fileName,
      sheetName,
      linkText,
    }: {
      from: Moment;
      to: Moment;
      workspaceId: string;
      userId: string;
      headerText: Array<string>;
      fileName: string;
      sheetName: string;
      linkText: string;
    }
  ) {
    try {
      const days = moment(to).diff(moment(from), "days");
      const report = new Workbook();
      const sheet = report.addWorksheet(sheetName);
      sheet.columns = [
        {
          header: headerText[0],
          key: "link",
          width: 12,
          style: { font: { underline: true, color: { argb: "FF02A1D1" } } },
        },
        { header: headerText[1], key: "idCount", width: 12 },
        { header: headerText[2], key: "cost", width: 12 },
        { header: headerText[3], key: "experimentName", width: 80 },
        { header: headerText[4], key: "billingDetailsType", width: 20 },
        { header: headerText[5], key: "predictName", width: 60 },
        { header: headerText[6], key: "createdBy", width: 30 },
        { header: headerText[7], key: "completedDate", width: 20 },
        { header: headerText[8], key: "status", width: 13 },
        { header: headerText[9], key: "isDeleted", width: 13 },
        { header: headerText[10], key: "workspaceName", width: 60 },
        { header: headerText[11], key: "workspaceCreatedBy", width: 30 },
        { header: headerText[12], key: "experimentId", width: 40 },
        { header: headerText[13], key: "predictId", width: 40 },
        { header: headerText[14], key: "workspaceId", width: 40 },
      ];
      commit("ADD_DOWNLOADING_JOB", {
        job: "download",
      });
      const host = window.location.protocol + "//" + window.location.host;
      let stop = false;
      let nextToken = state.tokenBillingDetails;

      while (!stop) {
        const workspaceQuery = workspaceId ? `&workspace=${workspaceId}` : "";
        const userQuery = userId ? `&user=${userId}` : "";
        const tokenQuery = nextToken ? `&_t=${nextToken}` : "";
        const {
          data: { content, token },
        } = await $http.get<{
          content: Array<ApiBillingDetailItem>;
          token: string;
        }>(
          `/metadata/billing/details?_limit=10&from=${from}&to=${to}${workspaceQuery}${userQuery}${tokenQuery}`
        );
        const data = content.map(convertDetailApiModel);
        nextToken = token;
        const current = data[0]?.completedDate;

        if (current && days) {
          commit("SET_DOWNLOADING_JOB_PROGRESS", {
            job: "download",
            progress: current.diff(from, "days") / days,
          });
        }
        stop = !token;

        data.forEach((e) =>
          sheet.addRow({
            ...e,
            link: e.isDeleted
              ? ""
              : {
                  text: linkText,
                  hyperlink: host + "//" + e.link,
                },
            createdBy: rootGetters["identities/getFullname"](e.createdBy),
            workspaceCreatedBy: rootGetters["identities/getFullname"](
              e.workspaceCreatedBy
            ),
            completedDate: e.completedDate.format("L LT"),
          })
        );
      }

      sheet.getRow(1).eachCell((cell) => {
        cell.style = { font: { bold: true } };
      });
      const buffer = await report.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), fileName);
      commit("SET_DOWNLOADING_JOB_PROGRESS", { job: "download", progress: 1 });
    } catch (err) {
      commit("SET_DOWNLOADING_JOB_PROGRESS", {
        job: "download",
        progress: 0,
        error: true,
      });
      console.log(err);
    }
  },
};

const billingGetters: GetterTree<BillingState, State> = {
  billingMonthly(state: BillingState): Array<BillingItem> {
    if (!state.billingItems) {
      return [];
    }
    return _.values(
      _.groupBy(state.billingItems, (val) => moment(val.id).format("YYYY/MM"))
    ).map((gr: Array<BillingItem>) =>
      gr.reduce(
        (pv, c) =>
          ({
            id: `${gr[0].id} - ${gr[gr.length - 1].id}`,
            automl: pv.automl + c.automl,
            autots: pv.autots + c.autots,
            automlCost: +(pv.automlCost + c.automlCost).toFixed(2),
            autotsCost: +(pv.autotsCost + c.autotsCost).toFixed(2),
            experiment: pv.experiment + c.experiment,
            experimentCost: pv.experimentCost + c.experimentCost,
          } as BillingItem)
      )
    );
  },
  hasMoreBillingDetails(state: BillingState): boolean {
    return (
      !!state.billingDetails?.length &&
      !state.loading.details &&
      !!state.tokenBillingDetails
    );
  },
};

export const billingModule: Module<BillingState, State> = {
  namespaced: true,
  state: billingState,
  getters: billingGetters,
  actions: billingActions,
  mutations: billingMutations,
};
