import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"height":"150px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chart = _resolveComponent("v-chart")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_v_chart, {
      option: _ctx.option,
      theme: _ctx.theme,
      autoresize: ""
    }, null, 8, ["option", "theme"])
  ]))
}