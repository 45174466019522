
import { defineComponent } from "vue";
import TaskRunning from "@/pages/Common/TaskRunning.vue";
import { mapActions, mapState } from "vuex";

export default defineComponent({
  components: { TaskRunning },
  props: {
    model: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("experiments", ["experimentDetail"]),
  },
  methods: {
    ...mapActions("autoML", ["startFullEvaluation", "loadFullEvaluation"]),
    async startEvaluation() {
      if (
        this.experimentDetail?.id &&
        this.experimentDetail?.workspaceId &&
        this.model
      ) {
        this.loading = true;
        await this.startFullEvaluation({
          experimentId: this.experimentDetail?.id,
          workspaceId: this.experimentDetail?.workspaceId,
          modelName: this.model,
        });
        await this.loadFullEvaluation({
          experimentId: this.experimentDetail?.id,
          workspaceId: this.experimentDetail?.workspaceId,
        });
        this.loading = false;
      }
    },
  },
});
