
import { defineComponent } from "vue";
import Empty from "./Empty.vue";
import ResidualChart from "@/pages/Experiment/automl/components/model-evaluation/ResidualChart.vue";
import PredictionVariance from "@/pages/Experiment/automl/components/model-evaluation/PredictionVariance.vue";
import ThresholdTuning from "@/pages/Experiment/automl/components/model-evaluation/ThresholdTuning.vue";
import { mapState } from "vuex";

export default defineComponent({
  components: {
    Empty,
    ResidualChart,
    PredictionVariance,
    ThresholdTuning,
  },
  props: {
    model: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      active: 0 as number,
      evaluationScore: {} as { [key: string]: number },
      features: {} as { [key: string]: number },
      feature: undefined as string | undefined,
    };
  },
  methods: {
    getCssClass(index: number) {
      return this.active === index ? "" : "p-button-text";
    },
    onClick(data: number) {
      this.active = data;
      this.refreshData();
    },
    refreshData() {
      const found = this.fullEvaluation.filter((x: any) => {
        return x.modelName === this.model && x.status === "COMPLETED";
      });
      if (found.length > 0) {
        this.evaluationScore = found[0].evaluationScore;
        this.features = found[0].featureImportance;
      }
    },
    onSelectFeature(feature: string) {
      this.feature = feature;
    },
  },
  computed: {
    ...mapState("experiments", ["experimentDetail"]),
    ...mapState("autoML", ["statusEvaluation", "fullEvaluation"]),
    payloadEvaluation(): {
      experimentId: string;
      workspaceId: string;
      modelName: string;
    } {
      return {
        experimentId: this.experimentDetail?.id,
        workspaceId: this.experimentDetail?.workspaceId,
        modelName: this.model,
      };
    },
    hasEvaluation(): number {
      const found = this.fullEvaluation.filter((x: any) => {
        return x.modelName === this.model;
      });
      if (found.length > 0) {
        if (found[0].status === "COMPLETED") {
          return 1; // Finished
        } else if (found[0].status === "RUNNING") {
          return 0; // Running
        }
      }
      return -1; // Not have evaluation yet
    },
  },
  watch: {
    model() {
      this.feature = undefined;
      this.refreshData();
    },
  },
});
