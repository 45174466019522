
import useVuelidate from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import { defineComponent } from "vue-demi";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import {
  ExperimentDetail,
  ExperimentStatus,
  ExperimentType,
  IJobTreeTable,
} from "../../types";
import LogDialog from "@/pages/Experiment/common/components/LogDialog.vue";

export default defineComponent({
  components: {
    LogDialog,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    workspaceId: {
      type: String,
      required: true,
    },
  },
  validations() {
    return {
      newName: {
        required,
        maxLength: maxLength(64),
      },
      newDescription: {
        maxLength: maxLength(512),
      },
    };
  },
  data(): {
    focusTabIndex: number;
    items: { label: string; icon: string; to: string; display: boolean }[];
    activeEditName: boolean;
    activeEditDescription: boolean;
    newName?: string;
    newDescription?: string;
    $reloadInterval?: number;
  } {
    return {
      focusTabIndex: 0,
      items: [
        {
          label: "automl.tab.evaluation",
          icon: "pi pi-fw pi-home",
          to: `/workspace/${this.workspaceId}/experiment/automl/${this.id}/evaluation`,
          display: true,
        },
        {
          label: "automl.tab.model_evaluation",
          icon: "pi p-fw pi-history",
          to: `/workspace/${this.workspaceId}/experiment/automl/${this.id}/model-evaluation`,
          display: false,
        },
        {
          label: "automl.tab.prediction",
          icon: "pi pi-fw pi-upload",
          to: `/workspace/${this.workspaceId}/experiment/automl/${this.id}/prediction`,
          display: false,
        },
        {
          label: "automl.tab.history",
          icon: "pi pi-fw pi-file",
          to: `/workspace/${this.workspaceId}/experiment/automl/${this.id}/history`,
          display: true,
        },
      ],
      activeEditName: false,
      activeEditDescription: false,
      newName: "",
      newDescription: "",
      $reloadInterval: undefined,
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  computed: {
    ...mapState("experiments", ["experimentDetail"]),
    ...mapState("autoML", ["predictions"]),
    ...mapState("jobs", ["jobTreeTable"]),
    ...mapGetters("experiments", ["getTransactionalDatasourceConfig"]),
    ...mapGetters("workspace", ["canEdit"]),
  },
  created() {
    this.loadExperiment({
      id: this.id,
      workspaceId: this.workspaceId,
    });
    this.loadDatasourceConfig({ id: this.id, workspaceId: this.workspaceId });
    this.loadJobsByExperimentId({
      experimentId: this.id,
      expType: ExperimentType.AutoML,
      workspaceId: this.workspaceId,
    });
    this.loadModels({ experimentId: this.id, workspaceId: this.workspaceId });
    this.loadPredictions({
      experimentId: this.id,
      loading: true,
      workspaceId: this.workspaceId,
    });
    this.loadFullEvaluation({
      experimentId: this.id,
      workspaceId: this.workspaceId,
    });
    this.$reloadInterval = window.setInterval(() => {
      if (
        this.experimentDetail.status === ExperimentStatus.Completed ||
        this.experimentDetail.status === ExperimentStatus.Failed ||
        this.experimentDetail.status === ExperimentStatus.Canceled
      ) {
        window.clearInterval(this.$reloadInterval);
      } else {
        this.loadJobsByExperimentId({
          experimentId: this.id,
          expType: ExperimentType.AutoML,
          workspaceId: this.workspaceId,
        });
        this.loadModels({
          experimentId: this.id,
          workspaceId: this.workspaceId,
        });
      }
    }, 5000);
  },
  methods: {
    ...mapActions("experiments", [
      "loadDatasourceConfig",
      "loadExperiment",
      "loadResult",
      "update",
      "cancelTrain",
    ]),
    ...mapActions("jobs", ["loadJobsByExperimentId"]),
    ...mapActions("autoML", [
      "loadModels",
      "loadPredictions",
      "loadMiniEvalClass",
      "loadFullEvaluation",
    ]),
    ...mapMutations("autoML", { clearAutoML: "CLEAR" }),
    ...mapMutations("experiments", { clearExperiment: "CLEAR" }),
    async updateCurrenName() {
      if (!this.v$.newName.$invalid) {
        if (this.experimentDetail) {
          this.experimentDetail.name = this.newName;
          this.experimentDetail.lastModifiedDate = new Date().toISOString();
          await this.update(this.experimentDetail);
          this.activeEditName = false;
        }
      }
    },
    cancelEditName() {
      this.newName = this.experimentDetail.name;
      this.activeEditName = false;
    },
    async updateDescription() {
      if (!this.v$.newDescription.$invalid) {
        if (this.experimentDetail) {
          this.experimentDetail.description = this.newDescription;
          this.experimentDetail.lastModifiedDate = new Date().toISOString();
          await this.update(this.experimentDetail);
          this.activeEditDescription = false;
        }
      }
    },
    cancelEditDescription() {
      this.newDescription = this.experimentDetail.description;
      this.activeEditDescription = false;
    },
    copyAutoML() {
      const datasource = this.getTransactionalDatasourceConfig;
      this.$router.push({
        name: "ConfigAUTOML",
        params: {
          id: datasource?.datasourceId,
          copiedTargetColumn: datasource?.targetColumn,
          copiedTimeSeriesColumn: datasource?.timeSeriesColumn,
          copiedTimeSeriesIdentifiers: datasource?.timeSeriesIdentifiers,
          copiedFeatureColumns: datasource?.featureColumns,

          copiedName: this.experimentDetail.name,
          copiedPredictionType: this.experimentDetail.config?.predictionType,
          copiedPrimaryMetric: this.experimentDetail.config?.primaryMetric,
          copiedOtherMetrics: this.experimentDetail.config?.otherMetrics,
          copiedTimeLimit: this.experimentDetail.config?.timeLimit,
          copiedTrainMode: this.experimentDetail.config?.trainMode,
          copiedSplitTrainEndDate:
            this.experimentDetail.config?.splitTrainEndDate,
          copiedSplitTestEndDate:
            this.experimentDetail.config?.splitTestEndDate,
          copiedSplitTestStartDate:
            this.experimentDetail.config?.splitTestStartDate,
          copiedSplitTrainStartDate:
            this.experimentDetail.config?.splitTrainStartDate,
          copiedSplitType: this.experimentDetail.config?.splitType,
          copiedTrainRatio: this.experimentDetail.config?.trainRatio,
          copiedTimeSeries: this.experimentDetail.config?.timeSeries,
          copiedFolderId: this.experimentDetail.folderId,
          workspaceId: this.workspaceId,
        },
        query: {
          folderId: this.experimentDetail.folderId,
        },
      });
    },
    confirmCancelTrain(event: Event, id: string) {
      event.preventDefault();

      this.$confirm.require({
        message: this.$t("experiment.dialog.cancel.confirm_message", {
          name: this.experimentDetail?.name,
        }),
        header: this.$t("experiment.dialog.cancel.title"),
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: async () => {
          await this.cancelTrain({ id: id, workspaceId: this.workspaceId });
          this.$toast.add({
            severity: "success",
            summary: this.$t("message.successful"),
            detail: this.$t("experiment.dialog.message.cancel_success"),
            life: 3000,
          });
        },
      });
    },
  },
  watch: {
    experimentDetail(newValue: ExperimentDetail) {
      if (!this.activeEditName) {
        this.newName = newValue?.name;
      }
      if (!this.activeEditDescription) {
        this.newDescription = newValue?.description;
      }
      if (newValue?.status == ExperimentStatus.Completed) {
        this.items[1].display = true;
        this.items[2].display = true;
      }
      if (newValue.config && newValue.config.predictionType !== "regression") {
        this.loadMiniEvalClass({
          experimentId: this.id,
          workspaceId: this.workspaceId,
        });
      }
    },
    jobTreeTable(newValue: Array<IJobTreeTable>) {
      for (const job of newValue) {
        if (
          (job.data?.status === ExperimentStatus.Completed ||
            job.data?.status === ExperimentStatus.Failed ||
            job.data?.status === ExperimentStatus.Canceled) &&
          this.experimentDetail?.status !== ExperimentStatus.Completed &&
          this.experimentDetail?.status !== ExperimentStatus.Failed &&
          this.experimentDetail?.status !== ExperimentStatus.Canceled
        ) {
          this.loadExperiment({
            id: this.id,
            workspaceId: this.workspaceId,
          });
        }
      }
    },
  },
  unmounted() {
    this.clearExperiment();
    this.clearAutoML();
    if (this.$reloadInterval) {
      window.clearInterval(this.$reloadInterval);
    }
  },
});
