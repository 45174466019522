import moment from "moment";

export const extractQuery = (
  param?: string | (string | null)[] | null
): string | null => {
  if (Array.isArray(param)) {
    return param[0];
  } else {
    return param as string;
  }
};

export const getFilterRangeFromQuery = (
  queryFrom?: string | (string | null)[] | null,
  queryTo?: string | (string | null)[] | null
): [Date | null, Date | null] => {
  const rangeFrom = extractQuery(queryFrom);
  const rangeTo = extractQuery(queryTo);

  if (!rangeFrom && !rangeTo) {
    return [moment().startOf("month").toDate(), new Date()];
  }

  const from = moment(rangeFrom);
  const to = moment(rangeTo);

  return [
    from.isValid() ? from.toDate() : null,
    to.isValid() ? to.toDate() : null,
  ];
};

export const getNewQueryFromFilterRange = (
  newValue: [Date | null, Date | null]
): [string, string] => {
  const from = moment(newValue[0]);
  const to = moment(newValue[1]);
  return [
    from.isValid() ? from.format("YYYY-MM-DD") : "",
    to.isValid() ? to.format("YYYY-MM-DD") : "",
  ];
};
