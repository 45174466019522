
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { defineComponent, PropType } from "vue";
import { mapState } from "vuex";
import { IAutoMLModel } from "../../types";
import { KpiName } from "@/pages/Experiment/types";
import { ExperimentStatus, IExperiment } from "@/pages/Experiment/types";
import { toShortNumber } from "@/plugin/utils/shortNumber";

use([CanvasRenderer, PieChart]);

export default defineComponent({
  props: {
    model: {
      type: Object as PropType<IAutoMLModel>,
    },
    experiment: {
      type: Object as PropType<IExperiment>,
    },
  },
  computed: {
    ...mapState("application", ["theme"]),
    isFinished(): boolean {
      return this.experiment?.status === ExperimentStatus.Completed;
    },
    primaryMetric(): string {
      return KpiName[this.experiment?.config?.primaryMetric] || "";
    },
    data(): { name: string; value: string }[] | null {
      if (this.model) {
        return Object.entries(this.model.featureImportance).map((entry) => {
          return { name: entry[0], value: entry[1].toFixed(2) };
        });
      }
      return null;
    },
    option(): unknown {
      return {
        series: [
          {
            type: "pie",
            radius: "80%",
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            data: this.data,
            label: {
              formatter: "{b}({d}%)",
              fontSize: 14,
              lineHeight: 22,
            },
          },
        ],
        grid: {
          left: "3%",
          right: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
          formatter: (params: any) => {
            return `${params.marker}${params.name} <b>${params.percent}%</b>`;
          },
        },
      };
    },
    title(): string {
      return this.$t("automl.evaluation.chart.model.title", {
        model: this.model?.name,
      });
    },
    testScore(): string {
      return this.$t("automl.evaluation.chart.test", {
        metric: this.primaryMetric,
        score:
          this.model?.testScore &&
          toShortNumber(this.model.testScore[this.model.primaryMetric]),
      });
    },
    valScore(): string {
      return this.$t("automl.evaluation.chart.validation", {
        metric: this.primaryMetric,
        score:
          this.model?.valScore &&
          toShortNumber(this.model.valScore[this.model.primaryMetric]),
      });
    },
  },
});
