export function getExperimentIcon(
  id:
    | "datasource"
    | "demand_forecast"
    | "autots"
    | "automl"
    | "DATASOURCE"
    | "DEMAND_FORECAST"
    | "AUTOTS"
    | "AUTOML"
    | "main_ws"
    | "share_ws"
    | string
): string {
  switch (id.toLowerCase()) {
    case "datasource":
      return "icon-datasource";
    case "demand_forecast":
      return "bx bx-bar-chart-alticon-demand-forecast";
    case "autots":
      return "icon-automl";
    case "automl":
      return "icon-automl";
    case "folder":
      return "bx bx-folder";
    case "main_ws":
      return "icon-main-ws";
    case "share_ws":
      return "icon-share-ws";
    default:
      return "";
  }
}
