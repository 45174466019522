
import { defineComponent } from "vue";
import { mapState, useStore } from "vuex";
export default defineComponent({
  props: {
    id: {
      type: String,
    },
  },
  data(): {
    expandedRowGroups: any;
  } {
    return {
      expandedRowGroups: null,
    };
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    ...mapState("jobs", ["jobs", "jobTreeTable"]),
  },
  methods: {
    getTooltip(node: any) {
      if (node.data?.status === "FAILED") {
        return node.data.runObj?.code
          ? node.data.runObj?.code
          : "Child process failed";
      }
      return "";
    },
  },
});
