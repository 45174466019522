
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { RootFolder } from "@/pages/Common/types";
export default defineComponent({
  props: {
    workspaceId: {
      type: String,
    },
  },
  computed: {
    ...mapState("experiments", ["currentFolder"]),
    rootFolderId(): string {
      return RootFolder.id;
    },
  },
});
