
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "@/store";
import { mapState, mapActions } from "vuex";
import {
  SchemaInfor,
  ExperimentType,
  ExperimentStatus,
} from "@/pages/Experiment/types";
import {
  DataRole,
  SettingObject,
  CandidatesTimeSeriesMinMax,
  AutoMLCloneConfig,
} from "@/types";
import AdvancedSetting from "./AdvancedSetting.vue";
import moment from "moment";
import {
  DEFAULT_SETTING_OBJECT,
  PREDICTION_LIST,
  REGRESSION_LIST,
  BINARY_LIST,
  MULTICLASS_LIST,
  TRAIN_TEST_SPLITS,
  TIME_SERIES_DATA,
  RUN_MODE_LIST,
  TIME_LIMIT_LIST,
  PREDICTION,
  TRAINTEST,
  TIMESERIES,
  PREDICTION_BINARY_OPTION,
  PREDICTION_MULTICLASS_OPTION,
  PREDICTION_REGRESSION_OPTION,
} from "@/pages/Experiment/common/constantSettingList";
import { PropType } from "vue";
import _ from "lodash";
import { ROOT_FOLDER } from "@/common/constant";

const DEFAULT_SCHEMA = {
  format: "",
  index: 0,
  name: "",
  type: "",
  role: "",
};

export default defineComponent({
  components: {
    AdvancedSetting,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  props: {
    autoMlName: {
      type: String,
      default: "",
    },
    autoMlTenantId: {
      type: String,
      default: "",
    },
    autoMlDescription: {
      type: String,
      default: "",
    },
    autoMlSchemas: {
      type: Array as PropType<Array<SchemaInfor>>,
      default: () => [],
    },
    copiedConfig: {
      type: Object as PropType<AutoMLCloneConfig>,
    },
    datasourceName: {
      type: String,
      default: "",
    },
    folderId: {
      type: String,
      default: ROOT_FOLDER,
    },
    workspaceId: {
      type: String,
    },
  },
  data(): {
    id: string | string[];
    selectedTargetColumn: SchemaInfor;
    selectedDateColumn: SchemaInfor;
    selectedPredictionType: SettingObject;
    selectedIDCs: Array<SchemaInfor> | undefined;
    isDisableIDCs: boolean;
    showClearTarget: boolean;
    showClearTimeSeries: boolean;
    selectedTrainTestSplit: SettingObject;
    trainTestSplits: Array<SettingObject>;
    selectedTimeseriesOption: SettingObject | undefined;
    timeseriesOptionList: Array<SettingObject>;
    trainPercentage: number | undefined;
    dataSourceInfor: Array<number>;
    startDate: Date;
    endDate: Date;
    trainDateFrom: Date | undefined;
    trainDateTo: Date | undefined;
    testDateFrom: Date | undefined;
    testDateTo: Date | undefined;
    metricOptions: Array<SettingObject>;
    selectedPrimaryMetric: SettingObject;
    otherMetrics: Array<SettingObject>;
    processing: boolean;
    displayAdvancedSetting: boolean;
    selectedAdvancedSettingRunMode: SettingObject;
    selectedAdvancedSettingLimitTime: SettingObject;
    selectedAdvancedSettingColumn: Array<SchemaInfor>;
    invalidTrainDate: boolean;
    invalidTestDate: boolean;
    copySkip: number;
  } {
    return {
      id: this.$route.params.id,
      selectedTargetColumn: DEFAULT_SCHEMA,
      selectedDateColumn: DEFAULT_SCHEMA,
      selectedPredictionType: DEFAULT_SETTING_OBJECT,
      selectedIDCs: undefined,
      isDisableIDCs: false,
      showClearTarget: false,
      showClearTimeSeries: false,
      selectedTrainTestSplit: TRAIN_TEST_SPLITS[0],
      trainTestSplits: TRAIN_TEST_SPLITS,
      selectedTimeseriesOption: undefined,
      timeseriesOptionList: TIME_SERIES_DATA,
      trainPercentage: 80,
      dataSourceInfor: [],
      startDate: moment([1970, 1, 1]).toDate(),
      endDate: moment().toDate(),
      trainDateFrom: undefined,
      trainDateTo: undefined,
      testDateFrom: undefined,
      testDateTo: undefined,
      metricOptions: [],
      selectedPrimaryMetric: DEFAULT_SETTING_OBJECT,
      otherMetrics: [],
      processing: false,
      displayAdvancedSetting: false,
      selectedAdvancedSettingRunMode: RUN_MODE_LIST[0],
      selectedAdvancedSettingLimitTime: TIME_LIMIT_LIST[0],
      selectedAdvancedSettingColumn: [],
      invalidTrainDate: false,
      invalidTestDate: false,
      copySkip: 0,
    };
  },
  watch: {
    autoMlSchemas: {
      async handler() {
        if (this.copiedConfig) {
          if (this.copiedConfig.targetColumn) {
            this.selectedTargetColumn = this.copiedConfig.targetColumn;
            await this.loadCandidatesTarget({
              id: this.id,
              target: this.selectedTargetColumn.name,
              workspaceId: this.workspaceId,
            });
          }

          if (this.copiedConfig.predictionType) {
            this.selectedPredictionType = this.copiedConfig.predictionType;
            this.onChangePredictionType({
              originalEvent: new PointerEvent("click"),
              value: this.copiedConfig.predictionType,
            });
          }

          if (this.copiedConfig.timeSeriesColumn) {
            this.copySkip++;
            this.selectedDateColumn = this.copiedConfig.timeSeriesColumn;
            this.loadCandidatesTimeSeries({
              id: this.id,
              timeSeries: this.selectedDateColumn.name,
              workspaceId: this.workspaceId,
            });
          }

          if (this.copiedConfig.timeSeries) {
            this.selectedTimeseriesOption = this.copiedConfig.timeSeries;
          }

          if (this.copiedConfig.timeSeriesIdentifiers) {
            this.selectedIDCs = this.copiedConfig.timeSeriesIdentifiers;
          }

          if (this.copiedConfig.splitType) {
            if (this.copiedConfig.splitType.key === TRAINTEST.TIMESERIES) {
              this.copySkip++;
            }
            this.selectedTrainTestSplit = this.copiedConfig.splitType;
          }

          if (this.copiedConfig.trainRatio) {
            this.trainPercentage = this.copiedConfig.trainRatio;
          }

          if (this.copiedConfig.splitTrainStartDate) {
            this.trainDateFrom = this.copiedConfig.splitTrainStartDate;
          }

          if (this.copiedConfig.splitTrainEndDate) {
            this.trainDateTo = this.copiedConfig.splitTrainEndDate;
          }

          if (this.copiedConfig.splitTestStartDate) {
            this.testDateFrom = this.copiedConfig.splitTestStartDate;
          }

          if (this.copiedConfig.splitTestEndDate) {
            this.testDateTo = this.copiedConfig.splitTestEndDate;
          }

          if (this.copiedConfig.primaryMetric) {
            this.selectedPrimaryMetric =
              [...BINARY_LIST, ...REGRESSION_LIST, ...MULTICLASS_LIST].find(
                (e) => e.key === this.copiedConfig?.primaryMetric
              ) || REGRESSION_LIST[0];
          }

          if (this.copiedConfig.otherMetrics) {
            // It might have duplicate values because BINARY and MULTICLASS share some metrics
            // We need to remove them
            this.otherMetrics = _.uniqBy(
              [...BINARY_LIST, ...REGRESSION_LIST, ...MULTICLASS_LIST],
              "key"
            ).filter((e) =>
              this.copiedConfig?.otherMetrics?.some((i) => e.key === i)
            );
          }

          if (this.copiedConfig.trainMode) {
            this.selectedAdvancedSettingRunMode = this.copiedConfig.trainMode;
          }

          if (this.copiedConfig.timeLimit) {
            this.selectedAdvancedSettingLimitTime = this.copiedConfig.timeLimit;
          }

          if (this.copiedConfig.featureColumns) {
            this.selectedAdvancedSettingColumn =
              this.copiedConfig.featureColumns;
          }
        }
      },
      deep: true,
    },
    candidatesTimeSeriesMinMax: {
      handler(newValue: CandidatesTimeSeriesMinMax) {
        this.startDate = newValue.min;
        this.endDate = newValue.max;

        this.setTrainTestDate();
      },
      deep: true,
    },
    selectedTimeseriesOption: {
      handler(newValue: SettingObject) {
        if (newValue) {
          if (newValue.key === TIMESERIES.YES) {
            this.isDisableIDCs = false;
            this.selectedTrainTestSplit = this.trainTestSplits[1];
          } else {
            this.isDisableIDCs = true;
            this.selectedIDCs = undefined;
          }
        } else {
          this.selectedIDCs = undefined;
        }
      },
      deep: true,
    },
    selectedTrainTestSplit: {
      handler(newValue: SettingObject) {
        if (newValue.key === TRAINTEST.RANDOM) {
          this.trainDateTo = undefined;
          this.trainDateFrom = undefined;
          this.testDateTo = undefined;
          this.testDateFrom = undefined;
          this.trainPercentage = 80;
        } else {
          this.trainPercentage = undefined;
          this.setTrainTestDate();
        }
      },
      deep: true,
    },
    trainDateTo: function (val: Date) {
      if (this.testDateFrom) {
        this.invalidTrainDate = val.getTime() > this.testDateFrom.getTime();
      }
      if (this.trainDateTo) {
        this.invalidTestDate = val.getTime() < this.trainDateTo.getTime();
      }
    },
    testDateFrom: function (val: Date) {
      if (this.trainDateTo) {
        this.invalidTestDate = val.getTime() < this.trainDateTo.getTime();
      }
      if (this.testDateFrom) {
        this.invalidTrainDate = val.getTime() > this.testDateFrom.getTime();
      }
    },
  },
  computed: {
    ...mapState("datasources", [
      "datasourceProfile",
      "loadingDatasourceProfile",
    ]),
    ...mapState("autoML", [
      "candidatesTimeSeries",
      "loadingCandidatesTimeSeries",
      "candidatesTargetDistinctLength",
      "loadingCandidatesTarget",
      "candidatesTimeSeriesMinMax",
    ]),
    isEnableCreateButton(): boolean {
      return (
        this.autoMlName.trim() !== "" &&
        !this.invalidTrainDate &&
        !this.invalidTestDate &&
        this.selectedTargetColumn.name !== "" &&
        this.selectedPredictionType.key !== "" &&
        ((this.selectedTrainTestSplit.key === TRAINTEST.TIMESERIES &&
          !!this.trainDateFrom &&
          !!this.trainDateTo &&
          !!this.testDateFrom &&
          !!this.testDateTo) ||
          this.selectedTrainTestSplit.key === TRAINTEST.RANDOM)
      );
    },
    getTargetColumn(): Array<SchemaInfor> {
      return this.autoMlSchemas.filter(
        (e: SchemaInfor) =>
          e.role == DataRole.Categorical || e.role == DataRole.Numeric
      );
    },
    getDateColumn(): Array<SchemaInfor> {
      return this.autoMlSchemas.filter(
        (e: SchemaInfor) => e.role == DataRole.DateTime
      );
    },
    getIdcs(): Array<SchemaInfor> {
      return this.autoMlSchemas.filter(
        (e: SchemaInfor) =>
          e.name !== this.selectedTargetColumn.name &&
          e.name !== this.selectedDateColumn.name
      );
    },
    getRemainColumn(): Array<SchemaInfor> {
      return this.getRemaningColumn();
    },
    predictionTypeList(): SettingObject[] {
      var predictionTypes = PREDICTION_LIST;
      if (this.selectedTargetColumn.type == "string") {
        predictionTypes = predictionTypes.filter(
          (e) => e.key != PREDICTION.REGRESSION
        );
      }
      if (this.candidatesTargetDistinctLength > 2) {
        predictionTypes = predictionTypes.filter(
          (e) => e.key != PREDICTION.BINARY
        );
      }
      return predictionTypes;
    },
    primaryMetricOptions(): SettingObject[] {
      // Task 1401 & 1420 & 2059
      return this.metricOptions.filter(
        (e) =>
          e.key !== "mean_absolute_percentage_error" &&
          e.key !== "scaled_mean_absolute_error" &&
          e.key !== "mean_absolute_error_percent"
      );
    },
    optionalMetricOptions(): SettingObject[] {
      // Fix bug 1979 - Do task 2059
      const isTimeSeries =
        this.selectedPredictionType.key === PREDICTION.REGRESSION &&
        this.selectedTimeseriesOption?.key === TIMESERIES.YES;
      let otherMetrics = this.metricOptions.filter(
        (x) => x.key !== this.selectedPrimaryMetric.key
      );
      if (!isTimeSeries) {
        otherMetrics = otherMetrics.filter(
          (x) =>
            x.key !== "scaled_mean_absolute_error" &&
            x.key !== "mean_absolute_error_percent"
        );
      }
      return otherMetrics;
    },
  },
  created() {
    this.loadDatasourceProfile({ id: this.id, workspaceId: this.workspaceId });
  },
  methods: {
    ...mapActions("datasources", ["loadDatasourceProfile", "datasourceConfig"]),
    ...mapActions("autoML", [
      "loadCandidatesTimeSeries",
      "loadCandidatesTarget",
    ]),
    ...mapActions("experiments", ["startTrain", "create"]),
    setTrainTestDate(): void {
      // if triggered via copied config, skip processing
      if (this.copySkip > 0) {
        this.copySkip--;
        return;
      }
      this.trainDateFrom = moment(this.startDate).toDate();
      this.testDateTo = moment(this.endDate).toDate();
      const trainRange =
        (moment(this.endDate).unix() - moment(this.startDate).unix()) * 0.8 +
        moment(this.startDate).unix();
      this.trainDateTo = moment.unix(trainRange).toDate();
      this.testDateFrom = moment.unix(trainRange).add("days", 1).toDate();
    },
    maxOfTestDataEnd(): Date {
      return moment(this.testDateTo).add(-1, "days").toDate();
    },
    isRandom(target: string) {
      return target === TRAINTEST.RANDOM;
    },
    isTimeSeries(target: string) {
      return target === TRAINTEST.TIMESERIES;
    },
    async onChangeTargetColumn(event: {
      originalEvent: PointerEvent;
      value: SchemaInfor;
    }) {
      this.showClearTarget = event.value != null;
      if (event.value != null) {
        this.selectedTargetColumn = event.value;
        await this.loadCandidatesTarget({
          id: this.id,
          target: this.selectedTargetColumn.name,
          workspaceId: this.workspaceId,
        });
      } else {
        this.selectedTargetColumn = DEFAULT_SCHEMA;
        this.selectedPredictionType = DEFAULT_SETTING_OBJECT;
      }
      this.checkTargetLength();
      this.selectedAdvancedSettingColumn = this.getRemaningColumn();
    },
    onChangeSelectDateColumn(datecolumn: SchemaInfor) {
      this.onChangeDateColumn(datecolumn);
    },
    onChangeDropdownDateColumn(event: {
      originalEvent: PointerEvent;
      value: SchemaInfor;
    }) {
      this.onChangeDateColumn(event.value);
    },
    onChangeDateColumn(value: SchemaInfor) {
      this.showClearTimeSeries = value != null;
      if (value != null) {
        this.selectedDateColumn = value;
        this.selectedTrainTestSplit = TRAIN_TEST_SPLITS[1];
        this.loadCandidatesTimeSeries({
          id: this.id,
          timeSeries: this.selectedDateColumn.name,
          workspaceId: this.workspaceId,
        });
        if (this.selectedPredictionType.key === PREDICTION.REGRESSION) {
          this.selectedTimeseriesOption = TIME_SERIES_DATA[0];
        } else {
          this.selectedTimeseriesOption = undefined;
        }
      } else {
        this.selectedDateColumn = DEFAULT_SCHEMA;
        this.selectedTrainTestSplit = TRAIN_TEST_SPLITS[0];
        this.selectedTimeseriesOption = TIME_SERIES_DATA[1];
        this.trainDateTo = undefined;
        this.trainDateFrom = undefined;
        this.testDateTo = undefined;
        this.testDateFrom = undefined;
        this.selectedIDCs = undefined;
      }
    },
    onChangePredictionType(event: {
      originalEvent: PointerEvent;
      value: SettingObject;
    }) {
      if (event.value != null) {
        this.selectedPredictionType = event.value;
        if (this.selectedPredictionType.key === PREDICTION.REGRESSION) {
          this.metricOptions = REGRESSION_LIST;
          this.selectedPrimaryMetric = REGRESSION_LIST[0];
          if (this.selectedDateColumn.name !== "") {
            this.selectedTimeseriesOption = TIME_SERIES_DATA[0];
          } else {
            this.selectedTimeseriesOption = undefined;
          }
        }
        if (this.selectedPredictionType.key === PREDICTION.BINARY) {
          this.metricOptions = BINARY_LIST;
          this.selectedPrimaryMetric = BINARY_LIST[0];
          this.selectedTimeseriesOption = undefined;
        }
        if (this.selectedPredictionType.key === PREDICTION.MULTICLASS) {
          this.metricOptions = MULTICLASS_LIST;
          this.selectedPrimaryMetric = MULTICLASS_LIST[0];
          this.selectedTimeseriesOption = undefined;
        }
        this.otherMetrics = [];
      } else {
        this.selectedPredictionType = DEFAULT_SETTING_OBJECT;
        this.metricOptions = [];
        this.selectedPrimaryMetric = DEFAULT_SETTING_OBJECT;
      }
    },
    getRemaningColumn(): Array<SchemaInfor> {
      return this.autoMlSchemas.filter(
        (e: SchemaInfor) =>
          e.name !== this.selectedTargetColumn.name &&
          e.name !== this.selectedDateColumn.name &&
          e.role !== DataRole.DateTime
      );
    },
    onChangeMetric(event: {
      originalEvent: PointerEvent;
      value: SettingObject;
    }) {
      this.otherMetrics = this.otherMetrics.filter((x) => x !== event.value);
    },
    onAdvancedSetting(event: any) {
      this.selectedAdvancedSettingRunMode =
        event.selectedAdvancedSettingRunMode;
      this.selectedAdvancedSettingLimitTime =
        event.selectedAdvancedSettingLimitTime;
      this.selectedAdvancedSettingColumn = event.selectedAdvancedSettingColumn;
      this.displayAdvancedSetting = false;
    },
    async Complete() {
      try {
        this.processing = true;
        const isTimeSeries =
          this.selectedTimeseriesOption?.key === TIMESERIES.YES;
        let experimentConfig = {
          predictionType: this.selectedPredictionType.key,
          primaryMetric: this.selectedPrimaryMetric.key,
          otherMetrics: this.otherMetrics
            .filter(
              (x) =>
                isTimeSeries ||
                (x.key !== "scaled_mean_absolute_error" &&
                  x.key !== "mean_absolute_error_percent")
            )
            .map((x) => x.key),
          timeLimit: this.selectedAdvancedSettingLimitTime.key,
          trainMode: this.selectedAdvancedSettingRunMode.key,
          splitTrainEndDate: moment(this.trainDateTo).format("YYYY-MM-DD"),
          splitTestEndDate: moment(this.testDateTo).format("YYYY-MM-DD"),
          splitTestStartDate: moment(this.testDateFrom).format("YYYY-MM-DD"),
          splitTrainStartDate: moment(this.trainDateFrom).format("YYYY-MM-DD"),
          splitType: this.selectedTrainTestSplit.key,
          timeSeriesIdentifiers: this.selectedIDCs?.map((x) => x.name),
          trainRatio: this.trainPercentage,
          timeSeries: isTimeSeries,
        };

        const experimentData = {
          name: this.autoMlName,
          description: this.autoMlDescription,
          type: ExperimentType.AutoML,
          status: ExperimentStatus.Setting,
          config: experimentConfig,
          folderId: this.folderId,
          workspaceId: this.workspaceId,
        };

        const autoMlData = await this.create(experimentData);

        const datasourceConfigData = {
          datasourceId: this.id,
          tenantId: this.autoMlTenantId,
          datasourceType: "TRANSACTIONAL_DATA",
          experimentId: autoMlData.id,
          targetColumn: this.selectedTargetColumn.name,
          timeSeriesColumn: this.selectedDateColumn.name,
          timeSeriesFormat: null,
          timeSeriesIdentifiers: this.selectedIDCs?.map((x) => x.name),
          valueColumn: null,
          zipcodeColumn: null,
          featureColumns: this.selectedAdvancedSettingColumn.map((x) => x.name),
          workspaceId: this.workspaceId,
        };

        await this.datasourceConfig(datasourceConfigData);
        await this.startTrain({
          id: autoMlData.id,
          workspaceId: this.workspaceId,
        });
        this.$router.push(
          `/workspace/${this.workspaceId}/experiment/automl/${autoMlData.id}`
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.processing = false;
      }
    },
    onSelectDatasource() {
      this.$router.push(
        `/workspace/${this.workspaceId}/experiment/automl/create?folderId=${this.folderId}`
      );
    },
    openAdvancedSetting() {
      this.displayAdvancedSetting = true;
    },
    checkTargetLength() {
      if (this.candidatesTargetDistinctLength <= 1) {
        // Task #1988 => DO NOTHING
        // this.$toast.add({
        //   severity: "warn",
        //   summary: this.$t("message.warn"),
        //   detail: this.$t(""),
        //   life: 10000,
        // });
        // this.selectedTargetColumn = DEFAULT_SCHEMA;
      } else if (this.candidatesTargetDistinctLength == 2) {
        this.selectedPredictionType = PREDICTION_BINARY_OPTION;
        this.metricOptions = BINARY_LIST;
        this.selectedPrimaryMetric = BINARY_LIST[0];
        this.selectedTimeseriesOption = TIME_SERIES_DATA[1];
      } else if (this.candidatesTargetDistinctLength >= 3) {
        if (this.selectedTargetColumn.role === DataRole.Categorical) {
          this.selectedPredictionType = PREDICTION_MULTICLASS_OPTION;
          this.metricOptions = MULTICLASS_LIST;
          this.selectedPrimaryMetric = MULTICLASS_LIST[0];
          this.selectedTimeseriesOption = TIME_SERIES_DATA[1];
        }
        if (this.selectedTargetColumn.role === DataRole.Numeric) {
          this.selectedPredictionType = PREDICTION_REGRESSION_OPTION;
          this.metricOptions = REGRESSION_LIST;
          this.selectedPrimaryMetric = REGRESSION_LIST[0];
          if (this.selectedDateColumn.name != "") {
            this.selectedTimeseriesOption = TIME_SERIES_DATA[0];
          }
          if (this.selectedTimeseriesOption?.key === TIMESERIES.YES) {
            this.selectedTrainTestSplit = TRAIN_TEST_SPLITS[1];
          } else {
            this.selectedTrainTestSplit = TRAIN_TEST_SPLITS[0];
          }
        }
      }
    },
  },
});
