
import { defineComponent } from "@vue/runtime-core";
import { ScatterChart } from "echarts/charts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { mapState } from "vuex";

use([CanvasRenderer, ScatterChart]);
export default defineComponent({
  props: {
    columnName: {
      type: String,
    },
    data: {
      type: Object,
    },
    xAxis: {
      type: String,
    },
    yAxis: {
      type: String,
    },
  },
  computed: {
    ...mapState("application", ["theme"]),
    option(): any {
      return {
        title: {
          show: false,
        },
        tooltip: {
          trigger: "item",
          formatter: (params: any) => {
            return `<b>${this.xAxis}</b>: ${params.data[0]}<br/><b>${this.yAxis}</b>: ${params.data[1]}`;
          },
          axisPointer: {
            type: "cross",
          },
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "0",
          top: "10",
          containLabel: true,
        },

        dataset: [
          {
            source: this.data,
          },
        ],
        xAxis: {
          axisLabel: {
            hideOverlap: true,
          },
        },
        yAxis: {
          axisLabel: {
            hideOverlap: true,
          },
        },
        series: {
          type: "scatter",
          datasetIndex: 0,
          symbolSize: 6,
        },

        dataZoom: [
          {
            type: "inside",
          },
          {
            type: "inside",
            orient: "vertical",
          },
        ],
      };
    },
  },
});
