
import { useStore } from "@/store";
import {
  Datasource,
  DatasourceDetail,
  DatasourceStatus,
  DatasourceType,
  DropdownOption,
} from "@/types";
import moment from "moment";
import { defineComponent, ref } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { debounce } from "lodash";
import { onBeforeRouteLeave } from "vue-router";
import { IExperiment } from "@/pages/Experiment/types";
import { IFolder, RootFolder } from "@/pages/Common/types";
import { mapMutations } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { maxLength, required } from "@vuelidate/validators";
import Empty from "./Empty.vue";
import FolderBreadcrumb from "./FolderBreadcrumb.vue";
import FolderSelector from "../../../components/FolderSelector.vue";

export default defineComponent({
  setup() {
    const store = useStore();
    const listAutoTS: any = ref(null);
    const leaving = ref(false);
    const op = ref("op");

    onBeforeRouteLeave(() => {
      leaving.value = true;
    });
    return { store, listAutoTS, leaving, op, v$: useVuelidate() };
  },
  components: {
    FolderSelector,
    Empty,
    FolderBreadcrumb,
  },
  validations() {
    return {
      newName: {
        required,
        maxLength: maxLength(64),
      },
    };
  },
  data(): {
    listStatus: Array<string>;
    listType: Array<DatasourceType>;
    timeRanges: Array<DropdownOption<string>>;
    newName?: string;
    folderNewInfo: {
      name: string;
      description: string;
      type: DatasourceType;
      folderId?: string;
      workspaceId: string;
    };
    selectedColumn: Array<Datasource>;
    editingId: string;
    selectedFolder: IFolder;
  } {
    return {
      listStatus: Object.values(DatasourceStatus),
      listType: [DatasourceType.FOLDER, DatasourceType.DATASOURCE],
      timeRanges: [
        { id: "all", name: "autots.overview.time.all" },
        { id: "last7", name: "autots.overview.time.day" },
        { id: "week", name: "autots.overview.time.week" },
        { id: "month", name: "autots.overview.time.month" },
        { id: "year", name: "autots.overview.time.year" },
      ],
      newName: "",
      folderNewInfo: {
        name: "New Folder",
        description: "FOLDER",
        type: DatasourceType.FOLDER,
        folderId: RootFolder.id,
        workspaceId: this.workspaceId,
      },
      selectedColumn: [],
      editingId: "",
      selectedFolder: RootFolder,
    };
  },
  props: {
    workspaceId: {
      type: String,
      required: true,
    },
  },
  async created() {
    await this.loadUniqueUsersOfDatasource(this.workspaceId);
    await this.reload();
  },

  computed: {
    ...mapState("datasources", [
      "datasources",
      "loading",
      "folders",
      "loadCount",
    ]),
    ...mapState("identities", ["uniqueUsersOfDatasources"]),
    ...mapGetters("datasources", ["hasMoreDatasource", "hasMoreFolders"]),
    ...mapGetters("identities", ["getFullname"]),
    ...mapGetters("workspace", ["canEdit"]),
    folderId: {
      get(): string {
        return this.$route.query.folderId?.toString() || RootFolder.id;
      },
      set(value: string) {
        this.$router.replace({ query: { folderId: value } });
      },
    },
    dynamicSortOrder: {
      get(): number {
        return this.$route.query.dsc?.toString() === "false" ? 1 : -1;
      },
      set(value: number) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            dsc: (value < 0) + "",
          },
        });
      },
    },
    dynamicSortField: {
      get(): string {
        return this.$route.query.sort?.toString() || "lastModifiedDate";
      },
      set(value: string | null | undefined) {
        if (!value) {
          value = "lastModifiedDate";
        }
        this.$router.replace({
          query: {
            ...this.$route.query,
            sort: value,
          },
        });
      },
    },
    selectedOwner: {
      get(): string {
        return this.$route.query.owner?.toString() || "";
      },
      set(value: string) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            owner: value,
          },
        });
      },
    },
    selectedType: {
      get(): string {
        return this.$route.query.type?.toString() || "";
      },
      set(value: string) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            type: value,
          },
        });
      },
    },
    selectedStatus: {
      get(): string {
        return this.$route.query.status?.toString() || "";
      },
      set(value: string) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            status: value,
          },
        });
      },
    },
    selectedTimeRange: {
      get(): DropdownOption<string> {
        if (this.$route.query.time) {
          return (
            this.timeRanges.find(
              (t: { id: string }) => t.id === this.$route.query.time
            ) || this.timeRanges[0]
          );
        }
        return this.timeRanges[0];
      },
      set(value: any) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            time: value.id,
          },
        });
      },
    },
    selectedDate(): [string, string] | null {
      if (this.selectedTimeRange.id === "last7") {
        return [
          moment().subtract(7, "days").format("YYYY-MM-DD"),
          moment().add(1, "days").format("YYYY-MM-DD"), // Fix bug #1397 - need to add 1 day because server side searchs by date time
        ];
      } else if (this.selectedTimeRange.id === "all") {
        return null;
      } else {
        return [
          moment()
            .startOf(this.selectedTimeRange.id as moment.unitOfTime.StartOf)
            .format("YYYY-MM-DD"),
          moment()
            .endOf(this.selectedTimeRange.id as moment.unitOfTime.StartOf)
            .add(1, "days") // Fix bug #1397 - need to add 1 day because server side searchs by date time
            .format("YYYY-MM-DD"),
        ];
      }
    },
    searchKey: {
      get(): string {
        return this.$route.query.name?.toString() || "";
      },
      set(value: string) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            name: value,
          },
        });
      },
    },
    queryPayload(): {
      name?: string;
      type: string;
      createdBy?: string;
      lastModifiedDateFrom?: string;
      lastModifiedDateTo?: string;
      status?: string;
      sort?: string;
      dsc?: boolean;
      folderId: string;
      workspaceId: string;
    } {
      this.SET_LOADING(true);
      return {
        name: this.searchKey?.trim() || "",
        type: this.selectedType ? this.selectedType : "",
        createdBy: this.selectedOwner,
        lastModifiedDateFrom: this.selectedDate ? this.selectedDate[0] : "",
        lastModifiedDateTo: this.selectedDate ? this.selectedDate[1] : "",
        status: this.selectedStatus !== null ? this.selectedStatus : "",
        sort: this.dynamicSortField,
        dsc: this.dynamicSortOrder < 0,
        folderId: this.folderId,
        workspaceId: this.workspaceId,
      };
    },
    isCheckedAll(): boolean {
      return (
        this.datasources.length &&
        this.selectedColumn.length === this.datasources.length
      );
    },
    targetFolderIds(): Array<string> {
      return this.selectedColumn.flatMap((f: Datasource) =>
        f.type === DatasourceType.FOLDER ? [f.id] : []
      );
    },
    disabledDestinationFolders(): Array<string> {
      const res = Array.from(
        new Set(
          this.selectedColumn.map((ds: Datasource) => ds.folderId || "ROOT")
        )
      );

      if (
        this.selectedColumn.some((ds) => ds.type === DatasourceType.FOLDER) &&
        (this.selectedFolder.folderPath.length >= 3 ||
          this.folders.length >= 32)
      ) {
        res.push(this.selectedFolder.id);
      }

      return res;
    },
    isEmpty(): boolean {
      return (
        this.loadCount > 0 &&
        !this.loading &&
        !this.datasources.length &&
        !this.$route.query.name &&
        !this.$route.query.owner &&
        !this.$route.query.type &&
        !this.$route.query.status &&
        !this.$route.query.time &&
        !this.$route.query.folderId
      );
    },
  },
  watch: {
    queryPayload() {
      // when navigating away from datasource page,
      // filter params will also get updated to empty
      // this guard prevents a bug where data is loaded after page change
      if (!this.leaving) {
        this.reload();
      }
    },
    folderId() {
      this.reload();
    },
  },
  methods: {
    ...mapActions("datasources", [
      "loadDatasources",
      "delete",
      "createFolder",
      "moveToFolder",
      "deleteAll",
      "updateDatasource",
      "loadFolder",
      "loadFolders",
      "loadMoreFolders",
      "folderHasDatasourceInUse",
    ]),
    ...mapMutations("datasources", [
      "SET_CURRENT_FOLDER",
      "CLEAR",
      "RESET_NAME_DATASOURCE",
      "SET_LOADING",
    ]),
    ...mapActions("identities", ["loadUniqueUsersOfDatasource"]),

    reload: debounce(function (this: any) {
      this.selectedColumn = [];
      if (this.folderId && this.folderId !== RootFolder.id) {
        this.loadFolder({ id: this.folderId, workspaceId: this.workspaceId });
      } else {
        this.SET_CURRENT_FOLDER(undefined);
      }
      this.loadDatasources(this.queryPayload);
    }, 300),

    onCancel() {
      this.onToggleSelectFolder(null);
    },

    async onSelect() {
      const result = await Promise.all(
        this.selectedColumn.map((ds) =>
          this.moveToFolder({ ds, destinationId: this.selectedFolder.id })
        )
      );

      const successList = result
        .map((x, i) => [x, i])
        .filter(([x]) => !x)
        .map(([, i]) => this.selectedColumn[i]);
      const failureList = result.map((x, i) => [x, i]).filter(([x]) => !!x);
      const failureOfType = (type: string) =>
        failureList
          .filter(([x]) => x instanceof Error && x.message === type)
          .map(([, i]) => this.selectedColumn[i]);
      const genericFailureList = failureOfType("message.move.error.generic");
      const maxLevelFailureList = failureOfType(
        "message.move.folder.error.max_level"
      );
      const maxChildrenFailureList = failureOfType(
        "message.move.folder.error.max_child"
      );
      const notify = (
        xs: Array<Datasource>,
        severity: string,
        summary: string,
        message: string
      ) => {
        if (!xs.length) return;
        const name = xs.map((x) => `"${x.name}"`).join(", ");

        this.$toast.add({
          severity,
          summary: this.$t(summary),
          detail: this.$t(message, { name }),
          life: 3000,
        });
      };

      notify(
        successList,
        "success",
        "message.successful",
        "message.move.success.generic"
      );
      notify(
        genericFailureList,
        "error",
        "message.error",
        "message.move.error.generic"
      );
      notify(
        maxLevelFailureList,
        "error",
        "message.error",
        "message.move.folder.error.max_level"
      );
      notify(
        maxChildrenFailureList,
        "error",
        "message.error",
        "message.move.folder.error.max_child"
      );

      this.selectedColumn = [];
      this.selectedFolder = RootFolder;
      (this.op as any).toggle();
      this.reload();
    },

    onNavigate(folder: IFolder) {
      this.selectedFolder = folder;
      this.loadFolders({
        parentFolderId: folder.id,
        workspaceId: this.workspaceId,
      });
    },

    onLoadMore() {
      this.loadMoreFolders({
        parentFolderId: this.selectedFolder.id,
        workspaceId: this.workspaceId,
      });
    },

    async callCreateFolder() {
      try {
        this.folderNewInfo.folderId = this.folderId;
        const folder: IFolder = await this.createFolder(this.folderNewInfo);
        this.editingId = folder.id;
        this.newName = folder.name;
        this.reload();
        const inputName = document.getElementById("input-name");
        console.log(inputName);
        if (inputName) {
          inputName.focus();
        }
      } catch (err: any) {
        if (err instanceof Error) {
          this.$toast.add({
            severity: "error",
            summary: this.$t("message.error"),
            detail: this.$t(err.message),
            life: 3000,
          });
        }
      }
    },

    async updateCurrentName(folderDetail: Datasource) {
      if (folderDetail && this.newName && !this.v$.newName.$invalid) {
        folderDetail.name = this.newName;
        folderDetail.lastModifiedDate = new Date().toISOString();
        await this.updateDatasource(folderDetail);
        this.reload();
        this.editingId = "";
      }
    },

    cancelEditName() {
      this.newName = "";
      this.editingId = "";
    },

    focusInput(event: Event) {
      event.stopPropagation();
    },

    onEdit(_event: Event, experiment: IExperiment) {
      this.editingId = experiment.id;
      this.newName = experiment.name;
    },

    onToggleSelectFolder(event: any) {
      this.selectedFolder = RootFolder;
      this.loadFolders({
        parentFolderId: RootFolder.id,
        workspaceId: this.workspaceId,
      });
      (this.op as any).toggle(event);
    },

    onClickCheckbox(event: Event) {
      event.stopPropagation();
    },

    onClickCheckboxAll() {
      if (this.isCheckedAll) {
        this.selectedColumn = [];
      } else {
        this.selectedColumn = this.datasources.map(
          (experiment: IExperiment) => experiment
        );
      }
    },

    showConfirmDelete(message: string, accept: () => unknown) {
      this.$confirm.require({
        message,
        header: this.$t("datasource.list-datasource.dialog.delete.title"),
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept,
      });
    },

    async onClickDeleteMultiple() {
      const confirmAndDelete = async (confirmMsg: string) => {
        this.showConfirmDelete(confirmMsg, async () => {
          const result = await this.deleteAll({
            datasourceIds: this.selectedColumn.map((x) => x.id),
            workspaceId: this.workspaceId,
          });
          const successList = result.flatMap((r: boolean, i: number) =>
            r ? [i] : []
          );
          const failureList = result.flatMap((r: boolean, i: number) =>
            r ? [] : [i]
          );

          const showNotify = (list: Array<number>, severity: string) => {
            if (list.length == 0) return;
            const name = list
              .map((i: number) => `"${this.selectedColumn[i].name}"`)
              .join(", ");
            const detail = this.$t(
              `datasource.list-datasource.dialog.message.delete_${severity}_many`,
              { name }
            );

            this.$toast.add({
              severity,
              summary: this.$t(
                `message.${severity == "success" ? "successful" : severity}`
              ),
              detail,
              life: 3000,
            });
          };

          showNotify(successList, "success");
          showNotify(failureList, "error");

          this.selectedColumn = [];
          this.reload();
        });
      };

      const foldersToDelete = this.selectedColumn.filter(
        (ds) => ds.type === DatasourceType.FOLDER
      );

      if (foldersToDelete.length) {
        const hasInUseDs = await Promise.all(
          foldersToDelete.map((f) =>
            this.folderHasDatasourceInUse({
              folderId: f.id,
              workspaceId: f.workspaceId,
            })
          )
        );

        if (hasInUseDs.some((e) => e)) {
          const name = hasInUseDs
            .map((e, i) => [e, `"${foldersToDelete[i].name}"`])
            .filter(([e]) => e)
            .map(([, name]) => name)
            .join(", ");

          this.showConfirmDelete(
            this.$t(
              "datasource.list-datasource.dialog.delete.selected.confirm_message"
            ),
            () => {
              // setTimeout workaround confirm dialog limitation,
              // otherwise second dialog won't show
              setTimeout(async () => {
                await confirmAndDelete(
                  this.$t(
                    "datasource.list-datasource.dialog.delete.selected.folder.with_inuse_ds.confirm_message",
                    { name }
                  )
                );
              }, 500);
            }
          );

          return;
        }
      }

      await confirmAndDelete(
        this.$t(
          "datasource.list-datasource.dialog.delete.selected.confirm_message"
        )
      );
    },

    async confirmDeleteDatasource(event: Event, ds: DatasourceDetail) {
      event.preventDefault();
      const confirmAndDelete = async (
        confirmMsg: string,
        successMsg: string,
        failureMsg: string
      ) => {
        this.showConfirmDelete(
          this.$t(confirmMsg, { name: ds.name }),
          async () => {
            const res = await this.delete({
              datasourceId: ds.id,
              workspaceId: this.workspaceId,
            });
            if (!res) {
              this.$toast.add({
                severity: "error",
                summary: this.$t("message.error"),
                detail: this.$t(failureMsg, { name: ds.name }),
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "success",
                summary: this.$t("message.successful"),
                detail: this.$t(successMsg, { name: ds.name }),
                life: 3000,
              });
            }

            this.reload();
          }
        );
      };

      if (ds.type === DatasourceType.FOLDER) {
        const hasInUseDs = await this.folderHasDatasourceInUse({
          folderId: ds.id,
          workspaceId: ds.workspaceId,
        });

        await confirmAndDelete(
          hasInUseDs
            ? "datasource.list-datasource.dialog.delete.folder.with_inuse_ds.confirm_message"
            : "datasource.list-datasource.dialog.delete.folder.confirm_message",
          hasInUseDs
            ? "datasource.list-datasource.dialog.message.folder.with_inuse_ds.delete_success"
            : "datasource.list-datasource.dialog.message.folder.delete_success",
          "datasource.list-datasource.dialog.message.folder.delete_error"
        );
      } else {
        await confirmAndDelete(
          "datasource.list-datasource.dialog.delete.confirm_message",
          "datasource.list-datasource.dialog.message.delete_success",
          "datasource.list-datasource.dialog.message.delete_error"
        );
      }

      this.reload();
    },

    async loadMoreDatasources() {
      await this.loadDatasources({ ...this.queryPayload, next: true });
    },

    onRowSelect({
      originalEvent,
      data,
    }: {
      originalEvent: Event;
      data: Datasource;
    }) {
      if (this.selectedColumn.includes(data)) {
        this.selectedColumn = this.selectedColumn.filter((x) => x !== data);
      } else {
        this.selectedColumn.push(data);
      }
      return originalEvent;
    },

    openDatasource(data: Datasource) {
      if (data.type === DatasourceType.DATASOURCE) {
        this.$router.push(
          `/workspace/${this.workspaceId}/datasource/${data.id}`
        );
      } else {
        this.$router.push(
          `/workspace/${this.workspaceId}/datasource?folderId=${data.id}`
        );
      }
    },

    onClickCreate() {
      this.RESET_NAME_DATASOURCE();
      this.$router.push(
        `/workspace/${this.workspaceId}/datasource/create?folderId=${this.folderId}`
      );
    },
    async onSort(event: any) {
      this.dynamicSortField = event.sortField;
      this.dynamicSortOrder = event.sortOrder;
    },
  },
  unmounted() {
    this.CLEAR();
  },
});
