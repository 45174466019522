import { ChartAxisOption, ChartDisplayOptions } from "@/pages/Experiment/types";

const getMinMaxFromData = (
  actualData: Array<{ value: number }>,
  forecastData: Array<{ forecast: number }>
) => {
  const res: {
    min: number;
    max: number;
  } = {
    min: +Infinity,
    max: -Infinity,
  };

  actualData.forEach((data: any) => {
    if (data.value > res.max) {
      res.max = Math.ceil(data.value);
    }
    if (data.value < res.min) {
      res.min = Math.floor(data.value);
    }
  });

  forecastData.forEach((data: any) => {
    if (data.forecast > res.max) {
      res.max = Math.ceil(data.forecast);
    }
    if (data.forecast < res.min) {
      res.min = Math.floor(data.forecast);
    }
  });

  return res;
};

export const getChartDisplayOption = (
  actualData: Array<{ value: number }>,
  forecastData: Array<{ forecast: number }>,
  chartAxisOption: ChartAxisOption,
  quantile: {
    upper: {
      show: boolean;
      value?: number;
    };
    under: {
      show: boolean;
      value?: number;
    };
  },
  targetColRange?: {
    min: number;
    max: number;
  },
  customRange?: {
    min?: number | null;
    max?: number | null;
  }
): ChartDisplayOptions => {
  const res = {
    yAxis: {
      min: +Infinity,
      max: -Infinity,
    },
    quantileMarkers: [],
  } as ChartDisplayOptions;

  // extract min/max from current series
  res.yAxis = getMinMaxFromData(actualData, forecastData);
  if (chartAxisOption === ChartAxisOption.CustomRange) {
    // custom input min / max range
    // default min / max to current data
    // only use custom input when it's valid
    if (customRange?.min !== null && customRange?.min !== undefined) {
      res.yAxis.min = Number(customRange?.min);
    }
    if (customRange?.max !== null && customRange?.max !== undefined) {
      res.yAxis.max = Number(customRange?.max);
    }
  }

  if (quantile.upper.show || quantile.under.show) {
    const sortedData = actualData
      .map((e: { value: number }) => e.value)
      .sort((a, b) => (a > b ? 1 : -1));

    if (quantile.upper.show) {
      const upperIndex = Math.round(
        (sortedData.length * (quantile.upper.value || 80)) / 100
      );

      if (sortedData[upperIndex] !== sortedData[sortedData.length - 1]) {
        res.quantileMarkers.push([
          {
            itemStyle: {
              color: "#faa",
              opacity: 0.8,
            },
            yAxis: sortedData[upperIndex],
          },
          {
            yAxis: sortedData[sortedData.length - 1],
          },
        ]);
      }
    }

    if (quantile.under.show) {
      const underIndex = Math.round(
        (sortedData.length * (quantile.under.value || 20)) / 100
      );

      if (sortedData[underIndex] !== sortedData[0]) {
        res.quantileMarkers.push([
          {
            itemStyle: {
              color: "#faa",
              opacity: 0.8,
            },
            yAxis: sortedData[0],
          },
          {
            yAxis: sortedData[underIndex],
          },
        ]);
      }
    }
  }

  return res;
};
