
import { defineComponent } from "vue";
import { PropType } from "vue";
import { IFolder } from "@/pages/Common/types";

export default defineComponent({
  name: "FolderSelector",
  props: {
    current: {
      type: Object as PropType<IFolder>,
      required: true,
    },
    children: {
      type: Object as PropType<Array<IFolder>>,
      required: true,
    },
    hasMoreChildren: {
      type: Boolean,
      required: true,
      default: false,
    },
    disabledChildren: {
      type: Object as PropType<Array<string>>,
      required: true,
      default: () => new Array<string>(),
    },
    disabledSelection: {
      type: Object as PropType<Array<string>>,
      required: true,
      default: () => new Array<string>(),
    },
  },
  emits: ["navigate", "select", "cancel", "loadMore"],
  computed: {
    disabled(): Array<boolean> {
      const pathId = (f: IFolder) =>
        f.folderPath?.reduce<string>((p, c) => p + "/" + c.id, "") + "/" + f.id;
      return this.$props.children.map((f) =>
        this.$props.disabledChildren.some((p) => pathId(f).indexOf(p) >= 0)
      );
    },
    selectionDisabled(): boolean {
      return this.$props.disabledSelection.some(
        (s) => s === this.$props.current.id
      );
    },
  },
  methods: {
    navigate(folder: IFolder | number) {
      if (typeof folder === "number") {
        const currentPath = this.$props.current.folderPath;
        folder = {
          id: currentPath[folder].id,
          name: currentPath[folder].name,
          folderPath: currentPath.slice(0, folder),
        } as IFolder;
      }
      this.$emit("navigate", folder);
    },
    select() {
      this.$emit("select");
    },
    cancel() {
      this.$emit("cancel");
    },
    loadMore() {
      this.$emit("loadMore");
    },
  },
});
