
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import KpiTag from "@/components/KpiTag.vue";
import {
  IAutoMLLeaderBoard,
  IAutoMLModel,
} from "@/pages/Experiment/automl/types";
import {
  KpiName,
  KpiShownInPercent,
  SmallestBestKpi,
} from "@/pages/Experiment/types";

export default defineComponent({
  components: { KpiTag },
  props: {
    models: {
      type: Array as PropType<IAutoMLModel[]>,
      default: () => [],
    },
    sortByVal: {
      type: Boolean,
    },
    sortByTest: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    sortOrder(): number {
      if (this.models?.length) {
        return SmallestBestKpi[this.models[0].primaryMetric] ? 1 : -1;
      }
      return 1;
    },
    sortField(): string | null {
      if (this.$props.sortByTest) return "testScore";
      else if (this.$props.sortByVal) return "valScore";
      return null;
    },
    bestValidationScore(): number {
      const sortFunc = this.sortOrder > 0 ? Math.min : Math.max;
      return sortFunc(
        ...this.models.map((x: IAutoMLModel): number =>
          x.valScore ? x.valScore[x.primaryMetric] || 0 : 0
        )
      );
    },
    bestTestScore(): number {
      const sortFunc = this.sortOrder > 0 ? Math.min : Math.max;
      return sortFunc(
        ...this.models.map((x: IAutoMLModel): number =>
          x.testScore ? x.testScore[x.primaryMetric] || 0 : 0
        )
      );
    },
    modelCount(): number {
      return this.models.length;
    },
    leaderBoard(): Array<IAutoMLLeaderBoard> {
      return this.models.map((x: IAutoMLModel) => ({
        ...x,
        primaryMetric: KpiName[x.primaryMetric],
        valScore: x.valScore ? x.valScore[x.primaryMetric] : undefined,
        testScore: x.testScore ? x.testScore[x.primaryMetric] : undefined,
        percentage: KpiShownInPercent[x.primaryMetric],
      }));
    },
  },
});
