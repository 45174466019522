
import { defineComponent } from "vue";
import { useStore } from "@/store";
import { mapState } from "vuex";
import {
  ExperimentState,
  ILogMessage,
  LogLevel,
} from "@/pages/Experiment/types";
import { SettingObject } from "@/types";

export default defineComponent({
  name: "LogDialog.vue",
  data(): {
    showDialog: boolean;
    selectedLog?: ILogMessage | null;
    logFilter: Array<SettingObject>;
    selectedLogFilter: SettingObject;
  } {
    const logFilter: Array<SettingObject> = [
      { key: LogLevel.Info, label: this.$t("experiment.log.type.info") },
      { key: LogLevel.Warn, label: this.$t("experiment.log.type.warn") },
      { key: LogLevel.Error, label: this.$t("experiment.log.type.error") },
    ];
    return {
      showDialog: false,
      selectedLog: null,
      logFilter,
      selectedLogFilter: logFilter[0],
    };
  },
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    toggleDialog() {
      this.showDialog = !this.showDialog;
    },
  },
  computed: {
    ...mapState("experiments", {
      logs: (state: unknown): Array<ILogMessage> | undefined => {
        try {
          return (state as ExperimentState).experimentDetail?.logMessage?.map(
            (log) => {
              const lines = decodeURIComponent(atob(log.message)).split("\n");
              let level: LogLevel;
              if (log.level >= 40) {
                level = LogLevel.Error;
              } else if (log.level >= 30) {
                level = LogLevel.Warn;
              } else {
                level = LogLevel.Info;
              }
              return {
                level,
                messages: {
                  title: lines[0],
                  rest: lines.slice(1),
                },
                timestamp: log.timestamp,
              } as ILogMessage;
            }
          );
        } catch {
          return undefined;
        }
      },
    }),

    filteredLog(): Array<ILogMessage> | undefined {
      if (this.selectedLogFilter.key === LogLevel.Error) {
        return this.logs?.filter((l) => l.level >= LogLevel.Error);
      }

      if (this.selectedLogFilter.key === LogLevel.Warn) {
        return this.logs?.filter((l) => l.level >= LogLevel.Warn);
      }

      return this.logs;
    },

    logCount(): {
      info: number;
      warn: number;
      error: number;
    } {
      const res = { info: 0, warn: 0, error: 0 };
      this.logs?.forEach((log) => {
        if (log.level === LogLevel.Info) {
          res.info++;
        } else if (log.level === LogLevel.Warn) {
          res.warn++;
        } else {
          res.error++;
        }
      });

      return res;
    },
  },
  watch: {
    selectedLogFilter() {
      this.selectedLog = null;
    },
  },
});
