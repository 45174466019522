import { ROOT_FOLDER } from "@/common/constant";

export interface IFolder {
  id: string;
  name: string;
  folderPath: Array<IFolder>;
}

export const RootFolder = Object.freeze({
  id: ROOT_FOLDER,
  name: "HOME",
  folderPath: [],
});

export interface IMenuModel {
  label: string;
  icon?: string;
  command: () => any;
}
