
import { defineComponent } from "vue-demi";
import { mapState, useStore } from "vuex";
import PredictionBoard from "./PredictionBoard.vue";

export default defineComponent({
  props: {
    workspaceId: {
      type: String,
    },
  },
  components: {
    PredictionBoard,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data(): {
    loading: boolean;
  } {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("autoML", ["models", "predictions"]),
  },
});
