import Keycloak from "keycloak-js";
export interface AuthState {
  token?: string;
  userId?: string;
  username?: string;
  didAutoLogout?: boolean;
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  picture?: string;
  keycloak?: Keycloak.KeycloakInstance;
  tenants: string[];
  activeTenant: string;
  roleInTenant: Map<string, RoleInTenant>;
}

export enum RoleInTenant {
  Admin = "admin",
  User = "user",
}
