export interface TaskState {
  fileUploadTasks: Array<FileUploadTask>;
  isShow: boolean;
}

export interface FileUploadTask {
  id: string;
  fileName: string;
  datasourcePath: string;
  status: UploadStatus | string;
  type: string;
  uploadTime: Date;
  doneTime: string;
  code?: string;
  message?: string;
  loadedBytes: number;
  totalBytes: number;
}

export enum UploadStatus {
  Uploading = "task.upload.file.status.uploading",
  Success = "task.upload.file.status.success",
  Failed = "task.upload.file.status.failed",
}
