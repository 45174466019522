
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "@/store";
import { mapActions, mapState } from "vuex";
import { mapMutations } from "vuex";
import KpiTag from "@/components/KpiTag.vue";
import {
  IAutoMLLeaderBoard,
  IAutoMLModel,
} from "@/pages/Experiment/automl/types";
import {
  KpiName,
  KpiShownInPercent,
  SmallestBestKpi,
} from "@/pages/Experiment/types";
import { ExperimentStatus } from "@/pages/Experiment/types";
import {
  checkIfStringHasSpecialChar,
  checkIfDuplicatedName,
} from "@/common/utils";

export default defineComponent({
  created() {
    this.SHOW_UPLOAD(true);
    this.checkHeaderHasError();
    this.mapDataType();
  },
  data() {
    return {
      selectedModel: undefined,
      notExistColumns: [] as string[],
      hasErrorHeader: false,
    };
  },
  components: { KpiTag },
  computed: {
    ...mapState("experiments", [
      "experimentDetail",
      "datasourceConfig",
      "datasourceConfigDetail",
    ]),
    ...mapState("autoML", ["models"]),
    ...mapState("datasources", [
      "disabled",
      "listRowData",
      "listHeader",
      "delimiter",
      "linebreak",
      "selectedDelimiter",
      "selectedLinebreak",
      "totalRow",
      "nameNewDatasource",
      "workbook",
      "loading",
    ]),
    worksheetNames(): string[] {
      return this.workbook.SheetNames.map((x: string) => ({ label: x }));
    },
    sortOrder(): number {
      if (this.models?.length) {
        return SmallestBestKpi[this.models[0].primaryMetric] ? 1 : -1;
      }
      return 1;
    },
    sortField(): string | null {
      if (this.isCompletedExperiment()) return "testScore";
      else if (this.isTrainingExperiment()) return "valScore";
      return null;
    },
    modelCount(): number {
      return this.models.length;
    },
    bestValidationScore(): number {
      const sortFunc = this.sortOrder > 0 ? Math.min : Math.max;
      return sortFunc(
        ...this.models.map((x: IAutoMLModel): number =>
          x.valScore ? x.valScore[x.primaryMetric] || 0 : 0
        )
      );
    },
    bestTestScore(): number {
      const sortFunc = this.sortOrder > 0 ? Math.min : Math.max;
      return sortFunc(
        ...this.models.map((x: IAutoMLModel): number =>
          x.testScore ? x.testScore[x.primaryMetric] || 0 : 0
        )
      );
    },
    leaderBoard(): Array<IAutoMLLeaderBoard> {
      return this.models.map((x: IAutoMLModel) => ({
        ...x,
        primaryMetric: KpiName[x.primaryMetric],
        valScore: x.valScore ? x.valScore[x.primaryMetric] : undefined,
        testScore: x.testScore ? x.testScore[x.primaryMetric] : undefined,
        percentage: KpiShownInPercent[x.primaryMetric],
      }));
    },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    ...mapMutations("datasources", [
      "SET_DELIMITER",
      "SET_LINEBREAK",
      "SET_SELECT_DELIMITER",
      "SET_SELECT_LINEBREAK",
      "SHOW_UPLOAD",
      "SET_MODEL_ID",
      "SET_MODEL_NAME",
    ]),
    ...mapActions("datasources", ["changeWorksheet"]),
    checkIfStringHasSpecialChar,
    checkIfDuplicatedName,
    onRowSelect(selectedModel: any) {
      if (
        this.nameNewDatasource !== "" &&
        this.notExistColumns.length === 0 &&
        !this.hasErrorHeader
      ) {
        this.SHOW_UPLOAD(false);
      }
      this.SET_MODEL_ID(selectedModel.data.id);
      this.SET_MODEL_NAME(selectedModel.data.name);
      this.$emit("selectRow", selectedModel.data.isExplainable);
    },
    onClear() {
      this.$emit("clicked", 0);
    },
    isTrainingExperiment(): boolean {
      return this.experimentDetail?.status === ExperimentStatus.Training;
    },
    isCompletedExperiment(): boolean {
      return this.experimentDetail?.status === ExperimentStatus.Completed;
    },
    onTabChange({ index }: { originalEvent: Event; index: number }) {
      this.changeWorksheet(index);
      this.checkHeaderHasError();
      this.mapDataType();
    },
    checkHeaderHasError() {
      this.notExistColumns = [];
      //check feature columns
      this.datasourceConfig[0].featureColumns.map((column: any) => {
        let notExist = true;
        this.listHeader.map((item: any) => {
          if (item.field === column) {
            notExist = false;
          }
        });
        if (notExist) this.notExistColumns.push(column);
      });
      let notHaveTimeSeriesColumn = true;
      //check timeSeriesColumn
      this.listHeader.map((item: any) => {
        if (
          this.datasourceConfig[0].timeSeriesColumn &&
          item.field === this.datasourceConfig[0].timeSeriesColumn
        ) {
          notHaveTimeSeriesColumn = false;
        }

        //Check header name
        this.hasErrorHeader =
          this.hasErrorHeader ||
          this.checkIfStringHasSpecialChar(item.field) ||
          this.checkIfDuplicatedName(item.field, this.listHeader);
      });
      if (
        this.datasourceConfig[0].timeSeriesColumn &&
        notHaveTimeSeriesColumn
      ) {
        this.notExistColumns.push(this.datasourceConfig[0].timeSeriesColumn);
      }
      // check timeSeriesIdentifiers
      if (this.datasourceConfig[0].timeSeriesIdentifiers) {
        this.datasourceConfig[0].timeSeriesIdentifiers.map((column: any) => {
          let notExist = true;
          this.listHeader.map((item: any) => {
            if (item.field === column) {
              notExist = false;
            }
          });
          if (notExist) this.notExistColumns.push(column);
        });
      }

      if (
        this.nameNewDatasource !== "" &&
        this.notExistColumns.length === 0 &&
        this.selectedModel &&
        !this.hasErrorHeader
      ) {
        this.SHOW_UPLOAD(false);
      } else {
        this.SHOW_UPLOAD(true);
      }
    },
    mapDataType() {
      if (!this.hasErrorHeader && this.notExistColumns.length === 0) {
        let schema: { [key: string]: string } = {};
        this.datasourceConfigDetail[
          this.datasourceConfig[0].datasourceId
        ].schemas.map((x: any) => {
          schema[x.name] = x.type;
        });
        this.listHeader.map((header: any) => {
          header.code.type = schema[header.field];
        });
      }
    },
  },
});
