
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    dataType: {
      type: String,
      default: "other",
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconClass() {
      switch (this.dataType) {
        case "binary":
          return "icon-type-binary";
        case "multiclass":
          return "icon-type-list";
        case "regression":
          return "icon-type-regression";
        default:
          return "icon-type-other";
      }
    },
    tooltip(): string {
      return this.showTooltip ? `datasource.datatype.${this.dataType}` : "";
    },
  },
});
