
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import ExperimentStatus from "@/components/ExperimentStatus.vue";
export default defineComponent({
  components: {
    ExperimentStatus,
  },
  props: {
    name: String,
    type: String,
    status: String,
    createdBy: String,
    createdDate: String,
  },
  computed: {
    ...mapGetters("identities", ["getFullname"]),
  },
});
