import { IExperiment, Kpi, SchemaInfor } from "../types";
import { SettingObject } from "@/types";

export interface AutoMLState {
  experiments: Array<IExperiment>;
  tokenExperiments: string;
  folderList: Array<IExperiment>;
  loading: boolean;
  folderId: string;
  loadingCandidatesTimeSeries: boolean;
  candidatesTimeSeriesMinMax?: CandidatesTimeSeriesMinMax;
  loadingCandidatesTarget: boolean;
  candidatesTargetDistinctLength: number;
  candidatesTargetMin?: number;
  candidatesTargetMax?: number;
  models: Array<IAutoMLModel>;
  tokenModels: string;
  predictions: Array<IAutoMLPrediction>;
  tokenPredictions: string;
  autoMLDetail: IExperiment;
  evaluation: Array<IAutoMLEvaluation>;
  evalClass: Array<IAutoMLEvaluationClass>;
  modelEvaluation: {
    loading: boolean;
    timeseries: Array<IModelEvaluationTimeseries>;
  };
  statusEvaluation: string;
  fullEvaluation: Array<any>;
  predictionVariance: Array<IModelPredictionVariance>;
  residual: Array<IModelResidual>;
}

export interface AutoMLCloneConfig {
  name?: string;
  targetColumn?: SchemaInfor;
  timeSeriesColumn?: SchemaInfor;
  timeSeriesIdentifiers?: SchemaInfor[];
  featureColumns?: SchemaInfor[];
  predictionType?: SettingObject;
  primaryMetric?: Kpi;
  otherMetrics?: Kpi[];
  timeLimit?: SettingObject;
  trainMode?: SettingObject;
  splitTrainEndDate?: Date;
  splitTestEndDate?: Date;
  splitTestStartDate?: Date;
  splitTrainStartDate?: Date;
  splitType?: SettingObject;
  trainRatio?: number;
  timeSeries?: SettingObject;
  folderId?: string;
}

export interface CandidatesTimeSeriesMinMax {
  min: Date;
  max: Date;
}

export interface IAutoMLLeaderBoard {
  id: string;
  name: string;
  modelType: string;
  stackLevel: number;
  fitTime: number;
  predictTime: number;
  primaryMetric: string;
  valScore: number;
  testScore: number;
  percentage: boolean;
}

export interface IAutoMLModel {
  id: string;
  folderId?: string;
  isDeleted: boolean;
  businessCaseId?: string;
  compositeName?: string;
  compositeType?: string;
  lastModifiedBy?: string;
  createdBy?: string;
  lastModifiedDate?: string;
  createdDate?: string;
  datasourceId?: string;
  experimentId: string;
  name: string;
  nameReading?: string;
  modelType: string;
  stackLevel: number;
  fitTime: number;
  predictTime: number;
  valScore: any;
  testScore: any;
  featureImportance: Record<string, number>;
  modelRanking: number;
  numberSubItems: number;
  parentId?: string;
  primaryMetric: string;
  rootId?: string;
  status?: string;
  tenantId: string;
  type: string;
}

export interface IAutoMLPrediction {
  businessCaseId?: string;
  compositeName?: string;
  compositeType?: string;
  createdBy: string;
  createdDate: string;
  datasourceId?: string;
  description?: string;
  experimentId: string;
  explainOutput?: string;
  explainStatus?: string;
  explanationTime: number;
  fileInputName?: string;
  folderId?: string;
  id: string;
  inputBlobId: string;
  isDeleted: boolean;
  lastModifiedBy: string;
  lastModifiedDate: string;
  modelId: string;
  modelName: string;
  name: string;
  nameReading: string;
  numberRecord: number;
  numberSubItems: number;
  parentId?: string;
  predictionOutput?: string;
  predictionStatus?: string;
  predictionTime: number;
  rootId: string;
  runExplain: boolean;
  status: string;
  tenantId: string;
  type?: string;
  sheetName?: string;
  sheetIndex?: number;
  fileExtension: string;
}

export interface IAutoMLEvaluationClass {
  labelEncode: number;
  labelDecode: string;
  numbers: number;
}

export interface IAutoMLEvaluation {
  actualAvg: number;
  actualMin: number;
  actualMax: number;
  groupName: string;
  isOutlier: boolean;
  groupCount: number;
  groupOrder: number;
  predictAvg: number;
  predictMax: number;
  predictMin: number;
  percentile10?: number;
  percentile90?: number;
}

export interface IModelEvaluationTimeseries {
  idColumns: Array<{ col: string; val: string | number }>;
  max: number;
  min: number;
  average: number;
  kpi: Partial<
    {
      [kpi in Kpi]: number;
    }
  >;
}
export interface IModelPredictionVariance {
  actual_avg: number;
  group_count: number;
  group_name: string;
  group_order: number;
  is_outlier: number;
  predict_avg: number;
}
export interface IModelResidual {
  actual: number;
  index: number;
  predict: number;
  residual: number;
}

export interface IApiModelEvaluationTimeseries {
  [idColumns: string]: any;
  maxUnit: number;
  minUnit: number;
  avgUnit: number;
  scaled_mean_absolute_error?: number;
  mean_absolute_error?: number;
  mean_absolute_error_percent?: number;
  mean_absolute_percentage_error?: number;
  root_mean_squared_error?: number;
  r2?: number;
}

export const ENSEMBLE_MODEL = "WeightedEnsembleModel";

export const convertModelAdvancedEvaluationTimeseries = (
  apiModel: IApiModelEvaluationTimeseries,
  idColumns: Array<string>
): IModelEvaluationTimeseries => {
  return {
    max: apiModel.maxUnit,
    min: apiModel.minUnit,
    average: apiModel.avgUnit,
    kpi: {
      [Kpi.sMAE]: apiModel.scaled_mean_absolute_error,
      [Kpi.MAE]: apiModel.mean_absolute_error,
      [Kpi.MAEPercent]: apiModel.mean_absolute_error_percent,
      [Kpi.MAPE]: apiModel.mean_absolute_percentage_error,
      [Kpi.RMSE]: apiModel.root_mean_squared_error,
      [Kpi.R2]: apiModel.r2,
    },
    idColumns: idColumns.map((col) => ({
      col,
      val: apiModel[col] as string,
    })),
  };
};
