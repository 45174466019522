export function setSelectedLinebreak(data: string): any {
  if (data) {
    const result = {
      selectedLinebreak: "",
      linebreak: "",
    };
    switch (data) {
      case "\n":
        result.selectedLinebreak = "linux";
        result.linebreak = "\\n";
        break;
      case "\r\n":
        result.selectedLinebreak = "windows";
        result.linebreak = "\\r\n";
        break;
      default:
        result.selectedLinebreak = "custom";
        result.linebreak = "";
    }
    return result;
  }
}

export function setSelectedDelimiter(data: string): any {
  if (data) {
    const result = {
      selectedDelimiter: "",
      delimiter: "",
    };
    switch (data) {
      case ",":
        result.selectedDelimiter = "comma";
        result.delimiter = ",";
        break;
      case "\t":
        result.selectedDelimiter = "tab";
        result.delimiter = "\\t";
        break;
      case "|":
        result.selectedDelimiter = "pipe";
        result.delimiter = "|";
        break;

      default:
        result.selectedDelimiter = "custom";
        result.delimiter = "";
        break;
    }
    return result;
  }
}
export function onDelimiter(data: string): any {
  if (data) {
    let delimiter = "";

    switch (data) {
      case "comma":
        delimiter = ",";
        break;
      case "tab":
        delimiter = "\\t";
        break;
      case "pipe":
        delimiter = "|";
        break;
      case "custom":
        delimiter = "";
        break;

      default:
        delimiter = "";
        break;
    }
    return delimiter;
  }
}

export function onLinebreak(data: string): any {
  if (data) {
    let linebreak = "";

    switch (data) {
      case "linux":
        linebreak = "\\n";
        break;
      case "windows":
        linebreak = "\\r\n";
        break;
      case "custom":
        linebreak = "";
        break;

      default:
        linebreak = "";
        break;
    }
    return linebreak;
  }
}

export function getIconDataType(dataType: string): any {
  if (dataType) {
    let getClass;
    switch (dataType) {
      case "date":
        getClass = "bx bx-calendar-event";
        break;
      case "string":
        getClass = "bx bx-font";
        break;
      case "boolean":
        getClass = "bx bxs-adjust";
        break;
      case "integer":
        getClass = "bx bx-hash";
        break;
      case "float":
        getClass = "bx bx-hash";
        break;
      default:
        break;
    }
    return getClass;
  }
}
