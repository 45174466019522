
import { defineComponent } from "vue";
import { mapState, mapActions } from "vuex";
import _ from "lodash";

export default defineComponent({
  props: {
    model: {
      type: String,
      required: true,
    },
    features: {
      type: Object,
    },
  },
  data() {
    return {
      selectedFeature: "" as string,
      listFeatures: [] as any,
      listEvaluationStore: [] as any,
    };
  },
  computed: {
    ...mapState("application", ["theme"]),
    ...mapState("autoML", ["predictionVariance"]),
    ...mapState("experiments", ["experimentDetail"]),
    getPredictionVariance() {
      return this.predictionVariance;
    },
    payloadEvaluation(): {
      experimentId: string;
      workspaceId: string;
      modelName: string;
      featureName: string;
    } {
      return {
        experimentId: this.experimentDetail.id || "",
        workspaceId: this.experimentDetail.workspaceId || "",
        modelName: this.model,
        featureName: this.selectedFeature,
      };
    },
    chartTimeSeries(): unknown {
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            label: {
              precision: 0,
            },
          },
        },
        grid: {
          left: "5%",
          right: "5%",
          containLabel: true,
        },
        legend: {
          data: ["Actual", "Predict"],
        },
        xAxis: {
          type: "category",
          nameLocation: "center",
          nameGap: 25,
          nameTextStyle: {
            fontSize: 14,
          },
          data: this.predictionVariance.map((e: any) => e.group_name),
        },
        yAxis: [
          {
            type: "value",
          },
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Actual",
            type: "line",
            stack: "Total",
            data: this.predictionVariance.map((e: any) => e.actual_avg),
          },
          {
            name: "Predict",
            type: "line",
            stack: "Total",
            data: this.predictionVariance.map((e: any) => e.predict_avg),
          },
        ],
      };
      return option;
    },
    chartHistogram(): unknown {
      const option = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.predictionVariance.map((e: any) => e.group_name),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Count",
            data: this.predictionVariance.map((e: any) => e.group_count),
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
            label: {
              show: true,
              color: "var(--primary-color)",
              position: "top",
              formatter: (data: any) => {
                const sum = _.sum(
                  this.predictionVariance.map((e: any) => e.group_count)
                );
                const percent = (data.value / sum) * 100;
                if (percent % 2 === 0) {
                  return percent + "%";
                } else {
                  return percent.toFixed(1) + "%";
                }
              },
            },
          },
        ],
      };
      return option;
    },
  },
  methods: {
    ...mapActions("autoML", ["loadPredictionVarianceModel"]),
    loadPredictionVariance() {
      this.loadPredictionVarianceModel(this.payloadEvaluation);
    },
    onChangeFeature(event: any) {
      this.selectedFeature = event.value;
      this.loadPredictionVariance();
    },
  },
  watch: {
    features(newValue) {
      if (newValue) {
        const result = Object.keys(newValue).map((key) => String(key));
        this.listFeatures = result;
        this.selectedFeature = result[0];
      }
      this.loadPredictionVariance();
    },
  },
});
