import { SettingObject, SettingObjectOf } from "@/types";
import moment, { Moment } from "moment";
import { ExperimentStatus } from "@/pages/Experiment/types";

export interface BillingPieData {
  value: number;
  name: string;
}

export interface BillingState {
  billingItems?: Array<BillingItem>;
  billingDetails?: Array<BillingDetailItem>;
  tokenBillingDetails?: string | null;
  loading: {
    report: boolean;
    details: boolean;
  };
  downloadingJobs: {
    [jobName: string]: {
      progress: number;
      error: boolean;
      // data: unknown;
    };
  };
}

export interface BillingItem {
  id: string;
  automl: number;
  automlCost: number;
  autots: number;
  autotsCost: number;
  experiment: number;
  experimentCost: number;
}

export interface ApiBillingItem {
  date: string;
  automl: number;
  automlCost: number;
  autots: number;
  autotsCost: number;
  experiment: number;
  experimentCost: number;
}

export interface BillingDetailItem {
  link: string;
  idCount: number;
  cost: number;
  experimentName: string;
  billingDetailsType: BillingDetailsType;
  predictName: string | null;
  createdBy: string;
  completedDate: Moment;
  status: ExperimentStatus;
  isDeleted: boolean;
  workspaceName: string;
  workspaceCreatedBy: string;
  experimentId: string;
  predictId: string | null;
  workspaceId: string;
}

export interface ApiBillingDetailItem {
  idCount: number;
  cost: number;
  experimentName: string;
  billingDetailsType: string;
  predictName: string | null;
  createdBy: string;
  completedDate: string;
  status: string;
  isDeleted: boolean;
  workspaceName: string;
  workspaceCreatedBy: string;
  experimentId: string;
  predictId: string | null;
  workspaceId: string;
}

export interface BillingFilter {
  rangeType: BillingDateRange;
  range: [Moment, Moment];
}

export interface BillingDetailFilter {
  range: [Moment, Moment];
  workspaceId?: string;
  userId?: string;
}

export enum BillingDetailsType {
  AutoML = "AUTOML",
  AutoTS = "AUTOTS",
  AutoMLPrediction = "AUTOML_PREDICT",
  AutoTSPrediction = "AUTOTS_PREDICT",
}

export enum BillingGranularity {
  Day = "day",
  Month = "month",
}

export const BillingGranularityFilters = Object.freeze([
  {
    key: BillingGranularity.Day,
    label: "billing.filter.granularity.day",
  } as SettingObject,
  {
    key: BillingGranularity.Month,
    label: "billing.filter.granularity.month",
  } as SettingObject,
]);

export enum BillingDateRange {
  Custom = -1,
  ThisMonth = 0,
  LastMonth = 1,
  Last3Months = 3,
  Last6Months = 6,
  Last12Months = 12,
}

export const BillingDateRangeFilters: ReadonlyArray<SettingObject> =
  Object.freeze([
    {
      key: BillingDateRange.Custom,
      label: "billing.filter.range.custom",
    },
    {
      key: BillingDateRange.ThisMonth,
      label: "billing.filter.range.this_month",
    },
    {
      key: BillingDateRange.LastMonth,
      label: "billing.filter.range.last_month",
    },
    {
      key: BillingDateRange.Last3Months,
      label: "billing.filter.range.last_3_months",
    },
    {
      key: BillingDateRange.Last6Months,
      label: "billing.filter.range.last_6_months",
    },
    {
      key: BillingDateRange.Last12Months,
      label: "billing.filter.range.last_12_months",
    },
  ]);

export const BillingDetailColumns: ReadonlyArray<
  SettingObjectOf<BillingDetailItem>
> = Object.freeze([
  { key: "link", label: "billing.detail_table.header.link" },
  { key: "idCount", label: "billing.detail_table.header.id_count" },
  { key: "cost", label: "billing.detail_table.header.cost" },
  { key: "experimentName", label: "billing.detail_table.header.exp_name" },
  { key: "billingDetailsType", label: "billing.detail_table.header.type" },
  { key: "predictName", label: "billing.detail_table.header.predict_name" },
  { key: "createdBy", label: "billing.detail_table.header.created_user_name" },
  { key: "completedDate", label: "billing.detail_table.header.completed_date" },
  { key: "status", label: "billing.detail_table.header.status" },
  { key: "isDeleted", label: "billing.detail_table.header.deleted" },
  { key: "workspaceName", label: "billing.detail_table.header.workspace_name" },
  {
    key: "workspaceCreatedBy",
    label: "billing.detail_table.header.workspace_created_username",
  },
  { key: "experimentId", label: "billing.detail_table.header.exp_id" },
  { key: "predictId", label: "billing.detail_table.header.predict_id" },
  { key: "workspaceId", label: "billing.detail_table.header.workspace_id" },
]);

export const BillingDetailHiddenColumns: Readonly<
  Set<keyof BillingDetailItem>
> = Object.freeze(
  new Set<keyof BillingDetailItem>(["experimentId", "predictId", "workspaceId"])
);

export const convertApiModel = (model: ApiBillingItem): BillingItem => ({
  id: moment(model.date).format("L"),
  automl: model.automl,
  automlCost: model.automlCost,
  autots: model.autots,
  autotsCost: model.autotsCost,
  experiment: model.experiment,
  experimentCost: model.experimentCost,
});

const generateLink = (model: ApiBillingDetailItem): string => {
  if (model.isDeleted) {
    return "";
  }
  switch (model.billingDetailsType) {
    case BillingDetailsType.AutoMLPrediction:
      return `/workspace/${model.workspaceId}/experiment/automl/${model.experimentId}/prediction`;
    case BillingDetailsType.AutoTSPrediction:
      return `/workspace/${model.workspaceId}/experiment/autots/${model.experimentId}/prediction`;
    case BillingDetailsType.AutoML:
      return `/workspace/${model.workspaceId}/experiment/automl/${model.experimentId}/evaluation`;
    case BillingDetailsType.AutoTS:
      return `/workspace/${model.workspaceId}/experiment/autots/${model.experimentId}/evaluation`;
    default:
      return "";
  }
};

export const convertDetailApiModel = (
  model: ApiBillingDetailItem
): BillingDetailItem => ({
  link: generateLink(model),
  idCount: model.idCount,
  cost: model.cost,
  experimentName: model.experimentName,
  billingDetailsType: model.billingDetailsType as BillingDetailsType,
  predictName: model.predictName,
  createdBy: model.createdBy,
  completedDate: moment(model.completedDate),
  status: model.status as ExperimentStatus,
  isDeleted: model.isDeleted,
  workspaceName: model.workspaceName,
  workspaceCreatedBy: model.workspaceCreatedBy,
  experimentId: model.experimentId,
  predictId: model.predictId,
  workspaceId: model.workspaceId,
});
