import { SummaryMetric } from "./types";

const METRICS_MAPPING: { [key: string]: SummaryMetric } = {
  sMAE: {
    name: "scaled_mean_absolute_error",
    isPercentage: true,
  },
  scaled_mean_absolute_error: {
    name: "scaled_mean_absolute_error",
    isPercentage: true,
  },
  MAE: {
    name: "mean_absolute_error",
    isPercentage: false,
  },
  mean_absolute_error: {
    name: "mean_absolute_error",
    isPercentage: false,
  },
  MAPE: {
    name: "mean_absolute_percentage_error",
    isPercentage: true,
  },
  mean_absolute_percentage_error: {
    name: "mean_absolute_percentage_error",
    isPercentage: true,
  },
  RMSE: {
    name: "root_mean_squared_error",
    isPercentage: false,
  },
  root_mean_squared_error: {
    name: "root_mean_squared_error",
    isPercentage: false,
  },
  r2: {
    name: "r2",
    isPercentage: true,
  },
  roc_auc: {
    name: "roc_auc",
    isPercentage: false,
  },
  accuracy: {
    name: "accuracy",
    isPercentage: true,
  },
  balanced_accuracy: {
    name: "balanced_accuracy",
    isPercentage: true,
  },
  MAEPercent: {
    name: "mean_absolute_error_percent",
    isPercentage: true,
  },
  mean_absolute_error_percent: {
    name: "mean_absolute_error_percent",
    isPercentage: true,
  },
};

export function convertToSummaryMetric(metricName: string): SummaryMetric {
  if (METRICS_MAPPING[metricName]) {
    return METRICS_MAPPING[metricName];
  }
  return {
    name: metricName,
    isPercentage: false,
  };
}
