
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "@/store";
import { mapState } from "vuex";
import { mapMutations } from "vuex";
import { TypeData } from "../../types";
import NewDatasourceControl from "./NewDatasourceControl.vue";
import { RootFolder } from "@/pages/Common/types";

const MAX_FILE_SIZE = 1024 * 1024 * 1024;

export default defineComponent({
  components: {
    NewDatasourceControl,
  },
  props: {
    workspaceId: {
      type: String,
    },
  },
  data() {
    return {
      items: [
        {
          label: "upload.datasource.label.upload",
          to: `/workspace/${this.workspaceId}/datasource/create?folderId=${this.folderId}`,
        },
        {
          label: "upload.datasource.label.preview",
          to: `/workspace/${this.workspaceId}/datasource/create/preview?folderId=${this.folderId}`,
        },
        {
          label: "upload.datasource.label.format",
          to: `/workspace/${this.workspaceId}/datasource/create/format?folderId=${this.folderId}`,
        },
      ],
      totalRow: 0,
      typeData: [] as TypeData[],
      typeDate: [] as TypeData[],
      text: null,
      delimiter: "",
      linebreak: "",
      selectedDelimiter: "",
      delimiters: [] as TypeData[],
      selectedLinebreak: "",
      linebreaks: [] as TypeData[],
      listValuePreviewData: [],
      previewDataLable: "",
      hasError: true,
      errorMessage: "",
      data: [] as any[][],
      listHeader: [] as { field: any; header: any }[],
      hasSetMetadata: false,
      maxFileSize: MAX_FILE_SIZE,
      step: 0,
    };
  },
  watch: {
    name: function (val) {
      if (val.trim() != "") {
        this.hasError = false;
      } else {
        this.hasError = true;
      }
    },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    ...mapState("datasources", ["nameNewDatasource"]),
    name: {
      get(): string {
        return this.nameNewDatasource;
      },
      set(value: string) {
        this.SET_NAME_DATASOURCE(value);
      },
    },
    showInputName(): boolean {
      return !this.$route.path.endsWith("/datasource/create");
    },
    folderId: {
      get(): string {
        return this.$route.query.folderId?.toString() || RootFolder.id;
      },
      set(value: string) {
        this.$router.replace({ query: { folderId: value } });
      },
    },
  },
  methods: {
    ...mapMutations("datasources", [
      "SHOW_UPLOAD_BUTTON",
      "SET_NAME_DATASOURCE",
      "CLEAR_NEW_DATASOURCE",
    ]),
  },
  unmounted() {
    this.CLEAR_NEW_DATASOURCE();
  },
});
