
import { IExperiment } from "@/pages/Experiment/types";
import { defineComponent } from "@vue/runtime-core";
import { mapActions, mapGetters, mapState } from "vuex";

export default defineComponent({
  props: {
    datasourceId: { type: String, required: true },
    workspaceId: { type: String, required: true },
  },
  data() {
    return { selectedColumn: [] as Array<IExperiment> };
  },
  computed: {
    ...mapState("datasources", ["relatedExperiments"]),
    ...mapGetters("datasources", ["hasMoreExperiment"]),
    ...mapGetters("identities", ["getFullname"]),
    ...mapGetters("workspace", ["canEdit"]),
    isCheckedAll(): boolean {
      return (
        this.relatedExperiments.length &&
        this.selectedColumn.length === this.relatedExperiments.length
      );
    },
  },
  methods: {
    ...mapActions("datasources", [
      "deleteRelatedExperiment",
      "deleteAllRelatedExperiment",
      "loadMoreRelatedExperiments",
    ]),
    async loadMoreExperiments() {
      await this.loadMoreRelatedExperiments({
        datasourceId: this.datasourceId,
        workspaceId: this.workspaceId,
      });
    },
    onRowSelect({
      originalEvent,
      data,
    }: {
      originalEvent: Event;
      data: IExperiment;
    }) {
      if (this.selectedColumn.includes(data)) {
        this.selectedColumn = this.selectedColumn.filter((x) => x !== data);
      } else {
        this.selectedColumn.push(data);
      }
      return originalEvent;
    },
    openExperiment(selectedExperiment: IExperiment) {
      let routeData = this.$router.resolve(
        `/workspace/${
          this.workspaceId
        }/experiment/${selectedExperiment.type.toLowerCase()}/${
          selectedExperiment.id
        }`
      );
      window.open(routeData.href, "_blank");
    },
    confirmDeleteExperiment(event: Event, experimentObject: IExperiment) {
      event.preventDefault();
      this.$confirm.require({
        message: this.$t(
          "experiment.list-experiment.dialog.delete.confirm_message",
          {
            name: experimentObject.name,
          }
        ),
        header: this.$t("experiment.list-experiment.dialog.delete.title"),
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: async () => {
          try {
            await this.deleteRelatedExperiment({
              experimentId: experimentObject?.id,
              workspaceId: this.workspaceId,
            });
            this.$toast.add({
              severity: "success",
              summary: this.$t("message.successful"),
              detail: this.$t(
                "experiment.list-experiment.dialog.message.delete_success",
                { name: experimentObject.name }
              ),
              life: 3000,
            });
          } catch (error: any) {
            this.$toast.add({
              severity: "error",
              summary: this.$t("message.error"),
              detail: this.$t(
                "experiment.list-experiment.dialog.message.delete_error",
                { name: experimentObject.name }
              ),
              life: 3000,
            });
          }
        },
      });
    },
    onClickCheckbox(event: Event) {
      event.stopPropagation();
    },
    onClickCheckboxAll() {
      if (this.isCheckedAll) {
        this.selectedColumn = [];
      } else {
        this.selectedColumn = this.relatedExperiments.map(
          (experiment: IExperiment) => experiment
        );
      }
    },
    async onClickDeleteMultiple() {
      const confirm = (msg: string, accept: () => unknown) => {
        this.$confirm.require({
          message: msg,
          header: this.$t("experiment.list-experiment.dialog.delete.title"),
          icon: "pi pi-exclamation-triangle",
          acceptClass: "p-button-danger",
          accept,
        });
      };

      const confirmAndDelete = async (confirmMsg: string) => {
        confirm(confirmMsg, async () => {
          const result = await this.deleteAllRelatedExperiment({
            experimentIds: this.selectedColumn.map((x: IExperiment) => x.id),
            workspaceId: this.workspaceId,
          });
          const successList = result.flatMap((r: boolean, i: number) =>
            r ? [i] : []
          );
          const failureList = result.flatMap((r: boolean, i: number) =>
            r ? [] : [i]
          );

          const showNotify = (list: Array<number>, severity: string) => {
            if (list.length == 0) return;
            const name = list
              .map((i: number) => `"${this.selectedColumn[i].name}"`)
              .join(", ");
            const detail = this.$t(
              `experiment.list-experiment.dialog.message.delete_${severity}_many`,
              { name }
            );

            this.$toast.add({
              severity,
              summary: this.$t(
                `message.${severity == "success" ? "successful" : severity}`
              ),
              detail,
              life: 3000,
            });
          };

          showNotify(successList, "success");
          showNotify(failureList, "error");

          this.selectedColumn = [];
        });
      };
      confirmAndDelete(
        this.$t(
          "experiment.list-experiment.dialog.delete.selected.confirm_message"
        )
      );
    },
  },
});
