
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { defineComponent, PropType } from "vue";
import { mapGetters, mapState } from "vuex";
import { IAutoMLModel } from "../../types";
import { KpiName } from "@/pages/Experiment/types";
import {
  DatasourceConfig,
  ExperimentStatus,
  IExperiment,
} from "@/pages/Experiment/types";
import { toShortNumber } from "@/plugin/utils/shortNumber";

use([CanvasRenderer, BarChart]);

export default defineComponent({
  props: {
    models: {
      type: Object as PropType<IAutoMLModel[]>,
    },
    experiment: {
      type: Object as PropType<IExperiment>,
    },
    datasourceConfig: {
      type: Object as PropType<DatasourceConfig>,
    },
  },
  data(): {
    selectedModel: string;
  } {
    return {
      selectedModel: "",
    };
  },
  created() {
    this.selectedModel = this.listModels[0];
  },
  watch: {
    models() {
      this.selectedModel = this.listModels[0];
    },
  },
  computed: {
    ...mapState("application", ["theme"]),
    ...mapGetters("autoML", ["sortedModelsList"]),
    experimentStatus(): number {
      switch (this.experiment?.status) {
        case ExperimentStatus.Completed:
          return 1;
        case ExperimentStatus.Failed:
        case ExperimentStatus.Canceled:
          return -1;
        default:
          return 0;
      }
    },
    primaryMetric(): string {
      return KpiName[this.experiment?.config?.primaryMetric] || "";
    },
    listModels(): string[] {
      return this.sortedModelsList(
        this.experiment?.config?.primaryMetric,
        this.experimentStatus
      );
    },
    model(): IAutoMLModel | null {
      if (this.models?.length) {
        for (const value of this.models) {
          if (value.name === this.selectedModel) {
            return value;
          }
        }
      }
      return null;
    },
    data(): string[][] {
      if (this.model) {
        let data = [["feature", "value"]]; // dimension definition
        data.push(
          ...Object.entries(this.model.featureImportance).map((entry) => {
            const percent = entry[1] * 100;
            return [entry[0], percent.toFixed(2)];
          })
        );
        return data;
      }
      return [];
    },
    option(): unknown {
      return {
        series: [
          {
            type: "bar",
            encode: { y: "feature", x: "value" },
            datasetIndex: 1,
            label: {
              show: true,
              color: "var(--primary-color)",
              position: "right",
              formatter: (data: any) => {
                return Math.round(data.value[1]) + "%";
              },
            },
          },
        ],
        dataset: [
          {
            source: this.data,
          },
          {
            transform: {
              type: "sort",
              config: { dimension: "value", order: "asc" },
            },
          },
        ],
        dataZoom: [
          {
            type: "inside",
            orient: "vertical",
            start: this.data.length <= 11 ? 0 : 30,
            end: 100,
          },
          {
            type: "slider",
            orient: "vertical",
            right: "3%",
          },
        ],
        yAxis: {
          type: "category",
        },
        grid: {
          left: "3%",
          right: "16%",
          bottom: "3%",
          top: "2%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLabel: {
            formatter: (value: number) => {
              return value + "%";
            },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
          },
          formatter: (params: any) => {
            return `<div>${params[0].value[0]}: <strong>${Math.round(
              params[0].value[1]
            )}%</strong></div>`;
          },
        },
      };
    },
    title(): string {
      return this.$t("automl.evaluation.chart.feature.title", {
        model: this.model?.name,
        top: this.data.length - 1,
        target: this.datasourceConfig?.targetColumn,
      });
    },
    testScore(): string {
      return this.$t("automl.evaluation.chart.test", {
        metric: this.primaryMetric,
        score:
          this.model?.testScore &&
          toShortNumber(this.model.testScore[this.model.primaryMetric]),
      });
    },
    valScore(): string {
      return this.$t("automl.evaluation.chart.validation", {
        metric: this.primaryMetric,
        score:
          this.model?.valScore &&
          toShortNumber(this.model.valScore[this.model.primaryMetric]),
      });
    },
  },
});
