import { IJob, IJobState, IJobTree, IJobTreeTable } from "../types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { State } from "@/store";
import { $http } from "@/main";
import { isNil } from "@/common/utils";

const jobState: IJobState = {
  jobs: [],
  jobTree: [],
  jobTreeTable: undefined,
  loading: false,
  token: "",
};

const LIMIT = 100;
const SORT = "createdDate";
const DSC = true;

const jobMutations: MutationTree<IJobState> = {
  SET_JOBS(state, jobs: Array<IJob>) {
    state.jobs = jobs;
  },
  ADD_JOBS(state, jobs: IJob[]) {
    state.jobs.push(...jobs);
  },
  SET_TOKEN_JOBS(state, token: string) {
    state.token = token;
  },
  SET_JOB_TREE(state, jobTree: IJobTree[]) {
    state.jobTree = jobTree;
    state.jobTreeTable = sortJobTree(jobTree.map(convertJobTree));
  },
};

function sortJobTree(jobs: Array<IJobTreeTable>): Array<IJobTreeTable> {
  for (const job of jobs) {
    if (job.children.length > 0) {
      job.children = sortJobTree(job.children);
    }
  }
  return jobs.sort((prev, curr) => {
    return prev.data.createdDate < curr.data.createdDate ? -1 : 1;
  });
}

function convertJobTree(job: IJobTree): IJobTreeTable {
  return {
    key: job.node.id,
    data: job.node,
    children: job.children ? job.children.map(convertJobTree) : [],
  };
}

const jobActions: ActionTree<IJobState, State> = {
  async loadJobsByExperimentId(
    { commit },
    { experimentId, expType, sort, dsc, workspaceId }
  ) {
    if (!sort) {
      sort = SORT;
    }
    if (isNil(dsc)) {
      dsc = DSC;
    }
    const result = await $http.get<{ content: IJob[]; token: string }>(
      `/workers/job/exp-id/${experimentId}/tree?type=${expType}&_limit=${LIMIT}&_sort=${sort}&_dsc=${dsc}&workspaceId=${workspaceId}`
    );

    if (result) {
      commit("SET_JOB_TREE", result.data.content);
      commit("SET_TOKEN_JOBS", result.data.token);
    }
  },
  async loadMoreJobsByExperimentId(
    { state, commit },
    { experimentId, expType, sort, dsc, workspaceId }
  ) {
    if (!state.loading) {
      commit("SET_LOADING", true);
      try {
        if (!sort) {
          sort = SORT;
        }
        if (isNil(dsc)) {
          dsc = DSC;
        }
        const result = await $http.get<{ content: IJob[]; token: string }>(
          `/workers/job/exp-id/${experimentId}?type=${expType}&_limit=${LIMIT}&_sort=${sort}&_dsc=${dsc}&_t=${state.token}&workspaceId=${workspaceId}`
        );
        if (result) {
          commit("ADD_JOBS", result.data.content);
          commit("SET_TOKEN_JOBS", result.data.token);
        }
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_LOADING", false);
      }
    }
  },
};

const jobGetters: GetterTree<IJobState, State> = {
  getJobTreeForTreeTable: (state) => () => {
    return {
      root: state.jobTree.map((job) => {
        return {
          key: job.node.id,
          data: job.node,
          children: job.children.map((job) => {
            return {
              key: job.node.id,
              data: job.node,
              children: job.children.map((job) => {
                return {
                  key: job.node.id,
                  data: job.node,
                  children: job.children,
                };
              }),
            };
          }),
        };
      }),
    };
  },
};

export const jobModule: Module<IJobState, State> = {
  namespaced: true,
  state: jobState,
  getters: jobGetters,
  mutations: jobMutations,
  actions: jobActions,
};
