
import { Kpi, IAutoTSRun, ExperimentStatus } from "@/pages/Experiment/types";
import { useStore } from "@/store";
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import KpiTag from "@/components/KpiTag.vue";

export default defineComponent({
  components: { KpiTag },
  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Array as PropType<IAutoTSRun[]>,
    },
    headers: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      kpiSortTypes: [Kpi.sMAE, Kpi.MAE, Kpi.MAEPercent, Kpi.MAPE, Kpi.RMSE],
      kpiSortBy: Kpi.sMAE,
      kpiType: Kpi,
    };
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    newData(): IAutoTSRun[] | undefined {
      return this.data
        ?.filter((x) => x.status !== ExperimentStatus.Failed)
        .map((x) => {
          return {
            ...x,
            sMAE: x.sMAE || x.scaled_mean_absolute_error,
            MAE: x.MAE || x.mean_absolute_error,
            MAPE: x.MAPE || x.mean_absolute_percentage_error,
            RMSE: x.RMSE || x.root_mean_squared_error,
            MAEPercent: x.MAEPercent || x.mean_absolute_error_percent,
          };
        });
    },
  },
  methods: {
    isBest(type: string, kpi: number) {
      if (this.newData) {
        return (
          Math.min(
            ...this.newData.map(
              (x: unknown) => (x as { [key: string]: number })[type] as number
            )
          ) === kpi
        );
      }
    },
  },
});
