import { App } from "vue";
import moment from "moment";
import "moment/locale/ja";

export const MomentPlugin = {
  install(app: App): void {
    moment.locale("en");
    app.config.globalProperties.$moment = moment;
  },
};
