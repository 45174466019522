
import { defineComponent } from "@vue/runtime-core";
import { SettingObject } from "@/types";
import {
  RUN_MODE_LIST,
  TIME_LIMIT_LIST,
} from "@/pages/Experiment/common/constantSettingList";
import { FilterMatchMode } from "primevue/api";

export default defineComponent({
  props: {
    autoMlSchemas: {
      default: [],
    },
    selectedAdvancedSettingRunMode: {
      default: RUN_MODE_LIST[0],
    },
    selectedAdvancedSettingLimitTime: {
      default: TIME_LIMIT_LIST[0],
    },
    selectedAdvancedSettingColumn: {
      default: [],
    },
    opening: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["onSetting"],
  data(): {
    selectedRunMode: SettingObject;
    runModeList: Array<SettingObject>;
    selectedLimitTime: SettingObject;
    limitTimeList: Array<SettingObject>;
    selectedColumn: Array<any>;
    filters: {
      [key: string]: {
        value: string | null;
        matchMode: string | undefined;
      };
    };
  } {
    return {
      selectedRunMode: RUN_MODE_LIST[0],
      runModeList: RUN_MODE_LIST,
      selectedLimitTime: TIME_LIMIT_LIST[0],
      limitTimeList: TIME_LIMIT_LIST,
      selectedColumn: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  created() {
    this.reset();
    if (this.selectedAdvancedSettingColumn.length == 0) {
      this.selectedColumn = this.autoMlSchemas;
    }
  },
  watch: {
    opening: {
      handler(newVal: boolean) {
        if (newVal) {
          this.reset();
        }
      },
    },
  },
  methods: {
    reset() {
      this.selectedColumn = this.selectedAdvancedSettingColumn;
      this.selectedRunMode = this.selectedAdvancedSettingRunMode;
      this.selectedLimitTime = this.selectedAdvancedSettingLimitTime;
    },
    onClickSetting() {
      this.$emit("onSetting", {
        selectedAdvancedSettingRunMode: this.selectedRunMode,
        selectedAdvancedSettingLimitTime: this.selectedLimitTime,
        selectedAdvancedSettingColumn: this.selectedColumn,
      });
    },
    onChangeRunMode(event: {
      originalEvent: PointerEvent;
      value: SettingObject;
    }) {
      if (event.value != null) {
        this.selectedRunMode = event.value;
      } else {
        this.selectedRunMode = RUN_MODE_LIST[0];
      }
    },
    onChangeLimitTime(event: {
      originalEvent: PointerEvent;
      value: SettingObject;
    }) {
      if (event.value != null) {
        this.selectedLimitTime = event.value;
      } else {
        this.selectedLimitTime = TIME_LIMIT_LIST[0];
      }
    },
  },
  computed: {
    isDisableSettingButton(): boolean {
      return this.selectedColumn.length == 0;
    },
  },
});
