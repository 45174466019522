
import Papa from "papaparse";
import { getListDataType } from "@/common/utils";
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "@/store";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { DatasourceDetail } from "../types";
import { setSelectedDelimiter, setSelectedLinebreak } from "@/common/helpers";
import XLSX, { utils, WorkBook } from "xlsx";

const MAX_FILE_SIZE = 1024 * 1024 * 1024;
export default defineComponent({
  data() {
    return {
      files: [],
      listRowData: [] as any[],
      listHeader: [] as DataHeader[],
      uploadFile: false,
      totalRow: null,
      delimiter: "",
      linebreak: "",
      selectLinebreak: "",
      selectDelimiter: "",
      getFirstDatsource: [] as DatasourceDetail,
      disabled: true,
      data: [] as any[],
      hasSetMetadata: false,
      maxFileSize: MAX_FILE_SIZE,
      workbook: undefined as WorkBook | undefined,
    };
  },
  props: {
    name: String,
    id: String,
    workspaceId: String,
  },
  async created() {
    this.getFirstDatsource = await this.getOneDataSource(
      this.id,
      this.workspaceId
    );
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    ...mapGetters("datasources", ["getOneDataSource"]),
    worksheetNames(): { label: string }[] | undefined {
      return this.workbook?.SheetNames.map((x: string) => ({ label: x }));
    },
  },
  methods: {
    ...mapActions("datasources", ["addFileDatasource"]),
    ...mapMutations("datasources", [
      "SET_SELECTED_SHEET_NAME",
      "SET_SELECTED_SHEET_INDEX",
    ]),
    onStep(results: any) {
      if (!this.hasSetMetadata) {
        this.hasSetMetadata = true;

        this.linebreak = setSelectedLinebreak(
          results.meta.linebreak[0]
        ).linebreak;
        this.delimiter = setSelectedDelimiter(
          results.meta.delimiter[0]
        ).delimiter;
        this.selectLinebreak = setSelectedLinebreak(
          results.meta.linebreak[0]
        ).selectedLinebreak;
        this.selectDelimiter = setSelectedDelimiter(
          results.meta.delimiter[0]
        ).selectedDelimiter;
        this.listHeader = results.meta.fields.map((x: any) => {
          const data = {
            field: x,
            header: x,
          };
          return data;
        });
      }
      if (this.data.length < 1000) {
        this.data.push(results.data);
      }
    },
    onComplete() {
      const typeData = getListDataType(this.data);

      this.listHeader = typeData.map((obj: any, index: number) =>
        Object.assign({}, obj, this.listHeader[index])
      );

      this.uploadFile = false;
      this.listRowData = this.data;

      this.disabled = false;
      this.checkValidateUploadFile();
    },

    checkValidateUploadFile() {
      const datasourceSchemas = JSON.parse(
        JSON.stringify(this.getFirstDatsource.schemas)
      );

      if (this.listHeader.length != datasourceSchemas.length) {
        this.displayMess(this.$t("upload.datasource.unmatch"), "error");
        this.disabled = true;
      }
    },

    onFileSelect(event: any) {
      this.uploadFile = true;
      let _files = event.files;
      if (_files[0] != undefined) {
        if (_files[0].name.endsWith(".csv")) {
          Papa.parse(_files[0], {
            header: true,
            worker: true,
            step: this.onStep,
            complete: this.onComplete,
            preview: 1000,
            skipEmptyLines: true,
            dynamicTyping: true,
            delimitersToGuess: [
              ",",
              "\t",
              "|",
              ";",
              Papa.RECORD_SEP,
              Papa.UNIT_SEP,
            ],
          });
        } else if (
          _files[0].name.endsWith(".xlsx") ||
          _files[0].name.endsWith(".xls")
        ) {
          this.handleXlsFile(_files[0]);
          this.disabled = false;
        }
        this.files = event.files;
      } else {
        this.uploadFile = false;
      }
    },

    async handleXlsFile(file: File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        var ab = e.target?.result;
        const workbook = XLSX.read(new Uint8Array(ab as any), {
          type: "array",
          sheetRows: 1001,
        });
        // this.SET_WORKBOOK(workbook);
        this.workbook = workbook;
        this.uploadFile = false;
        this.changeWorksheet(0);
      };
      reader.readAsArrayBuffer(file);
    },
    changeWorksheet(index: number) {
      if (!this.workbook) {
        return;
      }
      const wsname = this.workbook.SheetNames[index];
      const worksheet = this.workbook.Sheets[wsname];

      this.SET_SELECTED_SHEET_INDEX(index);
      this.SET_SELECTED_SHEET_NAME(wsname);

      /* Convert array of arrays */
      const data: string[][] = utils.sheet_to_json(worksheet, {
        raw: false,
      });
      const listHeader = Object.keys(data[0]).map((x: any) => {
        const data = {
          field: x,
          header: x,
        };
        return data;
      });
      if (data.length) {
        const typeData = getListDataType(data);
        this.listHeader = typeData.map((obj: any, index: number) =>
          Object.assign({}, obj, listHeader[index])
        );
        this.listRowData = data;
      }
    },
    onTabChange({ index }: { originalEvent: Event; index: number }) {
      this.changeWorksheet(index);
    },
    onClear() {
      this.files = [];
      this.listRowData = [];
      this.listHeader = [];
    },

    getTotalRow(results: any) {
      this.totalRow = results.data.length;
    },
    onUpload() {
      this.disabled = true;
      this.addFileDatasource({
        datasourceId: this.id,
        workspaceId: this.workspaceId,
        uploadFiles: this.files,
      })
        // Finishing uploading the files
        .then(() => {
          this.$router.push({
            path: `/workspace/${this.workspaceId}/datasource/${this.id}`,
          });
          this.displayMess(this.$t("upload.datasource.file"), "success");
        })
        .catch((err) => {
          console.error(err);
          if (err.response && err.response.data && err.response.data.message) {
            this.displayMess(err.response.data.message, "error");
          } else {
            this.displayMess(this.$t("upload.datasource.file.fail"), "error");
          }
          this.disabled = false;
        });
    },

    displayMess(mess: string, type: string): void {
      this.$toast.add({
        severity: type,
        summary: "Message",
        detail: mess,
        life: 3000,
      });
    },
  },
});

interface DataHeader {
  code: ColumnType;
  field: string;
  header: string;
}
interface ColumnType {
  type: string;
  format: string;
}
