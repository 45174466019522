import PrimeVue from "primevue/config";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import { createApp, reactive } from "vue";
import Main from "@/Main.vue";
import { routerPlugin } from "./router";
import { key, store } from "./store";
import { i18n } from "./plugin/i18n";
import "./registerServiceWorker";

import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import axios, { AxiosRequestConfig } from "axios";
import { PrimeVuePlugin } from "./plugin/primevue";
import { MomentPlugin } from "./plugin/moment";
import { UtilitiesPlugin } from "./plugin/utils";
import { CommonComponentsPlugin } from "./plugin/commonComponents";
import { getExperimentIcon } from "./common/icon";
import { KeycloakInstance } from "@dsb-norge/vue-keycloak-js/dist/types";
import VChart from "vue-echarts";
import { registerLocale, registerTheme } from "echarts";
import { theme } from "./common/echarts-theme/dim";
import { themeLight } from "./common/echarts-theme/light";
import { createVueMatchMediaPlugin } from "@cwist/vue-match-media";
import { echartsJA } from "./plugin/i18n/echartsJA";

const breakpoints = {
  // @media (min-width: 1920px)
  fullHD: 1920,
  // @media (min-width: 48em)
  medium: "48em",
  // Object notation
  // @media (max-width: 768px)
  mobile: { maxWidth: 591 },
  // @media (orientation: landscape)
  landscape: { orientation: "landscape" },
  // Multiple features
  // @media (min-width: 62em) and (min-resolution: 150dpi)
  highDPIDesktop: {
    minWidth: "62em",
    minResolution: "150dpi",
  },
  // @media (min-width: 768px) and (max-width: 992px)
  tablet: {
    minWidth: 592,
    maxWidth: 991,
  },
  desktop: {
    minWidth: 992,
  },
  // SSR support
  // In order to use SSR, you must provide a default value
  // @media (min-width: 62em)
  // Defaults to true during SSR, will be updated on client side
  large: {
    breakpoint: "62em",
    defaultValue: true,
  },
  // Defaults to false during SSR, will be updated on client side
  portrait: {
    breakpoint: { orientation: "portrait" },
    defaultValue: false,
  },
};

const VueMatchMediaPlugin = createVueMatchMediaPlugin({ breakpoints });

const defaultOptions: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
  headers: {
    "content-type": "application/json",
  },
};

// Create instance
export const $http = axios.create(defaultOptions);

registerTheme("dim", theme);
registerTheme("light", themeLight);
registerLocale("JA", echartsJA);

const app = createApp(Main);

function setTokenAndHeader(keycloak: KeycloakInstance) {
  store.commit("authentication/setToken", keycloak);
  const tenants = (keycloak?.idTokenParsed as any)?.tenant as string[];
  if (
    localStorage.activeTenant &&
    tenants &&
    tenants.includes(localStorage.activeTenant)
  ) {
    store.commit("authentication/setActiveTenant", localStorage.activeTenant);
  } else {
    store.commit("authentication/setActiveTenant", tenants[0]);
    localStorage.setItem("activeTenant", tenants[0]);
  }
  $http.defaults.headers.common["authorization"] = "Bearer " + keycloak?.token;
  $http.defaults.headers.common["x-usee-active-tenant"] =
    localStorage.activeTenant;
  store.dispatch("identities/loadOrganizations", tenants);
  store.dispatch("identities/loadAllIdentities");
}

app.use(VueKeyCloak, {
  init: {
    onLoad: "login-required",
  },
  config: {
    url: process.env.VUE_APP_AUTH_SERVER_URL,
    realm: process.env.VUE_APP_AUTH_SERVER_REALM as string,
    clientId: process.env.VUE_APP_AUTH_SERVER_CLIENT_ID as string,
  },
  onReady(keycloak: KeycloakInstance) {
    // console.info("Keycloak ready", keycloak);
    setTokenAndHeader(keycloak);
    store.dispatch(
      "workspace/loadLatestWorkspace",
      keycloak?.idTokenParsed?.sub as string
    );
    app.config.globalProperties.$appState = reactive({
      inputStyle: "outlined",
    });
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.info("Token refreshed");
            setTokenAndHeader(keycloak);
          }
        })
        .catch(() => {
          console.error("Failed to refresh token");
        });
    }, 5000);

    app.config.globalProperties.getExperimentIcon = getExperimentIcon;
    app.use(store, key);
    app.use(PrimeVue, { ripple: true });
    app.use(i18n);
    app.use(MomentPlugin);
    app.use(UtilitiesPlugin);
    app.use(routerPlugin, { keycloak: keycloak, store, $http });
    // Initialize PrimeVue components
    app.use(PrimeVuePlugin);
    app.use(CommonComponentsPlugin);
    app.use(VueMatchMediaPlugin);
    app.component("v-chart", VChart);
    app.mount("#app");
  },
});
