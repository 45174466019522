<template>
  <li :class="containerClass(item)" v-if="visible()">
    <template v-if="!template">
      <router-link
        v-if="item.to"
        :to="
          item.to
            .replace(':id', $route.params.id)
            .replace(':workspaceId', $route.params.workspaceId)
            .replace(':folderId', folderId)
        "
        custom
        v-slot="{ navigate, href }"
      >
        <a
          :href="href"
          class="p-menuitem-link"
          @click="onClick($event, navigate)"
        >
          <span v-if="item.icon" :class="iconClass"></span>
          <span v-if="item.label" class="p-menuitem-text">{{
            $t(item.label).replace(":folderName", folderName)
          }}</span>
        </a>
      </router-link>
      <a
        v-else
        :href="item.url || '#'"
        class="p-menuitem-link"
        @click="onClick"
        :target="item.target"
      >
        <span v-if="item.icon" :class="iconClass"></span>
        <span v-if="item.label" class="p-menuitem-text">{{
          $t(item.label)
        }}</span>
      </a>
    </template>
    <component v-else :is="template" :item="item"></component>
  </li>
</template>

<script>
/* eslint-disable */
export default {
  name: "BreadcrumbItem",
  props: {
    item: null,
    template: null,
    folderId: "",
    folderName: "",
  },
  methods: {
    onClick(event, navigate) {
      if (this.item.command) {
        this.item.command({
          originalEvent: event,
          item: this.item,
        });
      }

      if (this.item.to && navigate) {
        navigate(event);
      }
    },
    containerClass(item) {
      return [{ "p-disabled": this.disabled(item) }, this.item.class];
    },
    visible() {
      return typeof this.item.visible === "function"
        ? this.item.visible()
        : this.item.visible !== false;
    },
    disabled(item) {
      return typeof item.disabled === "function"
        ? item.disabled()
        : item.disabled;
    },
  },
  computed: {
    iconClass() {
      return ["p-menuitem-icon", "p-mr-2", this.item.icon];
    },
  },
};
</script>
