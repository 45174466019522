import { DatasourceDetail, SettingObject } from "@/types";

export enum ChartAxisOption {
  CurrentSeriesRange,
  CustomRange,
}

export interface ChartDisplayOptions {
  yAxis: {
    min: number;
    max: number;
  };
  quantileMarkers: Array<
    [
      {
        itemStyle: any;
        yAxis: number;
      },
      {
        yAxis: number;
      }
    ]
  >;
}

// Vuex State
export interface ExperimentState {
  experiments: Array<IExperiment>;
  loading: boolean;
  loadCount: number;
  experimentDetail?: IExperiment;
  datasourceConfig: IDatasourceConfig[];
  datasourceConfigDetail: { [key: string]: DatasourceDetail };
  tokenExperiments: string;
  tokenFolders: string;
  lastAccessExperiments: Array<IExperiment>;
  nameNewExperiment: string;
  experimentType: string;
  algorithms: string[];
  kpi: Array<IKpi>;
  targetColumnMinMax?: {
    min?: number;
    max?: number;
  };
  //autoTs
  stepExperiment: string;
  disableSelectButton: boolean;
  disableSettingButton: boolean;
  selectDataSource: DatasourceDetail;
  experimentConfig: ExperimentConfig;
  timeSeriesIdentifiersCandidates: TimeSeriesIdentifiersCandidate[];
  actualData: any;
  testData: any;
  forecastData: any;
  loadingPredictionGraphData: boolean;
  bestKpi: IBestKpi;
  currentFolder?: IFolder;
  folders: IFolder[];
}

export interface ChartOptions {
  selectedChartAxisOption: ChartAxisOption;
  highlightQuantile?: {
    top: number;
    bottom: number;
  };
}

export interface TimeSeriesIdentifiersCandidate {
  idc: string;
  candidate: any;
}

export interface TimeSeriesIdentifiersCandidateSelected {
  idc: string;
  candidate: any;
}

// Mapping with /experiment API
export interface IExperiment {
  id: string;
  name: string;
  type: ExperimentType;
  tenantId?: string;
  status?: ExperimentStatus;
  description?: string;
  createdBy?: string;
  createDate?: string;
  isDeleted?: boolean;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  config?: IExperimentConfig;
  numberSubItems?: number;
  folderId: string;
  folderName?: string;
  workspaceId?: string;
  kpiScores?: { [key: string]: Array<any> };
  logMessage?: Array<{
    level: number;
    message: string;
    code: string;
    timestamp: string;
  }> | null;
}

export enum LogLevel {
  Info,
  Warn,
  Error,
}

export interface ILogMessage {
  level: LogLevel;
  messages: {
    title: string;
    rest: Array<string>;
  };
  timestamp: string;
}

export interface IExperimentConfig {
  [key: string]: any;
}

export interface IDemandForecastConfig extends IExperimentConfig {
  targetColumn: string;
  timeSeriesColumn: string;
  timeSeriesIdentifiers: string[];
}

export enum ExperimentStatus {
  Draft = "DRAFT",
  Setting = "SETTING",
  Created = "CREATED",
  Analyzing = "ANALYZING",
  EDACompleted = "EDA-COMPLETED",
  Training = "TRAINING",
  Completed = "COMPLETED",
  Failed = "FAILED",
  Canceled = "CANCELED",
}

export enum ExperimentType {
  DemandForecast = "DEMAND_FORECAST",
  AutoTS = "AUTOTS",
  AutoML = "AUTOML",
  Folder = "FOLDER",
}

export interface ExperimentConfig {
  endDate?: Date | string;
  startDate?: Date | string;
  forecastHorizon?: number;
  frequency?: string;
  gap: number;
  targetColumn?: string;
  timeSeriesColumn?: string;
  timeSeriesIdentifiers?: Array<string>;
  transactionDsId?: string;
  primaryMetric: string;
  otherMetrics: string[];
  modelList?: string[];
  postProcessing?: IExperimentPostProcessing;
  predictionType?: string;
  timeLimit?: string | number;
}
export interface CloneConfig {
  name: string;
  endDate: Date;
  startDate: Date;
  splitDate: Date;
  forecastHorizon: number;
  frequency: string;
  gap: number;
  targetColumn: SchemaInfor;
  timeSeriesColumn: SchemaInfor;
  timeSeriesIdentifiers: Array<SchemaInfor>;
  primaryMetric: Kpi;
  otherMetrics: Kpi[];
  backtest: number;
  folderId: string;
  modelList?: string[];
  postProcessing?: IExperimentPostProcessing;
  timeLimit?: SettingObject;
  featureColumns?: SchemaInfor[];
}
export interface ExperimentDetail extends IExperiment {
  config?: ExperimentConfig;
  algorithmConfigs?: Array<AlgorithmConfigs>;
}

export interface AlgorithmConfigs {
  algorithmId: string;
  config: null;
}

export interface DsInfor {
  label: string;
  value: string;
  ele?: string | string[];
}

export interface AutoTSSteps {
  label: string;
  to: string;
}

export interface IDatasourceConfig {
  businessCaseId: string;
  createdBy: string;
  createdDate: string;
  datasourceId: string;
  datasourceType: string;
  experimentId: string;
  featureColumns: string[];
  id: string;
  idColumn: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  mainDsColumn: string;
  name: string;
  targetColumn: string;
  tenantId: string;
  timeSeriesColumn: string;
  timeSeriesFormat: string;
  timeSeriesIdentifiers: string[];
  valueColumn: string;
  zipcodeColumn: string;
}

export class DatasourceConfig implements IDatasourceConfig {
  businessCaseId = "";
  createdBy = "";
  createdDate = "";
  datasourceId = "";
  datasourceType = "";
  experimentId = "";
  featureColumns = [];
  id = "";
  idColumn = "";
  lastModifiedBy = "";
  lastModifiedDate = "";
  mainDsColumn = "";
  name = "";
  targetColumn = "";
  tenantId = "";
  timeSeriesColumn = "";
  timeSeriesFormat = "";
  timeSeriesIdentifiers = [];
  valueColumn = "";
  zipcodeColumn = "";
}

export interface IJob {
  businessCaseId: string;
  cost: number;
  createdBy: string;
  createdDate: string;
  currency: string;
  datasourceId: string;
  duration: number;
  endedTime: string;
  experimentId: string;
  id: string;
  isDeleted: true;
  jobConfig: {
    algorithmConfigs: [
      {
        algorithmId: string;
        config: {
          additionalProp1: string;
          additionalProp2: string;
          additionalProp3: string;
        };
      }
    ];
    datasourceConfig: {
      businessCaseId: string;
      createdBy: string;
      createdDate: string;
      datasourceId: string;
      experimentId: string;
      id: string;
      isDeleted: true;
      lastModifiedBy: string;
      lastModifiedDate: string;
      name: string;
      targetColumn: string;
      tenantId: string;
      timeSeriesColumn: string;
      timeSeriesIdentifiers: string[];
      transactionDatasource: string;
    };
    experimentConfig: {
      endDate: string;
      forecastHorizon: number;
      frequency: string;
      isRetrain: true;
      optimizationKpi: string;
      startDate: string;
      trainRatio: number;
    };
  };
  lastModifiedBy: string;
  lastModifiedDate: string;
  name: string;
  parentId: string;
  rootId: string;
  startedTime: string;
  status: string;
  tenantId: string;
  trigger: string;
  type: string;
}

export interface IJobTree {
  node: IJob;
  children: IJobTree[];
}

export interface IJobTreeTable {
  key: string;
  data: IJob;
  children: IJobTreeTable[];
}

export interface IJobState {
  jobs: IJob[];
  jobTree: IJobTree[];
  jobTreeTable?: IJobTreeTable[];
  loading: boolean;
  token: string;
}

export interface TrainPercentageInfor {
  name: string;
  dataPoints: number;
  percent: number;
}

export interface SchemaInfor {
  format: string;
  index: number;
  name: string;
  type: string;
  role: string;
}

export interface DecompositionChart {
  xData: string[];
  visitorQuantity: number[];
  trend: number[];
  seasonal: number[];
  residual: number[];
}

export interface HistogramChart {
  idcsCount: number[];
  idcsPercentage: number[];
  dateRange: string[];
  name: string;
}

export interface DistributionChart {
  data: { id: number; percentage: number }[];
  xName: string;
  yName: string;
}
export interface IntermittentChart {
  value: number;
  name: string;
}

export const EXPERIMENT_STATUS = [
  ExperimentStatus.Training,
  ExperimentStatus.Completed,
  ExperimentStatus.Failed,
  ExperimentStatus.Canceled,
];

export enum Kpi {
  MAEPercent = "MAEPercent",
  sMAE = "sMAE",
  MAE = "MAE",
  MAPE = "MAPE",
  RMSE = "RMSE",
  R2 = "R2",
}

export const KpiKey: Readonly<{ [key: string]: string }> = Object.freeze({
  sMAE: "scaled_mean_absolute_error",
  MAE: "mean_absolute_error",
  MAEPercent: "mean_absolute_error_percent",
  MAPE: "mean_absolute_percentage_error",
  RMSE: "root_mean_squared_error",
  R2: "r2",
});

export const KpiName: { [key: string]: string } = Object.freeze({
  root_mean_squared_error: "RMSE",
  mean_absolute_error: "MAE",
  mean_absolute_percentage_error: "MAPE",
  scaled_mean_absolute_error: "sMAE",
  mean_absolute_error_percent: "MAEPercent",
  r2: "R2",
  roc_auc: "AUC",
  accuracy: "Accuracy",
  balanced_accuracy: "Balanced Accuracy",
});

export const SmallestBestKpi: { [key: string]: boolean } = Object.freeze({
  root_mean_squared_error: true,
  mean_absolute_error: true,
  mean_absolute_percentage_error: true,
  scaled_mean_absolute_error: true,
  mean_absolute_error_percent: true,
});

export const KpiShownInPercent: { [key: string]: boolean } = Object.freeze({
  r2: true,
  mean_absolute_percentage_error: true,
  scaled_mean_absolute_error: true,
  mean_absolute_error_percent: true,
  accuracy: true,
  balanced_accuracy: true,
});

export interface IKpi {
  jobId: string;
  algorithmId: string;
  kpiName: Kpi;
  value: number;
}

export interface IAutoTSRun {
  jobId: string;
  algorithmId: string;
  startTime?: string;
  status?: ExperimentStatus;
  duration?: number;
  sMAE?: number;
  MAE?: number;
  MAEPercent?: number;
  MAPE?: number;
  RMSE?: number;
  R2?: number;
  scaled_mean_absolute_error?: number;
  mean_absolute_error?: number;
  mean_absolute_error_percent?: number;
  mean_absolute_percentage_error?: number;
  root_mean_squared_error?: number;
  r2?: number;
}

export interface IBestKpi {
  [key: string]: { value: number; algorithmId: string } | undefined;
}

export interface IFolder extends IExperiment {
  folderPath: Array<IFolderPath>;
}

export interface IFolderPath {
  id: string;
  name: string;
  level?: number;
}

export interface IExperimentPostProcessing {
  round: boolean;
  trimRange: { mode: string | null; min?: number; max?: number };
}
