import { RouteRecordRaw } from "vue-router";

export const authenticationRoutes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   name: "Login",
  //   alias: "/login",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "login" */ "./components/Authentication.vue"),
  // },
];
