import {
  Datasource,
  DatasourceState,
  DatasourceDetail,
  FileDetail,
  DatasourceProfile,
  EdaNumeric,
  DataPreview,
} from "./types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { State } from "@/store";
import { $http } from "@/main";
import { getListDataType, isNil } from "@/common/utils";
import { IExperiment } from "../Experiment/types";
import {
  getDataRoleFromDataType,
  IAutoMLPrediction,
  Prediction,
} from "@/types";
import { ROOT_FOLDER } from "@/common/constant";
import { RootFolder, IFolder } from "@/pages/Common/types";
import { utils, WorkBook } from "xlsx";
import request from "axios";

const getDefaultState = (): DatasourceState => {
  return {
    currentFolder: RootFolder,
    folders: [],
    datasources: [],
    datasourcesReady: [],
    tokenDatasources: "",
    tokenFolders: "",
    tokenFiles: "",
    tokenRelatedExperiments: "",
    newDatasource: {
      name: "",
      schemas: [],
    },
    files: [],
    lastAccessDatasources: [],
    showPopupDatasource: false,
    nameNewDatasource: "",
    desc: "",
    datasourceDetail: undefined,
    loading: false,
    loadCount: 0,
    loadingFiles: false,
    relatedExperiments: [],
    totalFiles: 0,
    totalSize: 0,
    datasourceProfile: undefined,
    loadingDatasourceProfile: false,
    correlation: {},
    modelId: "",
    modelName: "",
    experimentId: "",
    explaination: false,
    edaFinished: false,

    //Create new datasource
    showInputDatasource: false,
    selectedFile: [],
    listRowData: [],
    listHeader: [],
    totalRow: 0,
    delimiter: "",
    linebreak: "",
    typeData: [],
    typeDate: [],
    selectedDelimiter: "",
    selectedLinebreak: "",
    showUploadButton: false,
    selectedHeader: [],
    listPreviewData: [],
    disabled: false,
    encoding: "",
    disabledPreviewButton: false,
  };
};

const datasourceState: DatasourceState = getDefaultState();
const LIMIT = 20;
const SORT = "createdDate";
const DSC = true;

const datasourceMutations: MutationTree<DatasourceState> = {
  SET_SHOW_NAME_DATASOURCE(state, data: boolean) {
    state.showInputDatasource = data;
  },
  SET_SELECT_FILE(state, data: any) {
    state.selectedFile = data;
  },
  SET_NAME_DATASOURCE(state, data: string) {
    state.nameNewDatasource = data;
  },
  SET_DESC_DATASOURCE(state, data: string) {
    state.desc = data;
  },
  SET_DATASOURCES(state, datasources: Array<Datasource>) {
    state.datasources = datasources;
    state.datasourcesReady = datasources.filter((ds) => ds.status === "READY");
  },
  SET_LAST_ACCESS_DATASOURCES(state, datasources: Array<Datasource>) {
    state.lastAccessDatasources = datasources;
  },
  UPDATE_DATASOURCE(state, datasource: Datasource) {
    const ds = state.datasources.find((ds) => ds.id === datasource.id);
    Object.assign(ds, datasource);
  },
  ADD_DATASOURCE(state, datasourceObject: Datasource) {
    state.datasources.push(datasourceObject);
  },
  ADD_DATASOURCES(state, datasources: Datasource[]) {
    state.datasources.push(...datasources);
  },
  SET_FILES(state, files: Array<FileDetail>) {
    state.files = files;
  },
  ADD_FILES(state, files: Array<FileDetail>) {
    state.files.push(...files);
  },
  DELETE_FILE(state, fileId: string) {
    state.files = state.files.filter((file) => file.id != fileId);
  },
  SET_NEW_DATASOURCE(state, newDatasource: DatasourceDetail) {
    state.newDatasource = newDatasource;
  },
  CHANGE_SHOW_POPUP_DATASOURCE(state) {
    state.showPopupDatasource = !state.showPopupDatasource;
  },
  CHANGE_NAME_DATASOURCE(state, name) {
    state.nameNewDatasource = name;
  },
  CHANGE_DESC_DATASOURCE(state, name) {
    state.desc = name;
  },
  RESET_NAME_DATASOURCE(state) {
    state.nameNewDatasource = "";
  },
  RESET_DESC_DATASOURCE(state) {
    state.desc = "";
  },
  SET_TOKEN_DATASOURCES(state, token: string) {
    state.tokenDatasources = token;
  },
  SET_TOKEN_FOLDERS(state, token: string) {
    state.tokenFolders = token;
  },
  SET_TOKEN_FILES(state, token: string) {
    state.tokenFiles = token;
  },
  SET_TOKEN_RELATED_EXPERIMENTS(state, token: string) {
    state.tokenRelatedExperiments = token;
  },
  SET_LIST_ROW_DATA(state, listData: any) {
    state.listRowData = listData;
  },
  SET_LIST_HEADER_DATA(state, listData: any) {
    state.listHeader = listData;
  },
  RESET_LIST_HEADER_TYPE(state, new_data: any) {
    state.listHeader[new_data.index].code.type = new_data.data;
    state.listPreviewData[new_data.index].role = getDataRoleFromDataType(
      new_data.data
    );
  },
  RESET_LIST_HEADER_FORMAT(state, new_data: any) {
    state.listHeader[new_data.index].code.format = new_data.data;
  },
  SHOW_UPLOAD_BUTTON(state, data: boolean) {
    state.showUploadButton = data;
  },
  SET_LINEBREAK(state, data: string) {
    state.linebreak = data;
  },
  SET_DELIMITER(state, data: string) {
    state.delimiter = data;
  },
  SET_SELECT_LINEBREAK(state, data: any) {
    state.selectedLinebreak = data;
  },
  SET_SELECT_DELIMITER(state, data: any) {
    state.selectedDelimiter = data;
  },
  SET_LIST_REVIEW_DATA(state, data: any) {
    state.listPreviewData = data.map((x: DataPreview) => {
      return {
        ...x,
        originalDataType: x.dataType,
      };
    });
  },
  SET_SELECT_HEADER(state, data: any) {
    state.selectedHeader = data;
  },
  SHOW_UPLOAD(state, data: boolean) {
    state.disabled = data;
  },
  SET_PREVIEW_BUTTON(state, data: boolean) {
    state.disabledPreviewButton = data;
  },
  SET_TOTALROW(state, data: number) {
    state.totalRow = data;
  },
  SET_MODEL_ID(state, data: string) {
    state.modelId = data;
  },
  SET_MODEL_NAME(state, data: string) {
    state.modelName = data;
  },
  SET_EXPERIMENT_ID(state, data: string) {
    state.experimentId = data;
  },
  SET_EXPLAINATION(state, data: boolean) {
    state.explaination = data;
  },
  SET_EDA_FINISHED(state, data: boolean) {
    state.edaFinished = data;
  },
  CLEAR_NEW_DATASOURCE(state) {
    state.nameNewDatasource = "";
    state.desc = "";
    state.selectedFile = [];
    state.listRowData = [];
    state.listHeader = [];
    state.totalRow = 0;
    state.delimiter = "";
    state.linebreak = "";
    state.typeData = [];
    state.typeDate = [];
    state.selectedDelimiter = "";
    state.selectedLinebreak = "";
    state.showUploadButton = false;
    state.selectedHeader = [];
    state.listPreviewData = [];
    state.disabled = false;
    state.modelId = "";
    state.workbook = undefined;
    state.selectedSheetName = undefined;
    state.selectedSheetIndex = undefined;
  },
  SET_LOADING(state, payload: boolean) {
    state.loading = payload;
  },
  SET_LOADING_FILES(state, payload: boolean) {
    state.loadingFiles = payload;
  },
  SET_LOADING_DATASOURCE_PROFILE(state, payload: boolean) {
    state.loadingDatasourceProfile = payload;
  },
  SET_RELATED_EXPERIMENTS(state, payload: IExperiment[]) {
    state.relatedExperiments = payload;
  },
  ADD_RELATED_EXPERIMENTS(state, experiments: IExperiment[]) {
    state.relatedExperiments.push(...experiments);
  },
  DELETE_RELATED_EXPERIMENT(state, experimentId) {
    state.relatedExperiments = state.relatedExperiments.filter(
      (ds) => ds.id != experimentId
    );
  },
  SET_DATASOURCE_DETAIL(state, datasourceObject: DatasourceDetail) {
    state.datasourceDetail = datasourceObject;
  },
  SET_FOLDER_NAME(state, name: string) {
    if (state.datasourceDetail) {
      state.datasourceDetail.folderName = name;
    }
  },
  SET_TOTAL_FILES(state, payload: number) {
    state.totalFiles = payload;
  },
  SET_TOTAL_SIZE(state, payload: number) {
    state.totalSize = payload;
  },
  SET_DATASOURCE_PROFILE(state, payload: DatasourceProfile) {
    delete payload.variables.snapshotDate;
    for (const x in payload.variables) {
      delete payload.variables[x].value_counts_without_nan;
    }
    state.datasourceProfile = payload;
  },
  SET_EDA_NUMERIC(state, payload: EdaNumeric) {
    state.edaNumeric = payload;
  },
  SET_CORRELATION(state, payload: { [key: string]: number }) {
    state.correlation = payload;
  },
  CLEAR(state) {
    Object.assign(state, getDefaultState());
  },
  SET_ENCODING(state, payload: string) {
    state.encoding = payload;
  },
  SET_CURRENT_FOLDER(state, payload: IFolder) {
    if (payload) {
      state.currentFolder = payload;
      state.currentFolder.folderPath.push({
        name: payload.name,
        id: payload.id,
        folderPath: [],
      });
    } else {
      state.currentFolder = {
        id: ROOT_FOLDER,
        name: "HOME",
        folderPath: [
          {
            name: "HOME",
            id: "ROOT",
            folderPath: [],
          },
        ],
      };
    }
  },
  SET_FOLDERS(state, payload: IFolder[]) {
    state.folders = payload;
  },
  APPEND_FOLDERS(state, payload: IFolder[]) {
    state.folders.push(...payload);
  },
  SET_WORKBOOK(state, payload: WorkBook) {
    state.workbook = payload;
  },
  SET_SELECTED_SHEET_NAME(state, payload: string) {
    state.selectedSheetName = payload;
  },
  SET_SELECTED_SHEET_INDEX(state, payload: number) {
    state.selectedSheetIndex = payload;
  },
  INCREASE_LOAD_COUNT(state) {
    state.loadCount++;
  },
};

interface QueryDatasourcePayload {
  name: string;
  type: string;
  createdBy: string;
  lastModifiedDateFrom: string;
  lastModifiedDateTo: string;
  status: string;
  limit: number;
  sort: string;
  dsc: boolean;
  next: boolean;
  folderId: string;
  workspaceId: string;
}

function getQueryFromPayload(
  state: DatasourceState,
  payload?: QueryDatasourcePayload
) {
  let name = "";
  let type = "";
  let createdBy = "";
  let lastModifiedDateFrom = "";
  let lastModifiedDateTo = "";
  let status = "";
  let limit = LIMIT;
  let sort = SORT;
  let dsc = DSC;
  let next = false;
  let folderId = "";
  if (payload) {
    name = !isNil(payload.name) ? payload.name : "";
    type = !isNil(payload.type) ? payload.type : "";
    createdBy = !isNil(payload.createdBy) ? payload.createdBy : "";
    lastModifiedDateFrom = !isNil(payload.lastModifiedDateFrom)
      ? payload.lastModifiedDateFrom
      : "";
    lastModifiedDateTo = !isNil(payload.lastModifiedDateTo)
      ? payload.lastModifiedDateTo
      : "";
    status = !isNil(payload.status) ? payload.status : "";
    limit = !isNil(payload.limit) ? payload.limit : LIMIT;
    sort = !isNil(payload.sort) ? payload.sort : SORT;
    dsc = !isNil(payload.dsc) ? payload.dsc : DSC;
    next = !isNil(payload.next) ? payload.next : false;
    folderId = !isNil(payload.folderId) ? payload.folderId : "";
  }
  let url = `/metadata/datasource/q?name=${name}&_limit=${limit}&_sort=${sort}&_dsc=${dsc}&workspaceId=${payload?.workspaceId}`;
  if (type != "") {
    url += `&type=${type}`;
  }
  if (createdBy != "") {
    url += `&createdBy=${createdBy}`;
  }
  if (lastModifiedDateFrom != "") {
    url += `&lastModifiedDateFrom=${lastModifiedDateFrom}`;
  }
  if (lastModifiedDateTo != "") {
    url += `&lastModifiedDateTo=${lastModifiedDateTo}`;
  }
  if (status != "") {
    url += `&status=${status}`;
  }
  if (next) {
    url += `&_t=${state.tokenDatasources}`;
  }
  if (folderId) {
    url += `&folderId=${folderId}`;
  }
  return url;
}

const datasourceActions: ActionTree<DatasourceState, State> = {
  async loadFolder(
    { commit },
    { id, workspaceId }: { id: string; workspaceId: string }
  ) {
    const { data: datasource } = await $http.get<Datasource>(
      `/metadata/datasource/${id}?workspaceId=${workspaceId}`
    );
    // this.dispatch("identities/loadIdentities", [datasource.createdBy]);
    commit("SET_CURRENT_FOLDER", datasource);
  },
  async loadFolders(
    { commit },
    {
      parentFolderId,
      workspaceId,
    }: { parentFolderId: string; workspaceId: string }
  ) {
    const {
      data: { content: folders, token },
    } = await $http.get(
      `/metadata/datasource/q?folderId=${parentFolderId}&_limit=${32}&type=FOLDER&_sort=${SORT}&_dsc=${DSC}&workspaceId=${workspaceId}`
    );
    commit("SET_FOLDERS", folders);
    commit("SET_TOKEN_FOLDERS", token);
  },
  async loadMoreFolders(
    { state, commit },
    {
      parentFolderId,
      workspaceId,
    }: { parentFolderId: string; workspaceId: string }
  ) {
    const {
      data: { content: folders, token },
    } = await $http.get(
      `/metadata/datasource/q?folderId=${parentFolderId}&_limit=${32}&type=FOLDER&_sort=${SORT}&_dsc=${DSC}&_t=${
        state.tokenFolders
      }&workspaceId=${workspaceId}`
    );
    commit("APPEND_FOLDERS", folders);
    commit("SET_TOKEN_FOLDERS", token);
  },
  async createFolder(_, payload: Datasource) {
    try {
      const datasource: DatasourceDetail = (
        await $http.post("/metadata/datasource", payload)
      ).data;

      return datasource;
    } catch (err: any) {
      if (request.isAxiosError(err)) {
        /// TODO: backend need to supply error code instead of error message
        /// for now, checking error message is necessary
        if (
          err.response?.data?.message ===
          "The maximum level of folder could be created is 3"
        ) {
          throw new Error("message.create.folder.error.max_level");
        } else if (
          err.response?.data?.message === "The maximum number of folders is 32"
        ) {
          throw new Error("message.create.folder.error.max_child");
        }
      }

      throw new Error("message.create.folder.error.generic");
    }
  },
  async loadDatasources({ commit, state }, payload?: QueryDatasourcePayload) {
    try {
      commit("SET_LOADING", true);
      const url = getQueryFromPayload(state, payload);
      const result = await $http.get<{
        content: Datasource[];
        token: string;
      }>(url);
      if (result) {
        // this.dispatch(
        //   "identities/loadIdentities",
        //   new Set(result.data.content.map((ds) => ds.createdBy))
        // );
        if (payload && payload.next) {
          commit("ADD_DATASOURCES", result.data.content);
        } else {
          commit("SET_DATASOURCES", result.data.content);
        }
        commit("INCREASE_LOAD_COUNT");
        commit("SET_TOKEN_DATASOURCES", result.data.token);
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async loadMoreDatasources(
    { state, commit },
    payload?: {
      name: string;
      limit: number;
      sort: string;
      dsc: boolean;
      workspaceId: string;
    }
  ) {
    if (!state.loading) {
      commit("SET_LOADING", true);
      try {
        let name = "";
        let limit = LIMIT;
        let sort = SORT;
        let dsc = DSC;
        if (payload) {
          name = !isNil(payload.name) ? payload.name : "";
          limit = !isNil(payload.limit) ? payload.limit : LIMIT;
          sort = !isNil(payload.sort) ? payload.sort : SORT;
          dsc = !isNil(payload.dsc) ? payload.dsc : DSC;
        }
        const result = await $http.get<{
          content: Datasource[];
          token: string;
        }>(
          `/metadata/datasource/q?name=${name}&_limit=${limit}&_sort=${sort}&_dsc=${dsc}&_t=${state.tokenDatasources}&workspaceId=${payload?.workspaceId}`
        );
        if (result) {
          // this.dispatch(
          //   "identities/loadIdentities",
          //   new Set(result.data.content.map((ds) => ds.createdBy))
          // );
          commit("ADD_DATASOURCES", result.data.content);
          commit("SET_TOKEN_DATASOURCES", result.data.token);
        }
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_LOADING", false);
      }
    }
  },
  async createDatasource({ dispatch }, { newDatasource, listRow, files }) {
    if (!newDatasource) {
      return;
    }

    const datasource: DatasourceDetail = (
      await $http.post("/metadata/datasource", newDatasource)
    ).data;
    dispatch("uploadSample", { datasource, listRow });
    await dispatch("addFileDatasource", {
      datasourceId: datasource.id,
      uploadFiles: files,
      workspaceId: datasource.workspaceId,
    });
    return datasource;
  },
  async createPrediction({ state, dispatch }, { files, workspaceId }) {
    await dispatch("addFilePrediction", {
      experimentId: state.experimentId,
      uploadFiles: files,
      workspaceId: workspaceId,
    });
    return state.experimentId;
  },
  uploadSample(_, { datasource, listRow }) {
    return $http.post(
      `/workers/static/ds-id/${datasource.id}/sample?workspaceId=${datasource.workspaceId}`,
      listRow.slice(0, 100)
    );
  },
  async delete(
    _,
    { datasourceId, workspaceId }: { datasourceId: string; workspaceId: string }
  ): Promise<boolean> {
    try {
      await $http.delete(
        `/metadata/datasource/${datasourceId}?workspaceId=${workspaceId}`
      );
      return true;
    } catch (_) {
      return false;
    }
  },
  async folderHasDatasourceInUse(
    _,
    { folderId, workspaceId }: { folderId: string; workspaceId: string }
  ): Promise<boolean> {
    const { data } = await $http.get(
      `/metadata/datasource/folder-id/${folderId}/data-used?workspaceId=${workspaceId}`
    );
    return data && data.length;
  },
  async deleteAll(
    { dispatch },
    {
      datasourceIds,
      workspaceId,
    }: { datasourceIds: string[]; workspaceId: string }
  ): Promise<Array<boolean>> {
    return Promise.all(
      datasourceIds.map((datasourceId) =>
        dispatch("delete", { datasourceId, workspaceId })
      )
    );
  },
  async addFileDatasource(
    { state },
    {
      datasourceId,
      uploadFiles,
      workspaceId,
    }: { datasourceId: string; workspaceId: string; uploadFiles: File[] }
  ) {
    const extension =
      uploadFiles[0].type.indexOf("spreadsheet") >= 0 ? "xlsx" : "csv";
    const fileObject: FileDetail = {
      datasourceId: datasourceId,
      name: uploadFiles[0].name,
      extension: extension,
      size: uploadFiles[0].size,
      type: "DATASOURCE",
      encoding: state.encoding,
      workspaceId: workspaceId,
      sheetName: state.selectedSheetName,
      sheetIndex: state.selectedSheetIndex,
    };

    const result2 = (await $http.post(`/workers/blob/sas`, fileObject)).data;
    this.dispatch(
      "tasks/uploadFileToBlob",
      {
        sasData: result2,
        file: uploadFiles[0],
        link: `/workspace/${workspaceId}/datasource/${datasourceId}`,
      },
      { root: true }
    );

    return result2;
  },
  async addFilePrediction(
    { state, dispatch, commit },
    {
      experimentId,
      uploadFiles,
      workspaceId,
    }: { experimentId: string; uploadFiles: File[]; workspaceId: string }
  ) {
    const extension =
      uploadFiles[0].type.indexOf("spreadsheet") >= 0 ? "xlsx" : "csv";
    const fileObject: FileDetail = {
      datasourceId: experimentId,
      name: uploadFiles[0].name,
      extension: extension,
      size: uploadFiles[0].size,
      type: "AUTOML_PREDICTION",
      encoding: state.encoding,
      workspaceId: workspaceId,
      sheetName: state.selectedSheetName,
      sheetIndex: state.selectedSheetIndex,
    };

    const result2 = (await $http.post(`/workers/blob/sas`, fileObject)).data;
    await dispatch(
      "tasks/uploadFileToBlob",
      {
        sasData: result2,
        file: uploadFiles[0],
        link: `/workspace/${workspaceId}/experiment/automl/${experimentId}/prediction`,
      },
      { root: true }
    );
    const prediction: Prediction = {
      createdBy: "",
      description: state.desc,
      experimentId: state.experimentId,
      fileInputName: uploadFiles[0].name,
      inputBlobId: result2.file.id + `.${extension}`,
      modelId: state.modelId,
      modelName: state.modelName,
      name: state.nameNewDatasource,
      runExplain: state.explaination,
      workspaceId: workspaceId,
      sheetIndex: state.selectedSheetIndex,
      sheetName: state.selectedSheetName,
      fileExtension: extension,
    };
    const predictionEntity: IAutoMLPrediction = (
      await $http.post("/metadata/prediction", prediction)
    ).data;
    await $http.post(
      `/workers/job/prediction-id/${predictionEntity.id}/run?workspaceId=${workspaceId}`,
      {}
    );
    commit("CLEAR_NEW_DATASOURCE");
    return predictionEntity;
  },

  async moveToFolder(
    { dispatch },
    { ds, destinationId }: { ds: Datasource; destinationId: string }
  ): Promise<Error | void> {
    try {
      ds.folderId = destinationId;
      await dispatch("updateDatasource", ds);
    } catch (err: any) {
      if (request.isAxiosError(err)) {
        /// TODO: backend need to supply error code instead of error message
        /// for now, checking error message is necessary
        if (
          err.response?.data?.message ===
          "The maximum level of folder could be created is 3"
        ) {
          return new Error("message.move.folder.error.max_level");
        } else if (
          err.response?.data?.message === "The maximum number of folders is 32"
        ) {
          return new Error("message.move.folder.error.max_child");
        }
      }

      return new Error("message.move.error.generic");
    }
  },

  async updateDatasource(_, newDatasource: Datasource) {
    return (await $http.put("metadata/datasource", newDatasource)).data;
  },
  async downloadFile(_, { id, workspaceId }) {
    const downloadUrl = await $http.get<string>(
      `/workers/blob/download/${id}?workspaceId=${workspaceId}`
    );
    window.open(downloadUrl.data, "_blank");
  },
  async loadFilesByDsId(
    { commit },
    {
      datasourceId,
      name,
      sort,
      dsc,
      workspaceId,
    }: {
      datasourceId: string;
      name: string;
      sort: string;
      dsc: boolean;
      workspaceId: string;
    }
  ) {
    if (!sort) {
      sort = SORT;
    }
    if (isNil(dsc)) {
      dsc = DSC;
    }
    const result = await $http.get<{ content: FileDetail[]; token: string }>(
      `/workers/blob/q?datasourceId=${datasourceId}&name=${name}&_limit=${LIMIT}&_sort=${sort}&_dsc=${dsc}&workspaceId=${workspaceId}`
    );
    if (result) {
      // this.dispatch(
      //   "identities/loadIdentities",
      //   new Set(result.data.content.map((ds) => ds.createdBy))
      // );
      commit("SET_FILES", result.data.content);
      commit("SET_TOKEN_FILES", result.data.token);
    }
  },
  async loadMoreFilesByDsId(
    { state, commit },
    {
      datasourceId,
      name,
      sort,
      dsc,
      workspaceId,
    }: {
      datasourceId: string;
      name: string;
      sort: string;
      dsc: boolean;
      workspaceId: string;
    }
  ) {
    if (!state.loadingFiles) {
      commit("SET_LOADING_FILES", true);
      try {
        if (!sort) {
          sort = SORT;
        }
        if (isNil(dsc)) {
          dsc = DSC;
        }
        const result = await $http.get<{
          content: FileDetail[];
          token: string;
        }>(
          `/workers/blob/q?datasourceId=${datasourceId}&name=${name}&_limit=${LIMIT}&_sort=${sort}&_dsc=${dsc}&_t=${state.tokenFiles}&workspaceId=${workspaceId}`
        );
        if (result) {
          // this.dispatch(
          //   "identities/loadIdentities",
          //   new Set(result.data.content.map((ds) => ds.createdBy))
          // );
          commit("ADD_FILES", result.data.content);
          commit("SET_TOKEN_FILES", result.data.token);
        }
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_LOADING_FILES", false);
      }
    }
  },
  async deleteFile(
    { commit },
    { fileId, workspaceId }: { fileId: string; workspaceId: string }
  ) {
    try {
      await $http.delete(`/workers/blob/${fileId}?workspaceId=${workspaceId}`);
      commit("DELETE_FILE", fileId);
    } catch (error) {
      console.error(error);
      return error;
    }
  },
  async datasourceConfig(_, data) {
    await $http.post("/metadata/datasource-config/exp", data);
  },
  async loadRelatedExperiments(
    { commit },
    { datasourceId, workspaceId }: { datasourceId: string; workspaceId: string }
  ) {
    const relatedExperiments = (
      await $http.get<{ content: IExperiment[]; token: string }>(
        `/metadata/experiment/ds-id/${datasourceId}/?_limit=${LIMIT}&_sort=${SORT}&_dsc=${DSC}&workspaceId=${workspaceId}`
      )
    ).data;
    // this.dispatch(
    //   "identities/loadIdentities",
    //   new Set(relatedExperiments.content.map((x) => x.createdBy))
    // );
    commit("SET_RELATED_EXPERIMENTS", relatedExperiments.content);
    commit("SET_TOKEN_RELATED_EXPERIMENTS", relatedExperiments.token);
  },
  async loadMoreRelatedExperiments(
    { state, commit },
    { datasourceId, workspaceId }: { datasourceId: string; workspaceId: string }
  ) {
    if (!state.loading) {
      try {
        commit("SET_LOADING", true);
        const relatedExperiments = (
          await $http.get<{ content: IExperiment[]; token: string }>(
            `/metadata/experiment/ds-id/${datasourceId}/?_t=${state.tokenRelatedExperiments}&_limit=${LIMIT}&_sort=${SORT}&_dsc=${DSC}&workspaceId=${workspaceId}`
          )
        ).data;
        // this.dispatch(
        //   "identities/loadIdentities",
        //   new Set(relatedExperiments.content.map((x) => x.createdBy))
        // );
        commit("ADD_RELATED_EXPERIMENTS", relatedExperiments.content);
        commit("SET_TOKEN_RELATED_EXPERIMENTS", relatedExperiments.token);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_LOADING", false);
      }
    }
  },
  async deleteRelatedExperiment(
    { commit },
    { experimentId, workspaceId }: { experimentId: string; workspaceId: string }
  ) {
    try {
      await $http.delete(
        `/metadata/experiment/${experimentId}?workspaceId=${workspaceId}`
      );
      commit("DELETE_RELATED_EXPERIMENT", experimentId);
      return true;
    } catch (_: any) {
      return false;
    }
  },
  async deleteAllRelatedExperiment(
    { dispatch },
    {
      experimentIds,
      workspaceId,
    }: { experimentIds: string[]; workspaceId: string }
  ): Promise<Array<boolean>> {
    return Promise.all(
      experimentIds.map((e) =>
        dispatch("deleteRelatedExperiment", {
          experimentId: e,
          workspaceId: workspaceId,
        })
      )
    );
  },
  async loadDatasourceDetail(
    { commit },
    { id, workspaceId }: { id: string; workspaceId: string }
  ) {
    const datasource = (
      await $http.get(`/metadata/datasource/${id}?workspaceId=${workspaceId}`)
    ).data;
    // this.dispatch("identities/loadIdentities", [datasource.createdBy]);
    if (datasource.folderId !== "ROOT") {
      $http
        .get<IExperiment>(
          `/metadata/datasource/${datasource.folderId}?workspaceId=${workspaceId}`
        )
        .then((folder) => {
          const folderName = folder.data.name;
          commit("SET_DATASOURCE_DETAIL", datasource);
          commit("SET_FOLDER_NAME", folderName);
        });
    } else {
      commit("SET_DATASOURCE_DETAIL", datasource);
    }
  },
  async loadTotalFiles(
    { commit },
    { id, workspaceId }: { id: string; workspaceId: string }
  ) {
    const result = (
      await $http.get<{ totalFile: number; totalSize: number }>(
        `/workers/blob/statistics?datasourceId=${id}&workspaceId=${workspaceId}`
      )
    ).data;
    commit("SET_TOTAL_FILES", result.totalFile);
    commit("SET_TOTAL_SIZE", result.totalSize);
  },
  async loadDatasourceProfile(
    { commit },
    { id, workspaceId }: { id: string; workspaceId: string }
  ) {
    commit("SET_LOADING_DATASOURCE_PROFILE", true);
    try {
      const result = (
        await $http.get<{ data: DatasourceProfile }>(
          `/workers/static/ds-id/${id}/data-profile?workspaceId=${workspaceId}`
        )
      ).data;
      commit("SET_DATASOURCE_PROFILE", result);
      commit("SET_EDA_FINISHED", true);
    } catch (error) {
      console.error(error);
    } finally {
      commit("SET_LOADING_DATASOURCE_PROFILE", false);
    }
  },
  async loadNumeric(
    { commit },
    { id, workspaceId }: { id: string; workspaceId: string }
  ) {
    const result = (
      await $http.get<{ data: EdaNumeric }>(
        `/workers/static/ds-id/${id}/numeric?workspaceId=${workspaceId}`
      )
    ).data;
    commit("SET_EDA_NUMERIC", result);
  },
  async loadCorrelation(
    { commit },
    {
      id,
      columnName,
      workspaceId,
    }: { id: string; columnName: string; workspaceId: string }
  ) {
    const result = (
      await $http.get<{ data: DatasourceProfile }>(
        `/workers/static/ds-id/${id}/correlations/${columnName}?workspaceId=${workspaceId}`
      )
    ).data;
    commit("SET_CORRELATION", result);
  },
  clearAll({ commit }) {
    commit("CLEAR");
  },
  changeWorksheet({ state, commit }, index: number) {
    if (state.workbook) {
      const wsname = state.workbook.SheetNames[index];
      const worksheet = state.workbook.Sheets[wsname];

      /* Convert array of arrays */
      const data: string[][] = utils.sheet_to_json(worksheet, {
        raw: false,
        defval: null,
      });
      const listHeader = Object.keys(data[0]).map((x: any) => {
        const data = {
          field: x,
          header: x,
        };
        return data;
      });
      if (data.length) {
        commit("SET_SELECTED_SHEET_NAME", wsname);
        commit("SET_SELECTED_SHEET_INDEX", index);
        commit("SET_TOTALROW", data.length);
        commit("SET_LIST_ROW_DATA", data);
        const typeData = getListDataType(data);
        const _listHeader = typeData.map((obj: any, index: number) =>
          Object.assign({}, obj, listHeader[index])
        );
        commit("SET_LIST_HEADER_DATA", _listHeader);
        const listPreviewData = _listHeader.map((x: any) => {
          return {
            columns: x.header,
            dataType: x.code.type,
            role: getDataRoleFromDataType(x.code.type),
            format: x.code.format,
          };
        });
        commit("SET_LIST_REVIEW_DATA", listPreviewData);
        commit("SET_SELECT_HEADER", listPreviewData[0]);
      }
    }
  },
};

const datasourceGetters: GetterTree<DatasourceState, State> = {
  getOneDataSource: () => async (id: string, ws: string) => {
    const datasource = (
      await $http.get(`/metadata/datasource/${id}?workspaceId=${ws}`)
    ).data;
    return datasource;
  },
  getSampleDataDatasource: () => async (id: string, ws: string) => {
    return (
      await $http.get(`/workers/static/ds-id/${id}/sample?workspaceId=${ws}`)
    ).data;
  },
  // Get latest 100 success files
  getSuccessFiles: () => async (id: string, ws: string) => {
    const limit = 100;
    const { content }: { content: FileDetail[] } = (
      await $http.get(
        `/workers/blob/q?datasourceId=${id}&status=completed&_limit=${limit}&_sort=${SORT}&_dsc=${false}&workspaceId=${ws}`
      )
    ).data;
    return content;
  },
  hasMoreDatasource: (state: DatasourceState) => (): boolean => {
    return !isNil(state.tokenDatasources) && state.tokenDatasources.length > 0;
  },
  hasMoreFiles: (state: DatasourceState) => (): boolean => {
    return !isNil(state.tokenFiles) && state.tokenFiles.length > 0;
  },
  hasMoreFolders: (state: DatasourceState): boolean => !!state.tokenFolders,
  getDatasourceDetailNumericSchemas: (state: DatasourceState) => () => {
    return state.datasourceDetail?.schemas?.filter(
      (schema) => schema.type === "float" || schema.type === "integer"
    );
  },
  hasMoreExperiment: (state: DatasourceState) => (): boolean => {
    return (
      !isNil(state.tokenRelatedExperiments) &&
      state.tokenRelatedExperiments.length > 0
    );
  },
  variables: (state: DatasourceState) => (start?: number, end?: number) => {
    if (state.datasourceProfile?.variables) {
      return Object.keys(state.datasourceProfile?.variables).slice(start, end);
    }
    return [];
  },
};

export const datasourceModule: Module<DatasourceState, State> = {
  namespaced: true,
  state: datasourceState,
  getters: datasourceGetters,
  mutations: datasourceMutations,
  actions: datasourceActions,
};
