
import { defineComponent, PropType } from "vue";
import VChart from "vue-echarts";
import { BillingItem } from "@/pages/Billing/types";
import { mapState } from "vuex";
import { useStore } from "@/store";

export default defineComponent({
  name: "BillingChart.vue",
  components: { VChart },
  props: {
    data: {
      type: Object as PropType<Array<BillingItem>>,
      default: () => new Array<BillingItem>(),
    },
    showCost: {
      type: Boolean,
      default: () => true,
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    ...mapState("application", ["theme"]),
    option(): unknown {
      const series: Array<{
        name: string;
        type: string;
        stack?: string;
        data: Array<Array<string | number>>;
      }> = [];

      if (this.$props.showCost) {
        series.push(
          ...[
            {
              name: this.$t("billing.cost_table.header.automl_cost"),
              type: "bar",
              stack: "total",
              data: this.$props.data.map((d) => [d.id, d.automlCost]),
            },
            {
              name: this.$t("billing.cost_table.header.autots_cost"),
              type: "bar",
              stack: "total",
              data: this.$props.data.map((d) => [d.id, d.autotsCost]),
            },
            {
              name: this.$t("billing.cost_table.header.experiment_cost"),
              type: "line",
              data: this.$props.data.map((d) => [d.id, d.experimentCost]),
            },
          ]
        );
      } else {
        series.push(
          ...[
            {
              name: this.$t("billing.cost_table.header.automl"),
              type: "bar",
              stack: "total",
              data: this.$props.data.map((d) => [d.id, d.automl]),
            },
            {
              name: this.$t("billing.cost_table.header.autots"),
              type: "bar",
              stack: "total",
              data: this.$props.data.map((d) => [d.id, d.autots]),
            },
            {
              name: this.$t("billing.cost_table.header.experiment"),
              type: "line",
              data: this.$props.data.map((d) => [d.id, d.experiment]),
            },
          ]
        );
      }

      const toLocaleNumber = (n: number, d?: number): string =>
        n.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: d,
          minimumFractionDigits: d,
        });

      return {
        tooltip: {
          trigger: "axis",
          formatter: (
            params: Array<{
              seriesName: string;
              name: string;
              data: any;
              encode: any;
              color: string;
            }>
          ) => {
            const tooltip = params
              .map(
                (param) =>
                  `<tr>
                      <td style="color: ${param.color}; font-size:2.5rem">
                        &bull;
                      </td>
                      <td>
                        ${param.seriesName}:
                      </td>
                      <td style="text-align: right" class="p-pl-4">
                        <b>
                          ${
                            this.showCost
                              ? "$" +
                                toLocaleNumber(
                                  Number(param.data[param.encode.y[0]]),
                                  2
                                )
                              : toLocaleNumber(
                                  Number(param.data[param.encode.y[0]])
                                )
                          }
                        </b>
                      </td>
                   </tr>`
              )
              .join("");

            return `${params[0].name} <table>${tooltip}</table>`;
          },
        },
        xAxis: {
          type: "category",
          data: this.$props.data.map((d) => d.id),
          axisLabel: {
            rotate: 15,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: (value: number): string =>
              this.$props.showCost
                ? `$${toLocaleNumber(value, 2)}`
                : toLocaleNumber(value),
          },
        },
        series,
      };
    },
  },
});
