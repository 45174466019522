
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
} from "echarts/components";
import { defineComponent, PropType } from "vue";
import { mapState } from "vuex";
import { GridComponent } from "echarts/components";
import { IExperiment } from "../../../types";
import { IAutoMLModel } from "@/types";
import { IAutoMLEvaluation } from "../../types";
import { toShortNumber } from "@/plugin/utils/shortNumber";

use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
  DatasetComponent,
]);

export default defineComponent({
  props: {
    id: {
      type: String,
    },
    model: {
      type: Object as PropType<IAutoMLModel>,
      required: true,
    },
    experiment: {
      type: Object as PropType<IExperiment>,
    },
    evaluation: {
      type: Array as PropType<IAutoMLEvaluation[]>,
      required: true,
    },
    currentType: {
      type: String,
    },
    selected: {
      type: Object,
    },
    zoom: {
      type: Object,
    },
    targetColumn: {
      type: String,
    },
    predictionType: {
      type: String,
      default: "",
    },
    evalClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      initOptions: {
        locale: this.$i18n.locale,
      },
    };
  },
  emits: ["update:currentType", "update:selected", "update:zoom"],
  computed: {
    ...mapState("application", ["theme"]),
    chartOption(): any {
      let dataZoom: {
        type: string;
        start?: number;
        end?: number;
        startValue?: number;
        endValue?: number;
      } = {
        type: "slider",
      };
      if (this.zoom) {
        if (this.zoom.start) {
          dataZoom.start = this.zoom.start;
        }
        if (this.zoom.end) {
          dataZoom.end = this.zoom.end;
        }
        if (this.zoom.startValue) {
          dataZoom.startValue = this.zoom.startValue;
        }
        if (this.zoom.endValue) {
          dataZoom.endValue = this.zoom.endValue;
        }
      } else {
        dataZoom.start = 0;
        dataZoom.end = 100;
      }

      // Prepare chart options based on Prediction Type
      let xAxisLabel = "";
      let titleActual = "";
      let titlePredict = "";
      let yAxis = undefined;
      let tooltipFormatter = (params: any) => {
        let tooltip = `<strong>${this.$t(
          "automl.label.tooltip.classification",
          {
            group: params[0].data.groupName,
            count: params[0].data.groupCount,
          }
        )}</strong>`;
        for (const series of params) {
          const value =
            series.seriesIndex === 1
              ? series.value.actualAvg
              : series.value.predictAvg;
          const shownValue =
            this.predictionType === "regression"
              ? toShortNumber(value)
              : parseFloat(value.toFixed(1)) + "%";
          let ratio = "";
          if (series.seriesIndex === 0 && series.value.ratio != Infinity) {
            ratio += `<span class=${
              series.value.ratio >= 0 ? "p-text-success" : "p-error"
            }>`;
            ratio += " (";
            ratio += parseFloat(series.value.ratio.toFixed(1)) + "%";
            ratio += ") ";
            ratio += "</span>";
          }
          tooltip += `<br/>${series.marker}${series.seriesName}<span class="p-ml-4 p-text-bold" style="float: right">${ratio}${shownValue}</span>`;
        }
        if (this.predictionType === "regression") {
          const percentile =
            "[" +
            toShortNumber(params[0].data.percentile10) +
            " ~ " +
            toShortNumber(params[0].data.percentile90) +
            "]";
          const actualValue =
            "[" +
            toShortNumber(params[0].data.actualMin) +
            " ~ " +
            toShortNumber(params[0].data.actualMax) +
            "]";
          tooltip += "<br>";
          tooltip += `<div class="p-flex-column">`;
          tooltip += `<div class="p-d-flex p-jc-between"> <span class="p-ml-3"> ${this.$t(
            "automl.label.confidence.interval"
          )}</span><span class="p-text-bold">${percentile}</span></div>`;
          tooltip += `<div class="p-d-flex p-jc-between"> <span class="p-ml-3"> ${this.$t(
            "automl.label.min.max.interval"
          )}</span><span class="p-text-bold">${actualValue}</span></div>`;
          tooltip += "</div>";
        }
        return tooltip;
      };
      if (this.predictionType === "regression") {
        xAxisLabel = this.$t("automl.label.xaxis.regression", {
          target: this.targetColumn,
        });
        titleActual = this.$t("automl.label.average.actual", {
          target: this.targetColumn,
        });
        titlePredict = this.$t("automl.label.average.predict", {
          target: this.targetColumn,
        });
        yAxis = [
          {
            type: "value",
            position: "left",
            axisLabel: {
              formatter: (value: number) => {
                return toShortNumber(value);
              },
            },
          },
          {
            type: "value",
            position: "right",
            axisLabel: {
              formatter: (value: number) => {
                return toShortNumber(value);
              },
            },
          },
        ];
      } else {
        xAxisLabel = this.$t("automl.label.xaxis.classification", {
          class: this.evalClass,
        });
        titleActual = this.$t("automl.label.average.actual.class", {
          target: this.targetColumn,
          class: this.evalClass,
        });
        titlePredict = this.$t("automl.label.average.predict.class", {
          target: this.targetColumn,
          class: this.evalClass,
        });
        yAxis = [
          {
            type: "value",
            position: "left",
            min: 0,
            max: 100,
            axisLabel: {
              formatter: (value: number) => {
                return Math.round(value) + "%";
              },
            },
          },
          {
            type: "value",
            position: "right",
            min: 0,
            max: 100,
            axisLabel: {
              formatter: (value: number) => {
                return Math.round(value) + "%";
              },
            },
          },
        ];
      }
      const option: any = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            label: {
              precision: 0,
            },
          },
          formatter: tooltipFormatter,
        },
        dataset: [
          {
            source: this.evaluation.map((e: any) => {
              let actual = 0;
              let predict = 0;
              let ratio = 0;
              if (this.predictionType === "regression") {
                actual = e.actualAvg;
                predict = e.predictAvg;
                ratio = (predict / actual - 1) * 100;
              } else {
                actual = e.actualAvg * 100;
                predict = e.predictAvg * 100;
                ratio = predict - actual;
              }
              return {
                ...e,
                actualAvg: actual,
                predictAvg: predict,
                percentileLower: e.percentile10,
                percentileUpper: e.percentile90 - e.percentile10,
                ratio: ratio,
              };
            }),
          },
        ],
        grid: {
          left: "5%",
          right: "5%",
          containLabel: true,
        },
        legend: {
          data: [titlePredict, titleActual],
          top: "auto",
          bottom: "auto",
          selected: { titlePredict: true, titleActual: true },
          padding: [5, 5, 20, 5],
        },
        xAxis: {
          type: "category",
          name: xAxisLabel,
          nameLocation: "center",
          nameGap: 25,
          nameTextStyle: {
            fontSize: 14,
          },
        },
        yAxis: yAxis,
        series: [
          {
            name: titlePredict,
            type: this.currentType,
            encode: { x: "groupName", y: "predictAvg" },
            datasetIndex: 0,
            universalTransition: true,
            animationDurationUpdate: 1000,
          },
          {
            name: titleActual,
            type: this.currentType,
            encode: { x: "groupName", y: "actualAvg" },
            datasetIndex: 0,
            universalTransition: true,
            animationDurationUpdate: 1000,
          },
          {
            name: "percentileLower",
            type: "line",
            encode: { x: "groupName", y: "percentileLower" },
            datasetIndex: 0,
            lineStyle: {
              opacity: 0,
            },
            stack: "percentile",
            symbol: "none",
            tooltip: {
              show: false,
            },
          },
          {
            name: this.$t("automl.label.confidence.interval"),
            type: "line",
            encode: { x: "groupName", y: "percentileUpper" },
            datasetIndex: 0,
            lineStyle: {
              opacity: 0,
            },
            areaStyle: {
              color: "#ccc",
            },
            stack: "percentile",
            symbol: "none",
            tooltip: {
              show: false,
            },
          },
        ],
        dataZoom: [
          dataZoom,
          {
            type: "inside",
          },
        ],
        toolbox: {
          show: true,
          orient: "vertical",
          right: "5px",
          feature: {
            magicType: {
              type: ["line", "bar"],
            },
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
            saveAsImage: { name: this.experiment?.id },
          },
        },
      };
      if (this.selected) {
        option.legend.selected = this.selected;
      }

      if (this.predictionType === "regression") {
        option.legend.data.push({
          name: this.$t("automl.label.confidence.interval"),
          icon: "rect",
          itemStyle: {
            color: "#ccc",
          },
        });
        option.legend.selected[this.$t("automl.label.confidence.interval")] =
          true;
      }

      return option;
    },
  },
  methods: {
    magicTypeChanged(event: any) {
      this.$emit("update:currentType", event.currentType);
    },
    legendSelectChanged(event: any) {
      this.$emit("update:selected", event.selected);
    },
    dataZoom(event: any) {
      if (event.batch) {
        this.$emit("update:zoom", event.batch[0]);
      } else {
        this.$emit("update:zoom", event);
      }
    },
  },
});
