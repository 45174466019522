
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "@/store";
import { mapActions, mapState } from "vuex";
import { mapMutations } from "vuex";
import { TypeData } from "@/pages/Datasource/types";
import NewPredictionUpload from "./NewPredictionUpload.vue";
import NewPredictionPreview from "./NewPredictionPreview.vue";
import FixedHeader from "@/components/FixedHeader.vue";

const MAX_FILE_SIZE = 1024 * 1024 * 1024;

export default defineComponent({
  components: {
    NewPredictionUpload,
    NewPredictionPreview,
    FixedHeader,
  },
  props: {
    workspaceId: {
      type: String,
    },
  },
  data() {
    return {
      totalRow: 0,
      typeData: [] as TypeData[],
      typeDate: [] as TypeData[],
      text: null,
      delimiter: "",
      linebreak: "",
      selectedDelimiter: "",
      delimiters: [] as TypeData[],
      selectedLinebreak: "",
      linebreaks: [] as TypeData[],
      listValuePreviewData: [],
      previewDataLable: "",
      hasError: true,
      errorMessage: "",
      data: [] as any[][],
      hasSetMetadata: false,
      maxFileSize: MAX_FILE_SIZE,
      step: 0,
      showDialog: true,
      uploading: false,
      explaination: false,
      showExplaination: false,
    };
  },
  created() {
    this.SET_EXPERIMENT_ID(this.experimentDetail.id);
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    ...mapState("experiments", ["experimentDetail"]),
    ...mapState("datasources", [
      "nameNewDatasource",
      "listTypeData",
      "listRowData",
      "listHeader",
      "showUploadButton",
      "selectedFile",
      "disabled",
      "modelId",
      "desc",
    ]),
    name: {
      get(): string {
        return this.nameNewDatasource;
      },
      set(value: string) {
        this.SET_NAME_DATASOURCE(value.trim());
      },
    },
    description: {
      get(): string {
        return this.desc;
      },
      set(value: string) {
        this.SET_DESC_DATASOURCE(value);
      },
    },
    isEnabled(): boolean {
      return this.name.trim() != "" && this.modelId !== "";
    },
  },
  methods: {
    ...mapMutations("datasources", [
      "SET_NAME_DATASOURCE",
      "CLEAR_NEW_DATASOURCE",
      "SET_EXPERIMENT_ID",
      "SET_DESC_DATASOURCE",
      "SET_SELECT_FILE",
      "SET_LIST_ROW_DATA",
      "SET_SHOW_NAME_DATASOURCE",
      "SHOW_UPLOAD",
      "SET_EXPLAINATION",
      "CLEAR_NEW_DATASOURCE",
    ]),
    ...mapActions("datasources", ["createPrediction"]),
    onClear() {
      this.CLEAR_NEW_DATASOURCE();
      this.increaseStep(0);
      this.showExplaination = false;
    },
    increaseStep(newStep: number) {
      this.step = newStep;
    },
    showCheckBox(showCk: boolean) {
      this.showExplaination = showCk;
    },
    onUpload() {
      if (this.nameNewDatasource.trim() === "") {
        this.SET_SHOW_NAME_DATASOURCE(true);
        this.SHOW_UPLOAD(true);
        return;
      }
      this.SET_EXPLAINATION(this.explaination);
      this.uploading = true;

      this.createPrediction({
        listTypeData: this.listTypeData,
        listRow: this.listRowData,
        files: this.selectedFile,
        workspaceId: this.workspaceId,
      })
        .then(() => {
          this.step = 0;
          this.$emit("clicked", true);
          this.showDialog = false;
        })
        .finally(() => {
          this.uploading = false;
        });
    },
    handleClose() {
      this.$emit("clicked", false);
      this.step = 0;
      this.showDialog = false;
      this.CLEAR_NEW_DATASOURCE();
    },
  },
});
