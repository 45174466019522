
import { defineComponent, PropType } from "vue";
import Empty from "./Empty.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { IModelEvaluationTimeseries } from "@/pages/Experiment/automl/types";
import KpiTag from "@/components/KpiTag.vue";
import { KpiKey, KpiName } from "@/pages/Experiment/types";
import { sortBy } from "lodash";
import { KpiShownInPercent } from "@/pages/Experiment/types";

export default defineComponent({
  components: {
    Empty,
    KpiTag,
  },
  props: {
    model: {
      type: String,
      required: true,
    },
    idFilter: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
  },
  beforeMount() {
    this.load();
    if (this.kpiSortTypes) {
      this.kpiSortBy = this.kpiSortTypes[0];
    }
  },
  beforeUnmount() {
    this.CLEAR_MODEL_EVALUATION();
  },
  data() {
    return {
      kpiSortBy: "",
      sortField: null,
      sortOrder: 1,
      isPercentage: KpiShownInPercent,
      kpiKey: KpiKey,
      loadCount: 0,
    };
  },
  computed: {
    ...mapState("experiments", ["datasourceConfig", "experimentDetail"]),
    ...mapState("autoML", ["modelEvaluation"]),
    loading(): boolean {
      return this.modelEvaluation.loading;
    },
    timeseriesEvaluation(): Array<IModelEvaluationTimeseries> {
      if (!this.sortField) {
        return this.modelEvaluation.timeseries;
      }

      return sortBy<IModelEvaluationTimeseries>(
        this.modelEvaluation.timeseries,
        this.sortField === "kpi"
          ? (o) => (o.kpi as any)[this.kpiSortBy] * this.sortOrder
          : (o) =>
              (o[
                this.sortField as unknown as keyof IModelEvaluationTimeseries
              ] as number) * this.sortOrder
      );
    },
    targetColumn(): string {
      return this.datasourceConfig && this.datasourceConfig.length
        ? this.datasourceConfig[0].targetColumn
        : "";
    },
    kpiSortTypes(): Array<string> {
      const res =
        this.experimentDetail?.config.otherMetrics?.map(
          (m: string) => KpiName[m]
        ) || [];

      if (this.experimentDetail?.config.primaryMetric) {
        res.unshift(KpiName[this.experimentDetail.config.primaryMetric]);
      }

      return res;
    },
    payload(): {
      experimentId: string;
      workspaceId: string;
      model: string;
      idColumns: Array<string>;
      idFilter: Record<string, any>;
    } {
      return {
        experimentId: this.experimentDetail.id || "",
        workspaceId: this.experimentDetail.workspaceId || "",
        model: this.model,
        idColumns: this.experimentDetail.config.timeSeriesIdentifiers,
        idFilter: this.idFilter,
      };
    },
  },
  methods: {
    ...mapActions("autoML", ["loadModelEvaluation"]),
    ...mapMutations("autoML", ["CLEAR_MODEL_EVALUATION"]),
    onSort(event: any) {
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
    },
    load() {
      if (
        this.payload.model &&
        this.payload.experimentId &&
        this.payload.workspaceId &&
        this.payload.idColumns
      ) {
        this.loadModelEvaluation(this.payload);
      }
    },
  },
  watch: {
    payload() {
      this.load();
    },
    kpiSortTypes: {
      handler(newVal) {
        console.log(newVal);
        this.kpiSortBy = newVal[0];
      },
      deep: true,
    },

    timeseriesEvaluation() {
      this.loadCount++;
    },
  },
});
