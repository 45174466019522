import { IdentityState, IOrganization } from "./types";
import { Module } from "vuex";
import { State } from "@/store";
import { $http } from "@/main";

export const identityModule: Module<IdentityState, State> = {
  namespaced: true,
  state: {
    users: [],
    organizations: {},
    uniqueUsersOfExperiments: [],
    uniqueUsersOfDatasources: [],
    uniqueUsersOfWorkspace: [],
  },
  mutations: {
    SET_IDENTITY(state, payload) {
      state.users[payload.id] = payload;
    },
    SET_IDENTITIES(state, payload) {
      state.users = payload;
    },
    SET_ORGANIZATION(state, payload: IOrganization) {
      state.organizations[payload.id] = payload;
    },
    SET_UNIQUE_USERS_OF_EXPERIMENT(state, users: string[]) {
      state.uniqueUsersOfExperiments = users;
    },
    SET_UNIQUE_USERS_OF_DATASOURCE(state, users: string[]) {
      state.uniqueUsersOfDatasources = users;
    },
    SET_UNIQUE_USERS_OF_WORKSPACE(state, users: string[]) {
      state.uniqueUsersOfWorkspace = users;
    },
  },
  actions: {
    async loadUniqueUsers({ commit }, ws: string) {
      const uniqueUsers = (
        await $http.get(`/metadata/experiment/u/created-by?workspaceId=${ws}`)
      ).data;
      // dispatch("loadIdentities", uniqueUsers);
      commit("SET_UNIQUE_USERS_OF_EXPERIMENT", uniqueUsers);
    },

    async loadUniqueUsersOfDatasource({ commit }, ws: string) {
      const uniqueUsers = (
        await $http.get(`/metadata/datasource/u/created-by?workspaceId=${ws}`)
      ).data;
      // dispatch("loadIdentities", uniqueUsers);
      commit("SET_UNIQUE_USERS_OF_DATASOURCE", uniqueUsers);
    },
    async loadUniqueUsersOfWorkspace({ commit }) {
      const uniqueUsers = (await $http.get(`/metadata/workspace/u/created-by`))
        .data;
      // dispatch("loadIdentities", uniqueUsers);
      commit("SET_UNIQUE_USERS_OF_WORKSPACE", uniqueUsers);
    },
    loadAllIdentities() {
      $http
        .get(`/users/identities`)
        .then((result) => result.data)
        .then((data) => {
          this.commit("identities/SET_IDENTITIES", data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    loadIdentities({ state }, userIds: string[]) {
      for (const id of userIds) {
        if (!(id in state.users))
          $http
            .get(`/users/identities/${id}`)
            .then((result) => result.data)
            .then((data) => {
              this.commit("identities/SET_IDENTITY", data);
            })
            .catch((err) => {
              console.error(err);
              this.commit("identities/SET_IDENTITY", {
                id: id,
                firstName: id,
                lastName: "",
              });
            });
      }
    },
    loadOrganizations({ state }, organizationIds: string[]) {
      for (const id of organizationIds) {
        if (!(id in state.organizations))
          $http
            .get(`/users/organizations/${id}`)
            .then((result) => result.data)
            .then((data) => {
              this.commit("identities/SET_ORGANIZATION", data);
            })
            .catch((err) => {
              console.error(err);
              this.commit("identities/SET_ORGANIZATION", {
                id: id,
                displayName: id,
              });
            });
      }
    },
  },
  getters: {
    getFullname: (state: IdentityState) => (id: string) => {
      const user = state.users.find((u) => u.id === id);
      if (localStorage.language && localStorage.language === "ja") {
        return user ? [user.lastName, user.firstName].join(" ") : "";
      } else {
        return user ? [user.firstName, user.lastName].join(" ") : "";
      }
    },
    getUsername: (state: IdentityState) => (id: string) => {
      const user = state.users.find((u) => u.id === id);
      return user ? user.userName : "";
    },
    getOrganizationName: (state: IdentityState) => (id: string) => {
      return state.organizations[id] ? state.organizations[id].displayName : "";
    },
  },
};
