
import { DataRole } from "@/types";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    role: {
      type: String,
      default: DataRole.Categorical,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconClass() {
      switch (this.role) {
        case DataRole.DateTime:
          return "icon-type-datetime";
        case DataRole.Numeric:
          return "icon-type-numeric";
        default:
          return "icon-type-categorical";
      }
    },
    textClass() {
      switch (this.role) {
        case DataRole.DateTime:
          return "data-role-datetime";
        case DataRole.Numeric:
          return "data-role-numeric";
        default:
          return "data-role-categorical";
      }
    },
    tooltip(): string {
      return this.showTooltip
        ? `datasource.datarole.${this.role.toLowerCase()}`
        : "";
    },
  },
});
