import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"height":"300px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_v_chart = _resolveComponent("v-chart")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_ProgressSpinner, {
          key: 0,
          class: "p-d-flex p-jc-between p-ai-center",
          style: {"height":"100%"}
        }))
      : (_openBlock(), _createBlock(_component_v_chart, {
          key: 1,
          option: _ctx.option,
          theme: _ctx.theme
        }, null, 8, ["option", "theme"]))
  ]))
}