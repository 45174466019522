import { ColumnType } from "@/pages/Datasource/types";
import moment from "moment";
import {
  ID_COLUMN_SUFFIX,
  IS_CATEGORICAL_THRESHOLD,
  SUPPORTED_DATE_FORMAT,
} from "./constant";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isPromise(promise: any): boolean {
  return !isNil(promise) && typeof promise.then === "function";
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isFunction(fun: any): boolean {
  return !isNil(fun) && typeof fun === "function";
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isString(text: any): boolean {
  return !isNil(text) && (typeof text === "string" || text instanceof String);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isNil(value: any): boolean {
  return value === undefined || value === null;
}

export function getNumber(value: unknown, isCategorical: boolean): ColumnType {
  const data = {
    type: "",
    format: "",
  };
  // Bug #1782: Excel value is string type so need to convert to Number before check
  const numberToCheck =
    typeof value === "string" ? +value.replace(/,/g, "") : value;
  if (Number.isInteger(numberToCheck)) {
    // Task #1784: if unique value / total records < 0.05 so we treat them as Categorical column
    // Check only if value is INTEGER
    if (isCategorical) {
      data.type = "string";
    } else {
      data.type = "integer";
    }
  } else {
    data.type = "float";
  }
  return data;
}

export function getBoolean(): ColumnType {
  return {
    type: "boolean",
    format: "",
  };
}

export function getString(): ColumnType {
  return {
    type: "string",
    format: "",
  };
}

export function getDate(format: string): ColumnType {
  return {
    type: "date",
    format: format,
  };
}

export function getFormatDate(value: string): ColumnType {
  for (const format of SUPPORTED_DATE_FORMAT) {
    const d = moment(value, format, true);
    if (d.isValid()) {
      return getDate(format);
    }
  }
  return getString();
}

function isNumeric(str: number | boolean | string) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(+str.replace(/,/g, "")) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}
export function detectDataType(
  value: number | boolean | string,
  isCategorical: boolean
): ColumnType {
  if (typeof value === "number" || isNumeric(value)) {
    return getNumber(value, isCategorical);
  } else if (
    typeof value === "boolean" ||
    (value.toLowerCase && ["true", "false"].indexOf(value.toLowerCase())) >= 0 // For detecting excel boolean value (TRUE,FALSE) or abnormal true/false value (ex: tRuE, fAlSE,...)
  ) {
    return getBoolean();
  } else {
    return getFormatDate(value);
  }
}

interface DataType {
  code: {
    type: string;
    format: string;
  };
}

export function getListDataType(value: any[]): DataType[] {
  const data: any = [];
  const listHeader = Object.keys(value[0]);

  listHeader.forEach((i) => {
    const listData = value.map((j) => j[i]);
    const uniqueValues = new Set(listData);
    const isCategorical =
      uniqueValues.size === IS_CATEGORICAL_THRESHOLD ||
      ID_COLUMN_SUFFIX.some((item) => {
        return (
          toHalfFull(i.substring(i.length - item.length).toLowerCase()) === item
        );
      });
    const listDataType = [...uniqueValues]
      .filter((x) => x != null)
      .map((j) => detectDataType(j, isCategorical));

    const dataTypes = listDataType.map((x: any) => x.type);
    const dataFormats = listDataType.map((x: any) => x.format);
    data.push(getColumnDataType(dataTypes, dataFormats));
  });
  return data;
}

export function getColumnDataType(
  listDataType: Array<string>,
  listDataFormat: Array<string>
): DataType {
  const setDataType = new Set(listDataType);
  const setDataFormat = new Set(listDataFormat);
  if (setDataType.size === 1) {
    const dataType = Array.from(listDataType)[0];
    if (dataType === "date") {
      const format =
        setDataFormat.size == 1 ? setDataFormat.values().next().value : "";
      return { code: { type: dataType, format: format } };
    }
    return { code: { type: dataType, format: "" } };
  }
  if (
    setDataType.size === 2 &&
    setDataType.has("float") &&
    setDataType.has("integer")
  ) {
    return { code: { type: "float", format: "" } };
  }
  return { code: { type: "string", format: "" } };
}

export function checkIfHasIdInName(_string: string): string | undefined {
  let suffix = "";
  const hasSuffix = ID_COLUMN_SUFFIX.some((item) => {
    if (
      toHalfFull(
        _string.substring(_string.length - item.length).toLowerCase()
      ) === item
    ) {
      suffix = _string.substring(_string.length - item.length);
      return true;
    }
  });
  return hasSuffix ? suffix : undefined;
}

export function toHalfFull(chars: string): string {
  let ascii = "";
  for (let i = 0, l = chars.length; i < l; i++) {
    let c = chars[i].charCodeAt(0);

    // make sure we only convert half-full width char
    if (c >= 0xff00 && c <= 0xffef) {
      c = 0xff & (c + 0x20);
    }

    ascii += String.fromCharCode(c);
  }

  return ascii;
}

export function checkIfStringHasSpecialChar(_string: string): boolean {
  const spChars = /[`!%^&*()+=[\]{};':"\\|,.<>~\s]/;
  if (spChars.test(_string)) {
    return true;
  }
  return false;
}

export function checkIfDuplicatedName(
  _string: string,
  listHeader: Array<any>
): boolean {
  const countHeader = listHeader.filter(
    (col: any) => _string.toLowerCase() === col.header.toLowerCase()
  );
  return countHeader.length > 1;
}
