import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-menuitem-text"
}
const _hoisted_2 = {
  key: 1,
  class: "p-menuitem-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!

  return (_ctx.currentFolder && _ctx.currentFolder.folderPath)
    ? (_openBlock(), _createBlock(_component_Breadcrumb, {
        key: 0,
        model: _ctx.currentFolder.folderPath
      }, {
        item: _withCtx(({ item }) => [
          _createVNode(_component_router_link, {
            class: "p-menuitem-link",
            to: `/workspace/${this.workspaceId}/experiment?folderId=${item.id}`
          }, {
            default: _withCtx(() => [
              (item.id === _ctx.rootFolderId)
                ? (_openBlock(), _createBlock("span", _hoisted_1, _toDisplayString(_ctx.$t("menu.experiment")), 1))
                : (_openBlock(), _createBlock("span", _hoisted_2, _toDisplayString(item.name), 1))
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _: 1
      }, 8, ["model"]))
    : _createCommentVNode("", true)
}