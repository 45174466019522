import { RouteRecordRaw } from "vue-router";
import Billing from "./components/Billing.vue";
import BillingDetail from "./components/BillingDetail.vue";
import BillingReport from "./components/BillingReport.vue";

export const BillingRoutes: Array<RouteRecordRaw> = [
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    meta: {
      breadcrumb: [{ label: "Billing" }],
    },
    children: [
      {
        path: "",
        redirect: "/billing/report",
      },
      {
        path: "report",
        name: "BillingReport",
        component: BillingReport,
        meta: {
          breadcrumb: [{ label: "Billing" }],
        },
      },
      {
        path: "detail",
        name: "BillingDetail",
        component: BillingDetail,
        meta: {
          breadcrumb: [{ label: "Billing" }],
        },
      },
    ],
  },
];
