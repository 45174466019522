
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import { IAutoMLPrediction } from "@/pages/Experiment/automl/types";
import { mapActions, mapGetters, mapState } from "vuex";
import NewPrediction from "./NewPrediction.vue";
import { $http } from "@/main";

export default defineComponent({
  components: {
    NewPrediction,
  },
  props: {
    predictions: {
      type: Array as PropType<IAutoMLPrediction[]>,
      default: () => [],
    },
    workspaceId: {
      type: String,
    },
  },
  data(): {
    search_key: string;
    sortBy: string;
    sortOrder: number;
    displayNewPrediction: boolean;
    $reload?: any;
  } {
    return {
      search_key: "",
      sortBy: "createdDate",
      sortOrder: -1,
      displayNewPrediction: false,
    };
  },
  created() {
    this.reload();
  },
  beforeUnmount() {
    this.stopReload();
  },
  computed: {
    ...mapGetters("identities", ["getFullname"]),
    ...mapGetters("autoML", ["hasMorePrediction"]),
    ...mapState("experiments", ["experimentDetail"]),
    ...mapState("workspace", ["workspaceDetail"]),
    ...mapState("autoML", ["loading"]),
    ...mapGetters("workspace", ["canEdit"]),
  },
  methods: {
    ...mapActions("autoML", [
      "deletePrediction",
      "loadMorePredictions",
      "loadPredictions",
    ]),
    reload() {
      this.$reload = setInterval(() => {
        this.loadPredictions({
          experimentId: this.experimentDetail.id,
          name: this.search_key,
          limit: this.predictions.length,
          loading: false,
          workspaceId: this.workspaceId,
        });
      }, 10000);
    },
    stopReload() {
      if (this.$reload) {
        clearInterval(this.$reload);
      }
    },
    confirmDeletePrediction(event: Event, prediction: IAutoMLPrediction) {
      event.preventDefault();
      this.$confirm.require({
        message: this.$t("automl.prediction.board.delete.confirm_message", {
          name: prediction.name,
        }),
        header: this.$t("automl.prediction.board.delete.title"),
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: async () => {
          try {
            await this.deletePrediction({
              predictionId: prediction?.id,
              workspaceId: this.workspaceId,
            });
            this.$toast.add({
              severity: "success",
              summary: this.$t("message.successful"),
              detail: this.$t("automl.prediction.board.delete.success", {
                name: prediction.name,
              }),
              life: 3000,
            });
          } catch (error: any) {
            this.$toast.add({
              severity: "error",
              summary: this.$t("message.error"),
              detail: this.$t("automl.prediction.board.delete.error", {
                name: prediction.name,
              }),
              life: 3000,
            });
          }
        },
      });
    },
    onClickCreate() {
      this.displayNewPrediction = true;
    },
    async loadNextPredictions() {
      await this.loadMorePredictions({
        experimentId: this.experimentDetail.id,
        name: this.search_key,
        workspaceId: this.workspaceId,
      });
    },
    offDialog(isCreated: boolean) {
      this.displayNewPrediction = false;
      this.stopReload();
      this.loadPredictions({
        experimentId: this.experimentDetail.id,
        name: this.search_key,
        limit: isCreated
          ? this.predictions.length + 1
          : this.predictions.length,
        loading: false,
        workspaceId: this.workspaceId,
      });
      this.reload();
    },
    async downloadPrediction(predId: string) {
      console.log(`Download prediction ${predId}`);
      try {
        const downloadUrl = await $http.get<string>(
          `/workers/blob/download-automl-prediction/${predId}?workspaceId=${this.workspaceId}`
        );
        window.open(downloadUrl.data, "_blank");
      } catch (error) {
        console.error(error);
      }
    },
    async downloadExplaination(predId: string) {
      console.log(`Download explaination ${predId}`);
      try {
        const downloadUrl = await $http.get<string>(
          `/workers/blob/download-automl-prediction-explain/${predId}?workspaceId=${this.workspaceId}`
        );
        window.open(downloadUrl.data, "_blank");
      } catch (error) {
        console.error(error);
      }
    },
    search() {
      this.loadPredictions({
        experimentId: this.experimentDetail.id,
        name: this.search_key,
        loading: false,
        workspaceId: this.workspaceId,
      });
    },
    getFileName(data: IAutoMLPrediction) {
      return (
        data.fileInputName +
        (data.sheetName &&
        data.fileExtension &&
        data.fileExtension.indexOf("xls") >= 0
          ? ` [${data.sheetName}]`
          : "")
      );
    },
  },
});
