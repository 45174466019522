
import { useStore } from "@/store";
import { DatasourceSchema } from "@/types";
import { defineComponent, ref } from "@vue/runtime-core";
import { mapActions, mapGetters, mapState } from "vuex";
import DataSizeHistory from "./DataSizeHistory.vue";
import DatasourceEDAVariableDetail from "./DatasourceEDAVariableDetail.vue";
import Distribution from "./Distribution.vue";
import TaskRunning from "@/pages/Common/TaskRunning.vue";

export default defineComponent({
  data() {
    return {
      selectedOption: undefined as any,
      selectedCompare: undefined as any,
      selectedSize: 5,
      offset: 0,
      rows: 8,
      active: 0,
      accordionOptions: [
        { text: "datasource.metadata.overview", value: 0 },
        { text: "datasource.metadata.variables", value: 1 },
        { text: "datasource.metadata.correlation", value: 2 },
        { text: "datasource.metadata.history", value: 3 },
      ],
      start: 0,
      end: 10,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    workspaceId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const scrollComponent: any = ref(null);
    return { store, scrollComponent };
  },
  components: {
    DataSizeHistory,
    DatasourceEDAVariableDetail,
    Distribution,
    TaskRunning,
  },
  computed: {
    ...mapState("authentication", ["token"]),
    ...mapState("datasources", [
      "datasourceProfile",
      "datasourceDetail",
      "edaNumeric",
      "correlation",
      "loadingDatasourceProfile",
      "edaFinished",
    ]),
    ...mapGetters("datasources", ["variables"]),
    datasourceNumericSchemas(): DatasourceSchema[] {
      return this.datasourceDetail.schemas.filter(
        (schema: any) => schema.type === "float" || schema.type === "integer"
      );
    },
  },
  created() {
    if (this.datasourceDetail) {
      this.selectedOption = this.datasourceNumericSchemas[0];
    }
  },
  watch: {
    async selectedOption(newValue) {
      if (newValue) {
        await this.loadCorrelation({
          id: this.id,
          columnName: newValue.name,
          workspaceId: this.workspaceId,
        });
      }
    },
    datasourceDetail() {
      if (this.selectedOption == undefined) {
        this.selectedOption = this.datasourceNumericSchemas[0];
      }
    },
  },
  methods: {
    ...mapActions("datasources", ["loadCorrelation"]),
    getCssClass(index: number) {
      return this.active === index ? "" : "p-button-text";
    },
    edaData() {
      if (typeof this.edaNumeric == "string") {
        return [];
      }
      return Object.entries(this.correlation)
        .sort(([, a], [, b]) => Math.abs(b as number) - Math.abs(a as number))
        .filter(([key]) => key != this.selectedOption.name)
        .map(([key]) => key);
    },
    handleScroll() {
      if (this.scrollComponent) {
        let element = this.scrollComponent;
        if (element.getBoundingClientRect().bottom - 120 < window.innerHeight) {
          this.end += 10;
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
});
