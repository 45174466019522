
import { useStore } from "@/store";
import { defineComponent } from "@vue/runtime-core";
import { mapMutations } from "vuex";
import ListDatasources from "./ListDatasources.vue";

export default defineComponent({
  components: {
    ListDatasources,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    ...mapMutations("datasources", ["RESET_NAME_DATASOURCE"]),
  },
});
