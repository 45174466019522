
import { useStore } from "@/store";
import { defineComponent } from "@vue/runtime-core";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import {
  ExperimentDetail,
  ExperimentStatus,
  ExperimentType,
  IJobTreeTable,
} from "../../types";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import LogDialog from "@/pages/Experiment/common/components/LogDialog.vue";
import { DEFAULT_POST_PROCESSING } from "@/common/constant";

export default defineComponent({
  components: {
    LogDialog,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    workspaceId: {
      type: String,
      required: true,
    },
  },
  validations() {
    return {
      newName: {
        required,
        maxLength: maxLength(64),
      },
      newDescription: {
        maxLength: maxLength(512),
      },
    };
  },
  data(): {
    focusTabIndex: number;
    items: { label: string; icon: string; to: string }[];
    activeEditName: boolean;
    activeEditDescription: boolean;
    newName?: string;
    newDescription?: string;
    $reloadInterval?: number;
    algorithmStatus: { [key: string]: boolean };
  } {
    return {
      focusTabIndex: 0,
      items: [
        {
          label: "autots.tab.evaluation",
          icon: "pi pi-fw pi-home",
          to: `/workspace/${this.workspaceId}/experiment/autots/${this.id}/evaluation`,
        },
        // {
        //   label: "autots.tab.eda",
        //   icon: "pi pi-fw pi-calendar",
        //   to: `/workspace/${this.workspaceId}/experiment/autots/${this.id}/eda`,
        // },
        {
          label: "autots.tab.history",
          icon: "pi pi-fw pi-file",
          to: `/workspace/${this.workspaceId}/experiment/autots/${this.id}/history`,
        },
      ],
      activeEditName: false,
      activeEditDescription: false,
      newName: "",
      newDescription: "",
      $reloadInterval: undefined,
      algorithmStatus: {},
    };
  },
  setup() {
    const store = useStore();
    return { store, v$: useVuelidate() };
  },
  methods: {
    ...mapActions("experiments", [
      "loadDatasourceConfig",
      "loadExperiment",
      "loadResult",
      "update",
      "cancelTrain",
    ]),
    ...mapActions("jobs", ["loadJobsByExperimentId"]),
    ...mapMutations("experiments", ["SET_STEP_EXPERIMENT", "CLEAR"]),
    checkForecastTabEnable(status: ExperimentStatus) {
      return [ExperimentStatus.Training, ExperimentStatus.Completed].includes(
        status
      );
    },
    copyAutoTS() {
      const datasource = this.getTransactionalDatasourceConfig;
      this.$router.push({
        name: "ConfigAUTOTS",
        params: {
          id: datasource.datasourceId,
          copiedTargetColumn: datasource.targetColumn,
          copiedTimeSeriesColumn: datasource.timeSeriesColumn,
          copiedTimeSeriesIdentifiers: datasource.timeSeriesIdentifiers || [],
          copiedForecastHorizon: this.experimentDetail.config.forecastHorizon,
          copiedForecastFrequency: this.experimentDetail.config.frequency,
          copiedStartDate: this.experimentDetail.config.startDate,
          copiedEndDate: this.experimentDetail.config.endDate,
          copiedAutoTSName: this.experimentDetail.name,
          copiedGap: this.experimentDetail.config.gap
            ? this.experimentDetail.config.gap
            : 0,
          copiedPrimaryMetric: this.experimentDetail.config.primaryMetric,
          copiedOtherMetrics: this.experimentDetail.config.otherMetrics,
          copiedFolderId: this.experimentDetail.folderId,
          copiedModelList: this.experimentDetail.config.modelList || [],
          copiedPostProcessing: JSON.stringify(
            this.experimentDetail.config.postProcessing ||
              DEFAULT_POST_PROCESSING
          ),
          copiedTimeLimit: this.experimentDetail.config?.timeLimit,
          copiedFeatureColumns: datasource.featureColumns || [],
          workspaceId: this.workspaceId,
        },
        query: {
          folderId: this.experimentDetail.folderId,
        },
      });
    },
    async updateCurrenName() {
      if (!this.v$.newName.$invalid) {
        if (this.experimentDetail) {
          this.experimentDetail.name = this.newName;
          this.experimentDetail.lastModifiedDate = new Date().toISOString();
          await this.update(this.experimentDetail);
          this.activeEditName = false;
        }
      }
    },
    cancelEditName() {
      this.newName = this.experimentDetail.name;
      this.activeEditName = false;
    },
    async updateDescription() {
      if (!this.v$.newDescription.$invalid) {
        if (this.experimentDetail) {
          this.experimentDetail.description = this.newDescription;
          this.experimentDetail.lastModifiedDate = new Date().toISOString();
          await this.update(this.experimentDetail);
          this.activeEditDescription = false;
        }
      }
    },
    cancelEditDescription() {
      this.newDescription = this.experimentDetail.description;
      this.activeEditDescription = false;
    },
    confirmCancelTrain(event: Event, id: string) {
      event.preventDefault();

      this.$confirm.require({
        message: this.$t("experiment.dialog.cancel.confirm_message", {
          name: this.experimentDetail?.name,
        }),
        header: this.$t("experiment.dialog.cancel.title"),
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: async () => {
          await this.cancelTrain({ id: id, workspaceId: this.workspaceId });
          this.$toast.add({
            severity: "success",
            summary: this.$t("message.successful"),
            detail: this.$t("experiment.dialog.message.cancel_success"),
            life: 3000,
          });
        },
      });
    },
    backToFolder() {
      this.$router.push(`/workspace/${this.workspaceId}/experiment/autots`);
    },
    load() {
      this.loadExperiment({
        id: this.id,
        workspaceId: this.workspaceId,
      });
      this.loadDatasourceConfig({ id: this.id, workspaceId: this.workspaceId });
    },
  },
  computed: {
    ...mapState("experiments", [
      "experimentDetail",
      "datasourceConfig",
      "algorithms",
    ]),
    ...mapGetters("experiments", ["getTransactionalDatasourceConfig"]),
    ...mapState("jobs", ["jobs", "jobTreeTable"]),
    ...mapGetters("workspace", ["canEdit"]),
  },
  created() {
    this.loadExperiment({
      id: this.id,
      workspaceId: this.workspaceId,
    });
    this.loadDatasourceConfig({ id: this.id, workspaceId: this.workspaceId });
    this.loadJobsByExperimentId({
      experimentId: this.id,
      expType: ExperimentType.AutoTS,
      workspaceId: this.workspaceId,
    });
    this.$reloadInterval = window.setInterval(() => {
      if (
        this.experimentDetail.status === ExperimentStatus.Completed ||
        this.experimentDetail.status === ExperimentStatus.Canceled ||
        this.experimentDetail.status === ExperimentStatus.Failed
      ) {
        window.clearInterval(this.$reloadInterval);
      } else {
        this.loadJobsByExperimentId({
          experimentId: this.id,
          expType: ExperimentType.AutoTS,
          workspaceId: this.workspaceId,
        });
        // If all algorithms have been finished, try to get experiment status
        if (
          this.jobTreeTable.some(
            (x: IJobTreeTable) => x.data.status === ExperimentStatus.Completed
          )
        ) {
          this.loadExperiment({
            id: this.id,
            workspaceId: this.workspaceId,
          });
        }
      }
    }, 5000);
  },
  watch: {
    experimentDetail(newValue: ExperimentDetail) {
      if (!this.activeEditName) {
        this.newName = newValue?.name;
      }
      if (!this.activeEditDescription) {
        this.newDescription = newValue?.description;
      }
    },
    jobTreeTable(newValue: Array<IJobTreeTable>) {
      const checkStatus = (jobs: Array<IJobTreeTable>) => {
        for (const job of jobs) {
          if (job.data.name.match(/training_.*_pipeline/)) {
            if (job.data.status === ExperimentStatus.Completed) {
              if (!this.algorithmStatus[job.data.name]) {
                this.algorithmStatus[job.data.name] = true;
                this.loadExperiment({
                  id: this.id,
                  workspaceId: this.workspaceId,
                });
              }
            }
          } else if (job.children.length > 0) {
            checkStatus(job.children);
          }
        }
      };
      checkStatus(newValue);
    },
  },
  unmounted() {
    this.CLEAR();
    if (this.$reloadInterval) {
      window.clearInterval(this.$reloadInterval);
    }
  },
});
