
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "@/store";
import { mapActions, mapState } from "vuex";
import { mapMutations } from "vuex";
import {
  checkIfHasIdInName,
  checkIfStringHasSpecialChar,
  checkIfDuplicatedName,
} from "@/common/utils";

export default defineComponent({
  data() {
    return {
      hasError: false,
      selectedColumns: null,
    };
  },
  props: {
    workspaceId: {
      type: String,
    },
  },
  computed: {
    ...mapState("datasources", [
      "listRowData",
      "listHeader",
      "delimiter",
      "linebreak",
      "selectedDelimiter",
      "selectedLinebreak",
      "totalRow",
      "workbook",
    ]),
    worksheetNames(): string[] {
      return this.workbook.SheetNames.map((x: string) => ({ label: x }));
    },
  },
  created() {
    if (this.listRowData.length == 0) {
      return this.$router.push({
        path: `/workspace/${this.workspaceId}/datasource/create`,
      });
    }
    this.disablePreviewButton();
    this.selectedColumns = this.listHeader.slice(0, 20);
  },
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    ...mapMutations("datasources", [
      "SET_DELIMITER",
      "SET_LINEBREAK",
      "SET_SELECT_DELIMITER",
      "SET_SELECT_LINEBREAK",
      "SET_PREVIEW_BUTTON",
    ]),
    ...mapActions("datasources", ["changeWorksheet"]),
    checkIfStringHasSpecialChar,
    checkIfDuplicatedName,
    checkIfHasIdInName,
    onTabChange({ index }: { originalEvent: Event; index: number }) {
      this.changeWorksheet(index);
      this.disablePreviewButton();
      this.selectedColumns = this.listHeader.slice(0, 20);
    },
    disablePreviewButton() {
      const hasError = this.listHeader.some(
        (element: any) =>
          this.checkIfStringHasSpecialChar(element.field) ||
          this.checkIfDuplicatedName(element.field, this.listHeader)
      );
      this.SET_PREVIEW_BUTTON(hasError);
      this.hasError = hasError;
    },
  },
});
