
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "@/store";
import { mapGetters, mapState, mapActions } from "vuex";
import {
  ExperimentStatus,
  TrainPercentageInfor,
  SchemaInfor,
  ExperimentType,
  CloneConfig,
  ExperimentConfig,
  Kpi,
} from "@/pages/Experiment/types";
import { PropType } from "vue";
import BackTestGraph from "./BackTestGraph.vue";
import AdvancedSetting from "./AdvancedSetting.vue";
import moment from "moment";
import {
  AUTOTS_FORECAST_SETTING,
  DEFAULT_MODEL_LIST,
  DEFAULT_POST_PROCESSING,
  ROOT_FOLDER,
} from "@/common/constant";
import { SettingObject } from "@/types";
import {
  FORECAST_DURATION,
  TIME_LIMIT_LIST_AUTOTS,
} from "@/pages/Experiment/common/constantSettingList";
import { isNil } from "@/common/utils";
import { DataRole } from "@/types";

const schema = {
  format: "",
  index: 0,
  name: "",
  type: "",
  role: "",
};
export default defineComponent({
  components: {
    BackTestGraph,
    AdvancedSetting,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  props: {
    autoTsName: {
      type: String,
      default: "",
    },
    autoTsTenantId: {
      type: String,
      default: "",
    },
    autoTsDescription: {
      type: String,
      default: "",
    },
    autoTsSchemas: {
      default: [],
    },
    datasourceName: {
      type: String,
      default: "",
    },
    copiedConfig: Object as PropType<CloneConfig>,
    folderId: {
      type: String,
      default: ROOT_FOLDER,
    },
    workspaceId: {
      type: String,
    },
  },
  data() {
    return {
      id: this.$route.params.id as string,
      targetColumn: schema as SchemaInfor,
      dateColumn: schema as SchemaInfor,
      idcs: [] as Array<string>,
      forecastDuration: 7,
      forecastFrequency: FORECAST_DURATION[0],
      gap: 0,
      trainPercentageInfor: [] as Array<TrainPercentageInfor>,
      dataSourceInfor: [] as number[],
      dateRangeFrom: moment([1970, 1, 1]).toDate() as Date | undefined,
      dateRangeSplitFrom: new Date(),
      dateRangeSplitTo: new Date(),
      dateRangeTo: moment().toDate() as Date | undefined,
      disableButton: true,
      showClearTimeSeries: false as boolean,
      showClearTarget: false as boolean,
      processing: false,
      dateRangeSplitMin: new Date(),
      dateRangeSplitMax: new Date(),
      startDate: undefined as Date | undefined,
      endDate: undefined as Date | undefined,
      selectedIDCKeys: [] as Array<SchemaInfor>,
      keysList: [] as Array<string>,
      disabledMultiSelect: true,
      selectedPeriodicalDuration: {
        id: "day",
        name: "autots.create.select.periodical.duration.day",
      },
      periodicalDuration: [
        { id: "day", name: "autots.create.select.periodical.duration.day" },
        { id: "week", name: "autots.create.select.periodical.duration.week" },
        { id: "month", name: "autots.create.select.periodical.duration.month" },
        { id: "year", name: "autots.create.select.periodical.duration.year" },
      ],
      metricOptions: Object.values(Kpi),
      selectedPrimaryMetric: Kpi.MAEPercent,
      otherMetrics: [] as Array<Kpi>,
      displayAdvancedSetting: false,
      advancedSettingModel: DEFAULT_MODEL_LIST,
      advancedSettingPostProcessing: DEFAULT_POST_PROCESSING,
      advancedSettingColumn: [] as Array<SchemaInfor>,
      advancedSettingLimitTime: TIME_LIMIT_LIST_AUTOTS[3],
      warningEndDate: "",
    };
  },
  watch: {
    autoTsSchemas: {
      handler() {
        if (this.copiedConfig?.targetColumn) {
          if (this.copiedConfig.timeSeriesColumn) {
            this.dateColumn = this.copiedConfig.timeSeriesColumn;
            this.showClearTimeSeries = true;
          }
          this.targetColumn = this.copiedConfig.targetColumn;
          this.showClearTarget = true;
          this.disabledMultiSelect = false;
          this.getIdcList();
          this.getDataSource();
          this.selectedIDCKeys = this.copiedConfig.timeSeriesIdentifiers;
          this.keysList = this.selectedIDCKeys.map((e) => e.name);
          this.forecastDuration = this.copiedConfig.forecastHorizon;
          this.forecastFrequency =
            FORECAST_DURATION.find(
              (x: SettingObject) => x.key === this.copiedConfig?.frequency
            ) || FORECAST_DURATION[0];
          this.gap = this.copiedConfig.gap;
          this.selectedPrimaryMetric = this.copiedConfig.primaryMetric;
          this.otherMetrics = this.copiedConfig.otherMetrics;
          if (this.copiedConfig.modelList) {
            this.advancedSettingModel = this.copiedConfig.modelList;
          }
          if (this.copiedConfig.postProcessing) {
            this.advancedSettingPostProcessing =
              this.copiedConfig.postProcessing;
          }
          if (this.copiedConfig.timeLimit) {
            this.advancedSettingLimitTime = this.copiedConfig.timeLimit;
          }
          if (this.copiedConfig.featureColumns) {
            this.advancedSettingColumn = this.copiedConfig.featureColumns;
          }
          this.$forceUpdate();
        }
      },
      deep: true,
    },
    dateRangeTo() {
      this.checkEndDate();
    },
    forecastFrequency() {
      this.checkEndDate();
    },
    forecastDuration() {
      this.gap = Math.min(this.gap, this.maxForecastGap);
    },
  },
  computed: {
    ...mapState("experiments", ["algorithms"]),
    ...mapGetters("experiments", ["getCandidateStatistics"]),
    ...mapState("application", ["theme"]),
    getRemainColumn(): Array<SchemaInfor> {
      return this.getRemaningColumn();
    },
    totalDate(): number {
      let totalDate = moment.duration(
        moment(this.dateRangeTo).diff(moment(this.dateRangeFrom))
      );
      if (totalDate) {
        if (this.forecastFrequency.key === "W") {
          return Math.floor(totalDate.asWeeks());
        } else if (this.forecastFrequency.key === "M") {
          return Math.floor(totalDate.asMonths());
        } else {
          return totalDate.asDays() + 1;
        }
      } else {
        return 0;
      }
    },
    trainPercentage(): number {
      return 100 - this.gapPercentage - this.testPercentage;
    },
    gapPercentage(): number {
      return (this.gap * 100) / this.totalDate;
    },
    testPercentage(): number {
      return (this.forecastDuration * 100) / this.totalDate;
    },
    maxStartDate(): Date | undefined {
      let level = AUTOTS_FORECAST_SETTING[this.forecastFrequency.key].level;
      return this.dateRangeTo
        ? moment(this.dateRangeTo)
            .add(
              -this.forecastDuration * level * 2 - this.gap * level * 2 + 1,
              "days"
            )
            .toDate()
        : undefined;
    },
    minEndDate(): Date | undefined {
      let level = AUTOTS_FORECAST_SETTING[this.forecastFrequency.key].level;
      return this.dateRangeFrom
        ? moment(this.dateRangeFrom)
            .add(
              this.forecastDuration * level * 2 + this.gap * level * 2 - 1,
              "days"
            )
            .toDate()
        : undefined;
    },
    // isValid(): boolean {
    //   if (this.forecastDuration * 2 < this.gap) {
    //     return false;
    //   }
    //   if (3 * this.forecastDuration + this.gap > this.totalDate) {
    //     return false;
    //   }
    //   return true;
    // },
    isEnableCreateButton(): boolean {
      return (
        this.autoTsName.trim() !== "" &&
        this.targetColumn.name.length > 0 &&
        this.dateColumn.name.length > 0 &&
        this.dateRangeFrom != null &&
        this.dateRangeTo != null &&
        !isNil(this.forecastDuration) &&
        this.forecastDuration <= this.maxForecastDuration &&
        this.forecastDuration > 0 &&
        !isNil(this.gap) &&
        this.gap <= this.maxForecastGap &&
        this.gap >= 0
      );
    },
    primaryMetricOptions(): Kpi[] {
      return this.metricOptions.filter(
        (e) => e === Kpi.sMAE || e === Kpi.MAEPercent
      );
    },
    otherMetricOptions(): Kpi[] {
      return this.metricOptions.filter((e) => e !== this.selectedPrimaryMetric);
    },
    forecastFrequencyOptions(): SettingObject[] {
      return FORECAST_DURATION;
    },

    maxForecastDuration(): number {
      return Math.min(
        AUTOTS_FORECAST_SETTING[this.forecastFrequency.key].maxDuration,
        Math.floor(this.totalDate / 2)
      );
    },

    maxForecastGap(): number {
      return Math.min(
        AUTOTS_FORECAST_SETTING[this.forecastFrequency.key].maxGap,
        this.maxForecastDuration - this.forecastDuration
      );
    },
    minForecastDuration(): number {
      return Math.min(
        AUTOTS_FORECAST_SETTING[this.forecastFrequency.key].minDuration,
        this.maxForecastDuration
      );
    },
    minForecastGap(): number {
      return AUTOTS_FORECAST_SETTING[this.forecastFrequency.key].minGap;
    },
  },
  created() {
    this.loadAlgorithms(this.workspaceId);
  },
  methods: {
    ...mapActions("experiments", ["create", "loadAlgorithms", "startTrain"]),
    ...mapActions("datasources", ["datasourceConfig"]),
    checkEndDate() {
      if (this.forecastFrequency.key === "W") {
        const dow = moment(this.dateRangeTo).day();
        this.warningEndDate = dow === 0 ? "" : "W";
      } else if (this.forecastFrequency.key === "M") {
        const day = moment(this.dateRangeTo).add(1, "days").date();
        this.warningEndDate = day === 1 ? "" : "M";
      } else {
        this.warningEndDate = "";
      }
    },
    onChangeSelectDateColumn(datecolumn: SchemaInfor) {
      this.onChangeDateColumn(datecolumn);
    },
    onChangeDropdownDateColumn(event: {
      originalEvent: PointerEvent;
      value: SchemaInfor;
    }) {
      this.onChangeDateColumn(event.value);
    },
    onChangeDateColumn(value: SchemaInfor) {
      this.showClearTimeSeries = value != null;
      if (value != null) {
        this.dateColumn = value;
        this.onConfigChange();
      } else {
        this.dateColumn = schema;
      }
    },
    onChangeTargetColumn(event: any) {
      this.disabledMultiSelect = false;
      this.showClearTarget = event.value != null;
      if (event.value != null) {
        this.targetColumn = event.value;
        if (event.value.type === "integer") {
          this.advancedSettingPostProcessing.round = true;
        }
        this.onConfigChange();
      } else {
        this.targetColumn = schema;
        this.disableButton = true;
      }
      this.advancedSettingColumn = this.getRemaningColumn();
    },
    onChangeKeysList() {
      this.onConfigChange();
    },
    getRemaningColumn(): Array<SchemaInfor> {
      return this.autoTsSchemas.filter(
        (e: SchemaInfor) =>
          e.name !== this.targetColumn.name &&
          e.name !== this.dateColumn.name &&
          e.role !== DataRole.DateTime
      );
    },
    async getDataSource() {
      if (this.dateColumn) {
        const result = await this.getCandidateStatistics(
          this.id,
          this.dateColumn.name,
          this.workspaceId
        );
        this.startDate = moment(result.min).toDate();
        this.endDate = moment(result.max).toDate();

        this.dateRangeFrom =
          this.copiedConfig?.startDate || moment(this.startDate).toDate();
        this.dateRangeTo =
          this.copiedConfig?.endDate || moment(this.endDate).toDate();
      }
    },
    onConfigChange() {
      if (this.dateColumn.name != "") {
        this.getDataSource();
      }
      this.getIdcList();
      this.keysList = [];
      this.selectedIDCKeys.forEach((element) => {
        if (
          element.name !== this.targetColumn.name &&
          element.name !== this.dateColumn.name
        ) {
          this.keysList.push(element.name);
        }
      });
    },
    getIdcList() {
      this.idcs = this.autoTsSchemas.filter(
        (e: any) => e !== this.dateColumn && e !== this.targetColumn
      );
    },
    async Complete() {
      try {
        this.processing = true;

        const experimentConfig: ExperimentConfig = {
          startDate: moment(this.dateRangeFrom).format("YYYY-MM-DD"),
          endDate: moment(this.dateRangeTo).format("YYYY-MM-DD"),
          forecastHorizon: this.forecastDuration,
          frequency: this.forecastFrequency.key as string,
          gap: this.gap,
          targetColumn: this.targetColumn.name,
          timeSeriesColumn: this.dateColumn.name,
          timeSeriesIdentifiers: this.keysList.length
            ? this.keysList
            : undefined,
          transactionDsId: this.id,
          primaryMetric: this.selectedPrimaryMetric,
          otherMetrics: this.otherMetrics,
          modelList: this.advancedSettingModel,
          postProcessing: this.advancedSettingPostProcessing,
          timeLimit: this.advancedSettingLimitTime.key,
        };
        const experimentData = {
          name: this.autoTsName,
          description: this.autoTsDescription,
          type: ExperimentType.AutoTS,
          status: ExperimentStatus.Setting,
          config: experimentConfig,
          algorithmConfigs: this.algorithms.map((algo: string) => ({
            algorithmId: algo,
            config: null,
          })),
          folderId: this.folderId,
          workspaceId: this.workspaceId,
        };
        const autotsData = await this.create(experimentData);

        const datasourceConfigData = {
          datasourceId: this.id,
          tenantId: this.autoTsTenantId,
          datasourceType: "TRANSACTIONAL_DATA",
          experimentId: autotsData.id,
          targetColumn: this.targetColumn.name,
          timeSeriesColumn: this.dateColumn.name,
          timeSeriesFormat: null,
          timeSeriesIdentifiers: this.keysList.length
            ? this.keysList
            : undefined,
          valueColumn: null,
          zipcodeColumn: null,
          featureColumns: this.advancedSettingColumn.map((x) => x.name),
          workspaceId: this.workspaceId,
        };
        await this.datasourceConfig(datasourceConfigData);
        await this.startTrain({
          id: autotsData.id,
          workspaceId: this.workspaceId,
        });
        this.$router.push(
          `/workspace/${this.workspaceId}/experiment/autots/${autotsData.id}`
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.processing = false;
      }
    },
    onSelectDatasource() {
      this.$router.push(
        `/workspace/${this.workspaceId}/experiment/autots/create?folderId=${this.folderId}`
      );
    },
    openAdvancedSetting() {
      this.displayAdvancedSetting = true;
    },
    onAdvancedSetting(event: any) {
      this.advancedSettingModel = event.advancedSettingModel;
      this.advancedSettingPostProcessing = event.advancedSettingPostProcessing;
      this.advancedSettingColumn = event.advancedSettingColumn;
      this.advancedSettingLimitTime = event.advancedSettingLimitTime;
      this.displayAdvancedSetting = false;
    },
    onChangeMetric(event: { originalEvent: PointerEvent; value: Kpi }) {
      this.otherMetrics = this.otherMetrics.filter((x) => x !== event.value);
    },
  },
});
