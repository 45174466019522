import { ApplicationState } from "./type";
import { Module } from "vuex";
import { State } from "@/store";

export const applicationModule: Module<ApplicationState, State> = {
  namespaced: true,
  state: { theme: "light" },
  mutations: {
    SET_THEME(state, theme: string) {
      state.theme = theme;
    },
  },
};
