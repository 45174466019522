
import { PropType } from "vue";
import { defineComponent } from "vue-demi";
import { SettingObject } from "@/types";
import { ChartAxisOption } from "@/pages/Experiment/types";
import { ChartSettings } from "../types";

export default defineComponent({
  name: "ChartSettingsDialog",
  props: {
    targetColumn: String,
    selectedChartAxisOption: {
      type: Number as PropType<ChartAxisOption>,
      required: true,
    },
    highlightQuantile: {
      type: Object as PropType<{
        upper: number;
        under: number;
      }>,
      default: (): { upper: number; under: number } => ({
        upper: 80,
        under: 20,
      }),
      required: true,
    },
    showQuantile: {
      type: Object as PropType<{
        upper: boolean;
        under: boolean;
      }>,
      required: true,
    },
    customChartRange: {
      type: Object as PropType<{
        min?: number | null;
        max?: number | null;
      }>,
      required: true,
    },
  },
  data(): { settings: ChartSettings } {
    return {
      settings: {
        selectedChartAxisOption: this.$props.selectedChartAxisOption,
        highlightQuantile: this.$props.highlightQuantile,
        showQuantile: {
          upper: !!this.$props.showQuantile?.upper,
          under: !!this.$props.showQuantile?.under,
        },
        customChartRange: this.$props.customChartRange,
      },
    };
  },
  emit: ["saveSettings", "cancelSettings"],
  computed: {
    chartAxisOptions(): Array<SettingObject> {
      return [
        {
          key: ChartAxisOption.CurrentSeriesRange,
          label: this.$t(
            "experiment.evaluation.chart-settings.minmax.current-series",
            { column: this.$props.targetColumn }
          ),
        },
        {
          key: ChartAxisOption.CustomRange,
          label: this.$t("experiment.evaluation.chart-settings.minmax.custom"),
        },
      ];
    },
  },
  methods: {
    onSaveSettings() {
      this.$emit("saveSettings", this.settings);
    },
    onCancelSettings() {
      this.$emit("cancelSettings");
    },
  },
});
