
import { defineComponent } from "vue";
import { maxLength, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default defineComponent({
  setup: () => ({ v$: useVuelidate() }),
  emits: ["accept", "cancel", "update:model"],
  props: {
    model: null,
    maxLength: {
      type: Number,
      default: 64,
    },
  },
  validations() {
    return {
      model: {
        required,
        maxLength: maxLength(this.maxLength),
      },
    };
  },
  methods: {
    onInput(event: any) {
      this.$emit("update:model", event.target.value);
    },
    onAccept(event: any) {
      this.$emit("accept", event);
    },
    onCancel(event: any) {
      this.$emit("cancel", event);
    },
  },
  mounted() {
    document.getElementById("input-name")?.focus();
    (document.getElementById("input-name") as HTMLInputElement).select();
  },
});
