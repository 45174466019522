
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["onClickCreate"],
  methods: {
    onClickCreate(event: Event) {
      this.$emit("onClickCreate", event);
    },
  },
});
