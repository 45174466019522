import { RouteRecordRaw } from "vue-router";
import Workspace from "./components/Workspace.vue";
import ListWorkspace from "./components/ListWorkspace.vue";
import Home from "./components/Home.vue";

export const workspaceRoutes: Array<RouteRecordRaw> = [
  {
    path: "/workspace",
    name: "Workspace",
    component: Home,
    props: true,
    children: [
      {
        path: "",
        component: ListWorkspace,
      },
      {
        path: ":workspaceId",
        component: Workspace,
        props: true,
      },
    ],
  },
  {
    path: "/workspaces",
    name: "Share Workspace",
    component: ListWorkspace,
    props: true,
    meta: {},
  },
];
