
import { defineComponent } from "vue";
import { useStore } from "@/store";
import { mapGetters } from "vuex";
import moment from "moment";
import ConfigDs from "./ConfigDs.vue";
import { SchemaInfor } from "../../../types";
import { AutoMLCloneConfig } from "@/pages/Experiment/automl/types";
import {
  PREDICTION_LIST,
  RUN_MODE_LIST,
  TIME_SERIES_DATA,
  TRAIN_TEST_SPLITS,
  TIME_LIMIT_LIST,
} from "@/pages/Experiment/common/constantSettingList";

export default defineComponent({
  components: {
    ConfigDs,
  },
  props: [
    "id",
    "copiedTargetColumn",
    "copiedTimeSeriesColumn",
    "copiedTimeSeriesIdentifiers",
    "copiedFeatureColumns",
    "copiedName",
    "copiedPredictionType",
    "copiedPrimaryMetric",
    "copiedOtherMetrics",
    "copiedTimeLimit",
    "copiedTrainMode",
    "copiedSplitTrainEndDate",
    "copiedSplitTestEndDate",
    "copiedSplitTestStartDate",
    "copiedSplitTrainStartDate",
    "copiedSplitType",
    "copiedTrainRatio",
    "copiedTimeSeries",
    "copiedFolderId",
    "folderId",
    "workspaceId",
  ],
  data(): {
    autoMlName: string;
    autoMlDescription: string;
    autoMlSchemas: Array<SchemaInfor>;
    autoMlTenantId: string;
    datasourceName: string;
    copiedConfig: AutoMLCloneConfig;
  } {
    return {
      autoMlName: "",
      autoMlDescription: "",
      autoMlSchemas: [],
      autoMlTenantId: "",
      datasourceName: "",
      copiedConfig: {} as AutoMLCloneConfig,
    };
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    ...mapGetters("datasources", ["getOneDataSource"]),
  },
  async created() {
    const selectedDatasourceInformation = await this.getOneDataSource(
      this.id,
      this.workspaceId
    );
    this.datasourceName = selectedDatasourceInformation.name;
    this.autoMlName =
      selectedDatasourceInformation.name +
      " - " +
      moment().format("YYYY/MM/DD");
    this.autoMlSchemas = selectedDatasourceInformation.schemas;
    this.autoMlTenantId = selectedDatasourceInformation.tenantId;

    if (this.copiedName) {
      this.autoMlName = this.$t("experiment.copy.title", {
        name: this.copiedName,
      });
      this.copiedConfig.name = this.autoMlName;
      this.autoMlDescription = this.$t("experiment.copy.description", {
        name: this.copiedName,
        time: moment().format("YYYY/MM/DD HH:mm:ss"),
      });
    }

    if (this.copiedTargetColumn) {
      this.copiedConfig.targetColumn = this.autoMlSchemas.find(
        (e) => e.name === this.copiedTargetColumn
      );
    }

    if (this.copiedPredictionType) {
      this.copiedConfig.predictionType = PREDICTION_LIST.find(
        (e) => e.key === this.copiedPredictionType
      );
    }

    if (this.copiedTimeSeriesColumn) {
      this.copiedConfig.timeSeriesColumn = this.autoMlSchemas.find(
        (e) => e.name === this.copiedTimeSeriesColumn
      );
    }

    if (this.copiedTimeSeries && this.copiedTimeSeries === "true") {
      this.copiedConfig.timeSeries = TIME_SERIES_DATA[0];
    } else {
      this.copiedConfig.timeSeries = TIME_SERIES_DATA[1];
    }

    if (
      this.copiedTimeSeriesIdentifiers &&
      this.copiedTimeSeriesIdentifiers.some
    ) {
      this.copiedConfig.timeSeriesIdentifiers = this.autoMlSchemas.filter((e) =>
        this.copiedTimeSeriesIdentifiers.some((i: string) => e.name === i)
      );
    }

    if (this.copiedSplitType) {
      this.copiedConfig.splitType = TRAIN_TEST_SPLITS.find(
        (e) => e.key === this.copiedSplitType
      );
    }

    if (this.copiedSplitTrainStartDate) {
      this.copiedConfig.splitTrainStartDate = new Date(
        this.copiedSplitTrainStartDate
      );
    }

    if (this.copiedSplitTrainEndDate) {
      this.copiedConfig.splitTrainEndDate = new Date(
        this.copiedSplitTrainEndDate
      );
    }

    if (this.copiedSplitTestStartDate) {
      this.copiedConfig.splitTestStartDate = new Date(
        this.copiedSplitTestStartDate
      );
    }

    if (this.copiedSplitTestEndDate) {
      this.copiedConfig.splitTestEndDate = new Date(
        this.copiedSplitTestEndDate
      );
    }

    if (this.copiedPrimaryMetric) {
      this.copiedConfig.primaryMetric = this.copiedPrimaryMetric;
    }

    if (this.copiedOtherMetrics) {
      this.copiedConfig.otherMetrics = this.copiedOtherMetrics;
    }

    if (this.copiedTrainRatio) {
      this.copiedConfig.trainRatio = this.copiedTrainRatio;
    }

    if (this.copiedTrainMode) {
      this.copiedConfig.trainMode = RUN_MODE_LIST.find(
        (e) => e.key === this.copiedTrainMode
      );
    }

    if (this.copiedTimeLimit) {
      this.copiedConfig.timeLimit = TIME_LIMIT_LIST.find(
        (e) => e.key === parseInt(this.copiedTimeLimit)
      );
    }

    if (this.copiedFeatureColumns && this.copiedFeatureColumns.some) {
      this.copiedConfig.featureColumns = this.autoMlSchemas.filter((e) =>
        this.copiedFeatureColumns.some((i: string) => e.name === i)
      );
    }

    this.copiedConfig.folderId = this.copiedFolderId
      ? this.copiedFolderId
      : this.folderId;
  },
});
