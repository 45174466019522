
import { defineComponent, ref } from "@vue/runtime-core";
import { mapActions, mapGetters, mapState } from "vuex";
import { useStore } from "@/store";
import { PayloadTabChangeEvent } from "../../types";
import DatasourceSampleData from "./DatasourceSampleData.vue";
import DatasourceEDA from "../eda/DatasourceEDA.vue";
import DatasourceMetaDataFull from "./DatasourceMetaDataFull.vue";
import RelatedExperiments from "./RelatedExperiments.vue";
import { required, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { ExperimentType, IExperiment } from "@/pages/Experiment/types";
import { DatasourceDetail, DatasourceStatus } from "@/types";
import { getExperimentIcon } from "@/common/icon";
import { IMenuModel } from "@/pages/Common/types";

enum TabName {
  METADATA = "metadata",
  SAMPLE_DATA = "sample-data",
  EDA = "eda",
  EXPERIMENTS = "experiments",
}
const TAB_INDEX = [
  TabName.METADATA,
  TabName.SAMPLE_DATA,
  TabName.EDA,
  TabName.EXPERIMENTS,
];

const TAB_NAME_TO_INDEX: { [key: string]: number } = {
  [TabName.METADATA]: 0,
  [TabName.SAMPLE_DATA]: 1,
  [TabName.EDA]: 2,
  [TabName.EXPERIMENTS]: 3,
};

export default defineComponent({
  setup() {
    const store = useStore();
    const menu = ref("menu");
    const root = ref(null);
    return { store, v$: useVuelidate(), root, menu };
  },
  data(): {
    activeTabIndex: number;
    newName?: string;
    fileToken: string;
    search_key: string;
    activeEditName: boolean;
    items: IMenuModel[];
    $reload?: any;
  } {
    return {
      activeTabIndex: 0,
      newName: "",
      fileToken: "",
      search_key: "",
      activeEditName: false,
      items: [
        {
          label: "AutoTS",
          icon: getExperimentIcon(ExperimentType.AutoTS),
          command: () => this.createAutoTS(),
        },
        {
          label: "AutoML",
          icon: getExperimentIcon(ExperimentType.AutoML),
          command: () => this.createAutoML(),
        },
      ],
    };
  },
  validations() {
    return {
      newName: {
        required,
        maxLength: maxLength(64),
      },
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    tab: {
      type: String,
    },
    workspaceId: {
      type: String,
    },
  },
  components: {
    DatasourceSampleData,
    DatasourceEDA,
    DatasourceMetaDataFull,
    RelatedExperiments,
  },
  computed: {
    ...mapState("authentication", ["token", "userId"]),
    ...mapState("datasources", [
      "files",
      "loading",
      "loadingFiles",
      "relatedExperiments",
      "datasourceDetail",
      "totalFiles",
      "totalSize",
      "edaFinished",
    ]),
    ...mapGetters("datasources", ["getDatasourceFiles", "hasMoreFiles"]),
    ...mapGetters("workspace", ["canEdit"]),
    getLabel(): string {
      return this.datasourceDetail?.status !== DatasourceStatus.READY
        ? "tag.disabled"
        : "tag.enabled";
    },
    getLabelCSS(): string {
      return this.datasourceDetail?.status !== DatasourceStatus.READY
        ? "warning"
        : "default";
    },
    isReadyToCreateExperiment(): boolean {
      return this.datasourceDetail?.status == DatasourceStatus.READY;
    },
  },
  async created() {
    if (this.tab) {
      this.activeTabIndex = TAB_NAME_TO_INDEX[this.tab];
    }
    this.loadDatasourceDetail({ id: this.id, workspaceId: this.workspaceId });
    this.loadRelatedExperiments({
      datasourceId: this.id,
      workspaceId: this.workspaceId,
    });
    this.loadTotalFiles({ id: this.id, workspaceId: this.workspaceId });
    this.loadDatasourceProfile({ id: this.id, workspaceId: this.workspaceId });
    this.loadNumeric({ id: this.id, workspaceId: this.workspaceId });

    this.$reload = setInterval(() => {
      this.loadDatasourceDetail({ id: this.id, workspaceId: this.workspaceId });
      this.loadTotalFiles({ id: this.id, workspaceId: this.workspaceId });
      if (!this.edaFinished) {
        this.loadDatasourceProfile({
          id: this.id,
          workspaceId: this.workspaceId,
        });
        this.loadNumeric({ id: this.id, workspaceId: this.workspaceId });
      }
    }, 10000);
  },
  watch: {
    datasourceDetail(newValue: DatasourceDetail) {
      if (!this.activeEditName) {
        this.newName = newValue?.name;
      }
    },
    id(newValue: string) {
      this.loadDatasourceDetail({
        id: newValue,
        workspaceId: this.workspaceId,
      });
      this.loadRelatedExperiments({
        datasourceId: this.id,
        workspaceId: this.workspaceId,
      });
      this.loadTotalFiles({ id: newValue, workspaceId: this.workspaceId });
      this.loadDatasourceProfile({
        id: newValue,
        workspaceId: this.workspaceId,
      });
      this.loadNumeric({
        id: newValue,
        workspaceId: this.workspaceId,
      });
    },
  },
  beforeUnmount() {
    this.clearAll();
    if (this.$reload) {
      clearInterval(this.$reload);
    }
  },
  methods: {
    ...mapActions("datasources", [
      "updateDatasource",
      "loadRelatedExperiments",
      "loadDatasourceDetail",
      "loadTotalFiles",
      "loadDatasourceProfile",
      "loadNumeric",
      "clearAll",
    ]),
    addFile(): void {
      this.$router.push(
        `/workspace/${this.workspaceId}/datasource/${this.datasourceDetail?.id}/upload`
      );
    },
    createAutoTS(): void {
      this.$router.push(
        `/workspace/${this.workspaceId}/experiment/autots/create/${this.datasourceDetail?.id}`
      );
    },
    createAutoML(): void {
      this.$router.push(
        `/workspace/${this.workspaceId}/experiment/automl/create/${this.datasourceDetail?.id}`
      );
    },
    async updateCurDatasource() {
      if (!this.v$.$invalid) {
        if (this.datasourceDetail) {
          this.datasourceDetail.name = this.newName;
          this.datasourceDetail.lastModifiedDate = new Date().toISOString();
          this.datasourceDetail.lastModifiedBy = this.userId;

          this.store
            .dispatch("datasources/updateDatasource", this.datasourceDetail)
            .then(() => {
              this.activeEditName = false;
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    },
    goToTab(index: number): void {
      this.activeTabIndex = index;
    },
    tabChangeEvent(payload: PayloadTabChangeEvent): void {
      this.activeTabIndex = payload.index;
      this.$router.push(
        `/workspace/${this.workspaceId}/datasource/${this.id}/${
          TAB_INDEX[this.activeTabIndex]
        }`
      );
    },
    cancelEditDatasourceName() {
      this.newName = this.datasourceDetail.name;
      this.activeEditName = false;
    },
    onClickRelatedExperiment(experiment: IExperiment) {
      if (experiment.type === ExperimentType.AutoTS) {
        this.$router.push(
          `/workspace/${this.workspaceId}/experiment/autots/${experiment.id}`
        );
      }
    },
    toggle(event: any) {
      (this.menu as any).toggle(event);
    },
  },
});
