import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { authenticationRoutes } from "./pages/Authentication/router";
import { workspaceRoutes } from "./pages/Workspace/router";
import { DatasourceRoutes } from "./pages/Datasource/router";
import { BillingRoutes } from "./pages/Billing/router";
import { commonRoutes } from "./pages/Common/router";
import { App, Plugin } from "vue";
import Keycloak from "keycloak-js";
import { autoTSRoutes } from "./pages/Experiment/autots/router";
import { $http } from "./main";
import { Store } from "vuex";
import { State } from "./store";
import { AxiosInstance } from "axios";
// import { DemandForecastRoutes } from "./pages/DemandForecast/router";
import { autoMLRoutes } from "./pages/Experiment/automl/router";
import { experimentRoutes } from "./pages/Experiment/common/router";

const appRoutes: Array<RouteRecordRaw> = [];
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import("./pages/App/App.vue"),
    children: appRoutes,
    meta: {
      hideBreadcrum: true,
    },
  },
];

appRoutes.push(...authenticationRoutes);
appRoutes.push(...workspaceRoutes);
appRoutes.push(...DatasourceRoutes);
appRoutes.push(...experimentRoutes);
appRoutes.push(...autoTSRoutes);
appRoutes.push(...autoMLRoutes);
appRoutes.push(...BillingRoutes);
// appRoutes.push(...DemandForecastRoutes);
routes.push(...commonRoutes);

interface routerPluginOptions {
  keycloak: Keycloak.KeycloakInstance;
  store: Store<State>;
  $http: AxiosInstance;
}
export const router = createRouter({
  history: createWebHistory(),
  routes,
});

export const routerPlugin: Plugin = {
  async install(app: App, options: routerPluginOptions) {
    const keycloak = options.keycloak;

    router.beforeEach((to, _from, next) => {
      if (!to.meta.isPublic) {
        if (keycloak && !keycloak.authenticated) {
          keycloak.login();
        } else {
          next();
        }
      } else {
        // This page did not require authentication
        next();
      }
    });

    app.use(router);

    $http.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response.status == 404) {
          router.push("/not_found");
        } else if (error.response.status == 401) {
          router.push("/forbidden");
        }
        return Promise.reject(error);
      }
    );
  },
};
