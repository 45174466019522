import { RouteLocation, RouteRecordRaw } from "vue-router";
import Experiment from "./components/Experiment.vue";
import AutoTSDetail from "./components/AutoTSDetail.vue";
import SelectDatasource from "../common/components/SelectDatasource/SelectDatasource.vue";
import ConfigAutoTs from "./components/create/ConfigAutoTs.vue";
import Evaluation from "./components/evaluation/Evaluation.vue";
import EDA from "./components/EDA.vue";
import Metadata from "./components/Metadata.vue";
import History from "../common/components/History.vue";

export const autoTSRoutes: Array<RouteRecordRaw> = [
  {
    path: "/workspace/:workspaceId/experiment/autots",
    name: "AutoTS",
    component: Experiment,
    children: [
      {
        path: "",
        redirect: "/workspace/:workspaceId/experiment",
      },
      {
        path: ":id",
        component: AutoTSDetail,
        props: true,
        meta: {
          breadcrumb: [
            {
              label: "breadcrumb.experiment",
              to: "/workspace/:workspaceId/experiment",
            },
            {
              label: ":folderName",
              to: `/workspace/:workspaceId/experiment?folderId=:folderId`,
            },
            { label: "breadcrumb.detail.autots" },
          ],
        },
        children: [
          {
            path: "",
            redirect: (to: RouteLocation): string => {
              return `/workspace/${to.params.workspaceId}/experiment/autots/${to.params.id}/evaluation`;
            },
          },
          {
            path: "evaluation",
            component: Evaluation,
            props: true,
            meta: {
              breadcrumb: [
                {
                  label: "breadcrumb.experiment",
                  to: "/workspace/:workspaceId/experiment",
                },
                {
                  label: ":folderName",
                  to: `/workspace/:workspaceId/experiment?folderId=:folderId`,
                },
                { label: "breadcrumb.detail.autots" },
              ],
            },
          },
          {
            path: "eda",
            component: EDA,
            props: true,
            meta: {
              breadcrumb: [
                {
                  label: "breadcrumb.experiment",
                  to: "/workspace/:workspaceId/experiment",
                },
                {
                  label: ":folderName",
                  to: `/workspace/:workspaceId/experiment?folderId=:folderId`,
                },
                { label: "breadcrumb.detail.autots" },
              ],
            },
          },
          {
            path: "metadata",
            component: Metadata,
            props: true,
            meta: {
              breadcrumb: [
                {
                  label: "breadcrumb.experiment",
                  to: "/workspace/:workspaceId/experiment",
                },
                {
                  label: ":folderName",
                  to: `/workspace/:workspaceId/experiment?folderId=:folderId`,
                },
                { label: "breadcrumb.detail.autots" },
              ],
            },
          },
          {
            path: "history",
            component: History,
            props: true,
            meta: {
              breadcrumb: [
                {
                  label: "breadcrumb.experiment",
                  to: "/workspace/:workspaceId/experiment",
                },
                {
                  label: ":folderName",
                  to: `/workspace/:workspaceId/experiment?folderId=:folderId`,
                },
                { label: "breadcrumb.detail.autots" },
              ],
            },
          },
        ],
      },
      {
        path: "create",
        props: (route) => ({
          folderId: route.query.folderId,
          ...route.params,
        }),
        component: SelectDatasource,
        meta: {
          breadcrumb: [
            {
              label: "breadcrumb.experiment",
              to: "/workspace/:workspaceId/experiment",
            },
            { label: "breadcrumb.select.datasource" },
          ],
        },
      },
      {
        path: "create/:id",
        props: (route) => ({
          folderId: route.query.folderId,
          ...route.params,
        }),
        component: ConfigAutoTs,
        name: "ConfigAUTOTS",
        meta: {
          breadcrumb: [
            {
              label: "breadcrumb.experiment",
              to: "/workspace/:workspaceId/experiment",
            },
            {
              label: "breadcrumb.select.datasource",
              to: "/workspace/:workspaceId/experiment/autots/create",
            },
            { label: "breadcrumb.config.autots" },
          ],
        },
      },
    ],
    meta: {
      breadcrumb: [{ label: "breadcrumb.autots" }],
    },
    props: (route) => ({ folderId: route.query.folderId, ...route.params }),
  },
];
