
import { IDatasourceConfig, IExperiment, IKpi } from "@/pages/Experiment/types";
import { FilterMatchMode } from "primevue/api";
import { defineComponent, PropType } from "vue";
import { SummaryMetric } from "../types";
import { FORECAST_DURATION } from "@/pages/Experiment/common/constantSettingList";
import { SettingObject } from "@/types";

export default defineComponent({
  data() {
    return {
      showSettingDialog: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  props: {
    experiment: {
      type: Object as PropType<IExperiment>,
    },
    datasourceConfig: {
      type: Object as PropType<IDatasourceConfig>,
    },
    datasourceConfigDetail: {
      type: Object as PropType<{ [key: string]: IDatasourceConfig }>,
    },
    bestKpi: {
      type: Array as PropType<IKpi[]>,
    },
    metrics: {
      type: Array as PropType<SummaryMetric[]>,
    },
    autoMlSchemas: {
      default: [],
    },
    workspaceId: {
      type: String,
      required: true,
    },
  },
  methods: {
    rowClass(data: { name: string }) {
      if (
        this.datasourceConfig &&
        this.datasourceConfig.featureColumns &&
        !Object.values(this.datasourceConfig.featureColumns).includes(data.name)
      ) {
        return "unselected-feature";
      }
      return "";
    },
    getSuffix(frequency: string) {
      return (
        FORECAST_DURATION.find((x: SettingObject) => x.key === frequency)
          ?.label || "autots.create.label.days"
      );
    },
  },
});
