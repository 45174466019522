
import { EXPERIMENT_STATUS, ExperimentType } from "@/pages/Experiment/types";
import { IFolder, IMenuModel, RootFolder } from "@/pages/Common/types";
import { useStore } from "@/store";
import moment from "moment";
import { defineComponent, ref } from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Empty from "@/pages/Experiment/common/components/Empty.vue";
import FolderBreadcrumb from "./FolderBreadcrumb.vue";
import FolderSelector from "../../../../components/FolderSelector.vue";
import ListExperimentsMobile from "./ListExperimentsMobile.vue";
import { IExperiment } from "../../types";
import { maxLength, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { DropdownOption } from "@/pages/Datasource/types";
import { debounce } from "lodash";
import { onBeforeRouteLeave } from "vue-router";
import { DEFAULT_POST_PROCESSING, ROOT_FOLDER } from "@/common/constant";

export default defineComponent({
  props: {
    expType: {
      type: String,
      required: true,
    },
    folderId: {
      type: String,
      default: ROOT_FOLDER,
    },
    workspaceId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const listAutoTS: any = ref(null);
    const folderSetting: any = ref<HTMLInputElement[]>();
    const leaving = ref(false);
    onBeforeRouteLeave(() => {
      leaving.value = true;
    });
    const menu = ref("menu");
    const op = ref("op");
    return {
      menu,
      op,
      store,
      listAutoTS,
      folderSetting,
      v$: useVuelidate(),
      leaving,
    };
  },
  validations() {
    return {
      newName: {
        required,
        maxLength: maxLength(64),
      },
    };
  },
  components: {
    Empty,
    FolderBreadcrumb,
    ListExperimentsMobile,
    FolderSelector,
  },
  data(): {
    listStatus: Array<string>;
    listType: Array<ExperimentType>;
    timeRanges: DropdownOption<string>[];
    newName?: string;
    folderNewInfo: {
      name: string;
      description: string;
      type: ExperimentType.Folder;
      folderId: string;
      workspaceId?: string;
    };
    refresh: number;
    loadedTimes: number;
    selectedColumn: Array<IExperiment>;
    editingId: string;
    items: IMenuModel[];
    selectedFolder: IFolder;
  } {
    return {
      listStatus: EXPERIMENT_STATUS,
      listType: [
        ExperimentType.Folder,
        ExperimentType.AutoTS,
        ExperimentType.AutoML,
      ],
      timeRanges: [
        { id: "all", name: "autots.overview.time.all" },
        { id: "last7", name: "autots.overview.time.day" },
        { id: "week", name: "autots.overview.time.week" },
        { id: "month", name: "autots.overview.time.month" },
        { id: "year", name: "autots.overview.time.year" },
      ],
      newName: "",

      folderNewInfo: {
        name: "New Folder",
        description: "FOLDER",
        type: ExperimentType.Folder,
        folderId: this.folderId,
        workspaceId: this.workspaceId,
      },
      refresh: 0,
      loadedTimes: 0,
      selectedColumn: [],
      editingId: "",
      items: [
        {
          label: "AutoTS",
          command: () =>
            this.$router.push(
              `/workspace/${this.workspaceId}/experiment/autots/create?folderId=${this.folderId}`
            ),
        },
        {
          label: "AutoML",
          command: () =>
            this.$router.push(
              `/workspace/${this.workspaceId}/experiment/automl/create?folderId=${this.folderId}`
            ),
        },
      ],
      selectedFolder: RootFolder,
    };
  },
  created() {
    this.loadUniqueUsers(this.workspaceId);
    this.load();
  },

  computed: {
    ...mapState("experiments", [
      "experiments",
      "experimentDetail",
      "loading",
      "currentFolder",
      "folders",
      "loadCount",
    ]),
    ...mapState("identities", ["uniqueUsersOfExperiments"]),
    ...mapGetters("experiments", [
      "hasMoreExperiment",
      "hasMoreFolders",
      "getTransactionalDatasourceConfig",
    ]),
    ...mapGetters("identities", ["getFullname"]),
    ...mapGetters("workspace", ["canEdit"]),
    isEmpty(): boolean {
      return (
        this.loadCount > 0 &&
        !this.loading &&
        !this.experiments.length &&
        !this.$route.query.name &&
        !this.$route.query.owner &&
        !this.$route.query.type &&
        !this.$route.query.status &&
        !this.$route.query.time &&
        !this.$route.query.folderId
      );
    },
    dynamicSortOrder: {
      get(): number {
        return this.$route.query.dsc?.toString() === "false" ? 1 : -1;
      },
      set(value: number) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            dsc: (value < 0) + "",
          },
        });
      },
    },
    dynamicSortField: {
      get(): string {
        return this.$route.query.sort?.toString() || "lastModifiedDate";
      },
      set(value: string | null | undefined) {
        if (!value) {
          value = "lastModifiedDate";
        }
        this.$router.replace({
          query: {
            ...this.$route.query,
            sort: value,
          },
        });
      },
    },
    selectedOwner: {
      get(): string {
        return this.$route.query.owner?.toString() || "";
      },
      set(value: string) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            owner: value,
          },
        });
      },
    },
    selectedType: {
      get(): string {
        return this.$route.query.type?.toString() || "";
      },
      set(value: string) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            type: value,
          },
        });
      },
    },
    selectedStatus: {
      get(): string {
        return this.$route.query.status?.toString() || "";
      },
      set(value: string) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            status: value,
          },
        });
      },
    },
    selectedTimeRange: {
      get(): DropdownOption<string> {
        if (this.$route.query.time) {
          return (
            this.timeRanges.find(
              (t: { id: string }) => t.id === this.$route.query.time
            ) || this.timeRanges[0]
          );
        }
        return this.timeRanges[0];
      },
      set(value: DropdownOption<string>) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            time: value.id,
          },
        });
      },
    },
    selectedDate(): [string, string] | null {
      if (this.selectedTimeRange.id === "last7") {
        return [
          moment().subtract(7, "days").format("YYYY-MM-DD"),
          moment().add(1, "days").format("YYYY-MM-DD"), // Fix bug #1397 - need to add 1 day because server side searchs by date time
        ];
      } else if (this.selectedTimeRange.id === "all") {
        return null;
      } else {
        return [
          moment()
            .startOf(this.selectedTimeRange.id as moment.unitOfTime.StartOf)
            .format("YYYY-MM-DD"),
          moment()
            .endOf(this.selectedTimeRange.id as moment.unitOfTime.StartOf)
            .add(1, "days") // Fix bug #1397 - need to add 1 day because server side searchs by date time
            .format("YYYY-MM-DD"),
        ];
      }
    },
    searchKey: {
      get(): string {
        return this.$route.query.name?.toString() || "";
      },
      set(value: string) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            name: value,
          },
        });
      },
    },
    filterStr(): string {
      const obj: any = {
        name: encodeURIComponent(this.searchKey.trim()),
        createdBy: this.selectedOwner || "",
        lastModifiedDateFrom: this.selectedDate ? this.selectedDate[0] : "",
        lastModifiedDateTo: this.selectedDate ? this.selectedDate[1] : "",
        status: this.selectedStatus || "",
        type: this.selectedType || "",
      };
      let str = "q?";
      for (const property in obj) {
        if (obj[property] != "") str += `${property}=${obj[property]}&`;
      }
      return str;
    },
    queryPayload(): {
      filter: string;
      sort: string;
      dsc: boolean;
      folderID: string;
      workspaceId?: string;
    } {
      this.SET_LOADING(true);
      const str = this.filterStr.trim();
      return {
        filter: str !== "q?" ? str.substring(0, str.length - 1) : "q?name=",
        sort: this.dynamicSortField,
        dsc: this.dynamicSortOrder < 0,
        folderID: this.folderId,
        workspaceId: this.workspaceId,
      };
    },
    isCheckedAll(): boolean {
      return (
        this.experiments.length &&
        this.selectedColumn.length === this.experiments.length
      );
    },
    targetFolderIds(): Array<string> {
      return this.selectedColumn.flatMap((f: IExperiment) =>
        f.type === ExperimentType.Folder ? [f.id] : []
      );
    },
    disabledDestinationFolders(): Array<string> {
      const res = Array.from(
        new Set(this.selectedColumn.map((e: IExperiment) => e.folderId))
      );

      if (
        this.selectedColumn.some((e) => e.type === ExperimentType.Folder) &&
        (this.selectedFolder.folderPath.length >= 3 ||
          this.folders.length >= 32)
      ) {
        res.push(this.selectedFolder.id);
      }

      return res;
    },
  },
  methods: {
    ...mapActions("experiments", [
      "loadExperiments",
      "loadMoreExperiments",
      "delete",
      "createFolder",
      "moveToFolder",
      "update",
      "loadDatasourceConfig",
      "loadExperiment",
      "loadFolder",
      "loadFolders",
      "loadMoreFolders",
      "deleteAll",
      "folderHasRunningExp",
    ]),
    ...mapActions("identities", ["loadUniqueUsers"]),
    ...mapMutations("experiments", [
      "CLEAR_CREATE_EXPERIMENT",
      "CLEAR",
      "SET_CURRENT_FOLDER",
      "SET_LOADING",
    ]),
    load: debounce(function (this: any) {
      if (this.folderId && this.folderId !== ROOT_FOLDER) {
        this.loadFolder({ id: this.folderId, workspaceId: this.workspaceId });
      } else {
        this.SET_CURRENT_FOLDER(undefined);
      }
      this.loadExperiments(this.queryPayload);
      this.selectedColumn = [];
      this.loadedTimes++;
    }, 300),
    async loadMoreAutots() {
      await this.loadMoreExperiments(this.queryPayload);
    },
    async confirmDeleteExperiment(event: Event, exp: IExperiment) {
      event.preventDefault();
      const confirmAndDelete = async (
        confirmMsg: string,
        successMsg: string,
        failureMsg: string,
        exp: IExperiment
      ) => {
        this.$confirm.require({
          message: this.$t(confirmMsg, {
            name: exp.name,
          }),
          header: this.$t("experiment.list-experiment.dialog.delete.title"),
          icon: "pi pi-exclamation-triangle",
          acceptClass: "p-button-danger",
          accept: async () => {
            if (
              await this.delete({
                experimentId: exp.id,
                workspaceId: this.workspaceId,
              })
            ) {
              this.$toast.add({
                severity: "success",
                summary: this.$t("message.successful"),
                detail: this.$t(successMsg, { name: exp.name }),
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("message.error"),
                detail: this.$t(failureMsg, { name: exp.name }),
                life: 3000,
              });
            }

            this.load();
          },
        });
      };

      if (exp.type === ExperimentType.Folder) {
        const hasRunningExp = await this.folderHasRunningExp({
          folderId: exp.id,
          workspaceId: exp.workspaceId,
        });

        await confirmAndDelete(
          hasRunningExp
            ? "experiment.list-experiment.dialog.delete.folder.with_running_exp.confirm_message"
            : "experiment.list-experiment.dialog.delete.folder.confirm_message",
          hasRunningExp
            ? "experiment.list-experiment.dialog.message.folder.with_running_exp.delete_success"
            : "experiment.list-experiment.dialog.message.folder.delete_success",
          "experiment.list-experiment.dialog.message.folder.delete_error",
          exp
        );
      } else {
        await confirmAndDelete(
          "experiment.list-experiment.dialog.delete.confirm_message",
          "experiment.list-experiment.dialog.message.delete_success",
          "experiment.list-experiment.dialog.message.delete_error",
          exp
        );
      }
    },

    onCancel() {
      this.onToggleSelectFolder(null);
    },

    async onSelect() {
      const result = await Promise.all(
        this.selectedColumn.map((experiment) =>
          this.moveToFolder({
            experiment,
            destinationId: this.selectedFolder.id,
          })
        )
      );

      const successList = result
        .map((x, i) => [x, i])
        .filter(([x]) => !x)
        .map(([, i]) => this.selectedColumn[i]);
      const failureList = result.map((x, i) => [x, i]).filter(([x]) => !!x);
      const failureOfType = (type: string) =>
        failureList
          .filter(([x]) => x instanceof Error && x.message === type)
          .map(([, i]) => this.selectedColumn[i]);
      const genericFailureList = failureOfType("message.move.error.generic");
      const maxLevelFailureList = failureOfType(
        "message.move.folder.error.max_level"
      );
      const maxChildrenFailureList = failureOfType(
        "message.move.folder.error.max_child"
      );
      const notify = (
        xs: Array<IExperiment>,
        severity: string,
        summary: string,
        message: string
      ) => {
        if (!xs.length) return;
        const name = xs.map((x) => `"${x.name}"`).join(", ");

        this.$toast.add({
          severity,
          summary: this.$t(summary),
          detail: this.$t(message, { name }),
          life: 3000,
        });
      };

      notify(
        successList,
        "success",
        "message.successful",
        "message.move.success.generic"
      );
      notify(
        genericFailureList,
        "error",
        "message.error",
        "message.move.error.generic"
      );
      notify(
        maxLevelFailureList,
        "error",
        "message.error",
        "message.move.folder.error.max_level"
      );
      notify(
        maxChildrenFailureList,
        "error",
        "message.error",
        "message.move.folder.error.max_child"
      );

      this.selectedColumn = [];
      this.selectedFolder = RootFolder;
      (this.op as any).toggle();
      this.load();
    },

    onNavigate(folder: IFolder) {
      this.selectedFolder = folder;
      this.loadFolders({
        parentFolderId: folder.id,
        workspaceId: this.workspaceId,
      });
    },

    onLoadMore() {
      this.loadMoreFolders({
        parentFolderId: this.selectedFolder.id,
        workspaceId: this.workspaceId,
      });
    },

    onRowSelect({
      originalEvent,
      data,
    }: {
      originalEvent: Event;
      data: IExperiment;
    }) {
      if (this.selectedColumn.includes(data)) {
        this.selectedColumn = this.selectedColumn.filter((x) => x !== data);
      } else {
        this.selectedColumn.push(data);
      }
      return originalEvent;
    },

    openExperiment(data: IExperiment) {
      // Navigate to experiment detail when click row
      if (data.type === ExperimentType.Folder) {
        this.$router.push(
          `/workspace/${this.workspaceId}/experiment?folderId=${data.id}`
        );
      } else {
        this.$router.push(
          `/workspace/${
            this.workspaceId
          }/experiment/${data.type.toLowerCase()}/${data.id}`
        );
      }
    },

    async onSort(event: any) {
      this.dynamicSortField = event.sortField;
      this.dynamicSortOrder = event.sortOrder;
    },
    onCopy(event: Event, experimentObject: IExperiment) {
      this.loadDatasourceConfig({
        id: experimentObject.id,
        workspaceId: this.workspaceId,
      }).then(() => {
        const datasource = this.getTransactionalDatasourceConfig;
        switch (experimentObject.type) {
          case ExperimentType.AutoTS:
            this.$router.push({
              name: "Config" + experimentObject.type,
              params: {
                id: datasource.datasourceId,
                copiedTargetColumn: datasource.targetColumn,
                copiedTimeSeriesColumn: datasource.timeSeriesColumn,
                copiedTimeSeriesIdentifiers:
                  datasource.timeSeriesIdentifiers || [],
                copiedForecastHorizon: experimentObject.config?.forecastHorizon,
                copiedForecastFrequency: experimentObject.config?.frequency,
                copiedStartDate: experimentObject.config?.startDate,
                copiedEndDate: experimentObject.config?.endDate,
                copiedSplitDate: experimentObject.config?.splitDate,
                copiedAutoTSName: experimentObject.name,
                copiedGap: experimentObject.config?.gap
                  ? experimentObject.config?.gap
                  : 0,
                copiedPrimaryMetric: experimentObject.config?.primaryMetric,
                copiedOtherMetrics: experimentObject.config?.otherMetrics,
                copiedFeatureColumns: datasource.featureColumns || [],
                copiedFolderId: experimentObject.folderId,
                copiedModelList: experimentObject.config?.modelList || [],
                copiedPostProcessing: JSON.stringify(
                  experimentObject.config?.postProcessing ||
                    DEFAULT_POST_PROCESSING
                ),
                copiedTimeLimit: experimentObject.config?.timeLimit,
                workspaceId: this.workspaceId,
              },
              query: this.$route.query,
            });
            break;
          case ExperimentType.AutoML:
            this.$router.push({
              name: "Config" + experimentObject.type,
              params: {
                id: datasource?.datasourceId,
                copiedTargetColumn: datasource?.targetColumn,
                copiedTimeSeriesColumn: datasource?.timeSeriesColumn,
                copiedTimeSeriesIdentifiers: datasource?.timeSeriesIdentifiers,
                copiedFeatureColumns: datasource?.featureColumns,

                copiedName: experimentObject.name,
                copiedPredictionType: experimentObject.config?.predictionType,
                copiedPrimaryMetric: experimentObject.config?.primaryMetric,
                copiedOtherMetrics: experimentObject.config?.otherMetrics,
                copiedTimeLimit: experimentObject.config?.timeLimit,
                copiedTrainMode: experimentObject.config?.trainMode,
                copiedSplitTrainEndDate:
                  experimentObject.config?.splitTrainEndDate,
                copiedSplitTestEndDate:
                  experimentObject.config?.splitTestEndDate,
                copiedSplitTestStartDate:
                  experimentObject.config?.splitTestStartDate,
                copiedSplitTrainStartDate:
                  experimentObject.config?.splitTrainStartDate,
                copiedSplitType: experimentObject.config?.splitType,
                copiedTrainRatio: experimentObject.config?.trainRatio,
                copiedTimeSeries: experimentObject.config?.timeSeries,
                copiedFolderId: experimentObject.folderId,
                workspaceId: this.workspaceId,
              },
              query: this.$route.query,
            });
            break;
        }
      });
    },
    async callCreateFolder() {
      try {
        this.folderNewInfo.folderId = this.folderId;
        const folder: IExperiment = await this.createFolder(this.folderNewInfo);
        this.editingId = folder.id;
        this.newName = folder.name;
        this.load();
      } catch (err: any) {
        if (err instanceof Error) {
          this.$toast.add({
            severity: "error",
            summary: this.$t("message.error"),
            detail: this.$t(err.message),
            life: 3000,
          });
        }
      }
    },
    async updateCurrentName(folderDetail: IExperiment) {
      if (folderDetail && this.newName && !this.v$.newName.$invalid) {
        folderDetail.name = this.newName;
        folderDetail.lastModifiedDate = new Date().toISOString();
        await this.update(folderDetail);
        this.load();
        this.editingId = "";
      }
    },
    cancelEditName() {
      this.newName = "";
      this.editingId = "";
    },
    focusInput(event: Event) {
      event.stopPropagation();
    },
    onEdit(_event: Event, experiment: IExperiment) {
      this.editingId = experiment.id;
      this.newName = experiment.name;
    },
    toggle(event: any) {
      (this.menu as any).toggle(event);
    },
    onToggleSelectFolder(event: any) {
      this.selectedFolder = RootFolder;
      this.loadFolders({
        parentFolderId: RootFolder.id,
        workspaceId: this.workspaceId,
      });
      (this.op as any).toggle(event);
    },
    onClickCheckbox(event: Event) {
      event.stopPropagation();
    },
    onClickCheckboxAll() {
      if (this.isCheckedAll) {
        this.selectedColumn = [];
      } else {
        this.selectedColumn = this.experiments.map(
          (experiment: IExperiment) => experiment
        );
      }
    },
    async onClickDeleteMultiple() {
      const confirm = (msg: string, accept: () => unknown) => {
        this.$confirm.require({
          message: msg,
          header: this.$t("experiment.list-experiment.dialog.delete.title"),
          icon: "pi pi-exclamation-triangle",
          acceptClass: "p-button-danger",
          accept,
        });
      };

      const confirmAndDelete = async (confirmMsg: string) => {
        confirm(confirmMsg, async () => {
          const result = await this.deleteAll({
            experimentIds: this.selectedColumn.map((x: IExperiment) => x.id),
            workspaceId: this.workspaceId,
          });
          const successList = result.flatMap((r: boolean, i: number) =>
            r ? [i] : []
          );
          const failureList = result.flatMap((r: boolean, i: number) =>
            r ? [] : [i]
          );

          const showNotify = (list: Array<number>, severity: string) => {
            if (list.length == 0) return;
            const name = list
              .map((i: number) => `"${this.selectedColumn[i].name}"`)
              .join(", ");
            const detail = this.$t(
              `experiment.list-experiment.dialog.message.delete_${severity}_many`,
              { name }
            );

            this.$toast.add({
              severity,
              summary: this.$t(
                `message.${severity == "success" ? "successful" : severity}`
              ),
              detail,
              life: 3000,
            });
          };

          showNotify(successList, "success");
          showNotify(failureList, "error");

          this.selectedColumn = [];
          this.load();
        });
      };

      const foldersToDelete = this.selectedColumn.filter(
        (x) => x.type === ExperimentType.Folder
      );

      if (foldersToDelete.length) {
        const hasRunningExps = await Promise.all(
          foldersToDelete.map((f) =>
            this.folderHasRunningExp({
              folderId: f.id,
              workspaceId: f.workspaceId,
            })
          )
        );

        if (hasRunningExps.some((e: boolean) => e)) {
          const name = hasRunningExps
            .map((e, i) => [e, `"${foldersToDelete[i].name}"`])
            .filter(([e]) => e)
            .map(([, name]) => name)
            .join(", ");

          confirm(
            this.$t(
              "experiment.list-experiment.dialog.delete.selected.confirm_message"
            ),
            () => {
              // setTimeout workaround confirm dialog limitation,
              // otherwise second dialog won't show
              setTimeout(() => {
                confirmAndDelete(
                  this.$t(
                    "experiment.list-experiment.dialog.delete.folder.with_running_exp.confirm_message_many",
                    { name }
                  )
                );
              }, 500);
            }
          );
          return;
        }
      }

      await confirmAndDelete(
        this.$t(
          "experiment.list-experiment.dialog.delete.selected.confirm_message"
        )
      );
    },
  },
  watch: {
    queryPayload() {
      // when navigating away from experiment list page,
      // filter params will also get updated to empty
      // this guard prevents a bug where data is loaded after page change
      if (!this.leaving) {
        this.load();
      }
    },
    folderId() {
      this.load();
    },
  },
  unmounted() {
    this.CLEAR();
  },
});
