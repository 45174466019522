import { uploadFileToBlob } from "@/common/azure-storage-blob";
import { State } from "@/store";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { FileUploadTask, TaskState, UploadStatus } from "./types";

const taskState: TaskState = {
  fileUploadTasks: [],
  isShow: false,
};

const taskMutations: MutationTree<TaskState> = {
  ADD_NEW_TASK: (state, task: FileUploadTask) => {
    state.fileUploadTasks.unshift(task);
  },
  SET_SHOW_PROCESSING(state, isShow: boolean) {
    state.isShow = isShow;
  },
  SWITCH_SHOW(state) {
    state.isShow = !state.isShow;
  },
  UPDATE_TASK_STATUS(state, payload) {
    const task = state.fileUploadTasks.find((t) => t.id == payload.id);
    if (task) {
      const updateDate = new Date();
      task.status = payload.status;
      task.uploadTime = updateDate;
      task.doneTime = moment(updateDate).fromNow();
      task.code = payload.code;
      task.message = payload.message;
    }
  },
  UPDATE_DONE_TIME(state) {
    state.fileUploadTasks.every(
      (t) => (t.doneTime = moment(t.uploadTime).fromNow())
    );
  },
  DELETE_NOT_RUNING(state) {
    state.fileUploadTasks = state.fileUploadTasks.filter(
      (t) => t.status === UploadStatus.Uploading
    );
  },
  SET_LOADED_BYTES(state, payload) {
    const task = state.fileUploadTasks.find((t) => t.id == payload.id);
    if (task) {
      task.loadedBytes = payload.loadedBytes;
    }
  },
};

const taskActions: ActionTree<TaskState, State> = {
  onProgress(progress: unknown) {
    console.log(progress);
  },
  async uploadFileToBlob(context, payload) {
    const taskId = uuidv4();
    const createDate = new Date();
    const task: FileUploadTask = {
      id: taskId,
      fileName: payload.file.name,
      type: "upload",
      datasourcePath: payload.link,
      status: UploadStatus.Uploading,
      uploadTime: createDate,
      doneTime: moment(createDate).fromNow(),
      totalBytes: payload.file.size,
      loadedBytes: 0,
    };

    context.commit("ADD_NEW_TASK", task);
    context.commit("SET_SHOW_PROCESSING", true);

    uploadFileToBlob(
      payload.sasData.container,
      payload.sasData.uploadUrl,
      payload.sasData.uploadPath,
      payload.file,
      (progress) => {
        context.commit("SET_LOADED_BYTES", {
          id: taskId,
          loadedBytes: progress.loadedBytes,
        });
      }
    )
      .then((result) => {
        context.commit("UPDATE_TASK_STATUS", {
          id: taskId,
          status: UploadStatus.Success,
          code: result?._response.status,
        });
      })
      .catch((error) => {
        context.commit("UPDATE_TASK_STATUS", {
          id: taskId,
          status: UploadStatus.Failed,
          code: error.response.status,
          message: error.response.parsedBody.code,
        });
      });
  },
  setShow(context, payload) {
    context.commit("SET_SHOW_PROCESSING", payload.isShow);
  },
  switchShow(context) {
    context.commit("SWITCH_SHOW");
  },
  clear(context) {
    context.commit("DELETE_NOT_RUNING");
  },
  updateDoneTime(context) {
    context.commit("UPDATE_DONE_TIME");
  },
};

const taskGetters: GetterTree<TaskState, State> = {
  isShow(taskState: TaskState): boolean {
    return taskState.isShow;
  },
  getAllTask(taskState: TaskState) {
    return taskState.fileUploadTasks;
  },
  countTotal(taskState: TaskState): number {
    return taskState.fileUploadTasks.length;
  },
  countSuccess(taskState: TaskState): number {
    return taskState.fileUploadTasks.filter(
      (t) => t.status === UploadStatus.Success
    ).length;
  },
  countRunning(taskState: TaskState): number {
    return taskState.fileUploadTasks.filter(
      (t) => t.status === UploadStatus.Uploading
    ).length;
  },
};

export const taskModule: Module<TaskState, State> = {
  namespaced: true,
  state: taskState,
  getters: taskGetters,
  mutations: taskMutations,
  actions: taskActions,
};
