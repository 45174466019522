
import { defineComponent } from "vue";
import {
  extractQuery,
  getFilterRangeFromQuery,
  getNewQueryFromFilterRange,
} from "@/pages/Billing/shared";
import { useStore } from "@/store";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { UserProfile } from "@/pages/Identity/types";
import { IWorkspaceBasicInfo } from "@/pages/Workspace/types";
import {
  BillingDetailColumns,
  BillingDetailFilter,
  BillingDetailHiddenColumns,
  BillingDetailItem,
  BillingState,
} from "@/pages/Billing/types";
import { debounce } from "lodash";
import ExperimentStatus from "@/components/ExperimentStatus.vue";
import ExperimentTag from "@/components/ExperimentTag.vue";
import { SettingObjectOf } from "@/types";

export default defineComponent({
  component: [ExperimentStatus, ExperimentTag],
  name: "BillingDetail.vue",
  setup() {
    const store = useStore();
    return { store };
  },
  data(): {
    columns: ReadonlyArray<SettingObjectOf<BillingDetailItem>>;
    selectedColumns: Array<SettingObjectOf<BillingDetailItem>>;
    leaving: boolean;
  } {
    return {
      columns: BillingDetailColumns,
      selectedColumns: BillingDetailColumns.filter(
        (s) => !BillingDetailHiddenColumns.has(s.key)
      ),
      leaving: false,
    };
  },
  beforeMount() {
    this.loadTenantWorkspaces();
  },
  beforeRouteLeave() {
    this.leaving = true;
    this.CLEAR_BILLING_DETAILS();
  },
  computed: {
    ...mapState("identities", ["users"]),
    ...mapState("workspace", ["tenantWorkspaces"]),
    ...mapState("billing", ["billingDetails"]),
    ...mapState("billing", {
      loading: (state: unknown) => (state as BillingState).loading.details,
      jobs: (state: unknown) =>
        Object.values((state as BillingState).downloadingJobs),
    }),
    ...mapGetters("identities", ["getFullname"]),
    ...mapGetters("billing", ["hasMoreBillingDetails"]),
    shownColumns(): Set<string> {
      return new Set<string>(this.selectedColumns.map((c) => c.key));
    },
    filterRange: {
      get(): [Date | null, Date | null] {
        return getFilterRangeFromQuery(
          this.$route.query.from,
          this.$route.query.to
        );
      },
      set(newValue: [Date | null, Date | null]) {
        const [from, to] = getNewQueryFromFilterRange(newValue);
        this.$router.replace({
          query: {
            ...this.$route.query,
            from,
            to,
          },
        });
      },
    },
    selectedUser: {
      get(): UserProfile | null | undefined {
        return this.users?.find(
          (u: UserProfile) => u.id === extractQuery(this.$route.query.user)
        );
      },
      set(newValue: UserProfile) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            user: newValue?.id,
          },
        });
      },
    },
    selectedWorkspace: {
      get(): IWorkspaceBasicInfo | null | undefined {
        return this.tenantWorkspaces?.find(
          (u: UserProfile) => u.id === extractQuery(this.$route.query.workspace)
        );
      },
      set(newValue: IWorkspaceBasicInfo) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            workspace: newValue?.id,
          },
        });
      },
    },
    filterValue(): BillingDetailFilter {
      return {
        range: this.filterRange?.map((d) => this.$moment(d)),
        userId: this.selectedUser?.id,
        workspaceId: this.selectedWorkspace?.id,
      } as BillingDetailFilter;
    },
  },
  methods: {
    ...mapActions("workspace", ["loadTenantWorkspaces"]),
    ...mapActions("billing", [
      "loadBillingDetails",
      "loadMoreBillingDetails",
      "downloadDetails",
    ]),
    ...mapMutations("billing", ["CLEAR_BILLING_DETAILS"]),
    load: debounce(function (this: any) {
      const range = this.filterValue.range;
      if (!range || !range[0].isValid() || !range[1].isValid()) {
        return;
      }

      this.loadBillingDetails({
        from: range[0].format("YYYY-MM-DD"),
        to: range[1].format("YYYY-MM-DD"),
        userId: this.filterValue.userId,
        workspaceId: this.filterValue.workspaceId,
      });
    }, 300),
    loadMore() {
      const range = this.filterValue.range;
      if (!range || !range[0].isValid() || !range[1].isValid()) {
        return;
      }

      this.loadMoreBillingDetails({
        from: range[0].format("YYYY-MM-DD"),
        to: range[1].format("YYYY-MM-DD"),
        userId: this.filterValue.userId,
        workspaceId: this.filterValue.workspaceId,
      });
    },
    async downloadReport() {
      const from = this.filterValue.range[0].format("YYYY-MM-DD");
      const to = this.filterValue.range[1].format("YYYY-MM-DD");
      await this.downloadDetails({
        headerText: [
          this.$t("billing.detail_table.header.link"),
          this.$t("billing.detail_table.header.id_count"),
          this.$t("billing.detail_table.header.cost"),
          this.$t("billing.detail_table.header.exp_name"),
          this.$t("billing.detail_table.header.type"),
          this.$t("billing.detail_table.header.predict_name"),
          this.$t("billing.detail_table.header.created_user_name"),
          this.$t("billing.detail_table.header.completed_date"),
          this.$t("billing.detail_table.header.status"),
          this.$t("billing.detail_table.header.deleted"),
          this.$t("billing.detail_table.header.workspace_name"),
          this.$t("billing.detail_table.header.workspace_created_username"),
          this.$t("billing.detail_table.header.exp_id"),
          this.$t("billing.detail_table.header.predict_id"),
          this.$t("billing.detail_table.header.workspace_id"),
        ],
        from,
        to,
        fileName: this.$t("billing.detail_table.exported_filename", {
          from,
          to,
        }),
        sheetName: from + ` ~ ` + to,
        linkText: this.$t("billing.detail_table.body.link"),
      });
    },
  },
  watch: {
    filterValue() {
      // This guard is needed to prevent unnecessary API load on routeLeave
      if (this.leaving) {
        return;
      }

      this.load();
    },
  },
});
