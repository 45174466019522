const contrastColor = "#eeeeee";
const subContrastColor = "#aaaaaa";
const primaryColor = "#02a1d1";
export const theme = {
  color: [
    "#02a1d1",
    "#2E60AD",
    "#F9BA37",
    "#71C294",
    "#FF7461",
    "#BA74D2",
    "#FFA600",
    "#08B3AB",
    "#E84C3D",
    // "#3194F7",
    // "#a53f2b",
    // "#280004",
    // "#32213a",
    "#27727b",
    "#b5c334",
  ],
  backgroundColor: "rgba(0,0,0,0)",
  textStyle: {},
  title: {
    textStyle: {
      color: contrastColor,
    },
    subtextStyle: {
      color: subContrastColor,
    },
  },
  line: {
    itemStyle: {
      borderWidth: 1,
    },
    lineStyle: {
      width: 2,
    },
    symbolSize: 8,
    symbol: "circle",
  },
  radar: {
    itemStyle: {
      borderWidth: 1,
    },
    lineStyle: {
      width: 2,
    },
    symbolSize: 3,
    symbol: "circle",
    smooth: true,
  },
  bar: {
    itemStyle: {
      barBorderWidth: 0,
      barBorderColor: subContrastColor,
    },
  },
  pie: {
    itemStyle: {
      borderWidth: 0,
      borderColor: subContrastColor,
    },
    label: {
      color: contrastColor,
    },
  },
  scatter: {
    itemStyle: {
      borderWidth: 0,
      borderColor: subContrastColor,
    },
  },
  boxplot: {
    itemStyle: {
      borderWidth: 0,
      borderColor: subContrastColor,
    },
  },
  parallel: {
    itemStyle: {
      borderWidth: 0,
      borderColor: subContrastColor,
    },
  },
  sankey: {
    itemStyle: {
      borderWidth: 0,
      borderColor: subContrastColor,
    },
  },
  funnel: {
    itemStyle: {
      borderWidth: 0,
      borderColor: subContrastColor,
    },
  },
  gauge: {
    itemStyle: {
      borderWidth: 0,
      borderColor: subContrastColor,
    },
  },
  candlestick: {
    itemStyle: {
      color: "#d87a80",
      color0: "#2ec7c9",
      borderColor: "#d87a80",
      borderColor0: "#2ec7c9",
      borderWidth: 1,
    },
  },
  graph: {
    itemStyle: {
      borderWidth: 0,
      borderColor: subContrastColor,
    },
    lineStyle: {
      width: 1,
      color: subContrastColor,
    },
    symbolSize: 3,
    symbol: "emptyCircle",
    smooth: true,
    color: [
      "#02a1d1",
      "#2E60AD",
      "#F9BA37",
      "#71C294",
      "#FF7461",
      "#BA74D2",
      "#FFA600",
      "#08B3AB",
      "#E84C3D",
      "#3194F7",
    ],
    label: {
      color: contrastColor,
    },
  },
  map: {
    itemStyle: {
      areaColor: "#dddddd",
      borderColor: contrastColor,
      borderWidth: 0.5,
    },
    label: {
      color: "#d87a80",
    },
    emphasis: {
      itemStyle: {
        areaColor: "rgba(254,153,78,1)",
        borderColor: "#444",
        borderWidth: 1,
      },
      label: {
        color: "rgb(100,0,0)",
      },
    },
  },
  geo: {
    itemStyle: {
      areaColor: "#dddddd",
      borderColor: contrastColor,
      borderWidth: 0.5,
    },
    label: {
      color: "#d87a80",
    },
    emphasis: {
      itemStyle: {
        areaColor: "rgba(254,153,78,1)",
        borderColor: "#444",
        borderWidth: 1,
      },
      label: {
        color: "rgb(100,0,0)",
      },
    },
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: contrastColor,
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: contrastColor,
      },
    },
    axisLabel: {
      show: true,
      color: contrastColor,
    },
    splitLine: {
      show: false,
      lineStyle: {
        type: "dashed",
        color: subContrastColor,
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
    },
  },
  valueAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: contrastColor,
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: contrastColor,
      },
    },
    axisLabel: {
      show: true,
      color: contrastColor,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "dashed",
        color: subContrastColor,
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
    },
  },
  logAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: contrastColor,
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: contrastColor,
      },
    },
    axisLabel: {
      show: true,
      color: contrastColor,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "dashed",
        color: subContrastColor,
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
    },
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: contrastColor,
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: contrastColor,
      },
    },
    axisLabel: {
      show: true,
      color: contrastColor,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "dashed",
        color: subContrastColor,
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
      },
    },
  },
  toolbox: {
    iconStyle: {
      borderColor: contrastColor,
    },
    emphasis: {
      iconStyle: {
        borderColor: primaryColor,
      },
    },
  },
  legend: {
    textStyle: {
      color: contrastColor,
    },
  },
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: "#008acd",
        width: "1",
      },
      crossStyle: {
        color: "#008acd",
        width: "1",
      },
      label: {
        color: "#495057",
      },
    },
  },
  timeline: {
    lineStyle: {
      color: "#008acd",
      width: 1,
    },
    itemStyle: {
      color: "#008acd",
      borderWidth: 1,
    },
    controlStyle: {
      color: "#008acd",
      borderColor: "#008acd",
      borderWidth: 0.5,
    },
    checkpointStyle: {
      color: "#2ec7c9",
      borderColor: "#2ec7c9",
    },
    label: {
      color: "#008acd",
    },
    emphasis: {
      itemStyle: {
        color: "#a9334c",
      },
      controlStyle: {
        color: "#008acd",
        borderColor: "#008acd",
        borderWidth: 0.5,
      },
      label: {
        color: "#008acd",
      },
    },
  },
  visualMap: {
    color: [
      "#02a1d1",
      "#2E60AD",
      "#F9BA37",
      "#71C294",
      "#FF7461",
      "#BA74D2",
      "#FFA600",
      "#08B3AB",
      "#E84C3D",
      "#3194F7",
    ],
  },
  dataZoom: {
    backgroundColor: "rgba(47,69,84,0)",
    dataBackgroundColor: "#efefff",
    fillerColor: "rgba(182,162,222,0.2)",
    handleColor: "#008acd",
    handleSize: "100%",
    textStyle: {
      color: contrastColor,
    },
  },
  markPoint: {
    label: {
      color: contrastColor,
    },
    emphasis: {
      label: {
        color: contrastColor,
      },
    },
  },
  markArea: {
    label: {
      color: contrastColor,
    },
  },
};
