import { SettingObject } from "@/types";

export const DEFAULT_SETTING_OBJECT = {
  key: "",
  label: "",
} as SettingObject;

export enum PREDICTION {
  REGRESSION = "regression",
  BINARY = "binary",
  MULTICLASS = "multiclass",
}

export enum TRAINTEST {
  RANDOM = "random",
  TIMESERIES = "time_series",
}

export enum TIMESERIES {
  YES = "yes",
  NO = "no",
}

export const PREDICTION_REGRESSION_OPTION = {
  label: "automl.create.header.prediction.type.regression",
  key: PREDICTION.REGRESSION,
};

export const PREDICTION_BINARY_OPTION = {
  label: "automl.create.header.prediction.type.binary",
  key: PREDICTION.BINARY,
};
export const PREDICTION_MULTICLASS_OPTION = {
  label: "automl.create.header.prediction.type.multiclass",
  key: PREDICTION.MULTICLASS,
};

export const PREDICTION_LIST = [
  PREDICTION_REGRESSION_OPTION,
  PREDICTION_BINARY_OPTION,
  PREDICTION_MULTICLASS_OPTION,
] as Array<SettingObject>;

export const REGRESSION_LIST = [
  { key: "root_mean_squared_error", label: "RMSE" },
  { key: "scaled_mean_absolute_error", label: "sMAE" },
  { key: "mean_absolute_error_percent", label: "MAEPercent" },
  { key: "mean_absolute_error", label: "MAE" },
  { key: "mean_absolute_percentage_error", label: "MAPE" },
  { key: "r2", label: "R2" },
] as Array<SettingObject>;

export const BINARY_LIST = [
  { key: "roc_auc", label: "AUC" },
  { key: "accuracy", label: "Accuracy" },
  { key: "balanced_accuracy", label: "Balanced Accuracy" },
] as Array<SettingObject>;

export const MULTICLASS_LIST = [
  { key: "accuracy", label: "Accuracy" },
  { key: "balanced_accuracy", label: "Balanced Accuracy" },
] as Array<SettingObject>;

export const TRAIN_TEST_SPLITS = [
  { label: "automl.create.header.train.test.random", key: TRAINTEST.RANDOM },
  {
    label: "automl.create.header.datecolumn",
    key: TRAINTEST.TIMESERIES,
  },
] as Array<SettingObject>;

export const TIME_SERIES_DATA = [
  { label: "automl.create.timeseries.yes", key: TIMESERIES.YES },
  { label: "automl.create.timeseries.no", key: TIMESERIES.NO },
] as Array<SettingObject>;

export const RUN_MODE_LIST = [
  {
    label: "automl.label.run_mode.medium_quality_faster_train",
    key: "medium_quality_faster_train",
  },
  {
    label: "automl.label.run_mode.good_quality_faster_inference_only_refit",
    key: "good_quality_faster_inference_only_refit",
  },
  {
    label: "automl.label.run_mode.high_quality_fast_inference_only_refit",
    key: "high_quality_fast_inference_only_refit",
  },
  {
    label: "automl.label.run_mode.best_quality",
    key: "best_quality",
  },
] as Array<SettingObject>;

export const TIME_LIMIT_LIST = [
  {
    label: "automl.create.limit.time.0.5",
    key: 1800,
  },
  {
    label: "automl.create.limit.time.1",
    key: 3600,
  },
  {
    label: "automl.create.limit.time.2",
    key: 7200,
  },
  {
    label: "automl.create.limit.time.4",
    key: 14400,
  },
  {
    label: "automl.create.limit.time.8",
    key: 28800,
  },
  {
    label: "automl.create.limit.time.16",
    key: 57600,
  },
  {
    label: "automl.create.limit.time.24",
    key: 86400,
  },
  {
    label: "automl.create.limit.time.48",
    key: 172800,
  },
] as Array<SettingObject>;

export const TIME_LIMIT_LIST_AUTOTS = [
  {
    label: "automl.create.limit.time.1",
    key: 3600,
  },
  {
    label: "automl.create.limit.time.2",
    key: 7200,
  },
  {
    label: "automl.create.limit.time.4",
    key: 14400,
  },
  {
    label: "automl.create.limit.time.8",
    key: 28800,
  },
  {
    label: "automl.create.limit.time.16",
    key: 57600,
  },
  {
    label: "automl.create.limit.time.24",
    key: 86400,
  },
  {
    label: "automl.create.limit.time.48",
    key: 172800,
  },
] as Array<SettingObject>;

export const FORECAST_DURATION = [
  {
    label: "autots.create.label.days",
    key: "D",
  },
  {
    label: "autots.create.label.weeks",
    key: "W",
  },
  {
    label: "autots.create.label.months",
    key: "M",
  },
] as Array<SettingObject>;

export const AUTOTS_ADVANCE_SETTING_FORECAST_RANGE = [
  {
    label: "autots.create.advance.post.processing.range.no.setting",
    key: null,
  },
  {
    label: "autots.create.advance.post.processing.range.auto",
    key: "auto",
  },
  {
    label: "autots.create.advance.post.processing.range.manual",
    key: "manual",
  },
] as Array<SettingObject>;

export const YES_NO_SETTING = [
  {
    label: "button.yes",
    key: "true",
  },
  {
    label: "button.no",
    key: "false",
  },
] as Array<SettingObject>;
