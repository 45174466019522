import { IExperiment } from "../Experiment/types";
import { IFolder } from "@/pages/Common/types";
import { WorkBook } from "xlsx";

export interface DatasourceState {
  currentFolder: IFolder;
  folders: Array<IFolder>;
  datasources: Array<Datasource>;
  datasourcesReady: Array<Datasource>;
  newDatasource?: undefined | DatasourceDetail;
  relatedExperiments: Array<IExperiment>;
  files: Array<FileDetail>;
  lastAccessDatasources: Array<Datasource>;
  showPopupDatasource: boolean;
  nameNewDatasource?: string;
  desc: string;
  tokenDatasources: string; // Token to query next datasources
  tokenRelatedExperiments: string;
  tokenFolders: string;
  datasourceDetail?: DatasourceDetail;
  tokenFiles: string;
  loading: boolean;
  loadCount: number;
  loadingFiles: boolean;
  totalFiles: number;
  totalSize: number;
  datasourceProfile?: DatasourceProfile;
  loadingDatasourceProfile: boolean;
  edaNumeric?: EdaNumeric;
  correlation: { [key: string]: number };
  modelId: string;
  modelName: string;
  experimentId: string;
  explaination: boolean;
  edaFinished: boolean;

  //Create new datasource
  showInputDatasource: boolean;
  selectedFile: Array<any>;
  listRowData: Array<any>;
  listHeader: DataHeader[];
  totalRow: number;
  delimiter: string;
  linebreak: string;
  typeData: TypeData[];
  typeDate: TypeData[];
  selectedDelimiter: string;
  selectedLinebreak: string;
  showUploadButton: boolean;
  selectedHeader: Array<any>;
  listPreviewData: DataPreview[];
  disabled: boolean;
  encoding: string;
  disabledPreviewButton: boolean;
  workbook?: WorkBook;
  selectedSheetName?: string;
  selectedSheetIndex?: number;
}

export enum DatasourceStatus {
  INITIAL = "INITIAL",
  READY = "READY",
}

export interface Datasource {
  id: string;
  name: string;
  status: DatasourceStatus;
  description?: string;
  createdBy?: string;
  createDate?: string;
  isDeleted?: boolean;
  isReady?: boolean;
  isPublished?: boolean;
  size?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  columnDelimiter?: string;
  type?: string;
  folderId?: string;
  workspaceId?: string;
}

export enum DatasourceType {
  DATASOURCE = "DATASOURCE",
  FOLDER = "FOLDER",
}

export interface DatasourceDetail {
  id?: string;
  name?: string;
  description?: string;
  schemas?: Array<DatasourceSchema>;
  status?: DatasourceStatus;
  createdBy?: string;
  createDate?: string;
  isDeleted?: boolean;
  isReady?: boolean;
  isPublished?: boolean;
  size?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  columnDelimiter?: string;
  type?: string;
  folderId?: string;
  folderName?: string;
  workspaceId?: string;
}

export interface DatasourceSchema {
  index: number;
  name: string;
  type: string;
  role: DataRole;
  format?: string;
}

export enum FileStatus {
  INITIAL = "INITIAL",
  VALIDATING = "VALIDATING",
  REJECTED = "REJECTED",
  COMPLETED = "COMPLETED",
}

export interface FileDetail {
  createdBy?: string;
  createdDate?: Date;
  datasourceId: string;
  extension: string;
  sheetName?: string;
  sheetIndex?: number;
  id?: string;
  size: number;
  isDeleted?: boolean;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
  name: string;
  status?: string;
  tenantId?: string;
  type?: string;
  encoding?: string;
  workspaceId?: string;
}

export interface Experiment {
  id: string;
  name: string;
}

export interface PayloadTabChangeEvent {
  originalEvent: Event;
  index: number;
}

export interface DataHeader {
  code: ColumnType;
  field: string;
  header: string;
}

export interface Prediction {
  createdBy: string;
  description: string;
  experimentId: string;
  fileInputName?: string;
  inputBlobId: string;
  modelId: string;
  modelName: string;
  name?: string;
  runExplain: boolean;
  workspaceId?: string;
  sheetName?: string;
  sheetIndex?: number;
  fileExtension?: string;
}

export interface ColumnType {
  type: string;
  format: string;
}

export interface DataPreview {
  columns: string;
  dataType: string;
  role: DataRole;
  format: string;
}

export function getDataRoleFromDataType(dataType: string): DataRole {
  switch (dataType) {
    case "date":
      return DataRole.DateTime;
    case "integer":
    case "float":
      return DataRole.Numeric;
    default:
      return DataRole.Categorical;
  }
}

export enum DataRole {
  Categorical = "Categorical",
  DateTime = "DateTime",
  Numeric = "Numeric",
}

export interface TypeData {
  code: string;
  name: string;
}

export interface Sort {
  sort: string;
  dsc: boolean;
}

export interface ProfileTable {
  memory_size: number;
  n: number;
  n_cells_missing: number;
  n_var: number;
  n_vars_all_missing: number;
  n_vars_with_missing: number;
  p_cells_missing: number;
  record_size: number;
}

export interface Variable {
  [key: string]: any;
}

export interface DatasourceProfile {
  analysis: any;
  correlations: any;
  messages: string[];
  missing: any;
  package: any;
  sample: any[];
  scatter: any;
  table: ProfileTable;
  variables: { [key: string]: Variable };
}

export interface EdaNumeric {
  [key: string]: Array<number | null>;
}

export interface DropdownOption<T> {
  id: T;
  name: string;
}
