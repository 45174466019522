
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "@/store";
import { mapGetters } from "vuex";
import ConfigDs from "./ConfigDs.vue";
import moment from "moment";
import { SchemaInfor, CloneConfig } from "@/pages/Experiment/types";
import { TIME_LIMIT_LIST_AUTOTS } from "@/pages/Experiment/common/constantSettingList";

export default defineComponent({
  components: {
    ConfigDs,
  },
  data() {
    return {
      autoTsName: "",
      autoTsDescription: "",
      autoTsSchemas: [] as Array<SchemaInfor>,
      autoTsTenantId: "",
      datasourceName: "",
      copiedConfig: {} as CloneConfig,
    };
  },
  props: [
    "id",
    "copiedTargetColumn",
    "copiedTimeSeriesColumn",
    "copiedTimeSeriesIdentifiers",
    "copiedForecastHorizon",
    "copiedForecastFrequency",
    "copiedStartDate",
    "copiedEndDate",
    "copiedSplitDate",
    "copiedAutoTSName",
    "copiedGap",
    "copiedPrimaryMetric",
    "copiedOtherMetrics",
    "copiedFolderId",
    "copiedModelList",
    "copiedPostProcessing",
    "copiedTimeLimit",
    "copiedFeatureColumns",
    "folderId",
    "workspaceId",
  ],
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    ...mapGetters("datasources", ["getOneDataSource"]),
  },
  async created() {
    const selectedDatasourceInformation = await this.getOneDataSource(
      this.id,
      this.workspaceId
    );
    this.datasourceName = selectedDatasourceInformation.name;
    this.autoTsName =
      selectedDatasourceInformation.name +
      " - " +
      moment().format("YYYY/MM/DD");
    this.autoTsSchemas = selectedDatasourceInformation.schemas;
    if (this.copiedTargetColumn) {
      const targetColumn = this.autoTsSchemas.find(
        (e) => e.name === this.copiedTargetColumn
      );
      if (targetColumn) {
        this.copiedConfig.targetColumn = targetColumn;
      }
    }
    if (this.copiedTimeSeriesColumn) {
      const timeSeriesColumn = this.autoTsSchemas.find(
        (e) => e.name === this.copiedTimeSeriesColumn
      );
      if (timeSeriesColumn) {
        this.copiedConfig.timeSeriesColumn = timeSeriesColumn;
      }
    }
    if (this.copiedTimeSeriesIdentifiers) {
      const idcs: Array<SchemaInfor> = [];
      this.copiedTimeSeriesIdentifiers.forEach((element: string) => {
        const idc = this.autoTsSchemas.find((e) => e.name === element);
        if (idc) {
          idcs.push(idc);
        }
      });
      this.copiedConfig.timeSeriesIdentifiers = idcs;
    }
    if (this.copiedForecastHorizon) {
      this.copiedConfig.forecastHorizon = Number(this.copiedForecastHorizon);
    }
    if (this.copiedForecastFrequency) {
      this.copiedConfig.frequency = this.copiedForecastFrequency;
    }
    if (this.copiedStartDate) {
      this.copiedConfig.startDate = new Date(this.copiedStartDate);
    }
    if (this.copiedEndDate) {
      this.copiedConfig.endDate = new Date(this.copiedEndDate);
    }
    if (this.copiedSplitDate) {
      this.copiedConfig.splitDate = new Date(this.copiedSplitDate);
    }
    if (this.copiedAutoTSName) {
      this.autoTsName = this.$t("experiment.copy.title", {
        name: this.copiedAutoTSName,
      });
      this.copiedConfig.name = this.autoTsName;
      this.autoTsDescription = this.$t("experiment.copy.description", {
        name: this.copiedAutoTSName,
        time: moment().format("YYYY/MM/DD HH:mm:ss"),
      });
    }
    if (this.copiedGap) {
      this.copiedConfig.gap = Number(this.copiedGap);
    }
    if (this.copiedPrimaryMetric) {
      this.copiedConfig.primaryMetric = this.copiedPrimaryMetric;
    }
    if (this.copiedOtherMetrics) {
      this.copiedConfig.otherMetrics = this.copiedOtherMetrics;
    }
    if (this.copiedModelList?.length) {
      this.copiedConfig.modelList = this.copiedModelList;
    }
    if (this.copiedPostProcessing) {
      this.copiedConfig.postProcessing = JSON.parse(this.copiedPostProcessing);
    }
    if (this.copiedTimeLimit) {
      this.copiedConfig.timeLimit = TIME_LIMIT_LIST_AUTOTS.find(
        (e) => e.key === parseInt(this.copiedTimeLimit)
      );
    }
    if (this.copiedFeatureColumns) {
      this.copiedConfig.featureColumns = this.autoTsSchemas.filter((e) =>
        this.copiedFeatureColumns.some((i: string) => e.name === i)
      );
    }
    this.copiedConfig.folderId = this.copiedFolderId
      ? this.copiedFolderId
      : this.folderId;
    this.autoTsTenantId = selectedDatasourceInformation.tenantId;
  },
});
