
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  data() {
    return {
      showDialog: this.visible,
      name: "",
    };
  },
  props: {
    visible: Boolean,
    title: String,
    placeholder: String,
    label: String,
  },
  watch: {
    visible(newValue) {
      this.showDialog = newValue;
    },
    showDialog(newValue) {
      this.$emit("update:visible", newValue);
    },
  },
  emits: ["update:visible", "hide", "clickCreate"],
  methods: {
    onHide(event: Event) {
      this.$emit("update:visible", false);
      this.$emit("hide", event);
    },
    onClickCreate($event: Event) {
      this.$emit("clickCreate", $event, this.name);
    },
  },
});
