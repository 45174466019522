
import { defineComponent, ref } from "@vue/runtime-core";
import { BarChart } from "echarts/charts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { mapState } from "vuex";
import moment from "moment";
import _ from "lodash";

function renderItem(params: any, api: any) {
  var yValue = api.value(2);
  var start = api.coord([api.value(0), yValue]);
  var size = api.size([api.value(1) - api.value(0), yValue]);
  var style = api.style();

  return {
    type: "rect",
    shape: {
      x: start[0] + 1,
      y: start[1],
      width: size[0] - 2,
      height: size[1],
    },
    style: style,
  };
}

use([CanvasRenderer, BarChart]);
export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    variable: {
      type: Object,
      required: true,
    },
  },
  data(): {
    option: any;
    optionCommonValue: any;
    active: number;
  } {
    return {
      option: undefined,
      optionCommonValue: undefined,
      active: 0,
    };
  },
  async created() {
    if (this.variable.histogram) {
      if (this.variable.type === "DateTime") {
        this.option = this.createDateTimeOption(
          this.variable.histogram.bin_edges,
          this.variable.histogram.counts
        );
      } else {
        if (this.variable.common_values) {
          this.optionCommonValue = this.createCommonValueNumericOption(
            this.variable.common_values
          );
        } else {
          this.active = 1;
        }
      }
    } else if (this.variable.value_counts_index_sorted) {
      if (this.variable.type === "Boolean") {
        this.option = this.createPieOption(
          this.variable.value_counts_index_sorted
        );
      } else {
        this.option = this.createCategoryOption(
          this.variable.value_counts_index_sorted
        );
      }
    }
  },
  computed: {
    ...mapState("application", ["theme"]),
  },
  watch: {
    active(newValue) {
      if (newValue === 1) {
        this.option = this.createNumericOption(
          this.variable.histogram.bin_edges,
          this.variable.histogram.counts,
          this.variable.min,
          this.variable.max
        );
      } else {
        this.optionCommonValue = this.createCommonValueNumericOption(
          this.variable.common_values
        );
      }
    },
  },
  methods: {
    getCssClass(index: number) {
      return this.active === index ? "" : "p-button-text";
    },
    createDateTimeOption(binEdges: Array<number>, counts: Array<number>) {
      const data = [];
      for (let i = 0; i < binEdges.length - 1; i++) {
        data.push([binEdges[i], binEdges[i + 1], counts[i]]);
      }
      return ref({
        title: {
          show: false,
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          top: "10",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          formatter: (data: any) => {
            const marker = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#02a1d1;"></span>`;
            return `<b>${moment
              .unix(data[0].value[0])
              .format("YYYY/MM/DD HH:mm:ss")}</b> - <b>${moment
              .unix(data[0].value[1])
              .format("YYYY/MM/DD HH:mm:ss")}</b><br/>${marker} ${
              data[0].value[2]
            }`;
          },
        },
        xAxis: {
          type: "time",
        },
        yAxis: {
          type: "value",
          axisLabel: {
            hideOverlap: true,
          },
        },
        series: [
          {
            data: data,
            type: "custom",
            barWidth: "99.3%",
            renderItem: renderItem,
            encode: {
              x: [0, 1],
              y: 2,
              tooltip: 2,
              label: 2,
            },
          },
        ],
      });
    },
    createNumericOption(
      binEdges: Array<number>,
      counts: Array<number>,
      min: number,
      max: number
    ) {
      const data = [];
      for (let i = 0; i < binEdges.length - 1; i++) {
        data.push([binEdges[i], binEdges[i + 1], counts[i]]);
      }
      return ref({
        title: {
          show: false,
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          top: "10",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          formatter: (data: any) => {
            const marker = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#02a1d1;"></span>`;
            return `<b>${data[0].value[0]}</b> - <b>${data[0].value[1]}</b><br/>${marker} ${data[0].value[2]}`;
          },
        },
        xAxis: {
          min: Math.floor(min),
          max: Math.ceil(max),
          type: "value",
        },
        yAxis: {
          type: "value",
          axisLabel: {
            hideOverlap: true,
          },
        },
        series: [
          {
            data: data,
            type: "custom",
            barWidth: "99.3%",
            renderItem: renderItem,
            encode: {
              x: [0, 1],
              y: 2,
              tooltip: 2,
              label: 2,
            },
          },
        ],
      });
    },
    createCommonValueNumericOption(commonValues: Array<number>) {
      let commonValuesArray = Object.entries(commonValues).sort(
        (prev: any, curr: any) => curr[1] - prev[1]
      );
      let othersValue: any;
      const foundIdx = commonValuesArray.findIndex((el) => {
        if (el[0] === "others") {
          othersValue = el[1];
        }
        return el[0] === "others";
      });
      commonValuesArray.splice(foundIdx, 1);
      commonValuesArray.push(["others", othersValue]);

      let sumArray: Array<string | number> = [];
      commonValuesArray.forEach((element) => sumArray.push(element[1]));
      const sum = _.sum(sumArray);

      return ref({
        title: {
          show: false,
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          top: "10%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          type: "value",
        },
        dataset: {
          dimensions: ["key", "value"],
          source: commonValuesArray,
        },
        series: [
          {
            type: "bar",
            label: {
              show: true,
              color: "var(--primary-color)",
              position: "top",
              formatter: (data: any) => {
                const percent = (data.value[1] / sum) * 100;
                if (percent % 2 === 0) {
                  return percent + "%";
                } else {
                  return percent.toFixed(1) + "%";
                }
              },
            },
          },
        ],
      });
    },
    createCategoryOption(data: { [key: string]: number | string }) {
      let rawDatasource = Object.entries(data).sort(
        (prev: any, curr: any) => curr[1] - prev[1]
      );
      let datasource = rawDatasource.splice(0, 5);
      if (rawDatasource.length > 0) {
        let otherValues = rawDatasource.reduce(
          (accum, item) => {
            return [
              accum[0] + 1,
              accum[1] + (typeof item[1] === "string" ? 1 : item[1]),
            ];
          },
          [0, 0]
        );
        datasource.push([
          this.$t("datasource.eda.other.values", { count: otherValues[0] }),
          otherValues[1],
        ]);
      }
      let sumArray: Array<string | number> = [];
      datasource.forEach((element) => sumArray.push(element[1]));
      const sum = _.sum(sumArray);
      return ref({
        title: {
          show: false,
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          top: "10",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        dataset: {
          dimensions: ["key", "value"],
          source: datasource.reverse(),
        },
        xAxis: {},
        yAxis: {
          type: "category",
          axisLabel: {
            formatter: (value: string) => {
              if (value.length > 8) {
                return value.slice(0, 8) + "...";
              }
              return value;
            },
          },
        },
        series: {
          type: "bar",
          encode: { x: "value", y: "key" },
          datasetIndex: 0,
          label: {
            show: true,
            color: "var(--primary-color)",
            position: "right",
            formatter: (data: any) => {
              const percent = (data.value[1] / sum) * 100;
              if (percent % 2 === 0) {
                return percent + "%";
              } else {
                return percent.toFixed(1) + "%";
              }
            },
          },
        },
      });
    },
    createPieOption(data: { [key: string]: number | string }) {
      let datasource = Object.entries(data);
      return ref({
        title: {
          show: false,
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          top: "10",
          containLabel: true,
        },
        tooltip: {
          trigger: "item",
        },
        dataset: [
          {
            dimensions: ["key", "value"],
            source: datasource,
          },
        ],
        series: {
          type: "pie",
          label: {
            formatter: "{c} ({d}%)",
          },
        },
      });
    },
  },
});
