
import { DatasourceDetail } from "@/types";
import { defineComponent } from "@vue/runtime-core";
import { mapGetters, mapState } from "vuex";
import { ExperimentStatus } from "../../types";

export default defineComponent({
  props: {},
  data(): {
    datasourceDetail: DatasourceDetail | undefined;
  } {
    return {
      datasourceDetail: undefined,
    };
  },
  computed: {
    ...mapGetters("identities", ["getFullname"]),
    ...mapState("experiments", ["experimentDetail", "datasourceConfig"]),
    ...mapGetters("datasources", ["getOneDataSource"]),
  },
  created() {
    if (this.datasourceConfig && this.datasourceConfig[0]) {
      this.getOneDataSource(this.datasourceConfig[0].datasourceId).then(
        (result: DatasourceDetail) => {
          this.datasourceDetail = result;
        }
      );
    }
  },
  watch: {
    datasourceConfig: {
      handler(newValue) {
        this.getOneDataSource(newValue[0].datasourceId).then(
          (result: DatasourceDetail) => {
            this.datasourceDetail = result;
          }
        );
      },
      deep: true,
    },
  },
  methods: {
    getStatusStyle(status: ExperimentStatus) {
      switch (status) {
        case ExperimentStatus.Created:
          return "info";
        case ExperimentStatus.Completed:
          return "success";
        case ExperimentStatus.Failed:
          return "danger";
        default:
          return "warning";
      }
    },
    getTimeSeriesColumnFormat() {
      if (
        this.datasourceDetail &&
        this.datasourceConfig &&
        this.datasourceDetail.schemas
      ) {
        const timeSeriesColumn = this.datasourceDetail.schemas.find(
          (x) => x.name === this.datasourceConfig[0].timeSeriesColumn
        );
        if (timeSeriesColumn) {
          return timeSeriesColumn.format;
        }
      }
    },
  },
});
