import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-fluid" }
const _hoisted_2 = { class: "p-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: _ctx.title,
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDialog = $event)),
    style: { width: '50vw' },
    modal: true,
    onHide: _ctx.onHide
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.label,
        icon: "pi pi-arrow-right",
        onClick: _ctx.onClickCreate,
        disabled: !_ctx.name
      }, null, 8, ["label", "onClick", "disabled"])
    ]),
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_InputText, {
            id: "experiment-name",
            type: "text",
            modelValue: _ctx.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.name = $event)),
            placeholder: _ctx.placeholder,
            autofocus: ""
          }, null, 8, ["modelValue", "placeholder"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible", "onHide"]))
}