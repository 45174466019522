import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-col-12 datasource" }
const _hoisted_2 = { class: "p-mb-4" }
const _hoisted_3 = {
  key: 0,
  class: "p-fluid p-mb-4"
}
const _hoisted_4 = { class: "p-float-label" }
const _hoisted_5 = { for: "datasourceName" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "p-error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Steps = _resolveComponent("Steps")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NewDatasourceControl = _resolveComponent("NewDatasourceControl")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Steps, {
        model: _ctx.items,
        readonly: true,
        exact: false
      }, null, 8, ["model"])
    ]),
    (_ctx.showInputName)
      ? (_openBlock(), _createBlock("div", _hoisted_3, [
          _createVNode("span", _hoisted_4, [
            _createVNode(_component_InputText, {
              id: "datasourceName",
              type: "text",
              modelValue: _ctx.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.name = $event)),
              autoFocus: "",
              maxlength: "64",
              required: true
            }, null, 8, ["modelValue"]),
            _createVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("upload.datasource.name")), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.errorMessage)
      ? (_openBlock(), _createBlock("div", _hoisted_6, [
          _createVNode("p", _hoisted_7, _toDisplayString(_ctx.errorMessage), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    _createVNode(_component_NewDatasourceControl, { workspaceId: _ctx.workspaceId }, null, 8, ["workspaceId"])
  ]))
}