
import { defineComponent, ref } from "@vue/runtime-core";
import { mapActions, mapGetters, mapState } from "vuex";
import Board from "./Board.vue";
import Recent from "./Recent.vue";
import EditWorkspace from "./EditWorkspace.vue";
import { WorkspaceType } from "../types";
import { IMenuModel } from "@/pages/Common/types";

export default defineComponent({
  name: "workspace",
  components: { Board, Recent, EditWorkspace },
  setup() {
    const menu = ref("menu");
    const wsMenu = ref("wsMenu");
    return { menu, wsMenu };
  },
  props: {
    workspaceId: {
      type: String,
      required: true,
    },
  },
  data(): {
    items: IMenuModel[];
    wsItems: IMenuModel[];
    wsMode: string;
    displayEditWorkspace: boolean;
  } {
    return {
      items: [
        {
          label: this.$t("menu.datasource"),
          command: () =>
            this.$router.push(
              `/workspace/${this.workspaceId}/datasource/create`
            ),
        },
        {
          label: "AutoTS",
          command: () =>
            this.$router.push(
              `/workspace/${this.workspaceId}/experiment/autots/create`
            ),
        },
        {
          label: "AutoML",
          command: () =>
            this.$router.push(
              `/workspace/${this.workspaceId}/experiment/automl/create`
            ),
        },
      ],
      wsItems: [
        {
          label: this.$t("workspace.menu.edit"),
          command: () => this.editName(),
        },
        {
          label: this.$t("workspace.menu.members"),
          command: () => this.editMembers(),
        },
        {
          label: this.$t("workspace.menu.delete"),
          command: () => this.confirmDeleteWs(),
        },
      ],
      wsMode: "view",
      displayEditWorkspace: false,
    };
  },
  created() {
    this.loadWorkspaceById(this.workspaceId);
  },
  watch: {
    workspaceId(newValue) {
      this.loadWorkspaceById(newValue);
    },
  },
  methods: {
    ...mapActions("workspace", [
      "delete",
      "loadWorkspaceById",
      "loadPrivateWorkspace",
    ]),
    toggle(event: any) {
      (this.menu as any).toggle(event);
    },
    toggleWs(event: any) {
      (this.wsMenu as any).toggle(event);
    },
    onClickMembers() {
      this.wsMode = "view";
      this.displayEditWorkspace = true;
    },
    editMembers() {
      this.wsMode = "assign";
      this.displayEditWorkspace = true;
    },
    editName() {
      this.wsMode = "update";
      this.displayEditWorkspace = true;
    },
    offDialog(updated: boolean) {
      this.displayEditWorkspace = false;
      if (updated) {
        this.loadWorkspaceById(this.workspaceDetail.id);
      }
    },
    confirmDeleteWs() {
      if (this.workspaceDetail?.type === WorkspaceType.Shared) {
        this.$confirm.require({
          message: this.$t("workspace.delete.confirm.message", {
            workspace: this.workspaceDetail.name,
          }),
          header: this.$t("workspace.menu.delete"),
          icon: "pi pi-exclamation-triangle",
          acceptClass: "p-button-danger",
          accept: async () => {
            try {
              await this.delete(this.workspaceDetail.id);
              this.loadPrivateWorkspace(this.userId);
              this.$toast.add({
                severity: "success",
                summary: this.$t("message.successful"),
                detail: this.$t("workspace.delete.confirm.succeed", {
                  workspace: this.workspaceDetail.name,
                }),
                life: 3000,
              });
              this.$router.push("/workspaces");
            } catch (error: any) {
              this.$toast.add({
                severity: "error",
                summary: this.$t("message.error"),
                detail: this.$t("workspace.delete.confirm.error", {
                  workspace: this.workspaceDetail.name,
                }),
                life: 3000,
              });
            }
          },
        });
      }
    },
  },
  computed: {
    ...mapState("authentication", ["name", "userId"]),
    ...mapState("workspace", ["workspaceDetail"]),
    ...mapGetters("identities", ["getFullname"]),
    ...mapGetters("workspace", ["isAdmin", "canEdit", "isSharedWorkspace"]),
    wsName(): string {
      if (this.isSharedWorkspace()) {
        return this.workspaceDetail.name;
      } else {
        return this.$t("workspace.title", { name: this.name });
      }
    },
    displayDescription(): boolean {
      return (
        this.isSharedWorkspace() &&
        this.workspaceDetail.desc &&
        this.workspaceDetail.desc !== ""
      );
    },
  },
});
