import EmptyPage from "@/components/EmptyPage.vue";
import DeleteDialog from "@/components/DeleteDialog.vue";
import InputNameDialog from "@/components/InputNameDialog.vue";
import DataType from "@/components/DataType.vue";
import DataRole from "@/components/DataRole.vue";
import PredictionType from "@/components/PredictionType.vue";
import Steps from "@/components/Steps.vue";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue";
import ExperimentStatus from "@/components/ExperimentStatus.vue";
import DatasourceStatus from "@/components/DatasourceStatus.vue";
import CardItem from "@/components/CardItem.vue";
import ExperimentTag from "@/components/ExperimentTag.vue";
import InlineText from "@/components/InlineText.vue";
import { App } from "@vue/runtime-dom";

export const CommonComponentsPlugin = {
  install(app: App): void {
    app.component("EmptyPage", EmptyPage);
    app.component("DeleteDialog", DeleteDialog);
    app.component("InputNameDialog", InputNameDialog);
    app.component("DataType", DataType);
    app.component("DataRole", DataRole);
    app.component("PredictionType", PredictionType);
    app.component("Steps", Steps);
    app.component("Breadcrumb", Breadcrumb);
    app.component("ExperimentStatus", ExperimentStatus);
    app.component("DatasourceStatus", DatasourceStatus);
    app.component("CardItem", CardItem);
    app.component("ExperimentTag", ExperimentTag);
    app.component("InlineText", InlineText);
  },
};
