import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-pt-3" }
const _hoisted_2 = { class: "p-mb-4 p-d-flex p-justify-between" }
const _hoisted_3 = {
  class: "p-d-none p-d-md-block",
  style: {"overflow-x":"auto"}
}
const _hoisted_4 = { class: "p-d-flex p-jc-center p-ai-center" }
const _hoisted_5 = { class: "p-d-flex p-jc-center p-ai-center" }
const _hoisted_6 = { class: "p-d-flex p-jc-end" }
const _hoisted_7 = {
  key: 0,
  class: "p-d-flex p-jc-center p-ai-center"
}
const _hoisted_8 = {
  key: 1,
  class: "p-d-flex p-jc-center p-ai-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Column = _resolveComponent("Column")!
  const _component_ExperimentTag = _resolveComponent("ExperimentTag")!
  const _component_ExperimentStatus = _resolveComponent("ExperimentStatus")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t("billing.title.billing_detail")), 1),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", null, [
        _createVNode(_component_Calendar, {
          class: "p-mr-2",
          "selection-mode": "range",
          modelValue: _ctx.filterRange,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterRange = $event)),
          dateFormat: "yy-mm-dd"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_Dropdown, {
          class: "p-mr-2",
          options: _ctx.users,
          modelValue: _ctx.selectedUser,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectedUser = $event)),
          "show-clear": !!_ctx.selectedUser,
          placeholder: _ctx.$t('billing.filter.select.user')
        }, {
          value: _withCtx(({ value }) => [
            _createTextVNode(_toDisplayString(value?.id ? _ctx.getFullname(value.id) : _ctx.$t("billing.filter.select.user")), 1)
          ]),
          option: _withCtx(({ option: { id } }) => [
            _createTextVNode(_toDisplayString(_ctx.getFullname(id)), 1)
          ]),
          _: 1
        }, 8, ["options", "modelValue", "show-clear", "placeholder"]),
        _createVNode(_component_Dropdown, {
          class: "p-mr-2",
          options: _ctx.tenantWorkspaces,
          modelValue: _ctx.selectedWorkspace,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedWorkspace = $event)),
          "show-clear": !!_ctx.selectedWorkspace,
          placeholder: _ctx.$t('billing.filter.select.workspace')
        }, {
          value: _withCtx(({ value }) => [
            _createTextVNode(_toDisplayString(value?.name || _ctx.$t("billing.filter.select.workspace")), 1)
          ]),
          option: _withCtx(({ option: { name } }) => [
            _createTextVNode(_toDisplayString(name), 1)
          ]),
          _: 1
        }, 8, ["options", "modelValue", "show-clear", "placeholder"])
      ]),
      _createVNode("div", null, [
        _createTextVNode(_toDisplayString(_ctx.jobs) + " ", 1),
        _createVNode(_component_Button, {
          id: "download-btn",
          type: "Button",
          icon: "bx bx-download",
          class: "p-button-outlined p-button-secondary",
          onClick: _ctx.downloadReport
        }, null, 8, ["onClick"])
      ])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_DataTable, {
        responsiveLayout: "scroll",
        value: _ctx.billingDetails,
        loading: _ctx.loading,
        scrollable: true,
        scrollHeight: "flex",
        class: "p-datatable-lg"
      }, {
        empty: _withCtx(() => [
          _createVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("billing.detail_table.no_data")), 1)
        ]),
        loading: _withCtx(() => [
          _createVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("billing.detail_table.loading")), 1)
        ]),
        header: _withCtx(() => [
          _createVNode("div", _hoisted_6, [
            _createVNode(_component_MultiSelect, {
              modelValue: _ctx.selectedColumns,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectedColumns = $event)),
              options: _ctx.columns,
              style: {"width":"10em"}
            }, {
              value: _withCtx(() => [
                _createVNode("div", null, _toDisplayString(_ctx.$t("label.number.of.selected.columns", {
                    selected: _ctx.selectedColumns.length,
                    total: _ctx.columns.length,
                  })), 1)
              ]),
              option: _withCtx((props) => [
                _createTextVNode(_toDisplayString(_ctx.$t(props?.option?.label)), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "options"])
          ])
        ]),
        footer: _withCtx(() => [
          (!_ctx.loading && _ctx.hasMoreBillingDetails)
            ? (_openBlock(), _createBlock("div", _hoisted_7, [
                _createVNode(_component_Button, {
                  label: _ctx.$t('button.more'),
                  class: "p-button-text",
                  onClick: _ctx.loadMore
                }, null, 8, ["label", "onClick"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.loading)
            ? (_openBlock(), _createBlock("div", _hoisted_8, [
                _createVNode(_component_ProgressSpinner, {
                  class: "file-progress-spinner",
                  strokeWidth: "3",
                  animationDuration: ".5s",
                  style: {"width":"24px","height":"24px"}
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.link'),
            "header-class": "link-column",
            "body-class": "link-column",
            hidden: !_ctx.shownColumns.has('link')
          }, {
            body: _withCtx(({ data: { link, isDeleted } }) => [
              isDeleted
                ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [], 64))
                : (_openBlock(), _createBlock(_component_RouterLink, {
                    key: 1,
                    to: link,
                    custom: ""
                  }, {
                    default: _withCtx(({ href, navigate }) => [
                      _createVNode("a", {
                        class: "p-button p-button-text",
                        href: href,
                        onClick: navigate
                      }, _toDisplayString(_ctx.$t("billing.detail_table.body.link")), 9, ["href", "onClick"])
                    ]),
                    _: 2
                  }, 1032, ["to"]))
            ]),
            _: 1
          }, 8, ["header", "hidden"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.id_count'),
            "header-class": "id-count-column",
            "body-class": "id-count-column",
            hidden: !_ctx.shownColumns.has('idCount')
          }, {
            body: _withCtx(({ data: { idCount } }) => [
              _createTextVNode(_toDisplayString(idCount), 1)
            ]),
            _: 1
          }, 8, ["header", "hidden"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.cost'),
            "header-class": "cost-column",
            "body-class": "cost-column",
            hidden: !_ctx.shownColumns.has('cost')
          }, {
            body: _withCtx(({ data: { cost } }) => [
              _createTextVNode(" $" + _toDisplayString(cost), 1)
            ]),
            _: 1
          }, 8, ["header", "hidden"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.exp_name'),
            "header-class": "exp-name-column",
            "body-class": "exp-name-column",
            hidden: !_ctx.shownColumns.has('experimentName')
          }, {
            body: _withCtx(({ data: { experimentName } }) => [
              _createTextVNode(_toDisplayString(experimentName), 1)
            ]),
            _: 1
          }, 8, ["header", "hidden"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.type'),
            "header-class": "type-column",
            "body-class": "type-column",
            hidden: !_ctx.shownColumns.has('billingDetailsType')
          }, {
            body: _withCtx(({ data: { billingDetailsType } }) => [
              _createVNode(_component_ExperimentTag, { name: billingDetailsType }, null, 8, ["name"])
            ]),
            _: 1
          }, 8, ["header", "hidden"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.predict_name'),
            "header-class": "predict-name-column",
            "body-class": "predict-name-column",
            hidden: !_ctx.shownColumns.has('predictName')
          }, {
            body: _withCtx(({ data: { predictName } }) => [
              _createTextVNode(_toDisplayString(predictName), 1)
            ]),
            _: 1
          }, 8, ["header", "hidden"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.created_user_name'),
            "header-class": "user-name-column",
            "body-class": "user-name-column",
            hidden: !_ctx.shownColumns.has('createdBy')
          }, {
            body: _withCtx(({ data: { createdBy } }) => [
              _createTextVNode(_toDisplayString(_ctx.getFullname(createdBy)), 1)
            ]),
            _: 1
          }, 8, ["header", "hidden"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.completed_date'),
            "header-class": "completed-date-column",
            "body-class": "completed-date-column",
            hidden: !_ctx.shownColumns.has('completedDate')
          }, {
            body: _withCtx(({ data: { completedDate } }) => [
              _createTextVNode(_toDisplayString(_ctx.$moment(completedDate).format("L LT")), 1)
            ]),
            _: 1
          }, 8, ["header", "hidden"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.status'),
            "header-class": "status-column",
            "body-class": "status-column",
            hidden: !_ctx.shownColumns.has('status')
          }, {
            body: _withCtx(({ data: { status } }) => [
              _createVNode(_component_ExperimentStatus, { status: status }, null, 8, ["status"])
            ]),
            _: 1
          }, 8, ["header", "hidden"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.deleted'),
            "header-class": "is-deleted-column",
            "body-class": "is-deleted-column",
            hidden: !_ctx.shownColumns.has('isDeleted')
          }, {
            body: _withCtx(({ data: { isDeleted } }) => [
              _createTextVNode(_toDisplayString(isDeleted), 1)
            ]),
            _: 1
          }, 8, ["header", "hidden"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.workspace_name'),
            "header-class": "workspace-name-column",
            "body-class": "workspace-name-column",
            hidden: !_ctx.shownColumns.has('workspaceName')
          }, {
            body: _withCtx(({ data: { workspaceName } }) => [
              _createTextVNode(_toDisplayString(workspaceName), 1)
            ]),
            _: 1
          }, 8, ["header", "hidden"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.workspace_created_username'),
            "header-class": "user-name-column",
            "body-class": "user-name-column",
            hidden: !_ctx.shownColumns.has('workspaceCreatedBy')
          }, {
            body: _withCtx(({ data: { workspaceCreatedBy } }) => [
              _createTextVNode(_toDisplayString(_ctx.getFullname(workspaceCreatedBy)), 1)
            ]),
            _: 1
          }, 8, ["header", "hidden"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.exp_id'),
            "header-class": "id-column",
            "body-class": "id-column",
            hidden: !_ctx.shownColumns.has('experimentId')
          }, {
            body: _withCtx(({ data: { experimentId } }) => [
              _createTextVNode(_toDisplayString(experimentId), 1)
            ]),
            _: 1
          }, 8, ["header", "hidden"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.predict_id'),
            "header-class": "id-column",
            "body-class": "id-column",
            hidden: !_ctx.shownColumns.has('predictId')
          }, {
            body: _withCtx(({ data: { predictId } }) => [
              _createTextVNode(_toDisplayString(predictId), 1)
            ]),
            _: 1
          }, 8, ["header", "hidden"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('billing.detail_table.header.workspace_id'),
            "header-class": "id-column",
            "body-class": "id-column",
            hidden: !_ctx.shownColumns.has('workspaceId')
          }, {
            body: _withCtx(({ data: { workspaceId } }) => [
              _createTextVNode(_toDisplayString(workspaceId), 1)
            ]),
            _: 1
          }, 8, ["header", "hidden"])
        ]),
        _: 1
      }, 8, ["value", "loading"])
    ])
  ], 64))
}