import { App } from "vue";
import { getDuration } from "./getDuration";
import { humanFileSize } from "./humanFileSize";
import { toPercentage, toShortNumber } from "./shortNumber";

export const UtilitiesPlugin = {
  install(app: App): void {
    app.config.globalProperties.$humanFileSize = humanFileSize;
    app.config.globalProperties.$toShortNumber = toShortNumber;
    app.config.globalProperties.$toPercentage = toPercentage;
    app.config.globalProperties.$getDuration = getDuration;
  },
};
