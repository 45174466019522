
import { LineChart } from "echarts/charts";
import {
  AxisPointerComponent,
  GridComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
} from "echarts/components";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { defineComponent, ref } from "vue";
import { mapState } from "vuex";

use([
  CanvasRenderer,
  LineChart,
  TooltipComponent,
  ToolboxComponent,
  AxisPointerComponent,
  GridComponent,
  VisualMapComponent,
]);

export default defineComponent({
  name: "Cucumlative Distribution",
  props: ["data"],
  data(): {
    option: any;
  } {
    return {
      option: undefined,
    };
  },
  async created() {
    // Calculate 80,90, 95 points
    let sum_percentage = 0;
    let _80point = 0,
      _90point = 0,
      _95point = 0;
    const accumulate_percentage = this.data.data.map(
      (value: any, index: any) => {
        sum_percentage += value["percentage"];
        if (sum_percentage >= 80 && !_80point) {
          _80point = index + 1;
        }
        if (sum_percentage >= 90 && !_90point) {
          _90point = index + 1;
        }
        if (sum_percentage >= 95 && !_95point) {
          _95point = index + 1;
        }
        return {
          idcs_count: index + 1,
          percentage: Math.min(sum_percentage, 100).toFixed(2),
        };
      }
    );
    accumulate_percentage.unshift({ idcs_count: 0, percentage: "0" });

    // Create mark line
    const markLineData = [
      {
        xAxis: this.data.data.length,
        label: {
          formatter: `${this.data.data.length} - 100%`,
          position: "middle",
        },
      },
    ];
    if (_95point < this.data.data.length) {
      markLineData.unshift({
        xAxis: _95point,
        label: {
          formatter: `${_95point} - 95%`,
          position: "middle",
        },
      });
    }
    if (_90point < _95point) {
      markLineData.unshift({
        xAxis: _90point,
        label: {
          formatter: `${_90point} - 90%`,
          position: "middle",
        },
      });
    }
    if (_80point < _90point) {
      markLineData.unshift({
        xAxis: _80point,
        label: {
          formatter: `${_80point} - 80%`,
          position: "middle",
        },
      });
    }

    const option = ref({
      dataset: [
        {
          id: "accumulate_percentage",
          source: accumulate_percentage,
        },
      ],
      grid: {
        top: "10%",
        left: "5%",
        right: "5%",
        bottom: "10%",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          let result = `<strong>No. of ${this.data.xName}</strong> : `;
          result += params[0].data["idcs_count"] + "<br>";
          result += `<strong>${this.data.yName} (%)</strong> : `;
          result += params[0].data["percentage"];
          return result;
        },
        axisPointer: {
          label: {
            precision: 0,
          },
        },
      },
      xAxis: {
        name: `No. of ${this.data.xName}`,
        nameLocation: "center",
        nameGap: 25,
        min: 0,
        max: this.data.data.length,
        nameTextStyle: {
          fontWeight: "bold",
        },
      },
      yAxis: {
        name: `${this.data.yName} (%)`,
        min: 0,
        max: 100,
        nameTextStyle: {
          fontWeight: "bold",
          align: "left",
        },
      },
      series: [
        {
          type: "line",
          smooth: true,
          name: `No. of ${this.data.xName}`,
          datasetId: "accumulate_percentage",
          showSymbol: false,
          encode: {
            x: "idcs_count",
            y: "percentage",
          },
          markLine: {
            symbol: ["none", "none"],
            data: markLineData,
          },
          areaStyle: {},
        },
      ],
      visualMap: {
        type: "piecewise",
        show: false,
        dimension: 0,
        seriesIndex: 0,
        pieces: [
          {
            gt: 0,
            lt: _80point,
          },
          {
            gt: _80point,
            lt: _90point,
          },
          {
            gt: _90point,
            lt: _95point,
          },
          {
            gt: _95point,
          },
        ],
      },
    });
    this.option = option;
  },
  computed: {
    ...mapState("application", ["theme"]),
  },
  methods: {
    isLoading() {
      return this.data;
    },
  },
});
