import Encoding from "encoding-japanese";
export function detect(contents: unknown): string {
  return Encoding.detect(contents);
}

export function detectEncoding(contents: unknown): string {
  return convertToStandardEncoding(detect(contents));
}

const ENCODING_SYNAPSE_MAPPING: {
  [key: string]: string;
} = {
  ASCII: "UTF-8",
  UTF8: "UTF-8",
  UTF16: "UTF-16",
  UTF32: "UTF-32",
  EUCJP: "EUC-JP",
  SJIS: "SHIFT-JIS",
  JIS: "ISO-2022-JP",
  UNICODE: "UTF-16",
};

function convertToStandardEncoding(originalName: string) {
  if (ENCODING_SYNAPSE_MAPPING[originalName]) {
    return ENCODING_SYNAPSE_MAPPING[originalName];
  }
  return originalName;
}
