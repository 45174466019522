
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import { defineComponent, PropType } from "vue";
import { mapGetters, mapState } from "vuex";

import { ChartAxisOption, IExperiment, IKpi, KpiKey } from "../../../types";
import moment from "moment";
import { getChartDisplayOption } from "@/pages/Experiment/common/shared";

const GAP_BACKGROUND_COLOR = "#616161";
const TEST_BACKGROUND_COLOR = "#2196F3";
const FORECAST_BACKGROUND_COLOR = "#f00";

use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
  DatasetComponent,
]);

export default defineComponent({
  props: {
    id: {
      type: String,
    },
    kpi: {
      type: Array as PropType<IKpi[]>,
    },
    experiment: {
      type: Object as PropType<IExperiment>,
    },
    currentType: {
      type: String,
    },
    selected: {
      type: Object,
    },
    zoom: {
      type: Object,
    },
    selectedChartAxisOption: {
      type: Object as PropType<ChartAxisOption>,
    },
    customChartRange: {
      type: Object as PropType<{ min?: number | null; max?: number | null }>,
    },
    showQuantile: {
      type: Object as PropType<{ upper: boolean; under: boolean }>,
    },
    highlightQuantile: {
      type: Object as PropType<{ upper?: number; under?: number }>,
    },
  },
  data(): {
    initOptions: { locale: string };
  } {
    return {
      initOptions: {
        locale: this.$i18n.locale,
      },
    };
  },
  emits: ["update:currentType", "update:selected", "update:zoom"],
  methods: {
    magicTypeChanged(event: any) {
      this.$emit("update:currentType", event.currentType);
    },
    legendSelectChanged(event: any) {
      this.$emit("update:selected", event.selected);
    },
    dataZoom(event: any) {
      if (event.batch) {
        this.$emit("update:zoom", event.batch[0]);
      } else {
        this.$emit("update:zoom", event);
      }
    },
    addByFrequency(date: Date, amount: number) {
      if (this.frequency === "W") {
        return moment(date).add(amount, "weeks").toDate();
      } else if (this.frequency === "M") {
        return moment(date).add(amount, "month").toDate();
      }
      return moment(date).add(amount, "days").toDate();
    },
  },
  computed: {
    ...mapGetters("datasources", ["getSuccessFiles"]),
    ...mapState("application", ["theme"]),
    ...mapState("experiments", [
      "algorithms",
      "actualData",
      "forecastData",
      "targetColumnMinMax",
    ]),
    frequency(): string {
      return this.experiment?.config?.frequency || "D";
    },
    chartOption(): any {
      const adjust = 1;
      const startDate = moment
        .utc(this.experiment?.config?.startDate)
        .local(true)
        .toDate();
      let rawEndDate = moment.utc(this.experiment?.config?.endDate).local(true);
      if (this.frequency === "W") {
        rawEndDate = rawEndDate.add(-1, "days").startOf("week").add(1, "days");
      } else if (this.frequency === "M") {
        rawEndDate = rawEndDate.startOf("month");
      }
      const endDate = rawEndDate.toDate();
      const testStartDate = this.addByFrequency(
        endDate,
        -this.experiment?.config?.forecastHorizon + adjust
      );
      const forecastStartDate = this.addByFrequency(
        endDate,
        this.experiment?.config?.gap + adjust
      );
      const forecastEndDate = this.forecastData
        ? moment(
            this.forecastData.map((x: any) => x.predictDate)[
              this.forecastData.length - 1
            ]
          ).toDate()
        : undefined;
      let dataZoom: {
        type: string;
        start?: number;
        end?: number;
        startValue?: number;
        endValue?: number;
      } = {
        type: "slider",
      };
      if (this.zoom) {
        if (this.zoom.start) {
          dataZoom.start = this.zoom.start;
        }
        if (this.zoom.end) {
          dataZoom.end = this.zoom.end;
        }
        if (this.zoom.startValue) {
          dataZoom.startValue = this.zoom.startValue;
        }
        if (this.zoom.endValue) {
          dataZoom.endValue = this.zoom.endValue;
        }
      } else {
        dataZoom.start = 0;
        dataZoom.end = 100;
      }

      // chart configs: min max for vertical axis, quantile highlight areas
      const chartOptions = getChartDisplayOption(
        this.actualData,
        this.forecastData,
        this.selectedChartAxisOption || ChartAxisOption.CurrentSeriesRange,
        {
          upper: {
            show: !!this.showQuantile?.upper,
            value: this.highlightQuantile?.upper,
          },
          under: {
            show: !!this.showQuantile?.under,
            value: this.highlightQuantile?.under,
          },
        },
        this.targetColumnMinMax,
        this.customChartRange
      );
      const option: any = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            label: {
              precision: 0,
              formatter: (param: any) => {
                const startDate =
                  param.seriesData[0]?.data?.date ||
                  param.seriesData[0]?.data?.predictDate;
                const endDate = param.seriesData[0]?.data?.endDate;
                const label = param.seriesData[0]?.data?.label;
                if (this.frequency === "W") {
                  return `${startDate} ~ ${endDate} (${this.$t("week.number", {
                    week: label,
                  })})`;
                } else if (this.frequency === "M") {
                  return label;
                } else {
                  return `${moment(startDate).format("YYYY-MM-DD")} (${this.$t(
                    `day.of.week.${label}`
                  )})`;
                }
              },
            },
          },
        },
        dataset: [
          {
            source: this.actualData.map((e: any) => {
              return {
                ...e,
                value: e.value?.toFixed(2),
              };
            }),
          },
          {
            source: this.forecastData.map((e: any) => {
              return {
                ...e,
                forecast: e.forecast?.toFixed(2),
              };
            }),
          },
        ],
        grid: {
          left: "5%",
          right: "5%",
          top: 40,
          containLabel: true,
        },
        legend: {
          data: [this.$t("label.actual")],
          top: "auto",
          bottom: "auto",
          selected: { actual: true },
        },
        xAxis: { type: "time" },
        yAxis: [
          {
            type: "value",
            ...chartOptions.yAxis,
            min: chartOptions.yAxis.min,
            max: chartOptions.yAxis.max,
            axisLabel: {
              formatter: (value: number) => {
                if (value % 1 === 0) {
                  return value;
                } else {
                  return value.toFixed(2);
                }
              },
            },
          },
          {
            type: "value",
            ...chartOptions.yAxis,
            min: chartOptions.yAxis.min,
            max: chartOptions.yAxis.max,
            axisLabel: {
              formatter: (value: number) => {
                if (value % 1 === 0) {
                  return value;
                } else {
                  return value.toFixed(2);
                }
              },
            },
          },
        ],
        series: [
          {
            name: this.$t("label.actual"),
            type: this.currentType,
            encode: { x: "date", y: "value" },
            datasetIndex: 0,
            universalTransition: true,
            animationDurationUpdate: 1000,
            markArea: {
              data: [
                ...chartOptions.quantileMarkers,
                [
                  {
                    itemStyle: {
                      color: "#fff",
                      opacity: 0.1,
                    },
                    label: {
                      fontWeight: "bold",
                      fontSize: 14,
                    },
                    name: this.$t("label.train"),
                    xAxis: startDate,
                  },
                  {
                    xAxis: this.addByFrequency(
                      endDate,
                      -this.experiment?.config?.forecastHorizon -
                        this.experiment?.config?.gap +
                        1
                    ),
                  },
                ],
                [
                  {
                    itemStyle: {
                      color: TEST_BACKGROUND_COLOR,
                      opacity: 0.05,
                    },
                    label: {
                      fontWeight: "bold",
                      fontSize: 14,
                    },
                    name: this.$t("label.test"),
                    xAxis: testStartDate,
                  },
                  {
                    xAxis: endDate,
                  },
                ],
                [
                  {
                    itemStyle: {
                      color: FORECAST_BACKGROUND_COLOR,
                      opacity: 0.05,
                    },
                    label: {
                      fontWeight: "bold",
                      fontSize: 14,
                    },
                    name: this.$t("label.forecast"),
                    xAxis: forecastStartDate,
                  },
                  {
                    xAxis: forecastEndDate,
                  },
                ],
              ],
            },
          },
        ],
        dataZoom: [
          dataZoom,
          {
            type: "inside",
          },
        ],
        toolbox: {
          show: true,
          orient: "vertical",
          right: "5px",
          feature: {
            magicType: {
              type: ["line", "bar"],
            },
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
            saveAsImage: { name: this.experiment?.id },
          },
        },
      };
      if (this.forecastData && this.forecastData.length) {
        const primaryKpi = this.experiment?.config?.primaryMetric;
        const primaryKpiValue = this.kpi
          ?.filter((x) => x.kpiName === KpiKey[primaryKpi])
          .sort((a, b) => a.value - b.value);
        const completedAlgo = primaryKpiValue?.map((p) => p.algorithmId) || [];
        const algorithms = this.algorithms.filter((a: string) =>
          completedAlgo.find((ca) => ca === a)
        );

        for (const algorithm of algorithms) {
          option.dataset.push({
            fromDatasetIndex: 1,
            transform: {
              type: "filter",
              config: { dimension: "algorithmId", value: algorithm },
            },
          });
          option.series.push({
            name: this.$t(`${algorithm}`),
            type: this.currentType,
            encode: { x: "predictDate", y: "forecast" },
            datasetIndex: option.dataset.length - 1,
            universalTransition: true,
            animationDurationUpdate: 1000,
          });
          option.legend.data.push(this.$t(`${algorithm}`));
          option.legend.selected[this.$t(`${algorithm}`)] = false;
        }

        if (this.selected) {
          option.legend.selected = this.selected;
        } else if (primaryKpiValue && primaryKpiValue[0]) {
          option.legend.selected[this.$t(`${primaryKpiValue[0].algorithmId}`)] =
            true;
        }
      }
      if (this.experiment?.config?.gap) {
        const gapStartDate = this.addByFrequency(
          testStartDate,
          -this.experiment?.config?.gap
        );
        const gapEndDate = this.addByFrequency(testStartDate, -adjust);
        option.series[0].markArea.data.push([
          {
            itemStyle: {
              color: GAP_BACKGROUND_COLOR,
              opacity: 0.3,
            },
            label: {
              fontWeight: "bold",
              fontSize: 14,
            },
            name: this.$t("label.gap"),
            xAxis: gapStartDate,
          },
          {
            xAxis: gapEndDate,
          },
        ]);
      }
      return option;
    },
  },
});
