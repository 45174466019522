
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
} from "echarts/components";
import { defineComponent, PropType } from "vue";
import { mapState } from "vuex";
import { GridComponent } from "echarts/components";
import { SettingObject } from "@/types";

use([
  CanvasRenderer,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  PieChart,
  DatasetComponent,
]);

export default defineComponent({
  props: {
    train: {
      type: Number,
      required: true,
    },
    gap: {
      type: Number,
      required: true,
    },
    test: {
      type: Number,
      required: true,
    },
    frequency: {
      type: Object as PropType<SettingObject>,
      required: true,
    },
  },
  emits: ["onClick"],
  computed: {
    ...mapState("application", ["theme"]),
    option(): any {
      return {
        tooltip: {
          trigger: "axis",
        },
        legend: {},
        grid: {
          left: "7%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value} " + this.$t(this.frequency.label),
              hideOverlap: true,
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            data: ["Backtest 1"],
            position: "right",
          },
        ],
        series: [
          {
            name: "Train",
            type: "bar",
            stack: "Backtest",
            emphasis: {
              focus: "series",
            },
            data: [this.train],
          },
          {
            name: "Gap",
            type: "bar",
            stack: "Backtest",
            emphasis: {
              focus: "series",
            },
            data: [this.gap],
          },
          {
            name: "Test",
            type: "bar",
            stack: "Backtest",
            emphasis: {
              focus: "series",
            },
            data: [this.test],
          },
        ],
        color: ["#2196F3", "#FA6557", "#F7C137", "#E9ECEF"],
      };
    },
  },
});
