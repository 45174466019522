
import { useStore } from "@/store";
import { DatasourceSchema } from "@/types";
import { defineComponent } from "@vue/runtime-core";
import { mapGetters, mapState } from "vuex";
export default defineComponent({
  data(): {
    headers: string[];
    sampleData: Array<any>;
    loading: boolean;
    selectedColumns: string[];
  } {
    return {
      headers: [],
      sampleData: [],
      loading: true,
      selectedColumns: [],
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    schemas: {
      type: Array,
    },
    workspaceId: {
      type: String,
    },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    ...mapState("authentication", ["token"]),
    ...mapGetters("datasources", ["getSampleDataDatasource"]),
  },
  async created() {
    this.sampleData = await this.getSampleDataDatasource(
      this.id,
      this.workspaceId
    );
    if (this.sampleData.length > 0) {
      const row = this.sampleData[0] as Record<string, unknown>;
      this.headers = Object.keys(row) as Array<string>;
      this.selectedColumns = this.headers.slice(0, 20);
      this.loading = false;
    }
  },
  methods: {
    getDataType(columnName: string) {
      if (this.schemas) {
        for (const column of this.schemas as Array<DatasourceSchema>) {
          if (column.name === columnName) {
            return column.type;
          }
        }
      }
    },
  },
});
