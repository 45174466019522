import { AuthState, RoleInTenant } from "./types";
import { Module } from "vuex";
import { State } from "@/store";

export const authenticationModule: Module<AuthState, State> = {
  namespaced: true,
  state: {
    token: "",
    userId: "",
    username: "",
    firstName: "",
    lastName: "",
    name: "",
    email: "",
    didAutoLogout: false,
    keycloak: undefined,
    tenants: [],
    activeTenant: "",
    roleInTenant: new Map<string, RoleInTenant>(),
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload?.token;
      state.userId = payload?.idTokenParsed?.sub as string;
      state.username = payload?.idTokenParsed?.preferred_username as string;
      state.firstName = payload?.idTokenParsed?.given_name as string;
      state.lastName = payload?.idTokenParsed?.family_name as string;
      state.email = payload?.idTokenParsed?.email as string;
      state.picture = payload?.idTokenParsed?.picture as string;
      state.keycloak = payload;
      state.tenants = payload?.idTokenParsed?.tenant as string[];
      if (!localStorage.language && payload?.idTokenParsed?.locale) {
        localStorage.setItem("language", payload?.idTokenParsed?.locale);
      }
      if (localStorage.language && localStorage.language === "ja") {
        state.name = [state.lastName, state.firstName].join(" ");
      } else {
        state.name = [state.firstName, state.lastName].join(" ");
      }
      const roles = payload?.tokenParsed?.roles;
      state.roleInTenant = new Map<string, RoleInTenant>();
      if (roles && roles.length) {
        roles.forEach((r: string) => {
          const [tenant, role] = r.split("-");
          state.roleInTenant.set(tenant, role as RoleInTenant);
        });
      }
    },
    setActiveTenant(state, payload: string) {
      state.activeTenant = payload;
    },
    setFullname(state, name) {
      state.name = name;
    },
  },
  actions: {
    logout(store) {
      localStorage.removeItem("language");
      store.state.keycloak
        ?.logout({ redirectUri: window.location.origin })
        .then(() => store.state.keycloak?.clearToken());
    },
    changeTenant({ commit }, tenant: string) {
      commit("setActiveTenant", tenant);
      localStorage.setItem("activeTenant", tenant);
    },
    changeFullname({ state, commit }) {
      let name = "";
      if (localStorage.language && localStorage.language === "ja") {
        name = [state.lastName, state.firstName].join(" ");
      } else {
        name = [state.firstName, state.lastName].join(" ");
      }
      commit("setFullname", name);
    },
  },
  getters: {
    fullname(state: AuthState) {
      return state.name;
    },
    isCurrentTenantAdmin(state: AuthState) {
      return state.roleInTenant.get(state.activeTenant) === RoleInTenant.Admin;
    },
  },
};
