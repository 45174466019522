import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-col-12 datasource" }
const _hoisted_2 = { class: "p-fluid" }
const _hoisted_3 = { class: "p-field" }
const _hoisted_4 = { style: {"font-weight":"bold"} }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "p-d-flex p-jc-between" }
const _hoisted_8 = { class: "p-pb-1" }
const _hoisted_9 = { class: "p-d-flex p-ai-center" }
const _hoisted_10 = { class: "p-pl-2" }
const _hoisted_11 = {
  key: 2,
  class: "datasource"
}
const _hoisted_12 = { class: "p-fileupload-content" }
const _hoisted_13 = { class: "p-text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataType = _resolveComponent("DataType")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Toolbar = _resolveComponent("Toolbar")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _withDirectives(_createVNode("span", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.getFirstDatsource?.name), 1)
        ], 512), [
          [
            _directive_tooltip,
            _ctx.getFirstDatsource?.name,
            void 0,
            { top: true }
          ]
        ])
      ])
    ]),
    _createVNode("div", _hoisted_5, [
      (_ctx.listRowData.length > 0)
        ? (_openBlock(), _createBlock("div", _hoisted_6, [
            _createVNode("div", _hoisted_7, [
              _createVNode("div", _hoisted_8, _toDisplayString(_ctx.listHeader.length) + " " + _toDisplayString(_ctx.$t("upload.datasource.colums")) + " _ " + _toDisplayString(_ctx.listRowData.length) + " / " + _toDisplayString(_ctx.totalRow) + " " + _toDisplayString(_ctx.$t("upload.datasource.row")), 1)
            ]),
            _createVNode(_component_DataTable, {
              scrollable: true,
              scrollHeight: "400px",
              value: _ctx.listRowData,
              showGridlines: "",
              class: "p-datatable-sm"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.listHeader, (col, key) => {
                  return (_openBlock(), _createBlock(_component_Column, {
                    key: key,
                    field: col.field,
                    style: {"min-width":"200px"}
                  }, {
                    header: _withCtx(() => [
                      _createVNode("div", _hoisted_9, [
                        _createVNode(_component_DataType, {
                          dataType: col.code.type
                        }, null, 8, ["dataType"]),
                        _createVNode("div", _hoisted_10, _toDisplayString(col.header), 1)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["field"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"]),
            (_ctx.workbook)
              ? (_openBlock(), _createBlock(_component_TabMenu, {
                  key: 0,
                  model: _ctx.worksheetNames,
                  onTabChange: _ctx.onTabChange
                }, null, 8, ["model", "onTabChange"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.listRowData.length === 0 && !_ctx.uploadFile)
        ? (_openBlock(), _createBlock(_component_FileUpload, {
            key: 1,
            accept: ".csv,.xls,.xlsx",
            onSelect: _ctx.onFileSelect,
            maxFileSize: _ctx.maxFileSize,
            multiple: false,
            showUploadButton: true,
            invalidFileSizeMessage: _ctx.$t('message.error.file_size_exceeded')
          }, null, 8, ["onSelect", "maxFileSize", "invalidFileSizeMessage"]))
        : _createCommentVNode("", true),
      (_ctx.uploadFile)
        ? (_openBlock(), _createBlock("div", _hoisted_11, [
            _createVNode("div", _hoisted_12, [
              _createVNode("div", _hoisted_13, [
                _createVNode("h5", null, _toDisplayString(_ctx.$t("upload.datasource.checking")), 1),
                _createVNode(_component_ProgressSpinner, {
                  strokeWidth: "3",
                  animationDuration: ".5s"
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.listRowData.length > 0)
      ? (_openBlock(), _createBlock(_component_Toolbar, {
          key: 0,
          class: "p-mb-4"
        }, {
          start: _withCtx(() => [
            _createVNode(_component_Button, {
              label: _ctx.$t('button.back'),
              icon: "pi pi-arrow-circle-left",
              class: "p-button-outlined p-button-secondary",
              onClick: _ctx.onClear
            }, null, 8, ["label", "onClick"])
          ]),
          end: _withCtx(() => [
            _createVNode(_component_Button, {
              label: _ctx.$t('button.upload'),
              icon: "pi pi-upload",
              class: "p-button-primary",
              disabled: _ctx.disabled,
              onClick: _ctx.onUpload
            }, null, 8, ["label", "disabled", "onClick"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}