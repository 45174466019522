import { IExperimentPostProcessing } from "@/pages/Experiment/types";

export const typeData = [
  { code: "string", name: "String" },
  { code: "integer", name: "Integer" },
  { code: "float", name: "Float" },
  { code: "date", name: "Date" },
  { code: "boolean", name: "Boolean" },
];

export const SUPPORTED_DATE_FORMAT = [
  "YYYY-MM-DD",
  "YYYY/MM/DD",
  "DD-MM-YYYY",
  "DD/MM/YYYY",
  "MM/DD/YYYY",
  "MM-DD-YYYY",
  "YYYY/M/D",
  "YYYY-M-D",
  "M/D/YYYY",
  "M-D-YYYY",
  "M/D/YY",
  "M-D-YY",
  "YYYY-MM-DD H:m",
  "YYYY/MM/DD H:m",
  "DD-MM-YYYY H:m",
  "DD/MM/YYYY H:m",
  "MM/DD/YYYY H:m",
  "MM-DD-YYYY H:m",
  "YYYY/M/D H:m",
  "YYYY-M-D H:m",
  "M/D/YYYY H:m",
  "M-D-YYYY H:m",
  "M/D/YY H:m",
  "M-D-YY H:m",
  "YYYY-MM-DD H:m:s",
  "YYYY/MM/DD H:m:s",
  "DD-MM-YYYY H:m:s",
  "DD/MM/YYYY H:m:s",
  "MM/DD/YYYY H:m:s",
  "MM-DD-YYYY H:m:s",
  "YYYY/M/D H:m:s",
  "YYYY-M-D H:m:s",
  "M/D/YYYY H:m:s",
  "M-D-YYYY H:m:s",
  "M/D/YY H:m:s",
  "M-D-YY H:m:s",
];

export const typeDate = SUPPORTED_DATE_FORMAT.map((x) => ({
  name: x,
  code: x,
}));

export const linebreaks = [
  { name: "CRLF - Windows", code: "windows" },
  { name: "LM - Unix/ Linux", code: "linux" },
  { name: "Custom...", code: "custom" },
];

export const delimiters = [
  { name: "Comma", code: "comma" },
  { name: "Tab", code: "tab" },
  { name: "Pipe", code: "pipe" },
  { name: "Custom...", code: "custom" },
];

export const ROOT_FOLDER = "ROOT";

export const IS_CATEGORICAL_THRESHOLD = 2;

export const AUTOTS_FORECAST_SETTING: {
  [key: string]: {
    minDuration: number;
    maxDuration: number;
    minGap: number;
    maxGap: number;
    level: number;
  };
} = {
  D: {
    minDuration: 1,
    maxDuration: 100,
    minGap: 0,
    maxGap: 31,
    level: 1,
  },
  W: {
    minDuration: 1,
    maxDuration: 53,
    minGap: 0,
    maxGap: 14,
    level: 7,
  },
  M: {
    minDuration: 1,
    maxDuration: 120,
    minGap: 0,
    maxGap: 12,
    level: 30,
  },
};

export const DEFAULT_MODEL_LIST = [
  "DEEPAR_ESTIMATOR",
  "MOVING_AVERAGE_ESTIMATOR",
  "LAST_VALUE_ESTIMATOR",
];

export const DEFAULT_POST_PROCESSING: IExperimentPostProcessing = {
  round: false,
  trimRange: {
    mode: null,
  },
};

export const ID_COLUMN_SUFFIX = [
  "id",
  "cd",
  "no",
  "code",
  "key",
  "jan",
  "sku",
  "番号",
  "区分",
  "コード",
];
