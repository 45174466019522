
// import ClusteringChart from "./eda/ClusteringChart.vue";
// import Decomposition from "./eda/Decomposition.vue";
import Histogram from "./eda/Histogram.vue";
import Distribution from "./eda/Distribution.vue";
// import IntermittentTable from "./eda/IntermittentTable.vue";
import { defineComponent } from "vue-demi";
import {
  ExperimentStatus,
  IntermittentChart,
  HistogramChart,
  DistributionChart,
} from "../../types";
import { useStore } from "@/store";
import { mapActions, mapState, mapGetters } from "vuex";

export default defineComponent({
  components: {
    // ClusteringChart,
    // IntermittentTable,
    // Decomposition,
    Histogram,
    Distribution,
  },
  data(): {
    intermittentData: any[] | undefined;
    nonIntermittentData: any[] | undefined;
    intermittentHeaders: string[] | undefined;
    nonIntermittentHeaders: string[] | undefined;
    loading: boolean;
    activeIndex: number;
    decompositionData: any | undefined;
    id: string | string[];
    intermittentChartData: IntermittentChart[];
    histogramChartData: HistogramChart | undefined;
    distributionChartData: DistributionChart | undefined;
    target: string;
    active: number;
  } {
    return {
      intermittentData: undefined,
      nonIntermittentData: undefined,
      intermittentHeaders: undefined,
      nonIntermittentHeaders: undefined,
      loading: false,
      activeIndex: 0,
      decompositionData: undefined,
      id: this.$route.params.id,
      intermittentChartData: [],
      histogramChartData: undefined,
      distributionChartData: undefined,
      target: "",
      active: 2,
    };
  },
  props: { workspaceId: { type: String } },
  setup() {
    const store = useStore();
    return { store };
  },
  async created() {
    if (this.getTransactionalDatasourceConfig) {
      this.load(this.getTransactionalDatasourceConfig);
    }
  },
  methods: {
    ...mapActions("experiments", [
      "loadIntermittent",
      "loadHistogram",
      "loadDistribution",
      "loadDecomposition",
    ]),
    onClickClusteringChart(event: any) {
      const { dataIndex } = event;
      this.activeIndex = dataIndex;
    },
    getCssClass(index: number) {
      return this.active === index
        ? "p-button-rounded"
        : "p-button-secondary p-button-text";
    },
    isCompleted() {
      return this.experimentDetail?.status === ExperimentStatus.Completed;
    },
    isTraining() {
      return this.experimentDetail?.status === ExperimentStatus.Training;
    },
    isSetting() {
      return this.experimentDetail?.status === ExperimentStatus.Setting;
    },
    async load(config: any) {
      this.target = config.timeSeriesIdentifiers?.join(", ") || "";
      const timeSeriesData = await this.getCandidate(
        config.datasourceId,
        config.timeSeriesColumn,
        this.workspaceId
      );
      const countDays =
        Math.round(
          ((Math.max(...timeSeriesData) - Math.min(...timeSeriesData)) *
            0.000001) /
            (1000 * 60 * 60 * 24)
        ) + 1;
      const arrayDays: [number, number][] = [];
      const numberDay = countDays / 10;
      let start = 0;
      let end = numberDay - 1;

      while (end <= countDays) {
        arrayDays.push([start, end]);
        start = end;
        end = start + numberDay;
      }
      const histogramChartDateRange: string[] = [];
      arrayDays.map((x) => {
        histogramChartDateRange.push(
          Math.ceil(x[0]) + "~" + Math.ceil(x[x.length - 1]) + " Days"
        );
      });

      // this.loadIntermittent(this.id).then((data) => {
      //   if (data) {
      //     if (data["noneIntermittent"].length > 0) {
      //       const row = data["noneIntermittent"][0] as Record<string, unknown>;
      //       this.nonIntermittentHeaders = Object.keys(row) as Array<
      //         keyof typeof row
      //       >;
      //       this.intermittentChartData.push({
      //         value: data["noneIntermittent"].length,
      //         name: "Non-Intermittent",
      //       } as IntermittentChart);
      //     } else {
      //       this.intermittentChartData.push({
      //         value: 0,
      //         name: "Non-Intermittent",
      //       } as IntermittentChart);
      //     }
      //     if (data["intermittent"].length > 0) {
      //       const row = data["intermittent"][0] as Record<string, unknown>;
      //       this.intermittentHeaders = Object.keys(row) as Array<
      //         keyof typeof row
      //       >;
      //       this.intermittentChartData.push({
      //         value: data["intermittent"].length,
      //         name: "Intermittent",
      //       } as IntermittentChart);
      //     } else {
      //       this.intermittentHeaders = this.nonIntermittentHeaders;
      //       this.intermittentChartData.push({
      //         value: 0,
      //         name: "Intermittent",
      //       } as IntermittentChart);
      //     }
      //     if (!this.intermittentHeaders) {
      //       this.intermittentHeaders = this.nonIntermittentHeaders;
      //     }
      //     if (!this.nonIntermittentHeaders) {
      //       this.nonIntermittentHeaders = this.intermittentHeaders;
      //     }
      //     this.intermittentData = data["intermittent"];
      //     this.nonIntermittentData = data["noneIntermittent"];
      //   }
      // });

      this.loadHistogram({ id: this.id, workspaceId: this.workspaceId }).then(
        (data) => {
          let seriesData: any[] = [];
          if (data) {
            for (var i = 0; i < 10; i++) {
              const d = data.find((x: any) => x.datePercentageGroup == i);
              if (d) {
                seriesData.push(d);
              } else {
                seriesData.push({
                  datePercentageGroup: i,
                  idcsCount: 0,
                  idcsPercentage: 0,
                });
              }
            }
            this.histogramChartData = {
              idcsCount: seriesData.map((x: any) => x.idcsCount),
              idcsPercentage: seriesData.map((x: any) => x.idcsPercentage),
              dateRange: histogramChartDateRange,
              name: `No. of ${config.timeSeriesIdentifiers.join(", ")}`,
            };
          }
        }
      );

      this.loadDistribution({
        id: this.id,
        workspaceId: this.workspaceId,
      }).then((data) => {
        if (data) {
          this.distributionChartData = {
            data: data,
            xName: config.timeSeriesIdentifiers.join(", "),
            yName: config.targetColumn,
          };
        }
      });
    },
  },
  computed: {
    ...mapState("experiments", ["experimentDetail"]),
    ...mapGetters("experiments", [
      "getTransactionalDatasourceConfig",
      "getCandidate",
    ]),
  },
  watch: {
    getTransactionalDatasourceConfig(newValue: any) {
      this.load(newValue);
    },
  },
});
