import { renderSlot as _renderSlot, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-col-12" }
const _hoisted_2 = { class: "p-d-flex p-jc-center" }
const _hoisted_3 = { class: "p-d-flex p-jc-center" }
const _hoisted_4 = { class: "p-d-flex p-jc-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Card, null, {
      title: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "title")
        ])
      ]),
      content: _withCtx(() => [
        _createVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "content")
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            icon: "pi pi-plus",
            label: _ctx.$t('button.create'),
            onClick: _ctx.onClickCreate
          }, null, 8, ["label", "onClick"])
        ])
      ]),
      _: 1
    })
  ]))
}