
import { defineComponent } from "@vue/runtime-core";
import { PieChart } from "echarts/charts";
import {
  AxisPointerComponent,
  GridComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PropType } from "vue";
import VChart from "vue-echarts";
import { mapState } from "vuex";
import { useStore } from "@/store";
import { BillingItem, BillingPieData } from "@/pages/Billing/types";
import { sumBy } from "lodash";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  AxisPointerComponent,
  GridComponent,
  LegendComponent,
]);

export default defineComponent({
  name: "BillingPieChart",
  components: {
    VChart,
  },
  props: {
    data: {
      type: Object as PropType<Array<BillingItem>>,
      default: () => new Array<BillingItem>(),
    },
    showCost: {
      type: Boolean,
      default: () => true,
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    ...mapState("application", ["theme"]),
    pieData(): Array<BillingPieData> {
      if (!this.$props.data.length) return [];
      if (this.$props.showCost) {
        return [
          {
            name: this.$t("billing.cost_table.header.automl_cost"),
            value: +this.$props.data.reduce(
              (p: number, c: BillingItem) => p + c.automlCost,
              0
            ),
          },
          {
            name: this.$t("billing.cost_table.header.autots_cost"),
            value: +this.$props.data.reduce(
              (p: number, c: BillingItem) => p + c.autotsCost,
              0
            ),
          },
        ];
      }
      return [
        {
          name: this.$t("billing.cost_table.header.automl"),
          value: +this.$props.data.reduce(
            (p: number, c: BillingItem) => p + c.automl,
            0
          ),
        },
        {
          name: this.$t("billing.cost_table.header.autots"),
          value: +this.$props.data.reduce(
            (p: number, c: BillingItem) => p + c.autots,
            0
          ),
        },
      ];
    },
    option(): any {
      const toLocaleNumber = (n: number, d?: number): string =>
        n.toLocaleString(this.$i18n.locale, {
          maximumFractionDigits: d,
          minimumFractionDigits: d,
        });

      const sumData = sumBy(
        this.pieData,
        ({ value }: { value: number }) => value
      );

      return {
        tooltip: {
          trigger: "item",
          formatter: (params: {
            seriesName: string;
            name: string;
            data: any;
            encode: any;
            color: string;
          }) => `<table><tr>
                      <td style="color: ${params.color}; font-size:2.5rem">
                        &bull;
                      </td>
                      <td>
                        ${params.name}:
                      </td>
                      <td style="text-align: right" class="p-pl-4">
                        <b>
                          ${
                            this.$props.showCost
                              ? "$" + toLocaleNumber(Number(params.data.value))
                              : toLocaleNumber(Number(params.data.value))
                          }
                        </b>
                      </td>
                   </tr></table>`,
        },
        title: {
          show: !!this.pieData,
          text:
            (this.$props.showCost ? "$" : "") +
            toLocaleNumber(
              this.pieData.reduce(
                (p: number, c: BillingPieData) => p + c.value,
                0
              ),
              this.$props.showCost ? 2 : 0
            ),
          top: "center",
          left: "center",
          textStyle: {
            fontSize: 28,
          },
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["60%", "75%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "outside",
              formatter: ({
                name,
                value,
              }: {
                name: string;
                value: number;
              }): string =>
                `${name}
${this.$props.showCost ? "$" : ""}${toLocaleNumber(value, 2)} (${toLocaleNumber(
                  (value / sumData) * 100,
                  2
                )}%)`,
            },
            labelLine: {
              show: true,
            },
            data: this.pieData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
  },
});
