import { RouteRecordRaw } from "vue-router";
import NewDatasource from "./components/create/NewDatasource.vue";
import DatasourceDetail from "./components/detail/DatasourceDetail.vue";
import DatasourceFileUpload from "./components/DatasourceFileUpload.vue";
import DatasourceHome from "./components/DatasourceHome.vue";
import Datasource from "./components/Datasource.vue";
import NewDatasourcePreview from "./components/create/NewDatasourcePreview.vue";
import NewDatasourceUpload from "./components/create/NewDatasourceUpload.vue";
import NewDatasourceFormat from "./components/create/NewDatasourceFormat.vue";

export const DatasourceRoutes: Array<RouteRecordRaw> = [
  {
    path: "/workspace/:workspaceId/datasource",
    name: "Datasource",
    component: Datasource,
    children: [
      {
        path: "",
        component: DatasourceHome,
        props: true,
        meta: {
          breadcrumb: [{ label: "breadcrumb.datasource" }],
          addButtonRedirectUrl: "/workspace/:workspaceId/datasource/create",
        },
      },
      {
        path: ":id",
        component: DatasourceDetail,
        props: true,
        meta: {
          breadcrumb: [
            {
              label: "breadcrumb.datasource",
              to: "/workspace/:workspaceId/datasource",
            },
            {
              label: ":folderName",
              to: `/workspace/:workspaceId/datasource?folderId=:folderId`,
            },
            { label: "breadcrumb.detail" },
          ],
        },
      },
      {
        path: ":id/:tab",
        component: DatasourceDetail,
        props: true,
        meta: {
          breadcrumb: [
            {
              label: "breadcrumb.datasource",
              to: "/workspace/:workspaceId/datasource",
            },
            {
              label: ":folderName",
              to: `/workspace/:workspaceId/datasource?folderId=:folderId`,
            },
            { label: "breadcrumb.detail" },
          ],
        },
      },
      {
        path: ":id/upload",
        component: DatasourceFileUpload,
        props: true,
        meta: {
          breadcrumb: [
            {
              label: "breadcrumb.datasource",
              to: "/workspace/:workspaceId/datasource",
            },
            {
              label: ":folderName",
              to: `/workspace/:workspaceId/datasource?folderId=:folderId`,
            },
            {
              label: "breadcrumb.detail",
              to: "/workspace/:workspaceId/datasource/:id",
            },
            { label: "breadcrumb.upload" },
          ],
        },
      },
      {
        path: "create",
        name: "NewDatasource",
        component: NewDatasource,
        props: true,
        children: [
          {
            path: "",
            component: NewDatasourceUpload,
            props: true,
          },
          {
            path: "preview",
            component: NewDatasourcePreview,
            props: true,
          },
          {
            path: "format",
            component: NewDatasourceFormat,
            props: true,
          },
        ],
        meta: {
          breadcrumb: [
            {
              label: "breadcrumb.datasource",
              to: "/workspace/:workspaceId/datasource",
            },
            { label: "breadcrumb.create" },
          ],
        },
      },
    ],
    props: true,
    meta: {
      breadcrumb: [{ label: "breadcrumb.datasource" }],
    },
  },
];
