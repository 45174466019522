import { TaskState } from "./pages/Task/types";
import { createStore, Store, useStore as baseUseStore } from "vuex";
import { AuthState } from "@/types";
import { InjectionKey } from "vue";
import { authenticationModule } from "@/pages/Authentication";
import { datasourceModule } from "./pages/Datasource/store";
import { DatasourceState } from "./pages/Datasource/types";
import { ExperimentState, IJobState } from "./pages/Experiment/types";
import { experimentsModule } from "./pages/Experiment/common/store";
import { taskModule } from "./pages/Task/store";
import { IdentityState } from "./pages/Identity/types";
import { identityModule } from "./pages/Identity/store";
import { ApplicationState } from "./pages/App/type";
import { applicationModule } from "./pages/App/store";
import { jobModule } from "./pages/Experiment/autots/job-store";
import { AutoMLState } from "./pages/Experiment/automl/types";
import { autoMLModule } from "./pages/Experiment/automl/store";
import { workspacesModule } from "./pages/Workspace/store";
import { WorkspaceState } from "./pages/Workspace/types";
import { billingModule } from "./pages/Billing/store";
import { BillingState } from "./pages/Billing/types";

export interface State {
  applicationModule: ApplicationState;
  authentication: AuthState;
  datasources: DatasourceState;
  experiments: ExperimentState;
  autoML: AutoMLState;
  tasks: TaskState;
  identities: IdentityState;
  jobs: IJobState;
  workspace: WorkspaceState;
  billing: BillingState;
}

export const store = createStore<State>({
  modules: {
    application: applicationModule,
    authentication: authenticationModule,
    datasources: datasourceModule,
    experiments: experimentsModule,
    autoML: autoMLModule,
    tasks: taskModule,
    identities: identityModule,
    jobs: jobModule,
    workspace: workspacesModule,
    billing: billingModule,
  },
});

export const key: InjectionKey<Store<State>> = Symbol();

export function useStore(): Store<State> {
  return baseUseStore(key);
}
