
import { defineComponent } from "@vue/runtime-core";
import ListExperiments from "./ListExperiments.vue";
export default defineComponent({
  components: {
    ListExperiments,
  },
  props: {
    folderId: {
      type: String,
    },
  },
  data(): {
    expType: string;
  } {
    return {
      expType: "",
    };
  },
});
