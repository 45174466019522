
import { useStore } from "@/store";
import moment from "moment";
import { defineComponent, ref } from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { required, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { DropdownOption } from "@/pages/Datasource/types";
import { debounce } from "lodash";
import { onBeforeRouteLeave } from "vue-router";
import EditWorkspace from "./EditWorkspace.vue";

export default defineComponent({
  setup() {
    const store = useStore();
    const listWorkspaces: any = ref(null);
    const leaving = ref(false);
    onBeforeRouteLeave(() => {
      leaving.value = true;
    });
    return {
      store,
      listWorkspaces,
      v$: useVuelidate(),
      leaving,
    };
  },
  components: {
    EditWorkspace,
  },
  validations() {
    return {
      newName: {
        required,
        maxLength: maxLength(64),
      },
    };
  },
  data(): {
    timeRanges: DropdownOption<string>[];
    newName?: string;
    refresh: number;
    loadedTimes: number;
    displayCreateWorkspace: boolean;
  } {
    return {
      timeRanges: [
        { id: "all", name: "autots.overview.time.all" },
        { id: "last7", name: "autots.overview.time.day" },
        { id: "week", name: "autots.overview.time.week" },
        { id: "month", name: "autots.overview.time.month" },
        { id: "year", name: "autots.overview.time.year" },
      ],
      newName: "",
      refresh: 0,
      loadedTimes: 0,
      displayCreateWorkspace: false,
    };
  },
  async created() {
    await this.loadUniqueUsersOfWorkspace();
    await this.load();
  },

  computed: {
    ...mapState("workspace", ["workspaces"]),
    ...mapState("identities", ["uniqueUsersOfWorkspace"]),
    ...mapGetters("workspace", ["hasMoreWorkspaces"]),
    ...mapGetters("identities", ["getFullname"]),
    isEmpty(): boolean {
      return !this.workspaces.length && this.loadedTimes == 1;
    },
    dynamicSortOrder: {
      get(): number {
        return this.$route.query.dsc?.toString() === "false" ? 1 : -1;
      },
      set(value: number) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            dsc: (value < 0) + "",
          },
        });
      },
    },
    dynamicSortField: {
      get(): string {
        return this.$route.query.sort?.toString() || "createdDate";
      },
      set(value: string | null | undefined) {
        if (!value) {
          value = "createdDate";
        }
        this.$router.replace({
          query: {
            ...this.$route.query,
            sort: value,
          },
        });
      },
    },
    selectedOwner: {
      get(): string {
        return this.$route.query.owner?.toString() || "";
      },
      set(value: string) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            owner: value,
          },
        });
      },
    },
    selectedStatus: {
      get(): string {
        return this.$route.query.status?.toString() || "";
      },
      set(value: string) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            status: value,
          },
        });
      },
    },
    selectedTimeRange: {
      get(): DropdownOption<string> {
        if (this.$route.query.time) {
          return (
            this.timeRanges.find(
              (t: { id: string }) => t.id === this.$route.query.time
            ) || this.timeRanges[0]
          );
        }
        return this.timeRanges[0];
      },
      set(value: DropdownOption<string>) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            time: value.id,
          },
        });
      },
    },
    selectedDate(): [string, string] | null {
      if (this.selectedTimeRange.id === "last7") {
        return [
          moment().subtract(7, "days").format("YYYY-MM-DD"),
          moment().add(1, "days").format("YYYY-MM-DD"),
        ];
      } else if (this.selectedTimeRange.id === "all") {
        return null;
      } else {
        return [
          moment()
            .startOf(this.selectedTimeRange.id as moment.unitOfTime.StartOf)
            .format("YYYY-MM-DD"),
          moment()
            .endOf(this.selectedTimeRange.id as moment.unitOfTime.StartOf)
            .add(1, "days")
            .format("YYYY-MM-DD"),
        ];
      }
    },
    searchKey: {
      get(): string {
        return this.$route.query.name?.toString() || "";
      },
      set(value: string) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            name: value,
          },
        });
      },
    },
    filterStr(): string {
      const obj: any = {
        name: this.searchKey.trim(),
        createdBy: this.selectedOwner || "",
        createdDateFrom: this.selectedDate ? this.selectedDate[0] : "",
        createdDateTo: this.selectedDate ? this.selectedDate[1] : "",
      };
      let str = "q?";
      for (const property in obj) {
        if (obj[property] != "") str += `${property}=${obj[property]}&`;
      }
      return str;
    },
    queryPayload(): {
      filter: string;
      sort: string;
      dsc: boolean;
    } {
      const str = this.filterStr.trim();
      return {
        filter: str !== "q?" ? str.substring(0, str.length - 1) : "q?name=",
        sort: this.dynamicSortField,
        dsc: this.dynamicSortOrder < 0,
      };
    },
  },
  methods: {
    ...mapActions("workspace", ["loadWorkspaces", "loadMoreWorkspaces"]),
    ...mapActions("identities", ["loadUniqueUsersOfWorkspace"]),
    ...mapMutations("workspace", ["CLEAR_WORKSPACES"]),
    createWorkspace() {
      this.displayCreateWorkspace = true;
    },
    offDialog(created: boolean) {
      this.displayCreateWorkspace = false;
      if (created) {
        this.load();
      }
    },
    load: debounce(function (this: any) {
      this.loadWorkspaces(this.queryPayload);
      this.loadedTimes++;
    }, 300),
    async loadMore() {
      await this.loadMoreWorkspaces(this.queryPayload);
    },
    async onRowSelect(selectedWorkspace: any) {
      this.$router.push(`/workspace/${selectedWorkspace.data.id}`);
    },
    async onSort(event: any) {
      this.dynamicSortField = event.sortField;
      this.dynamicSortOrder = event.sortOrder;
    },
  },
  watch: {
    queryPayload() {
      // when navigating away from experiment list page,
      // filter params will also get updated to empty
      // this guard prevents a bug where data is loaded after page change
      if (!this.leaving) {
        this.load();
      }
    },
  },
  unmounted() {
    this.CLEAR_WORKSPACES();
  },
});
