
import { defineComponent } from "vue";
import BillingList from "@/pages/Billing/components/BillingReport/BillingList.vue";
import BillingChart from "@/pages/Billing/components/BillingReport/BillingChart.vue";
import BillingPieChart from "@/pages/Billing/components/BillingReport/BillingPieChart.vue";
import {
  BillingDateRange,
  BillingDateRangeFilters,
  BillingFilter,
  BillingGranularity,
  BillingGranularityFilters,
  BillingState,
} from "../types";
import { SettingObject } from "@/types";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { useStore } from "@/store";
import { Moment } from "moment";
import { debounce } from "lodash";
import {
  getFilterRangeFromQuery,
  getNewQueryFromFilterRange,
} from "@/pages/Billing/shared";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

export default defineComponent({
  name: "BillingReport",
  components: {
    BillingList,
    BillingChart,
    BillingPieChart,
  },
  data(): {
    rangeFilters: Readonly<Array<SettingObject>>;
    granularityFilter: Readonly<Array<SettingObject>>;
    leaving: boolean;
  } {
    return {
      rangeFilters: BillingDateRangeFilters,
      granularityFilter: BillingGranularityFilters,
      leaving: false,
    };
  },
  beforeMount() {
    this.load();
  },
  beforeRouteLeave() {
    this.leaving = true;
    this.CLEAR_BILLING_INFO();
  },
  setup() {
    const store = useStore();
    return { store };
  },
  watch: {
    filterValue: {
      handler(newValue: BillingFilter, oldValue: BillingFilter) {
        // This guard is needed to prevent unnecessary API load on routeLeave
        if (this.leaving) {
          return;
        }

        // Changing granularity or cost/quantity show will trigger
        // this.$route.query changes, which in turn will trigger
        // filterValue change, even if the actual value of filterValue
        // is not changed. So this guard is needed

        // Also block when the date range is not changed, or not valid
        // (ex: only start date selected)
        if (
          // if range type is changed, and new type is not custom
          (newValue.rangeType !== oldValue.rangeType &&
            newValue.rangeType !== this.rangeFilters[0].key) ||
          // or if range is changed, and new range are valid date
          (newValue.range[0].isValid() &&
            newValue.range[1].isValid() &&
            (newValue.range[0].format("L") !== oldValue.range[0].format("L") ||
              newValue.range[1].format("L") !== oldValue.range[1].format("L")))
        ) {
          this.load();
        }
      },
      deep: true,
    },
    filterRange: {
      handler(newValue: [Date | null, Date | null]) {
        if (!newValue[1]) {
          this.filterRangeType = this.rangeFilters[0];
        }
      },
    },
  },
  methods: {
    ...mapActions("billing", ["loadBillingInfo"]),
    ...mapMutations("billing", ["CLEAR_BILLING_INFO"]),
    load: debounce(function (this: any) {
      const range = this.filterValue.range;
      if (!range || !range[0].isValid() || !range[1].isValid()) {
        return;
      }

      this.loadBillingInfo({
        from: range[0].format("YYYY-MM-DD"),
        to: range[1].format("YYYY-MM-DD"),
      });
    }, 300),
    async downloadReport() {
      const report = new Workbook();
      const from = this.filterValue.range[0].format("YYYY-MM-DD");
      const to = this.filterValue.range[1].format("YYYY-MM-DD");
      const sheet = report.addWorksheet(from + " ~ " + to);

      sheet.columns = [
        {
          header: this.$t(
            `billing.cost_table.header.granularity.${
              this.showDaily ? "daily" : "monthly"
            }`
          ),
          key: "id",
          width: 15,
        },
        {
          header: this.$t(`billing.cost_table.header.automl`),
          key: "automl",
          width: 15,
        },
        {
          header: this.$t(`billing.cost_table.header.automl_cost`),
          key: "automlCost",
          width: 15,
        },
        {
          header: this.$t(`billing.cost_table.header.autots`),
          key: "autots",
          width: 15,
        },
        {
          header: this.$t(`billing.cost_table.header.autots_cost`),
          key: "autotsCost",
          width: 15,
        },
        {
          header: this.$t(`billing.cost_table.header.experiment`),
          key: "experiment",
          width: 15,
        },
        {
          header: this.$t(`billing.cost_table.header.experiment_cost`),
          key: "experimentCost",
          width: 15,
        },
      ];

      sheet.getRow(1).eachCell((cell) => {
        cell.style = { font: { bold: true } };
      });
      sheet.addRows(this.showDaily ? this.billingItems : this.billingMonthly);
      const buffer = await report.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer]),
        this.$t("billing.cost_table.exported_filename", {
          from,
          to,
        })
      );
    },
  },
  computed: {
    ...mapState("billing", ["billingItems", "billingDetails"]),
    ...mapState("billing", {
      loading: (state: unknown) => (state as BillingState).loading.report,
    }),
    ...mapGetters("billing", ["billingMonthly"]),
    showDaily(): boolean {
      return this.filterGranularity === BillingGranularityFilters[0];
    },
    showCost: {
      get(): boolean {
        return this.$route.query.show !== "quantity";
      },
      set(newValue: boolean) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            show: newValue ? "cost" : "quantity",
          },
        });
      },
    },
    filterRangeType: {
      get(): SettingObject {
        const rangeType = Number(this.$route.query.rangeType);
        if (rangeType) {
          return (
            BillingDateRangeFilters.find((f) => f.key === rangeType) ||
            BillingDateRangeFilters[1]
          );
        }

        return BillingDateRangeFilters[1];
      },
      set(newValue: SettingObject) {
        const updatedQuery: any = {
          rangeType: newValue.key,
        };
        const fmt = (date: Moment): string => date.format("YYYY-MM-DD");

        if (newValue.key === BillingDateRange.ThisMonth) {
          const from = this.$moment().startOf("month");
          updatedQuery.from = fmt(from);
          updatedQuery.to = fmt(this.$moment());
          updatedQuery.granularity = BillingGranularity.Day;
        } else if (newValue.key !== BillingDateRange.Custom) {
          const from = this.$moment()
            .add(-Number(newValue.key), "month")
            .startOf("month");
          const to = this.$moment().add(-1, "month").endOf("month");
          updatedQuery.from = fmt(from);
          updatedQuery.to = fmt(to);

          if (Number(newValue.key) >= 3) {
            updatedQuery.granularity = BillingGranularity.Month;
          } else {
            updatedQuery.granularity = BillingGranularity.Day;
          }
        }

        this.$router.replace({
          query: {
            ...this.$route.query,
            ...updatedQuery,
          },
        });
      },
    },
    filterRange: {
      get(): [Date | null, Date | null] {
        return getFilterRangeFromQuery(
          this.$route.query.from,
          this.$route.query.to
        );
      },
      set(newValue: [Date | null, Date | null]) {
        const [from, to] = getNewQueryFromFilterRange(newValue);
        this.$router.replace({
          query: {
            ...this.$route.query,
            from,
            to,
          },
        });
      },
    },
    filterRangeFrom(): string {
      return this.$moment(this.filterRange[0]).format("YYYY-MM-DD");
    },
    filterRangeTo(): string {
      return this.$moment(this.filterRange[1]).format("YYYY-MM-DD");
    },
    filterGranularity: {
      get(): SettingObject {
        if (this.$route.query.granularity === "month") {
          return BillingGranularityFilters[1];
        }

        return BillingGranularityFilters[0];
      },
      set(newValue: SettingObject) {
        const granularity =
          newValue.key === BillingGranularity.Month ? "month" : "day";

        this.$router.replace({
          query: {
            ...this.$route.query,
            granularity,
          },
        });
      },
    },
    filterValue(): BillingFilter {
      return {
        rangeType: this.filterRangeType.key as BillingDateRange,
        range: this.filterRange?.map((d) => this.$moment(d)),
      } as BillingFilter;
    },
  },
});
