
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
} from "echarts/components";
import { defineComponent, PropType } from "vue";
import { mapGetters, mapState } from "vuex";
import { GridComponent } from "echarts/components";

import { ChartAxisOption, IExperiment } from "../../../types";
import moment from "moment";
import { IAutoMLModel } from "@/types";
import { SmallestBestKpi } from "@/pages/Experiment/types";
import { getChartDisplayOption } from "@/pages/Experiment/common/shared";

const TEST_BACKGROUND_COLOR = "#2196F3";
const GAP_BACKGROUND_COLOR = "#616161";

use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  BarChart,
  DatasetComponent,
]);

export default defineComponent({
  props: {
    id: {
      type: String,
    },
    models: {
      type: Array as PropType<IAutoMLModel[]>,
      required: true,
    },
    experiment: {
      type: Object as PropType<IExperiment>,
    },
    currentType: {
      type: String,
    },
    selected: {
      type: Object,
    },
    zoom: {
      type: Object,
    },
    selectedChartAxisOption: {
      type: Object as PropType<ChartAxisOption>,
    },
    customChartRange: {
      type: Object as PropType<{ min?: number | null; max?: number | null }>,
    },
    showQuantile: {
      type: Object as PropType<{ upper: boolean; under: boolean }>,
    },
    highlightQuantile: {
      type: Object as PropType<{ upper?: number; under?: number }>,
    },
  },
  data() {
    return {
      initOptions: {
        locale: this.$i18n.locale,
      },
    };
  },
  emits: ["update:currentType", "update:selected", "update:zoom"],
  computed: {
    ...mapGetters("datasources", ["getSuccessFiles"]),
    ...mapState("application", ["theme"]),
    ...mapState("experiments", [
      "actualData",
      "forecastData",
      "targetColumnMinMax",
    ]),
    chartOption(): any {
      const trainStartDate = moment
        .utc(this.experiment?.config?.splitTrainStartDate)
        .local(true)
        .toDate();
      const trainEndDate = moment
        .utc(this.experiment?.config?.splitTrainEndDate)
        .local(true)
        .toDate();
      const testStartDate = moment
        .utc(this.experiment?.config?.splitTestStartDate)
        .local(true)
        .toDate();
      const testEndDate = moment
        .utc(this.experiment?.config?.splitTestEndDate)
        .local(true)
        .toDate();
      let dataZoom: {
        type: string;
        start?: number;
        end?: number;
        startValue?: number;
        endValue?: number;
      } = {
        type: "slider",
      };
      if (this.zoom) {
        if (this.zoom.start) {
          dataZoom.start = this.zoom.start;
        }
        if (this.zoom.end) {
          dataZoom.end = this.zoom.end;
        }
        if (this.zoom.startValue) {
          dataZoom.startValue = this.zoom.startValue;
        }
        if (this.zoom.endValue) {
          dataZoom.endValue = this.zoom.endValue;
        }
      } else {
        dataZoom.start = 0;
        dataZoom.end = 100;
      }

      // chart configs: min max for vertical axis, quantile highlight areas
      const chartOptions = getChartDisplayOption(
        this.actualData,
        this.forecastData,
        this.selectedChartAxisOption || ChartAxisOption.CurrentSeriesRange,
        {
          upper: {
            show: !!this.showQuantile?.upper,
            value: this.highlightQuantile?.upper,
          },
          under: {
            show: !!this.showQuantile?.under,
            value: this.highlightQuantile?.under,
          },
        },
        this.targetColumnMinMax,
        this.customChartRange
      );
      const option: any = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            label: {
              precision: 0,
            },
          },
        },
        dataset: [
          {
            source: this.actualData.map((e: any) => {
              return {
                ...e,
                value: e.value?.toFixed(2),
              };
            }),
          },
          {
            source: this.forecastData.map((e: any) => {
              return {
                ...e,
                forecast: e.forecast?.toFixed(2),
              };
            }),
          },
        ],
        grid: {
          left: "5%",
          right: "5%",
          containLabel: true,
        },
        legend: {
          data: [this.$t("label.actual")],
          top: "auto",
          bottom: "auto",
          selected: { actual: true },
          padding: [5, 5, 20, 5],
        },
        xAxis: { type: "time" },
        yAxis: [
          {
            type: "value",
            ...chartOptions.yAxis,
            min: chartOptions.yAxis.min,
            max: chartOptions.yAxis.max,
            axisLabel: {
              formatter: (value: number) => {
                if (value % 1 === 0) {
                  return value;
                } else {
                  return value.toFixed(2);
                }
              },
            },
          },
          {
            type: "value",
            ...chartOptions.yAxis,
            min: chartOptions.yAxis.min,
            max: chartOptions.yAxis.max,
            axisLabel: {
              formatter: (value: number) => {
                if (value % 1 === 0) {
                  return value;
                } else {
                  return value.toFixed(2);
                }
              },
            },
          },
        ],
        series: [
          {
            name: this.$t("label.actual"),
            type: this.currentType,
            encode: { x: "date", y: "value" },
            datasetIndex: 0,
            universalTransition: true,
            animationDurationUpdate: 1000,
            markArea: {
              data: [
                ...chartOptions.quantileMarkers,
                [
                  {
                    itemStyle: {
                      color: "#fff",
                      opacity: 0.1,
                    },
                    label: {
                      fontWeight: "bold",
                      fontSize: 14,
                    },
                    name: this.$t("label.train"),
                    xAxis: trainStartDate,
                  },
                  {
                    xAxis: trainEndDate,
                  },
                ],
                [
                  {
                    itemStyle: {
                      color: TEST_BACKGROUND_COLOR,
                      opacity: 0.05,
                    },
                    label: {
                      fontWeight: "bold",
                      fontSize: 14,
                    },
                    name: this.$t("label.test"),
                    xAxis: testStartDate,
                  },
                  {
                    xAxis: testEndDate,
                  },
                ],
              ],
            },
          },
        ],
        dataZoom: [
          dataZoom,
          {
            type: "inside",
          },
        ],
        toolbox: {
          show: true,
          orient: "vertical",
          right: "5px",
          feature: {
            magicType: {
              type: ["line", "bar"],
            },
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
            saveAsImage: { name: this.experiment?.id },
          },
        },
      };
      if (this.forecastData && this.forecastData.length && this.models.length) {
        for (const model of this.models) {
          option.dataset.push({
            fromDatasetIndex: 1,
            transform: {
              type: "filter",
              config: { dimension: "algorithmId", value: model.name },
            },
          });
          option.series.push({
            name: model.name,
            type: this.currentType,
            encode: { x: "predictDate", y: "forecast" },
            datasetIndex: option.dataset.length - 1,
            universalTransition: true,
            animationDurationUpdate: 1000,
          });
          option.legend.data.push(model.name);
          option.legend.selected[model.name] = false;
        }
        const primaryMetric = this.experiment?.config?.primaryMetric;
        if (SmallestBestKpi[primaryMetric]) {
          const better = this.models.reduce(function (prev, curr) {
            return prev.testScore &&
              curr.testScore &&
              prev.testScore[primaryMetric] < curr.testScore[primaryMetric]
              ? prev
              : curr;
          });
          option.legend.selected[better.name] = true;
        } else {
          const better = this.models.reduce(function (prev, curr) {
            return prev.testScore &&
              curr.testScore &&
              prev.testScore[primaryMetric] > curr.testScore[primaryMetric]
              ? prev
              : curr;
          });
          option.legend.selected[better.name] = true;
        }
      }
      if (this.selected) {
        option.legend.selected = this.selected;
      }
      if (testStartDate.valueOf() - trainEndDate.valueOf() > 86400000) {
        const gapStartDate = moment(trainEndDate).add(1, "days").toDate();
        const gapEndDate = moment(testStartDate).add(-1, "days").toDate();
        option.series[0].markArea.data.push([
          {
            itemStyle: {
              color: GAP_BACKGROUND_COLOR,
              opacity: 0.3,
            },
            label: {
              fontWeight: "bold",
              fontSize: 14,
            },
            name: this.$t("label.gap"),
            xAxis: gapStartDate,
          },
          {
            xAxis: gapEndDate,
          },
        ]);
      }

      return option;
    },
  },
  methods: {
    magicTypeChanged(event: any) {
      this.$emit("update:currentType", event.currentType);
    },
    legendSelectChanged(event: any) {
      this.$emit("update:selected", event.selected);
    },
    dataZoom(event: any) {
      if (event.batch) {
        this.$emit("update:zoom", event.batch[0]);
      } else {
        this.$emit("update:zoom", event);
      }
    },
  },
});
