
import { useStore } from "@/store";
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { IMember, WorkspaceRole } from "../types";
import { FilterMatchMode } from "primevue/api";
import { UserProfile } from "@/pages/Identity/types";

export default defineComponent({
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "view",
    },
  },
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  emits: ["update"],
  data(): {
    filters: {
      [key: string]: {
        value: string | null;
        matchMode: string | undefined;
      };
    };
    selectedUsers: Array<any>;
    selectedRole: { [key: string]: string };
  } {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      selectedUsers: [],
      selectedRole: {},
    };
  },
  created() {
    if (this.workspaceDetail && this.mode !== "create") {
      this.selectedUsers = this.users.filter(
        (u: UserProfile) =>
          this.workspaceDetail.userRoleList
            .map((u: IMember) => {
              this.selectedRole[u.userId] = u.role;
              return u.userId;
            })
            .indexOf(u.id) >= 0
      );
    }
    if (!this.selectedRole[this.userId]) {
      this.selectedRole[this.userId] = WorkspaceRole.Admin;
    }
  },
  computed: {
    ...mapGetters("identities", ["getFullname", "getUsername"]),
    ...mapState("workspace", ["workspaceDetail"]),
    ...mapState("identities", ["users"]),
    ...mapState("authentication", ["userId"]),
    newMembers(): IMember[] {
      let members = [
        {
          userId: this.userId,
          role: this.selectedRole[this.userId],
        },
      ];
      for (const user of this.selectedUsers) {
        members.push({
          userId: user.id,
          role: this.selectedRole[user.id],
        });
      }
      return members;
    },
    workspaceRoleList(): Array<string> {
      return [WorkspaceRole.Admin, WorkspaceRole.Member, WorkspaceRole.Viewer];
    },
  },
  methods: {
    removeMember(event: Event, member: IMember) {
      event.preventDefault();
      this.selectedUsers = this.selectedUsers.filter(
        (u) => u.id !== member.userId
      );
      delete this.selectedRole[member.userId];
      this.$emit("update", this.newMembers);
    },
    onRowSelect(event: any) {
      this.selectedRole[event.data.id] = WorkspaceRole.Viewer;
      this.$emit("update", this.newMembers);
    },
    onRowUnselect(event: any) {
      delete this.selectedRole[event.data.id];
      this.$emit("update", this.newMembers);
    },
    onChangeRole(event: any, userId: string) {
      this.selectedRole[userId] = event.value;
      this.$emit("update", this.newMembers);
    },
    onSelectAll(event: any) {
      for (const user of event.data) {
        if (!this.selectedRole[user.id]) {
          this.selectedRole[user.id] = WorkspaceRole.Viewer;
        }
      }
      this.$emit("update", this.newMembers);
    },
    onUnselectAll() {
      this.$emit("update", this.newMembers);
    },
  },
});
