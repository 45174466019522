import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "p-d-inline",
  style: {"flex-grow":"1"}
}
const _hoisted_2 = { class: "p-inputgroup" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = {
  key: 1,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_InputText, {
        value: _ctx.model,
        onInput: _ctx.onInput,
        maxlength: _ctx.maxLength,
        class: { 'p-invalid': _ctx.v$.model.$invalid },
        onKeyup: [
          _withKeys(_ctx.onCancel, ["esc"]),
          _withKeys(_ctx.onAccept, ["enter"])
        ],
        id: "input-name",
        autoFocus: ""
      }, null, 8, ["value", "onInput", "maxlength", "class", "onKeyup"]),
      _createVNode(_component_Button, {
        icon: "pi pi-check",
        onClick: _ctx.onAccept,
        class: "p-button-success p-button-outlined p-inl"
      }, null, 8, ["onClick"]),
      _createVNode(_component_Button, {
        icon: "pi pi-times",
        class: "p-button-danger p-button-outlined",
        onClick: _ctx.onCancel
      }, null, 8, ["onClick"])
    ]),
    (_ctx.v$.model.required.$invalid)
      ? (_openBlock(), _createBlock("small", _hoisted_3, _toDisplayString(_ctx.v$.model.required.$message), 1))
      : _createCommentVNode("", true),
    (_ctx.v$.model.maxLength.$invalid)
      ? (_openBlock(), _createBlock("small", _hoisted_4, _toDisplayString(_ctx.v$.model.maxLength.$message), 1))
      : _createCommentVNode("", true)
  ]))
}