
import { ExperimentType } from "@/pages/Experiment/types";
import { DatasourceType } from "@/types";
import { defineComponent } from "vue";
import { mapActions, mapState, mapMutations } from "vuex";
export default defineComponent({
  props: {
    workspaceId: {
      type: String,
    },
  },
  created() {
    // this.loadRecentExperiments({
    //   createdBy: this.userId,
    // });
    this.loadRecentItems(this.workspaceId);
  },
  computed: {
    ...mapState("experiments", ["experiments"]),
    ...mapState("workspace", ["recentItems"]),
    ...mapState("authentication", ["userId"]),
  },
  methods: {
    ...mapActions("experiments", ["loadRecentExperiments"]),
    ...mapActions("workspace", ["loadRecentItems"]),
    ...mapMutations("workspace", ["CLEAR_RECENT_ITEMS"]),
    getLink(item: any) {
      switch (item.type) {
        case DatasourceType.DATASOURCE:
          return `/workspace/${this.workspaceId}/datasource/${item.id}`;
        case ExperimentType.AutoTS:
        case ExperimentType.AutoML:
          return `/workspace/${
            this.workspaceId
          }/experiment/${item.type.toLowerCase()}/${item.id}`;
      }
    },
  },
  watch: {
    workspaceId(newValue) {
      this.loadRecentItems(newValue);
    },
  },
  unmounted() {
    this.CLEAR_RECENT_ITEMS();
  },
});
