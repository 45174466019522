import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "datasource" }
const _hoisted_2 = { class: "dialog-sticky-header" }
const _hoisted_3 = { class: "p-fluid" }
const _hoisted_4 = { class: "p-col-12 p-p-0 p-d-flex" }
const _hoisted_5 = { class: "p-col-6 p-pl-0" }
const _hoisted_6 = { class: "p-float-label" }
const _hoisted_7 = { for: "name" }
const _hoisted_8 = { class: "p-col-6 p-pr-0" }
const _hoisted_9 = { class: "p-float-label" }
const _hoisted_10 = { for: "description" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "p-error" }
const _hoisted_13 = { class: "p-d-flex p-jc-between" }
const _hoisted_14 = { class: "p-d-flex" }
const _hoisted_15 = { class: "p-m-2 p-mb-6" }
const _hoisted_16 = { class: "p-d-flex p-jc-left" }
const _hoisted_17 = {
  key: 0,
  class: "p-mt-2 p-mr-2"
}
const _hoisted_18 = { for: "explainCheckBox" }
const _hoisted_19 = { class: "p-d-flex p-jc-center p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewPredictionUpload = _resolveComponent("NewPredictionUpload")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_fixed_header = _resolveComponent("fixed-header")!
  const _component_NewPredictionPreview = _resolveComponent("NewPredictionPreview")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: _ctx.$t('automl.prediction.dialog.title'),
    style: { width: '70vw' },
    modal: true,
    "onUpdate:visible": [
      _ctx.handleClose,
      _cache[4] || (_cache[4] = ($event: any) => (_ctx.showDialog = $event))
    ],
    visible: _ctx.showDialog
  }, {
    footer: _withCtx(() => [
      _createVNode("div", _hoisted_16, [
        (_ctx.showExplaination)
          ? (_openBlock(), _createBlock("div", _hoisted_17, [
              _createVNode(_component_Checkbox, {
                id: "explainCheckBox",
                modelValue: _ctx.explaination,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.explaination = $event)),
                binary: true
              }, null, 8, ["modelValue"]),
              _createVNode("label", _hoisted_18, _toDisplayString(_ctx.$t("automl.upload.prediction.runExplaination")), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode("div", _hoisted_19, [
        (_ctx.showUploadButton)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: _ctx.$t('automl.prediction.button.upload'),
              icon: "pi pi-upload",
              class: "p-button-primary p-mt-8",
              disabled: _ctx.disabled || !_ctx.isEnabled,
              onClick: _ctx.onUpload,
              loading: _ctx.uploading
            }, null, 8, ["label", "disabled", "onClick", "loading"]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        (_ctx.step === 0)
          ? (_openBlock(), _createBlock(_component_NewPredictionUpload, {
              key: 0,
              onClicked: _ctx.increaseStep,
              workspaceId: _ctx.workspaceId
            }, null, 8, ["onClicked", "workspaceId"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.step === 1)
        ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_fixed_header, null, {
              default: _withCtx(() => [
                _createVNode("div", _hoisted_2, [
                  _createVNode("div", _hoisted_3, [
                    _createVNode("div", _hoisted_4, [
                      _createVNode("div", _hoisted_5, [
                        _createVNode("span", _hoisted_6, [
                          _createVNode(_component_InputText, {
                            id: "name",
                            type: "text",
                            modelValue: _ctx.name,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.name = $event)),
                            autoFocus: "",
                            maxlength: "64",
                            required: true
                          }, null, 8, ["modelValue"]),
                          _createVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("automl.upload.prediction.name")), 1)
                        ])
                      ]),
                      _createVNode("div", _hoisted_8, [
                        _createVNode("span", _hoisted_9, [
                          _createVNode(_component_InputText, {
                            id: "description",
                            type: "text",
                            modelValue: _ctx.description,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.description = $event)),
                            autoFocus: "",
                            maxlength: "64",
                            required: true
                          }, null, 8, ["modelValue"]),
                          _createVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("automl.upload.prediction.desc")), 1)
                        ])
                      ])
                    ])
                  ]),
                  (_ctx.errorMessage)
                    ? (_openBlock(), _createBlock("div", _hoisted_11, [
                        _createVNode("p", _hoisted_12, _toDisplayString(_ctx.errorMessage), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode("div", _hoisted_13, [
                    _createVNode("div", _hoisted_14, [
                      _createVNode("h6", _hoisted_15, _toDisplayString(_ctx.$t("automl.upload.prediction.preview")), 1),
                      _createVNode(_component_Button, {
                        onClick: _ctx.onClear,
                        label: _ctx.$t('automl.upload.prediction.selectAnotherFile'),
                        class: "p-ml-2 p-button-link"
                      }, null, 8, ["onClick", "label"])
                    ])
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_NewPredictionPreview, {
              onSelectRow: _ctx.showCheckBox,
              onClicked: _ctx.increaseStep
            }, null, 8, ["onSelectRow", "onClicked"])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["header", "onUpdate:visible", "visible"]))
}