import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-d-flex p-jc-center p-ai-center" }
const _hoisted_2 = { class: "p-d-flex p-jc-center p-ai-center" }
const _hoisted_3 = {
  key: 0,
  class: "p-d-flex p-jc-center p-ai-center"
}
const _hoisted_4 = {
  key: 1,
  class: "p-d-flex p-jc-center p-ai-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Row = _resolveComponent("Row")!
  const _component_ColumnGroup = _resolveComponent("ColumnGroup")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.currentBilling,
    responsiveLayout: "scroll",
    scrollable: true,
    scrollHeight: "45rem",
    class: "p-datatable-lg clear-table",
    loading: _ctx.loading
  }, {
    empty: _withCtx(() => [
      _createVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("billing.cost_table.no_data")), 1)
    ]),
    loading: _withCtx(() => [
      _createVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("billing.cost_table.loading")), 1)
    ]),
    footer: _withCtx(() => [
      (!_ctx.loading && _ctx.hasMore)
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode(_component_Button, {
              label: _ctx.$t('button.more'),
              class: "p-button-text",
              onClick: _ctx.loadMore
            }, null, 8, ["label", "onClick"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.loading)
        ? (_openBlock(), _createBlock("div", _hoisted_4, [
            _createVNode(_component_ProgressSpinner, {
              class: "file-progress-spinner",
              strokeWidth: "3",
              animationDuration: ".5s",
              style: {"width":"24px","height":"24px"}
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ColumnGroup, { type: "header" }, {
        default: _withCtx(() => [
          _createVNode(_component_Row, null, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                header: _ctx.$t(`billing.cost_table.header.granularity.${_ctx.granularity}`),
                rowspan: 2
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('billing.cost_table.header.automl'),
                colspan: 2
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('billing.cost_table.header.autots'),
                colspan: 2
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('billing.cost_table.header.experiment'),
                colspan: 2
              }, null, 8, ["header"])
            ]),
            _: 1
          }),
          _createVNode(_component_Row, null, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                header: _ctx.$t('billing.cost_table.header.automl')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('billing.cost_table.header.automl_cost')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('billing.cost_table.header.autots')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('billing.cost_table.header.autots_cost')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('billing.cost_table.header.experiment')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('billing.cost_table.header.experiment_cost')
              }, null, 8, ["header"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "id",
        header: _ctx.$t(`billing.cost_table.header.granularity.${_ctx.granularity}`),
        rowspan: 2
      }, {
        body: _withCtx(({ data: { id } }) => [
          _createTextVNode(_toDisplayString(id), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "automl",
        header: _ctx.$t('billing.cost_table.header.automl')
      }, {
        body: _withCtx(({ data: { automl } }) => [
          _createTextVNode(_toDisplayString(automl), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "automlCost",
        header: _ctx.$t('billing.cost_table.header.automl_cost')
      }, {
        body: _withCtx(({ data: { automlCost } }) => [
          _createTextVNode(_toDisplayString((automlCost || 0).toFixed(2)), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "autots",
        header: _ctx.$t('billing.cost_table.header.autots')
      }, {
        body: _withCtx(({ data: { autots } }) => [
          _createTextVNode(_toDisplayString(autots), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "autotsCost",
        header: _ctx.$t('billing.cost_table.header.autots_cost')
      }, {
        body: _withCtx(({ data: { autotsCost } }) => [
          _createTextVNode(_toDisplayString((autotsCost || 0).toFixed(2)), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "experiment",
        header: _ctx.$t('billing.cost_table.header.experiment')
      }, {
        body: _withCtx(({ data: { experiment } }) => [
          _createTextVNode(_toDisplayString(experiment), 1)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "experimentCost",
        header: _ctx.$t('billing.cost_table.header.experiment_cost')
      }, {
        body: _withCtx(({ data: { experimentCost } }) => [
          _createTextVNode(_toDisplayString((experimentCost || 0).toFixed(2)), 1)
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    _: 1
  }, 8, ["value", "loading"]))
}