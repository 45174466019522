
import { useStore } from "@/store";
import { defineComponent } from "@vue/runtime-core";
import { mapGetters, mapState } from "vuex";
import { Datasource } from "../../types";
import DatasourceFileList from "./DatasourceFileList.vue";
export default defineComponent({
  data(): {
    key_word: string;
  } {
    return { key_word: "" };
  },
  props: {
    datasourceDetail: {
      type: Object as () => Datasource,
    },
    fileListLength: {
      type: Number,
      required: true,
    },
    fileListId: {
      type: String,
      required: true,
    },
    totalSize: {
      type: Number,
    },
    workspaceId: {
      type: String,
      required: true,
    },
  },
  components: {
    DatasourceFileList,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    ...mapState("authentication", ["token"]),
    ...mapGetters("identities", ["getFullname"]),
    ...mapState("datasources", ["loadingFiles"]),
  },
  methods: {
    addFile(): void {
      this.$router.push(
        `/workspace/${this.workspaceId}/datasource/${this.datasourceDetail?.id}/upload`
      );
    },
  },
});
