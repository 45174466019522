export * from "@/pages/Authentication/types";
export * from "@/pages/Datasource/types";
export * from "@/pages/Task/types";
export * from "@/pages/Experiment/automl/types";
// export * from "@/Workspace/";

export interface SettingObjectOf<T> {
  key: keyof T;
  label: string;
}

export interface SettingObject {
  key: string | number;
  label: string;
}
