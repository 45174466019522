import { ExperimentStatus, ExperimentType, IExperiment } from "../types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { State } from "@/store";
import { $http } from "@/main";
import {
  AutoMLState,
  ENSEMBLE_MODEL,
  IModelEvaluationTimeseries,
  IAutoMLEvaluation,
  IAutoMLEvaluationClass,
  IAutoMLModel,
  IApiModelEvaluationTimeseries,
  convertModelAdvancedEvaluationTimeseries,
  IModelPredictionVariance,
  IModelResidual,
} from "./types";
import { SmallestBestKpi } from "../types";
import { IAutoMLPrediction } from "@/types";
import { isNil } from "@/common/utils";
import moment from "moment";
import { ROOT_FOLDER } from "@/common/constant";

const getDefaultState = (): AutoMLState => {
  return {
    experiments: [],
    tokenExperiments: "",
    folderList: [],
    loading: false,
    folderId: "",
    loadingCandidatesTimeSeries: false,
    loadingCandidatesTarget: false,
    candidatesTargetDistinctLength: 0,
    models: [],
    tokenModels: "",
    predictions: [],
    evaluation: [],
    evalClass: [],
    tokenPredictions: "",
    autoMLDetail: {
      id: "abc",
      name: "automl name",
      type: ExperimentType.AutoML,
      tenantId: "tenant",
      status: ExperimentStatus.Completed,
      folderId: ROOT_FOLDER,
    },
    modelEvaluation: {
      loading: false,
      timeseries: [],
    },
    statusEvaluation: "",
    fullEvaluation: [],
    predictionVariance: [],
    residual: [],
  };
};
const AutoMLState: AutoMLState = getDefaultState();

const autoMLMutations: MutationTree<AutoMLState> = {
  SET_FOLDERLIST(state, folderList: Array<IExperiment>) {
    state.folderList = folderList;
  },
  ADD_FOLDER(state, folderObject: IExperiment) {
    state.folderList.push(folderObject);
  },
  DELETE_FOLDER(state, folderID) {
    state.folderList = state.folderList.filter((ds) => ds.id != folderID);
  },
  SET_EXPERIMENTS(state, experiments: Array<IExperiment>) {
    state.experiments = experiments;
  },
  ADD_EXPERIMENT(state, experimentObject: IExperiment) {
    state.experiments.push(experimentObject);
  },
  ADD_EXPERIMENTS(state, experiments: IExperiment[]) {
    state.experiments.push(...experiments);
  },
  DELETE_EXPERIMENT(state, experimentId) {
    state.experiments = state.experiments.filter((ds) => ds.id != experimentId);
  },
  SET_TOKEN_EXPERIMENTS(state, token) {
    state.tokenExperiments = token;
  },
  CLEAR(state) {
    Object.assign(state, getDefaultState());
  },
  SET_FOLDER_ID(state, payload: string) {
    state.folderId = payload;
  },
  SET_LOADING_CANDIDATES_TIMESERIES(state, payload: boolean) {
    state.loadingCandidatesTimeSeries = payload;
  },
  SET_CANDIDATES_TIMESERIES(state, { min, max }: { min: number; max: number }) {
    state.candidatesTimeSeriesMinMax = {
      min: moment(min).toDate(),
      max: moment(max).toDate(),
    };
  },
  SET_LOADING_CANDIDATES_TARGET(state, payload: boolean) {
    state.loadingCandidatesTarget = payload;
  },
  SET_CANDIDATES_TARGET_COUNT(state, payload: number) {
    state.candidatesTargetDistinctLength = payload;
  },
  SET_CANDIDATES_MIN(state, payload: number) {
    state.candidatesTargetMin = payload;
  },
  SET_CANDIDATES_MAX(state, payload: number) {
    state.candidatesTargetMax = payload;
  },
  SET_MODELS(state, models: Array<IAutoMLModel>) {
    if (JSON.stringify(models) != JSON.stringify(state.models)) {
      state.models = models;
    }
  },
  SET_TOKEN_MODELS(state, token: string) {
    state.tokenModels = token;
  },
  ADD_MODELS(state, models: Array<IAutoMLModel>) {
    state.models.push(...models);
  },
  SET_PREDICTIONS(state, predictions: Array<IAutoMLPrediction>) {
    state.predictions = predictions;
  },
  ADD_PREDICTIONS(state, predictions: Array<IAutoMLPrediction>) {
    state.predictions.push(...predictions);
  },
  SET_TOKEN_PREDICTIONS(state, token: string) {
    state.tokenPredictions = token;
  },
  DELETE_PREDICTION(state, predictionId: string) {
    state.predictions = state.predictions.filter((p) => p.id != predictionId);
  },
  SET_EVALUATION(state, evaluation: Array<IAutoMLEvaluation>) {
    state.evaluation = evaluation;
  },
  SET_EVALUATION_CLASS(state, evalClass: Array<IAutoMLEvaluationClass>) {
    state.evalClass = evalClass;
  },
  SET_LOADING_MODEL_EVALUATION(state, loading: boolean) {
    state.modelEvaluation.loading = loading;
  },
  CLEAR_MODEL_EVALUATION(state) {
    state.modelEvaluation = { loading: false, timeseries: [] };
  },
  SET_MODEL_EVALUATION_TIMESERIES(
    state,
    data: Array<IModelEvaluationTimeseries>
  ) {
    state.modelEvaluation.timeseries = data;
  },
  SET_STATUS_EVALUATION(state, data: string) {
    state.statusEvaluation = data;
  },
  SET_FULL_EVALUATION(state, data: Array<any>) {
    state.fullEvaluation = data;
  },
  SET_MODEL_PREDICTION_VARIANCE(state, data: Array<IModelPredictionVariance>) {
    state.predictionVariance = data;
  },
  SET_MODEL_RESIDUAL(state, data: Array<IModelResidual>) {
    state.residual = data;
  },
};

const LIMIT = 100;
const SORT = "createdDate";
const DSC = true;

const autoMLActions: ActionTree<AutoMLState, State> = {
  async loadCandidatesTimeSeries(
    { commit },
    payload: { id: string; timeSeries: string; workspaceId: string }
  ) {
    commit("SET_LOADING_CANDIDATES_TIMESERIES", true);
    try {
      const result = (
        await $http.get<{ min: string; max: string }>(
          `/workers/static/ds-id/${payload.id}/candidates/${payload.timeSeries}/statistics?workspaceId=${payload.workspaceId}`
        )
      ).data;
      commit("SET_CANDIDATES_TIMESERIES", result);
    } catch (error) {
      console.error(error);
    } finally {
      commit("SET_LOADING_CANDIDATES_TIMESERIES", false);
    }
  },
  async loadCandidatesTarget(
    { commit },
    payload: { id: string; target: string; workspaceId: string }
  ) {
    commit("SET_LOADING_CANDIDATES_TARGET", true);
    try {
      const result = (
        await $http.get<{
          count: number;
          min?: number | Date;
          max?: number | Date;
        }>(
          `/workers/static/ds-id/${payload.id}/candidates/${payload.target}/statistics?workspaceId=${payload.workspaceId}`
        )
      ).data;
      commit("SET_CANDIDATES_TARGET_COUNT", result.count);
      if (result.min) {
        commit("SET_CANDIDATES_MIN", result.min);
      }
      if (result.max) {
        commit("SET_CANDIDATES_MAX", result.max);
      }
    } catch (error) {
      console.error(error);
    } finally {
      commit("SET_LOADING_CANDIDATES_TARGET", false);
    }
  },
  async loadModels({ commit }, { experimentId, workspaceId }) {
    try {
      const result = (
        await $http.get<{ content: string[]; token: string }>(
          `/metadata/model/exp-id/${experimentId}?_limit=${LIMIT}&_sort=${SORT}&_dsc=${DSC}&workspaceId=${workspaceId}`
        )
      ).data;
      commit("SET_MODELS", result.content);
      commit("SET_TOKEN_MODELS", result.token);
    } catch (error) {
      console.error(error);
    }
  },
  async loadMoreModels({ state, commit }, { experimentId, workspaceId }) {
    try {
      const result = (
        await $http.get<{ content: IExperiment[]; token: string }>(
          `/metadata/model/exp-id/${experimentId}?_t=${state.tokenModels}&_limit=${LIMIT}&_sort=${SORT}&_dsc=${DSC}&workspaceId=${workspaceId}`
        )
      ).data;
      // this.dispatch(
      //   "identities/loadIdentities",
      //   new Set(result.content.map((x) => x.createdBy))
      // );
      commit("ADD_MODELS", result.content);
      commit("SET_TOKEN_MODELS", result.token);
    } catch (error) {
      console.error(error);
    }
  },
  async loadPredictions(
    { state, commit },
    payload: {
      experimentId: string;
      limit: number;
      name: string;
      loading: boolean;
      workspaceId: string;
    }
  ) {
    try {
      state.loading = payload.loading;
      const result = (
        await $http.get<{ content: IAutoMLPrediction[]; token: string }>(
          `/metadata/prediction/exp-id/${payload.experimentId}?_name=${
            payload.name || ""
          }&_limit=${
            payload.limit ? payload.limit : LIMIT
          }&_sort=${SORT}&_dsc=${DSC}&workspaceId=${payload.workspaceId}`
        )
      ).data;
      result.content?.length > 0 &&
        // this.dispatch(
        //   "identities/loadIdentities",
        //   new Set(result.content.map((x) => x.createdBy))
        // );
        commit("SET_PREDICTIONS", result.content);
      commit("SET_TOKEN_PREDICTIONS", result.token);
    } catch (error) {
      console.error(error);
    } finally {
      state.loading = false;
    }
  },
  async loadMorePredictions(
    { state, commit },
    payload: { experimentId: string; name: string; workspaceId: string }
  ) {
    try {
      const result = (
        await $http.get<{ content: IAutoMLPrediction[]; token: string }>(
          `/metadata/prediction/exp-id/${payload.experimentId}?_name=${
            payload.name || ""
          }&_t=${
            state.tokenPredictions
          }&_limit=${LIMIT}&_sort=${SORT}&_dsc=${DSC}&workspaceId=${
            payload.workspaceId
          }`
        )
      ).data;
      result.content?.length > 0 &&
        // this.dispatch(
        //   "identities/loadIdentities",
        //   new Set(result.content.map((x) => x.createdBy))
        // );
        commit("ADD_PREDICTIONS", result.content);
      commit("SET_TOKEN_PREDICTIONS", result.token);
    } catch (error) {
      console.error(error);
    }
  },
  async deletePrediction({ commit }, { predictionId, workspaceId }) {
    await $http.delete(
      `/metadata/prediction/${predictionId}?workspaceId=${workspaceId}`
    );
    commit("DELETE_PREDICTION", predictionId);
  },
  async loadMiniEvalClass({ commit }, { experimentId, workspaceId }) {
    try {
      const result = (
        await $http.get(
          `/workers/static/exp-id/${experimentId}/mini-eval/list-class?workspaceId=${workspaceId}`
        )
      ).data;
      commit("SET_EVALUATION_CLASS", result);
    } catch (error) {
      console.error(error);
    }
  },
  async loadEvaluationClass(
    { commit },
    { experimentId, evalClass, model, workspaceId }
  ) {
    try {
      const result = (
        await $http.get(
          `/workers/static/exp-id/${experimentId}/mini-eval-class/${evalClass}/${model}/?workspaceId=${workspaceId}`
        )
      ).data;
      commit("SET_EVALUATION", result);
    } catch (error) {
      console.error(error);
    }
  },
  async loadEvaluation({ commit }, { experimentId, model, workspaceId }) {
    try {
      const result = (
        await $http.get(
          `/workers/static/exp-id/${experimentId}/mini-eval/${model}/?workspaceId=${workspaceId}`
        )
      ).data;
      commit("SET_EVALUATION", result);
    } catch (error) {
      console.error(error);
    }
  },

  async loadModelEvaluation(
    { commit },
    {
      experimentId,
      workspaceId,
      model,
      idColumns,
      idFilter,
    }: {
      experimentId: string;
      workspaceId: string;
      model: string;
      idColumns: Array<string>;
      idFilter: Record<string, any>;
    }
  ) {
    commit("SET_LOADING_MODEL_EVALUATION", true);
    try {
      const { data } = await $http.post<Array<IApiModelEvaluationTimeseries>>(
        `/reports/experiment/${experimentId}/kpi?workspaceId=${workspaceId}`,
        { algorithmId: model, keys: idFilter }
      );

      commit(
        "SET_MODEL_EVALUATION_TIMESERIES",
        data.map((id) =>
          convertModelAdvancedEvaluationTimeseries(id, idColumns)
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      commit("SET_LOADING_MODEL_EVALUATION", false);
    }
  },
  async startFullEvaluation(
    { commit },
    {
      experimentId,
      workspaceId,
      modelName,
    }: {
      experimentId: string;
      workspaceId: string;
      modelName: string;
    }
  ) {
    commit("SET_LOADING_MODEL_EVALUATION", true);
    try {
      const startEvaluation = await $http.post<any>(`/metadata/evaluation`, {
        name: "automl_evaluation",
        experimentId: experimentId,
        modelId: modelName + "_" + experimentId,
        modelName: modelName,
        status: "SETTING",
        tenantId: "useeinternal",
        type: "AUTOML_EVALUATION",
        workspaceId: workspaceId,
      });
      const { data } = await $http.post<any>(
        `/workers/job/evaluation-id/${startEvaluation.data.id}/run?workspaceId=${workspaceId}`,
        {}
      );
      commit("SET_STATUS_EVALUATION", data.status);
    } catch (error) {
      console.error(error);
    } finally {
      commit("SET_LOADING_MODEL_EVALUATION", false);
    }
  },
  async loadFullEvaluation(
    { commit },
    {
      experimentId,
      workspaceId,
    }: {
      experimentId: string;
      workspaceId: string;
    }
  ) {
    commit("SET_LOADING_MODEL_EVALUATION", true);
    try {
      const { data } = await $http.get<{ content: Array<any> }>(
        `/metadata/evaluation/exp-id/${experimentId}?_limit=${LIMIT}&workspaceId=${workspaceId}`
      );
      commit("SET_FULL_EVALUATION", data.content);
    } catch (error) {
      console.error(error);
    } finally {
      commit("SET_LOADING_MODEL_EVALUATION", false);
    }
  },
  async loadPredictionVarianceModel(
    { commit },
    {
      experimentId,
      workspaceId,
      modelName,
      featureName,
    }: {
      experimentId: string;
      workspaceId: string;
      modelName: string;
      featureName: string;
    }
  ) {
    commit("SET_LOADING_MODEL_EVALUATION", true);
    try {
      const { data } = await $http.get<Array<any>>(
        `/workers/static/exp-id/${experimentId}/full-eval/${modelName}/${featureName}?workspaceId=${workspaceId}`
      );
      commit("SET_MODEL_PREDICTION_VARIANCE", data);
    } catch (error) {
      console.error(error);
      commit("SET_MODEL_PREDICTION_VARIANCE", []);
    } finally {
      commit("SET_LOADING_MODEL_EVALUATION", false);
    }
  },
  async loadResidualModel(
    { commit },
    {
      experimentId,
      workspaceId,
      modelName,
    }: {
      experimentId: string;
      workspaceId: string;
      modelName: string;
    }
  ) {
    commit("SET_LOADING_MODEL_EVALUATION", true);
    try {
      const { data } = await $http.get<Array<any>>(
        `/workers/static/exp-id/${experimentId}/residual/${modelName}?workspaceId=${workspaceId}`
      );
      commit("SET_MODEL_RESIDUAL", data);
    } catch (error) {
      commit("SET_MODEL_RESIDUAL", []);
      console.error(error);
    } finally {
      commit("SET_LOADING_MODEL_EVALUATION", false);
    }
  },
};

const autoMLGetters: GetterTree<AutoMLState, State> = {
  hasMorePrediction: (state: AutoMLState) => (): boolean => {
    return !isNil(state.tokenPredictions) && state.tokenPredictions.length > 0;
  },
  hasMoreModel: (state: AutoMLState) => (): boolean => {
    return !isNil(state.tokenModels) && state.tokenModels.length > 0;
  },
  sortedModelsList:
    (state: AutoMLState) =>
    (primaryMetric: string, experimentStatus: number): string[] => {
      if (state.models && state.models.length > 0) {
        const models = state.models;
        models.sort((prev, curr) => {
          const scoreType = experimentStatus == 1 ? "testScore" : "valScore";
          if (prev[scoreType] && curr[scoreType]) {
            if (SmallestBestKpi[primaryMetric]) {
              return prev[scoreType][primaryMetric] <
                curr[scoreType][primaryMetric]
                ? -1
                : 1;
            } else {
              return prev[scoreType][primaryMetric] >
                curr[scoreType][primaryMetric]
                ? -1
                : 1;
            }
          }
          return 0;
        });
        // Do task 1554 - If WeightedEnsemble was best model, shows feature importance of largest weight model
        if (models[0].modelType === ENSEMBLE_MODEL) {
          const bestModelName = Object.entries(
            models[0].featureImportance
          ).sort((prev, curr) => {
            return prev[1] > curr[1] ? -1 : 1;
          })[0][0];
          const bestModel = models.find((e) => e.name === bestModelName);
          if (bestModel) {
            const bestModelIndex = models.findIndex(
              (e) => e.name === bestModelName
            );
            models.splice(bestModelIndex, 1);
            models.unshift(bestModel);
          }
        }
        return models
          ?.filter((value) => {
            return (
              value.modelType !== ENSEMBLE_MODEL &&
              value.featureImportance &&
              Object.entries(value.featureImportance).length > 0
            );
          })
          .map((model) => {
            return model.name;
          });
      }
      return [""];
    },
};

export const autoMLModule: Module<AutoMLState, State> = {
  namespaced: true,
  state: AutoMLState,
  getters: autoMLGetters,
  mutations: autoMLMutations,
  actions: autoMLActions,
};
