
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
} from "echarts/components";
import { ref, defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import { GridComponent } from "echarts/components";
import { FileDetail } from "../../types";
import { humanFileSize } from "@/plugin/utils/humanFileSize";
import moment from "moment";

use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  DatasetComponent,
]);

export default defineComponent({
  props: {
    id: {
      type: String,
    },
    workspaceId: {
      type: String,
    },
  },
  data(): {
    option: any;
  } {
    return {
      option: undefined,
    };
  },
  async created() {
    let files: FileDetail[] = await this.getSuccessFiles(
      this.id,
      this.workspaceId
    );
    let accumulatedSize = 0;
    let source = files.map((file) => {
      return {
        createdDate: file.createdDate,
        size: (accumulatedSize += file.size),
      };
    });
    this.option = ref({
      title: {
        show: false,
      },
      tooltip: {
        trigger: "axis",
        formatter: function (params: any) {
          if (params && params.length > 0) {
            let result = `${moment(params[0].data["createdDate"]).format(
              "YYYY-MM-DD HH:mm:ss"
            )}<br />${params[0].marker} ${
              params[0].seriesName
            }: ${humanFileSize(params[0].data["size"])}`;
            return result;
          }
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      dataset: {
        dimensions: ["createdDate", "size"],
        source: source,
      },
      xAxis: {
        type: "time",
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: function (value: number) {
            return humanFileSize(value);
          },
        },
      },
      series: [
        {
          type: "line",
          name: "size",
          smooth: true,
          symbol: "emptyCircle",
          symbolSize: 10,
        },
      ],
    });
  },
  computed: {
    ...mapGetters("datasources", ["getSuccessFiles"]),
    ...mapState("application", ["theme"]),
  },
});
