import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-tag-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("span", _mergeProps({
    class: `p-tag p-component ${_ctx.containerClass}`
  }, _ctx.$attrs), [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createVNode("span", _hoisted_1, _toDisplayString(_ctx.status), 1)
    ])
  ], 16))
}