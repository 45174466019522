
import { defineComponent, ref } from "vue";
import { mapGetters, mapMutations } from "vuex";
export default defineComponent({
  props: {
    expType: {
      type: String,
      required: true,
    },
    workspaceId: {
      type: String,
    },
  },
  setup() {
    const menu = ref("menu");
    return { menu };
  },
  data() {
    return {
      items: [
        {
          label: "AutoTS",
          command: () =>
            this.$router.push(
              `/workspace/${this.workspaceId}/experiment/autots/create`
            ),
        },
        {
          label: "AutoML",
          command: () =>
            this.$router.push(
              `/workspace/${this.workspaceId}/experiment/automl/create`
            ),
        },
      ],
    };
  },
  computed: {
    ...mapGetters("workspace", ["canEdit"]),
  },
  methods: {
    ...mapMutations("experiments", ["CLEAR_CREATE_EXPERIMENT"]),
    createExperiment() {
      this.CLEAR_CREATE_EXPERIMENT();
      this.$router.push(
        `/workspace/${
          this.workspaceId
        }/experiment/${this.expType.toLowerCase()}/create`
      );
    },
    toggle(event: any) {
      (this.menu as any).toggle(event);
    },
  },
});
