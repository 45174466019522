
import { defineComponent } from "@vue/runtime-core";
import { mapState } from "vuex";
import { mapMutations } from "vuex";
import { TypeData } from "../../types";
import { typeData, typeDate } from "@/common/constant";
import { checkIfHasIdInName } from "@/common/utils";

export default defineComponent({
  data() {
    return {
      listValuePreviewData: [],
      previewDataLable: [],
      selectHeader: "",
      typeDate: [] as TypeData[],
    };
  },
  props: {
    workspaceId: {
      type: String,
    },
  },
  computed: {
    ...mapState("datasources", [
      "listRowData",
      "listPreviewData",
      "selectedHeader",
      "listHeader",
      "disabled",
    ]),
  },
  created() {
    if (this.listRowData.length == 0) {
      return this.$router.push({
        path: `/workspace/${this.workspaceId}/datasource/create`,
      });
    }
    this.typeDate = typeDate;
    this.getListValuePreviewData(this.listPreviewData[0]);
    this.selectedHeader = this.selectHeader;
  },
  methods: {
    ...mapMutations("datasources", [
      "SHOW_UPLOAD",
      "RESET_LIST_HEADER_TYPE",
      "RESET_LIST_HEADER_FORMAT",
    ]),
    listDataType(type: string): TypeData[] {
      if ("boolean" === type) {
        return typeData.filter((x) => x.code === type);
      } else {
        return typeData;
      }
    },
    hasNullValue(data: any) {
      let rows: Array<any> = this.listRowData.map((x: any) => {
        return x[data.columns];
      });
      return rows.includes(null) && data.dataType === "boolean";
    },
    getListValuePreviewData(data: any) {
      this.selectHeader = data;
      this.listValuePreviewData = [];
      const listData = [] as any;
      this.listRowData.map((x: any, index: number) => {
        listData.push({ index: index, value: x[data.columns] });
      });
      this.previewDataLable = data.columns;
      this.listValuePreviewData = listData;
    },
    onRowClick(e: any) {
      this.getListValuePreviewData(e.data);
    },
    onDataTypeChange(data: string, index: number) {
      const result = {
        index,
        data,
      };
      this.SHOW_UPLOAD(
        data === "date" && this.listHeader[index].code.format === ""
      );
      this.RESET_LIST_HEADER_TYPE(result);
    },
    onChangeDateFormat(data: string, index: number) {
      const result = {
        index,
        data,
      };
      this.SHOW_UPLOAD(false);
      this.RESET_LIST_HEADER_FORMAT(result);
    },
    checkIfHasIdInName,
  },
});
