import {
  BlobServiceClient,
  BlobUploadCommonResponse,
  BlockBlobParallelUploadOptions,
  ContainerClient,
} from "@azure/storage-blob";

const createBlobInContainer = async (
  containerClient: ContainerClient,
  file: File,
  filename: string,
  onProgress?: (progress: any) => any
) => {
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(filename);

  // set mimetype as determined from browser with file upload control
  const options: BlockBlobParallelUploadOptions = {
    blobHTTPHeaders: { blobContentType: file.type },
    onProgress: (progress) => {
      if (onProgress) {
        onProgress(progress);
      }
    },
  };

  // upload file
  return blobClient.uploadData(file, options);
};

export const uploadFileToBlob = async (
  containerName: string,
  uploadUrl: string,
  uploadPath: string,
  file: File | null,
  onProgress?: (progress: any) => any
): Promise<BlobUploadCommonResponse | undefined> => {
  if (!file) return;
  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(uploadUrl);

  // get Container - full public read access
  const containerClient: ContainerClient =
    blobService.getContainerClient(containerName);

  // upload file
  return createBlobInContainer(containerClient, file, uploadPath, onProgress);
};
