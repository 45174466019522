const units = ["", "k", "M", "G", "T", "P", "E", "Z", "Y"];
/*
yotta	Y	10^24	1000000000000000000000000	septillion
zetta	Z	10^21	1000000000000000000000	sextillion
exa	E	10^18	1000000000000000000	quintillion
peta	P	10^15	1000000000000000	quadrillion
tera	T	10^12	1000000000000	trillion
giga	G	10^9	1000000000	billion	
mega	M	10^6	1000000	million
kilo	k	10^3	1000	thousand
*/

export function toShortNumber(bytes = 0, precision = 2): string {
  if (
    bytes == undefined ||
    isNaN(parseFloat(String(bytes))) ||
    !isFinite(bytes)
  )
    return "--";

  let unit = 0;
  let bytesArg = bytes;
  while (bytesArg >= 1000) {
    bytesArg /= 1000;
    unit += 1;
  }

  return bytesArg.toFixed(+precision) + "" + units[unit];
}

export function toPercentage(value: number, precision = 2): string {
  if (isNaN(parseFloat(String(value))) || !isFinite(value)) return "--";

  return (value * 100).toFixed(+precision) + "%";
}
