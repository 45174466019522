
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      showDialog: this.visible,
    };
  },
  props: {
    visible: Boolean,
    name: String,
  },
  watch: {
    visible(newValue) {
      this.showDialog = newValue;
    },
    showDialog(newValue) {
      this.$emit("update:visible", newValue);
    },
  },
  emits: ["yes", "no", "update:visible", "hide"],
  methods: {
    onClickYes(event: Event) {
      this.$emit("yes", event);
    },
    onClickNo(event: Event) {
      this.$emit("no", event);
      this.onHide(event);
    },
    onHide(event: Event) {
      this.$emit("update:visible", false);
      this.$emit("hide", event);
    },
  },
});
