
import { defineComponent } from "vue-demi";
import { mapActions, mapGetters, mapState, useStore } from "vuex";
import FeatureChart from "./FeatureChart.vue";
import ModelChart from "./ModelChart.vue";
import Summary from "../../../common/components/Summary.vue";
import { convertToSummaryMetric } from "@/pages/Experiment/common/metrics";
import Prediction from "./Prediction.vue";
import EvaluationChart from "./EvaluationChart.vue";
import LeaderBoard from "@/pages/Experiment/automl/components/evaluation/LeaderBoard.vue";
import {
  ChartAxisOption,
  ExperimentStatus,
  IBestKpi,
} from "@/pages/Experiment/types";
import _ from "lodash";
import {
  TimeSeriesIdentifiersCandidate,
  IDatasourceConfig,
} from "../../../types";
import {
  ENSEMBLE_MODEL,
  IAutoMLEvaluation,
  IAutoMLEvaluationClass,
  IAutoMLModel,
} from "../../types";
import { $http } from "@/main";
import ChartSettingsDialog from "@/pages/Experiment/common/components/ChartSettingsDialog.vue";
import { ChartSettings } from "@/pages/Experiment/common/types";

const KPI_BEST_VALUE_BY_MAX = [
  "accuracy",
  "balanced_accuracy",
  "r2",
  "roc_auc",
];

export default defineComponent({
  components: {
    LeaderBoard,
    FeatureChart,
    ModelChart,
    Summary,
    Prediction,
    ChartSettingsDialog,
    EvaluationChart,
  },
  props: {
    id: {
      type: String,
    },
    workspaceId: {
      type: String,
    },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data(): {
    runTableHeaders: string[];
    loading: boolean;
    activeIndex: number;
    timeSeriesIdentifierCandidateOption: TimeSeriesIdentifiersCandidate[][];
    timeSeriesIdentifierCandidateSelectedValue: TimeSeriesIdentifiersCandidate[];
    // summaryMetrics: SummaryMetric[];
    downloading: boolean;
    chartType: string;
    selected: unknown;
    zoom?: unknown;
    chartSettings: ChartSettings;
    selectedModel: string | undefined;
    selectedEvalClass: IAutoMLEvaluationClass | undefined;
  } {
    return {
      runTableHeaders: ["Algorithm", "Status", "KPIs"],
      loading: false,
      activeIndex: 0,
      timeSeriesIdentifierCandidateOption: [],
      timeSeriesIdentifierCandidateSelectedValue: [],
      downloading: false,
      chartType: "line",
      selected: undefined,
      chartSettings: {
        selectedChartAxisOption: ChartAxisOption.CustomRange,
        customChartRange: { min: 0 },
        showQuantile: { upper: false, under: false },
        highlightQuantile: { upper: 80, under: 20 },
      },
      selectedModel: undefined,
      selectedEvalClass: undefined,
    };
  },
  created() {
    if (this.datasourceConfig) {
      this.loadIdcsCandidate({
        datasourceConfig: this.datasourceConfig[0],
        workspaceId: this.workspaceId,
      });
    }
    const timeSeriesIdentifiers =
      this.experimentDetail?.config?.timeSeriesIdentifiers;
    if (
      timeSeriesIdentifiers.length === 0 &&
      this.experimentDetail.config.predictionType === "regression" &&
      this.experimentDetail.config.splitType === "time_series"
    ) {
      this.loadPredictionDataforAutoML({
        id: this.experimentDetail.id,
        payload: {
          keys: {},
          from: this.experimentDetail.config.splitTrainStartDate,
          to: this.experimentDetail.config.splitTestEndDate,
        },
        workspaceId: this.workspaceId,
        isCompleted:
          this.experimentDetail.status === ExperimentStatus.Completed,
      });
    }
    this.loading = this.evaluation.length === 0;
    this.selectedModel = this.listModels[0] || undefined;
    this.selectedEvalClass = this.evalClass[0] || undefined;
  },
  watch: {
    datasourceConfig: {
      handler(newValue: IDatasourceConfig[], oldValue: IDatasourceConfig[]) {
        if (
          newValue.length &&
          JSON.stringify(newValue[0]) != JSON.stringify(oldValue[0])
        ) {
          this.loadIdcsCandidate({
            datasourceConfig: newValue[0],
            workspaceId: this.workspaceId,
          });
          this.loadTargetColumnMinMax({
            workspaceId: this.workspaceId,
            target: newValue[0].targetColumn,
            id: newValue[0].datasourceId,
          });
        }
      },
    },
    timeSeriesIdentifiersCandidates: {
      handler() {
        const idcs = this.datasourceConfig[0]?.timeSeriesIdentifiers;
        if (idcs) {
          this.timeSeriesIdentifierCandidateOption = new Array(idcs.length);
          this.timeSeriesIdentifierCandidateSelectedValue = new Array(
            idcs.length
          );
          idcs.forEach((e: any, i: number) => {
            const candidate = this.getTimeSeriesIdentifiersCandidates(e, "");
            this.timeSeriesIdentifierCandidateOption[i] = candidate;
            this.timeSeriesIdentifierCandidateSelectedValue[i] = candidate[0];
          });
        }
      },
      deep: true,
    },
    timeSeriesIdentifierCandidateSelectedValue: {
      handler(newValue: TimeSeriesIdentifiersCandidate[]) {
        if (!newValue.some((item) => item === undefined)) {
          let payload: { [k: string]: any } = {};
          newValue.forEach((e: TimeSeriesIdentifiersCandidate) => {
            payload[e.idc.toString()] = e.candidate;
          });
          Object.entries(payload).length &&
            this.loadPredictionDataforAutoML({
              id: this.experimentDetail.id,
              payload: {
                keys: payload,
                from: this.experimentDetail.config.splitTrainStartDate,
                to: this.experimentDetail.config.splitTestEndDate,
              },
              workspaceId: this.workspaceId,
              isCompleted:
                this.experimentDetail.status === ExperimentStatus.Completed,
            });
        }
      },
      deep: true,
    },
    experimentDetail(newValue, oldValue) {
      if (
        newValue.status !== oldValue.status &&
        newValue.config.predictionType === "regression" &&
        newValue.config.splitType === "time_series"
      ) {
        let payload: { [k: string]: any } = {};
        if (
          !this.timeSeriesIdentifierCandidateSelectedValue.some(
            (item) => item === undefined
          ) &&
          newValue?.config?.timeSeriesIdentifiers.length > 0
        ) {
          this.timeSeriesIdentifierCandidateSelectedValue.forEach(
            (e: TimeSeriesIdentifiersCandidate) => {
              payload[e.idc.toString()] = e.candidate;
            }
          );
        }
        this.loadPredictionDataforAutoML({
          id: newValue.id,
          payload: {
            keys: payload,
            from: newValue.config.splitTrainStartDate,
            to: newValue.config.splitTestEndDate,
          },
          workspaceId: this.workspaceId,
          isCompleted: newValue.status === ExperimentStatus.Completed,
        });
      }
    },
    listModels(newValue) {
      if (newValue.length) {
        this.selectedModel = newValue[0];
        this.updateEvaluation();
      }
    },
    evalClass(newValue) {
      if (newValue.length) {
        this.selectedEvalClass = newValue[0];
        this.updateEvaluation();
      }
    },
    evaluation() {
      this.loading = false;
    },
  },
  computed: {
    ...mapState("autoML", ["models", "evaluation", "evalClass"]),
    ...mapState("workspace", ["workspaceDetail"]),
    ...mapState("experiments", [
      "experimentDetail",
      "datasourceConfig",
      "datasourceConfigDetail",
      "timeSeriesIdentifiersCandidates",
      "actualData",
      "forecastData",
      "loadingPredictionGraphData",
    ]),
    ...mapState("jobs", ["jobs", "jobTreeTable"]),
    ...mapGetters("experiments", [
      "getTimeSeriesIdentifiersCandidates",
      "targetColumnMinMax",
    ]),
    ...mapGetters("autoML", ["sortedModelsList"]),
    modelEvaluation(): IAutoMLEvaluation[] {
      const evaluation = this.evaluation;
      return evaluation.sort(
        (prev: IAutoMLEvaluation, curr: IAutoMLEvaluation) => {
          return prev.groupOrder < curr.groupOrder ? -1 : 1;
        }
      );
    },
    summaryMetrics() {
      const metrics: string[] = [];
      if (this.experimentDetail && this.experimentDetail.config) {
        if (this.experimentDetail.config.primaryMetric) {
          metrics.push(this.experimentDetail.config.primaryMetric);
        }
        if (this.experimentDetail.config.otherMetrics) {
          metrics.push(...this.experimentDetail.config.otherMetrics);
        }
      }
      return metrics.map(convertToSummaryMetric);
    },
    isTrainingExperiment(): boolean {
      return this.experimentDetail?.status === ExperimentStatus.Training;
    },
    isCompletedExperiment(): boolean {
      return this.experimentDetail?.status === ExperimentStatus.Completed;
    },
    bestKpi() {
      const bestKpi: IBestKpi = {
        [this.experimentDetail.config.primaryMetric]: undefined,
      };
      for (const kpi of this.experimentDetail.config.otherMetrics) {
        bestKpi[kpi] = undefined;
      }
      for (const kpi in bestKpi) {
        let bestValue;
        if (KPI_BEST_VALUE_BY_MAX.includes(kpi)) {
          bestValue = _.maxBy(this.models, `testScore.${kpi}`) as any;
        } else {
          bestValue = _.minBy(this.models, `testScore.${kpi}`) as any;
        }
        if (bestValue) {
          bestKpi[kpi] = {
            value: bestValue?.testScore[kpi],
            algorithmId: bestValue?.name,
          };
        }
      }
      return bestKpi;
    },
    emsembleModel(): IAutoMLModel | null {
      for (const model of this.models) {
        if (model.modelType === ENSEMBLE_MODEL) {
          return model;
        }
      }
      return null;
    },
    experimentStatus(): number {
      switch (this.experimentDetail.status) {
        case ExperimentStatus.Completed:
          return 1;
        case ExperimentStatus.Failed:
        case ExperimentStatus.Canceled:
          return -1;
        default:
          return 0;
      }
    },
    listModels(): string[] {
      return this.sortedModelsList(
        this.experimentDetail?.config?.primaryMetric,
        this.experimentStatus
      );
    },
  },
  methods: {
    ...mapActions("experiments", [
      "loadPredictionDataforAutoML",
      "loadIdcsCandidate",
      "loadTargetColumnMinMax",
    ]),
    ...mapActions("autoML", ["loadEvaluation", "loadEvaluationClass"]),
    toggle(event: any) {
      (this.$refs.op as any).toggle(event);
    },
    toggleChartSettings(event: Event) {
      (this.$refs.chartSettingsOP as any).toggle(event);
    },
    onSaveChartSettings(chartSettings: ChartSettings) {
      this.chartSettings.selectedChartAxisOption =
        chartSettings.selectedChartAxisOption;
      this.chartSettings.customChartRange = {
        ...chartSettings.customChartRange,
      };
      this.chartSettings.highlightQuantile = {
        ...chartSettings.highlightQuantile,
      };
      this.chartSettings.showQuantile = { ...chartSettings.showQuantile };
      this.toggleChartSettings(new Event(""));
    },
    onCancelChartSettings() {
      this.toggleChartSettings(new Event(""));
    },
    async onModelSelect(event: { data: { name: string; id: string } }) {
      (this.$refs.op as any).hide();
      this.downloading = true;
      await this.downloadPredictionModelFile(this.id, event.data.name);
      this.downloading = false;
    },
    async downloadPredictionModelFile(
      expId: string | undefined,
      modelName: string
    ) {
      console.log(`Download model file ${modelName}`);
      try {
        const downloadUrl = await $http.get<string>(
          `/workers/blob/download-prediction-chart-csv/${expId}/${modelName}?workspaceId=${this.workspaceId}`
        );
        window.open(downloadUrl.data, "_blank");
      } catch (error) {
        console.error(error);
      }
    },
    onfilterChanged(idc: string, index: number, event: any) {
      this.timeSeriesIdentifierCandidateOption[index] =
        this.getTimeSeriesIdentifiersCandidates(idc, event.value);
    },
    updateEvaluation() {
      if (this.selectedModel && !this.experimentDetail.config.timeSeries) {
        if (this.experimentDetail.config.predictionType === "regression") {
          this.loadEvaluation({
            experimentId: this.experimentDetail.id,
            model: this.selectedModel,
            workspaceId: this.workspaceId,
          });
        } else if (this.selectedEvalClass) {
          this.loadEvaluationClass({
            experimentId: this.experimentDetail.id,
            evalClass: this.selectedEvalClass.labelDecode,
            model: this.selectedModel,
            workspaceId: this.workspaceId,
          });
        }
      }
    },
    onUpdateModel() {
      this.updateEvaluation();
    },
    onUpdateEvalClass() {
      this.updateEvaluation();
    },
  },
});
