import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "pi pi-info-circle p-mr-4 p-ml-2" }
const _hoisted_5 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_ExperimentStatus = _resolveComponent("ExperimentStatus")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_KpiTag = _resolveComponent("KpiTag")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    removableSort: "",
    responsiveLayout: "scroll",
    loading: _ctx.loading,
    value: _ctx.newData
  }, {
    empty: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("datasource.sample_data.no.data.found")), 1)
    ]),
    loading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("datasource.sample_data.loading.data.please.wait")), 1)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "algorithmId",
        header: _ctx.$t('autots.evaluation.runTable.headers.algorithm'),
        key: "algorithmId",
        sortable: true
      }, {
        body: _withCtx(({ data }) => [
          _createVNode("div", _hoisted_1, [
            _createVNode("span", null, _toDisplayString(_ctx.$t(`${data.algorithmId}`)), 1)
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "startTime",
        header: _ctx.$t('autots.evaluation.runTable.headers.startTime'),
        key: "algorithmId",
        sortable: true
      }, {
        body: _withCtx(({ data }) => [
          (data.startTime)
            ? (_openBlock(), _createBlock("span", _hoisted_2, _toDisplayString(_ctx.$moment(data.startTime).format("LLL")), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "status",
        header: _ctx.$t('autots.evaluation.runTable.headers.status'),
        key: "algorithmId",
        sortable: true
      }, {
        body: _withCtx(({ data }) => [
          _createVNode(_component_ExperimentStatus, {
            status: data.status
          }, null, 8, ["status"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "duration",
        header: _ctx.$t('autots.evaluation.runTable.headers.trainDuration'),
        key: "algorithmId",
        sortable: true
      }, {
        body: _withCtx(({ data }) => [
          (data.duration)
            ? _withDirectives((_openBlock(), _createBlock("span", _hoisted_3, [
                _createTextVNode(_toDisplayString(_ctx.$moment.duration(data.duration, "seconds").humanize()), 1)
              ], 512)), [
                [_directive_tooltip, `${_ctx.$getDuration(data.duration)}`]
              ])
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: _ctx.kpiSortBy,
        key: "algorithmId",
        sortable: true
      }, {
        header: _withCtx(() => [
          _createVNode("span", null, _toDisplayString(_ctx.$t("autots.evaluation.runTable.headers.kpi")), 1),
          _withDirectives(_createVNode("i", _hoisted_4, null, 512), [
            [
              _directive_tooltip,
              _ctx.$t('autots.evaluation.runTable.kpiTooltip'),
              void 0,
              { top: true }
            ]
          ]),
          _createVNode(_component_Dropdown, {
            style: {"width":"7rem"},
            modelValue: _ctx.kpiSortBy,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.kpiSortBy = $event)),
            options: _ctx.kpiSortTypes
          }, {
            value: _withCtx((slotProps) => [
              _createVNode("span", null, _toDisplayString(_ctx.$t(`label.metric.${slotProps.value}`)), 1)
            ]),
            option: _withCtx((slotProps) => [
              _createVNode("span", null, _toDisplayString(_ctx.$t(`label.metric.${slotProps.option}`)), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options"])
        ]),
        body: _withCtx(({ data }) => [
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_KpiTag, {
              name: _ctx.kpiType.sMAE,
              value: data.sMAE,
              best: _ctx.isBest(_ctx.kpiType.sMAE, data.sMAE),
              percentage: true
            }, null, 8, ["name", "value", "best"]),
            _createVNode(_component_KpiTag, {
              name: _ctx.kpiType.MAE,
              value: data.MAE,
              best: _ctx.isBest(_ctx.kpiType.MAE, data.MAE)
            }, null, 8, ["name", "value", "best"]),
            _createVNode(_component_KpiTag, {
              name: _ctx.kpiType.MAEPercent,
              value: data.MAEPercent,
              best: _ctx.isBest(_ctx.kpiType.MAEPercent, data.MAEPercent),
              percentage: true
            }, null, 8, ["name", "value", "best"]),
            _createVNode(_component_KpiTag, {
              name: _ctx.kpiType.MAPE,
              value: data.MAPE,
              best: _ctx.isBest(_ctx.kpiType.MAPE, data.MAPE),
              percentage: true
            }, null, 8, ["name", "value", "best"]),
            _createVNode(_component_KpiTag, {
              name: _ctx.kpiType.RMSE,
              value: data.RMSE,
              best: _ctx.isBest(_ctx.kpiType.RMSE, data.RMSE)
            }, null, 8, ["name", "value", "best"])
          ])
        ]),
        _: 1
      }, 8, ["field"])
    ]),
    _: 1
  }, 8, ["loading", "value"]))
}