
import { DEFAULT_MODEL_LIST, DEFAULT_POST_PROCESSING } from "@/common/constant";
import { SettingObject } from "@/types";
import {
  AUTOTS_ADVANCE_SETTING_FORECAST_RANGE,
  TIME_LIMIT_LIST_AUTOTS,
  YES_NO_SETTING,
} from "@/pages/Experiment/common/constantSettingList";
import { defineComponent } from "@vue/runtime-core";
import { FilterMatchMode } from "primevue/api";

export default defineComponent({
  props: {
    algorithms: {
      default: [],
    },
    autoTsSchemas: {
      default: [],
    },
    selectedAdvancedSettingModel: {
      default: DEFAULT_MODEL_LIST,
    },
    selectedAdvancedSettingPostProcessing: {
      default: DEFAULT_POST_PROCESSING,
    },
    selectedAdvancedSettingLimitTime: {
      default: TIME_LIMIT_LIST_AUTOTS[3],
    },
    selectedAdvancedSettingColumn: {
      default: [],
    },
    opening: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["onSetting"],
  data(): {
    selectedModel: Array<string>;
    selectedPostProcessingTrim?: SettingObject;
    selectedPostProcessingTrimMin?: number;
    selectedPostProcessingTrimMax?: number;
    selectedPostProcessingRound?: SettingObject;
    selectedLimitTime: SettingObject;
    selectedColumn: Array<any>;
    forecastRangeOptions: Array<SettingObject>;
    yesNoOptions: Array<SettingObject>;
    limitTimeList: Array<SettingObject>;
    filters: {
      [key: string]: {
        value: string | null;
        matchMode: string | undefined;
      };
    };
    showFeatureSelection: boolean;
  } {
    return {
      selectedModel: [],
      selectedPostProcessingTrim: AUTOTS_ADVANCE_SETTING_FORECAST_RANGE[0],
      selectedPostProcessingTrimMin: undefined,
      selectedPostProcessingTrimMax: undefined,
      selectedPostProcessingRound: YES_NO_SETTING[1],
      selectedLimitTime: TIME_LIMIT_LIST_AUTOTS[3],
      selectedColumn: [],
      forecastRangeOptions: AUTOTS_ADVANCE_SETTING_FORECAST_RANGE,
      yesNoOptions: YES_NO_SETTING,
      limitTimeList: TIME_LIMIT_LIST_AUTOTS,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      showFeatureSelection: false, // Task 1975
    };
  },
  created() {
    this.reset();
  },
  watch: {
    opening: {
      handler(newVal: boolean) {
        if (newVal) {
          this.reset();
        }
      },
    },
  },
  methods: {
    reset() {
      this.selectedModel = this.selectedAdvancedSettingModel;
      this.selectedPostProcessingTrim =
        AUTOTS_ADVANCE_SETTING_FORECAST_RANGE.find(
          (x: SettingObject) =>
            x.key === this.selectedAdvancedSettingPostProcessing.trimRange.mode
        );
      this.selectedPostProcessingTrimMin =
        this.selectedAdvancedSettingPostProcessing.trimRange.min;
      this.selectedPostProcessingTrimMax =
        this.selectedAdvancedSettingPostProcessing.trimRange.max;
      this.selectedPostProcessingRound = YES_NO_SETTING.find(
        (x: SettingObject) =>
          x.key === String(this.selectedAdvancedSettingPostProcessing.round)
      );
      this.selectedLimitTime = this.selectedAdvancedSettingLimitTime;
      this.selectedColumn = this.selectedAdvancedSettingColumn.length
        ? this.selectedAdvancedSettingColumn
        : this.autoTsSchemas;
    },
    onChangeLimitTime(event: {
      originalEvent: PointerEvent;
      value: SettingObject;
    }) {
      if (event.value != null) {
        this.selectedLimitTime = event.value;
      } else {
        this.selectedLimitTime = TIME_LIMIT_LIST_AUTOTS[3];
      }
    },
    onClickSetting() {
      this.$emit("onSetting", {
        advancedSettingModel: this.selectedModel,
        advancedSettingPostProcessing: {
          round: this.selectedPostProcessingRound?.key === "true",
          trimRange: {
            mode: this.selectedPostProcessingTrim?.key,
            min: this.selectedPostProcessingTrimMin,
            max: this.selectedPostProcessingTrimMax,
          },
        },
        advancedSettingLimitTime: this.selectedLimitTime,
        advancedSettingColumn: this.selectedColumn,
      });
    },
  },
  computed: {
    isDisableSettingButton(): boolean {
      return (
        this.selectedColumn.length === 0 ||
        this.selectedModel.length === 0 ||
        (this.selectedPostProcessingTrim?.key === "manual" &&
          this.selectedPostProcessingTrimMin !== undefined &&
          this.selectedPostProcessingTrimMin !== null &&
          this.selectedPostProcessingTrimMax !== undefined &&
          this.selectedPostProcessingTrimMax !== null &&
          this.selectedPostProcessingTrimMin >=
            this.selectedPostProcessingTrimMax)
      );
    },
  },
});
