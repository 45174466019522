
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  beforeRouteEnter() {
    if (!store.getters["authentication/isCurrentTenantAdmin"]) {
      return { path: "/" };
    }
  },
});
