import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chart = _resolveComponent("v-chart")!

  return (_ctx.actualData)
    ? (_openBlock(), _createBlock(_component_v_chart, {
        key: 0,
        class: "chart",
        option: _ctx.chartOption,
        theme: _ctx.theme,
        autoresize: "",
        onMagictypechanged: _ctx.magicTypeChanged,
        onLegendselectchanged: _ctx.legendSelectChanged,
        onDatazoom: _ctx.dataZoom,
        "init-options": _ctx.initOptions
      }, null, 8, ["option", "theme", "onMagictypechanged", "onLegendselectchanged", "onDatazoom", "init-options"]))
    : _createCommentVNode("", true)
}