
import { defineComponent } from "@vue/runtime-core";
import { useStore } from "@/store";
import DatasourcesList from "./DatasourcesList.vue";
import DsMetadata from "./DsMetadata.vue";
import { DatasourceDetail } from "@/pages/Datasource/types";
import { getIconDataType } from "@/common/helpers";
import { ROOT_FOLDER } from "@/common/constant";

export default defineComponent({
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      selectedDataSource: {} as DatasourceDetail,
      isDataSourceSelected: false as boolean,
    };
  },
  props: {
    folderId: {
      type: String,
      default: ROOT_FOLDER,
    },
    workspaceId: {
      type: String,
    },
  },
  components: {
    DatasourcesList,
    DsMetadata,
  },
  methods: {
    onListDatasourceClickRow({ data }: { data: DatasourceDetail }) {
      this.selectedDataSource = data;
      this.isDataSourceSelected = true;
    },
    onSelectDatasource() {
      this.$emit("datasourceName", this.selectedDataSource.name);
      this.$router.push(
        `${this.$route.path}/${this.selectedDataSource.id}?folderId=${this.folderId}`
      );
    },
    getIconData(dataType: string) {
      const getClass = getIconDataType(dataType);
      return getClass;
    },
  },
});
