
import { useStore } from "@/store";
import { defineComponent } from "@vue/runtime-core";
import { mapActions, mapGetters, mapState } from "vuex";
import { Datasource } from "@/pages/Datasource/types";
export default defineComponent({
  data(): {
    key_word: string;
  } {
    return { key_word: "" };
  },
  props: {
    datasourceDetail: {
      type: Object as () => Datasource,
      required: true,
    },
    workspaceId: {
      type: String,
    },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    ...mapGetters("identities", ["getFullname"]),
    ...mapState("datasources", ["totalFiles", "totalSize"]),
  },
  methods: {
    ...mapActions("datasources", ["loadTotalFiles"]),
  },
  watch: {
    datasourceDetail: {
      handler(newValue: Datasource) {
        this.loadTotalFiles({
          id: newValue.id,
          workspaceId: this.workspaceId,
        });
      },
    },
  },
});
